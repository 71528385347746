import { Card, Col, Row, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React from "react";
import { DebtTabHeader } from "./DebtTabHeader";
import { DebtContact } from "./DebtContact";
import { DebtTarget } from "./DebtTarget";

export interface props {
  onChange: (type: any) => void;
  tabResult: any;
  cifId: string;
  baseURL: string;
  caption: string;
  loading: boolean;
  accountNo: string;
  accessRole: string;
  debtContact: any;
}

export const DebtNoAction: React.FC<props> = ({
  onChange,
  tabResult,
  cifId,
  baseURL,
  caption,
  loading,
  accountNo,
  accessRole,
  debtContact,
}): React.ReactElement => {
  return (
    <div>
      <Card style={{ width: "100%", textAlign: "left" }}>
        <Tabs onChange={onChange} type="card" defaultActiveKey={accountNo.toString()}>
          {tabResult?.map((account: any) => (
            <TabPane tab={account.account_no.toString()} key={account.account_no.toString()} />
          ))}
        </Tabs>
        <DebtTabHeader cifId={cifId} baseURL={baseURL} caption={caption} loading={false} accountNo={accountNo} />
      </Card>
      <Row style={{ marginTop: "10px" }}>
        <Col span={24}>
          <Card style={{ width: "100%", textAlign: "left" }}>
            <DebtContact
              debtContact={debtContact}
              cifId={cifId}
              baseURL={baseURL}
              caption={""}
              loading={false}
              accountNo={accountNo}
              accessRole={accessRole}
            />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <DebtTarget cifId={cifId} baseURL={baseURL} caption={""} loading={false} accountNo={accountNo} />
        </Card>
      </Row>
    </div>
  );
};
