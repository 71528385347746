import React, { useEffect, useState, useRef } from "react";
import {
  // Route,
  // useRouteMatch,
  // Switch,
  // Link,
  useHistory,
  useLocation,
} from "react-router-dom";
// import { Icon } from "@iconify/react";
// import Moment from "moment";
import { CSVLink } from "react-csv";
import {
  Card,
  Row,
  Col,
  // Typography,
  // Collapse,
  // Form,
  // Input,
  // Radio,
  Button,
  DatePicker,
  Select,
  Space,
  Table,
  message,
  Spin,
  Pagination,
} from "antd";
import { EditFilled, BellFilled } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import { DashboardService } from "./services/noti-dashboard-service";
// import { isNull } from "cypress/types/lodash";
// import jwt_decode from "jwt-decode";
// import {
//   IuserProfile,
//   ValueInDetail,
// } from "../datamanagement/models/models-datamanagement";
// import { DatamanagementService } from "../datamanagement/services/datamanagement-service";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import { numberWithCommas, numberWithCommasNodigit } from "../../utils/index";
import { DateStamp } from "../../utils/index";

const appConfig = loadAppConfig();
// const { RangePicker } = DatePicker;
// const { Option } = Select;
// const tailLayout = {
//   wrapperCol: { offset: 1, span: 24 },
// };

// interface CollectorType {
//   id: number;
//   name: string;
// }
// interface ProductType {
//   type: string;
//   code: string;
//   desc: string;
// }
export interface Props {
  baseUrl: string;
  loading: boolean;
}

export const NotificationList: React.FC<Props> = ({ baseUrl }) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const BaseUrl = baseUrl;
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const csvDownload = useRef<any | null>(null);
  const debtService = DashboardService;
  // const { path } = useRouteMatch();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  let ls: any = "";
  ls = location.state;
  let filterContition = "";
  if (ls) {
    if (ls.type == "BANK") {
      filterContition = ls.conditionid;
    }

    if (ls.type == "OA") {
      filterContition = ls.conditionid;
    }
  }
  const [resData, setResData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [filterConditionState, setFilterSupName] = useState(filterContition);
  const [takePage, setTakePage] = useState<number>(10);
  const [skipPage, setSkipPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lengthPage, setLengthPage] = useState<number>(0);
  const csvHeader = [
    { key: "key", label: "No." },
    { key: "assignDate", label: "วันที่ได้รับงาน" },
    { key: "cif", label: "CIF" },
    { key: "customerName", label: "ชื่อลูกหนี้" },
    { key: "nameLoanType", label: "Loan Type" },
    { key: "productDescription", label: "รายละเอียดผลิตภัณฑ์" },
    { key: "accountNo", label: "Account No." },
    { key: "grantTotal", label: "ภาระหนี้ทั้งหมด" },
    { key: "oaName", label: "OA" },
    { key: "actionCodeName", label: "Action Code" },
    { key: "statusCodeName", label: "Status Code" },
    { key: "collector", label: "Collector" },
    { key: "actionDate", label: "วันที่ติดตามล่าสุด" },
  ];
  useEffect(() => {
    async function fetchDataForSearch() {
      getDataTable(1, 10);
    }
    fetchDataForSearch();
  }, [BaseUrl]);
  const changePage = (page: number, take: number) => {
    setCurrentPage(page);
    setTakePage(take);
    getDataTable(page, take);
  };
  const getDataTable = async (page: number, take: number) => {
    setLoading(true);
    let skip = (page - 1) * take;
    setSkipPage(skip);
    let condition = {} as any;
    if (filterConditionState != "") {
      condition = { ...condition, conditionId: filterConditionState };
    }
    condition = { take: take, skip: skip, ...condition };
    let formPost = condition;
    try {
      const count = (await debtService(apiBaseUrlDebt).getCountDebt(formPost)) as any;
      let countData = count[0].count;
      setLengthPage(countData);
      const res = (await debtService(apiBaseUrlDebt).getDebtList(formPost)) as any;
      const data = (await res.map(
        (
          x: {
            account_no: string;
            action_code_name: string;
            assign: string;
            bal: string;
            cif: string;
            custname: string;
            loantype: string;
            productdesc: string;
            status_code_name: string;
            updated_date: string;
            collname: string;
            oaname: string;
          },
          index: number,
        ) => {
          let nameLoantype = "";
          if (x.loantype === "LOAN") {
            nameLoantype = "LOAN";
          } else if (x.loantype === "Card Link") {
            nameLoantype = "CREDIT";
          } else {
            nameLoantype = "OD";
          }
          const mapData = {
            key: index + skip + 1,
            assignDate: x.assign !== "" && x.assign !== null && x.assign !== undefined ? DateStamp(x.assign) : "-",
            cif: x.cif,
            accountNo: x.account_no,
            loantype: x.loantype,
            nameLoanType: nameLoantype,
            productDescription: x.productdesc,
            customerName: x.custname,
            grantTotal: numberWithCommas(x.bal),
            actionCodeName: x.action_code_name,
            statusCodeName: x.status_code_name,
            collector: x.collname,
            actionDate: x.updated_date,
            oaName: x.oaname,
          };
          return mapData;
        },
      )) as any;
      setResData([]);
      setResData(data);
    } catch (err) {
      message.error("ไม่พบข้อมูล");
    }
    setLoading(false);
  };
  const setCsvList = async () => {
    setLoading(true);
    let skip = 0;
    setSkipPage(skip);
    let condition = {} as any;
    if (filterConditionState != "") {
      condition = { ...condition, conditionId: filterConditionState };
    }
    const res1 = await debtService(apiBaseUrlDebt).getCountDebt(condition);
    let datacount: any = 0;
    if (res1.length > 0) {
      datacount = res1[0];
    }
    let cnt = datacount.count;
    condition = { take: cnt, skip: skip, ...condition };
    let formPost = condition;
    try {
      const res = await debtService(apiBaseUrlDebt).getDebtList(formPost);
      const data = await res.map(
        (
          x: {
            account_no: string;
            action_code_name: string;
            assign: string;
            bal: string;
            cif: string;
            custname: string;
            loantype: string;
            productdesc: string;
            status_code_name: string;
            updated_date: string;
            user_id: string;
            supId: string;
            teamId: string;
            oaname: string;
            collname: string;
          },
          index: number,
        ) => {
          let nameLoantype = "";
          if (x.loantype === "LOAN") {
            nameLoantype = "LOAN";
          } else if (x.loantype === "Card Link") {
            nameLoantype = "CREDIT";
          } else {
            nameLoantype = "OD";
          }
          const mapData = {
            key: index + skip + 1,
            assignDate: x.assign !== "" && x.assign !== null && x.assign !== undefined ? DateStamp(x.assign) : "-",
            cif: x.cif,
            accountNo: x.account_no,
            loantype: x.loantype,
            nameLoanType: nameLoantype,
            productDescription: x.productdesc,
            customerName: x.custname,
            grantTotal: numberWithCommas(x.bal),
            actionCodeName: x.action_code_name !== null ? x.action_code_name : "-",
            statusCodeName: x.status_code_name !== null ? x.status_code_name : "-",
            collector: x.collname !== null ? x.collname : "-",
            actionDate:
              x.updated_date !== "" && x.updated_date !== null && x.updated_date !== undefined
                ? dayjs(x.updated_date).locale("th").add(543, "year").format("DD-MM-YYYY")
                : "-",
            supId: x.updated_date,
            teamId: x.updated_date,
            oaName: x.oaname,
          };
          return mapData;
        },
      );
      setCsvData(data);
      setTimeout(() => {
        csvDownload.current.link.click();
        setLoading(false);
      }, 5000);
    } catch (err) {
      message.error("ไม่พบข้อมูล");
    }
  };

  const columns: any = [
    {
      title: "No.",
      // width: 80,
      dataIndex: "key",
      render: (value: string) => numberWithCommasNodigit(value),
    },
    {
      title: "วันที่ได้รับงาน",
      dataIndex: "assignDate",
      key: "assignDate",
      // width: 150,
    },
    {
      title: "CIF",
      dataIndex: "cif",
      key: "cif",
      // width: 150,
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "customerName",
      key: "customerName",
      // width: 300,
    },
    {
      title: "Loan Type",
      dataIndex: "nameLoanType",
      key: "loantype",
      // width: 150,
    },

    {
      title: "รายละเอียดผลิตภัณฑ์",
      dataIndex: "productDescription",
      key: "productDescription",
      // width: 300,
    },

    {
      title: "Account No.",
      dataIndex: "accountNo",
      key: "key",
      // width: 150,
      render: (text: string, record: any, index: number) => {
        return text ? (
          <a key={index} onClick={() => push(`/debtmanagement/acc/${record?.cif}/${text}`)}>
            {text}
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "ภาระหนี้ทั้งหมด",
      dataIndex: "grantTotal",
      key: "grantTotal",
      // width: 150,
      align: "right" as const,
      render: (text: string, record: any, index: number) => (
        <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={""} />
      ),
    },
    {
      title: "OA",
      dataIndex: "oaName",
      key: "oaName",
      // width: 500,
    },
    {
      title: "Action Code",
      dataIndex: "actionCodeName",
      key: "actionCodeName",
      // width: 150,
      render: (event: string) => {
        return event !== null ? event : "-";
      },
    },
    {
      title: "Status Code",
      dataIndex: "statusCodeName",
      key: "statusCodeName",
      // width: 150,
      render: (event: string) => {
        return event !== null ? event : "-";
      },
    },
    {
      title: "Collector",
      dataIndex: "collector",
      key: "collector",
      // width: 200,
      render: (event: string) => {
        return event !== null ? event : "-";
      },
    },
    {
      title: "วันที่ติดตามล่าสุด",
      dataIndex: "actionDate",
      key: "actionDate",
      // width: 150,
      render: (text: string, record: any, index: number) =>
        text !== "" && text !== null && text !== undefined ? dayjs(text).locale("th").add(543, "year").format("DD-MM-YYYY") : "-",
    },
  ];

  const date = new Date();
  let day = dayjs(date).locale("th").add(543, "year").format("YYYYMMDD");

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#FF9500", fontSize: "24px" }}>
            <BellFilled /> {`NOTIFICATION`}
          </b>
        </Card>
      </Row>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={16}>
              <h1 style={{ display: "inline" }}>แสดงข้อมูล</h1>{" "}
            </Col>
            <Col span={8}>
              <Button type="primary" onClick={setCsvList} danger style={{ float: "right", textAlign: "center" }}>
                <Space>
                  <EditFilled /> {`Export CSV`}
                </Space>
              </Button>
              <CSVLink
                filename={`debt-list-${day}.csv`}
                className="btn btn-primary"
                data={csvData}
                headers={csvHeader}
                target="_blank"
                ref={csvDownload}
                hidden={true}
                asyncOnClick={false}
              />
            </Col>
          </Row>

          <Row>
            <Table
              size="small"
              dataSource={resData}
              columns={columns}
              scroll={{ x: "calc(500px + 100%)" }}
              rowKey={(record) => record}
              pagination={false}
              style={{ width: "100%" }}
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={24} style={{ textAlign: "right" }}>
              {lengthPage ? <Pagination defaultCurrent={currentPage} total={lengthPage} onChange={changePage} /> : <></>}
            </Col>
          </Row>
        </Card>
      </Row>
    </Spin>
  );
};
