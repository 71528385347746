import { Card, Col, Row, Image } from "antd";
import React, { useState, useEffect } from "react";
import imageAll from "./../images/GrouAll.png";
import imageLetter from "./../images/ImageLetter.png";
import imageOther from "./../images/ImageOther.png";
import imageContact from "./../images/GroupContact.png";
import imageNocantact from "./../images/GroupNoconcect.png";
import { DashboardService } from "./../services/dashboard-service";
import NumberFormat from "react-number-format";
import { any } from "cypress/types/bluebird";
import { useHistory, useLocation } from "react-router-dom";
import { serviceBackend, serviceBackendDebt } from "../../../drivers/service/axiosBackend";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();
interface Props {
  baseUrl: string;
}

export const ActionContact: React.FC<Props> = ({ baseUrl }) => {
  const rowStyle = {
    fontSize: "10px",
    color: "#000000",
    whiteSpace: "pre-wrap",
  } as any;

  const history = useHistory();

  function getCookie(key: string) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  const decodedCookie = getCookie("id24Token");
  const [dataSourceByActionContact, setDataSourceByActionContact] = useState([]);
  const [dataSourceByActionNoContact, setDataSourceByActionNoContact] = useState([]);
  const [dataSourceByActionLetter, setDataSourceByActionLetter] = useState([]);
  const [dataSourceByActionOther, setDataSourceByActionOther] = useState([]);
  const [dataSourceAll, setDataSourceAll] = useState([]);

  const [dataSumAll, setDataSumAll] = useState([]);
  const [sumAllCheckCnt, setSumAllCheckCnt] = useState(0);
  const [sumAllCheckAmt, setSumAllCheckAmt] = useState(0);
  const [sumCntPer, setSumCntPer] = useState(100);
  const [sumAmtPer, setSumAmtPer] = useState(100);

  const [dataSumContact, setDataSumContact] = useState([]);
  const [sumContactCheckCnt, setSumContactCheckCnt] = useState(0);
  const [sumContactCheckCount, setSumContactCheckCount] = useState(0);

  const [dataSumNoContact, setDataSumNoContact] = useState([]);
  const [sumNoContactCheckCnt, setSumNoContactCheckCnt] = useState(0);
  const [sumNoContactCheckCount, setSumNoContactCheckCount] = useState(0);

  const [dataSumLetter, setDataSumLetter] = useState([]);
  const [sumLetterCheckCnt, setSumLetterCheckCnt] = useState(0);
  const [sumLetterCheckCount, setSumLetterCheckCount] = useState(0);

  const [dataSumOther, setDataSumOther] = useState([]);
  const [sumOtherCheckCnt, setSumOtherCheckCnt] = useState(0);
  const [sumOtherCheckCount, setSumOtherCheckCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  async function sumAll(data: any) {
    let sumAllData: any = [];
    let sumCnt: number = 0;
    let sumAmt: number = 0;
    await data.map((v: any) => {
      sumCnt += v.cnt;
      sumAmt += v.sumamt;
    });
    let all = {
      status: "all",
      sumCnt: sumCnt,
      sumAmt: sumAmt,
    };
    sumAllData.push(all);
    await setSumAllCheckCnt(all.sumCnt);
    await setSumAllCheckAmt(all.sumAmt);
    await setDataSumAll(sumAllData);
  }

  async function sumAllDetail(data: any) {
    let sumAllData: any = [];
    await data.map((v: any) => {
      let sp = (v.sumamt * 100) / sumAllCheckAmt;
      let cp = (v.cnt * 100) / sumAllCheckCnt;
      let all = {
        actionstatus: v.actionstatus,
        sumamt: v.sumamt,
        cnt: v.cnt,
        sumamtPer: sp,
        cntPer: cp,
      };
      sumAllData.push(all);
    });
    await setDataSourceAll(sumAllData);
  }

  async function sumContact(data: any) {
    let sumContactData: any = [];
    let sumCnt: number = 0;
    let sumCount: number = 0;
    data.map((v: any) => {
      sumCnt += v.cnt;
      sumCount += v.count;
    });
    let cop = (sumCnt * 100) / sumAllCheckAmt;
    let cnp = (sumCount * 100) / sumAllCheckCnt;
    let all = {
      status: "contact",
      sumCnt: sumCnt,
      sumCount: sumCount,
      sumCntPer: cnp,
      sumCountPer: cop,
    };
    sumContactData.push(all);
    await setSumContactCheckCnt(sumCnt);
    await setSumContactCheckCount(sumCount);
    await setDataSumContact(sumContactData);
  }

  async function sumContactDetail(data: any) {
    let sumAllData: any = [];
    await data.map((v: any) => {
      let cop = (v.count * 100) / sumAllCheckCnt;
      let cnp = (v.cnt * 100) / sumAllCheckAmt;
      let all = {
        status_name: v.status_name,
        count: v.count,
        cnt: v.cnt,
        countPer: cop,
        cntPer: cnp,
        status_code: v.status_code,
      };
      sumAllData.push(all);
    });
    await setDataSourceByActionContact(sumAllData);
  }

  async function sumNoContact(data: any) {
    let sumNoContactData: any = [];
    let sumCnt: number = 0;
    let sumCount: number = 0;
    data.map((v: any) => {
      sumCnt += v.cnt;
      sumCount += v.count;
    });
    let cop = (sumCnt * 100) / sumAllCheckAmt;
    let cnp = (sumCount * 100) / sumAllCheckCnt;
    let all = {
      status: "noContact",
      sumCnt: sumCnt,
      sumCount: sumCount,
      sumCntPer: cnp,
      sumCountPer: cop,
    };
    sumNoContactData.push(all);
    await setSumNoContactCheckCnt(sumCnt);
    await setSumNoContactCheckCount(sumCount);
    await setDataSumNoContact(sumNoContactData);
  }

  async function sumNoContactDetail(data: any) {
    let sumAllData: any = [];
    await data.map((v: any) => {
      let cop = (v.count * 100) / sumAllCheckCnt;
      let cnp = (v.cnt * 100) / sumAllCheckAmt;
      let all = {
        status_name: v.status_name,
        count: v.count,
        cnt: v.cnt,
        countPer: cop,
        cntPer: cnp,
        status_code: v.status_code,
      };
      sumAllData.push(all);
    });
    await setDataSourceByActionNoContact(sumAllData);
  }

  async function sumLetter(data: any) {
    let sumLetterData: any = [];
    let sumCnt: number = 0;
    let sumCount: number = 0;
    data.map((v: any) => {
      sumCnt += v.cnt;
      sumCount += v.count;
    });
    let cop = (sumCnt * 100) / sumAllCheckAmt;
    let cnp = (sumCount * 100) / sumAllCheckCnt;
    let all = {
      status: "letter",
      sumCnt: sumCnt,
      sumCount: sumCount,
      sumCntPer: cnp,
      sumCountPer: cop,
    };
    sumLetterData.push(all);
    await setSumLetterCheckCnt(sumCnt);
    await setSumLetterCheckCount(sumCount);
    await setDataSumLetter(sumLetterData);
  }

  async function sumLetterDetail(data: any) {
    let sumAllData: any = [];
    await data.map((v: any) => {
      let cop = (v.count * 100) / sumAllCheckCnt;
      let cnp = (v.cnt * 100) / sumAllCheckAmt;
      let all = {
        status_name: v.status_name,
        count: v.count,
        cnt: v.cnt,
        countPer: cop,
        cntPer: cnp,
        status_code: v.status_code,
      };
      sumAllData.push(all);
    });
    await setDataSourceByActionLetter(sumAllData);
  }

  async function sumOther(data: any) {
    let sumOtherData: any = [];
    let sumCnt: number = 0;
    let sumCount: number = 0;
    data.map((v: any) => {
      sumCnt += v.cnt;
      sumCount += v.count;
    });
    let cop = (sumCnt * 100) / sumAllCheckAmt;
    let cnp = (sumCount * 100) / sumAllCheckCnt;
    let all = {
      status: "other",
      sumCnt: sumCnt,
      sumCount: sumCount,
      sumCntPer: cnp,
      sumCountPer: cop,
    };
    sumOtherData.push(all);
    await setSumOtherCheckCnt(sumCnt);
    await setSumOtherCheckCount(sumCount);
    await setDataSumOther(sumOtherData);
  }

  async function sumOtherDetail(data: any) {
    let sumAllData: any = [];
    await data.map((v: any) => {
      let cop = (v.count * 100) / sumAllCheckCnt;
      let cnp = (v.cnt * 100) / sumAllCheckAmt;
      let all = {
        status_name: v.status_name,
        count: v.count,
        cnt: v.cnt,
        countPer: cop,
        cntPer: cnp,
        status_code: v.status_code,
      };
      sumAllData.push(all);
    });
    await setDataSourceByActionOther(sumAllData);
  }

  const toDebt = (data: string) => {
    let all: string = "all";
    if (data === "New") {
      all = "new";
    } else if (data === "Action") {
      all = "action";
    } else {
      all = "noAction";
    }
    history.push("/debtmanagement", all);
  };

  const toDebtContact = (code: string, name: string) => {
    if (code) {
      let all = {
        actionCode: "contact",
        statusCode: code,
        statusName: name,
      };
      history.push("/debtmanagement", all);
    }
  };

  const toDebtNoContact = (data: string) => {
    if (data) {
      let all = {
        actionCode: "noContact",
        statusCode: data,
      };
      history.push("/debtmanagement", all);
    }
  };

  const toDebtLetter = (data: string) => {
    if (data) {
      let all = {
        actionCode: "letter",
        statusCode: data,
      };
      history.push("/debtmanagement", all);
    }
  };

  const toDebtOther = (data: string) => {
    if (data) {
      let all = {
        actionCode: "other",
        statusCode: data,
      };
      history.push("/debtmanagement", all);
    }
  };

  const toDebtAction = (data: string) => {
    if (data) {
      let all = {
        action: data,
      };
      history.push("/debtmanagement", all);
    }
  };

  const toDebtAll = () => {
    let all: string = "all";
    history.push("/debtmanagement", all);
  };

  const { tokenAccess, login, logout, id24Axios } = useId24();

  useEffect(() => {
    async function fetchData() {
      // closeDialog(true);
      const apiCaller = id24Axios(appConfig.apiBaseUrlDebt);
      const resByActionContact: any = await DashboardService(apiCaller).getOaCollectorByAction({
        actionCode: "contact",
      });
      const resByActionNoContact: any = await DashboardService(apiCaller).getOaCollectorByAction({
        actionCode: "noContact",
      });
      const resAll: any = await DashboardService(apiCaller).getOaCollectorAll("");
      const resByActionLetter: any = await DashboardService(apiCaller).getOaCollectorByAction({
        actionCode: "letter",
      });
      const resByActionOther: any = await DashboardService(apiCaller).getOaCollectorByAction({
        actionCode: "other",
      });
      // if (resByActionContact) {
      sumAll(resAll.result);
      // setLoading(true);
      // }

      sumAllDetail(resAll.result);

      sumContact(resByActionContact.result);
      sumContactDetail(resByActionContact.result);

      sumNoContact(resByActionNoContact.result);
      sumNoContactDetail(resByActionNoContact.result);

      sumLetter(resByActionLetter.result);
      sumLetterDetail(resByActionLetter.result);

      sumOther(resByActionOther.result);
      sumOtherDetail(resByActionOther.result);
    }
    fetchData();
  }, [sumAllCheckCnt, sumAllCheckAmt]);

  // console.log(loading);

  return (
    <>
      <Row gutter={8}>
        <div style={{ width: "auto", display: "flex" }}>
          {dataSumAll.map((dataSumAll: any, index: any) => {
            if (dataSumAll.status === "all") {
              return (
                <Col span={8}>
                  <Card style={{ textAlign: "left", borderRadius: "10px" }}>
                    <Row gutter={8}>
                      <Col span={6}>
                        <img src={imageAll} width="50px" />
                      </Col>
                      <Col span={18}>
                        {/* <a onClick={() =>toDebtAll()} style={{ fontSize: "16px", color: "#0056B4" }}> */}
                        <b style={{ fontSize: "15px", color: "#0056B4" }}>{`All`}</b>
                        <Row>
                          <Col span={12}>
                            <Row>
                              <Col span={24} style={rowStyle}>
                                {`A/C `}
                                <NumberFormat
                                  value={dataSumAll.sumCnt}
                                  displayType={"text"}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`BAL `}
                                <NumberFormat
                                  value={dataSumAll.sumAmt}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <Row>
                              <Col span={24} style={rowStyle}>
                                {`%A/C `}
                                <NumberFormat
                                  value={sumCntPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`%BAL `}
                                <NumberFormat
                                  value={sumAmtPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            }
          })}
          {dataSumContact.map((dataSumContact: any, index: any) => {
            if (dataSumContact.status === "contact") {
              return (
                <Col span={8}>
                  <Card style={{ textAlign: "left", borderRadius: "10px" }}>
                    <Row gutter={8}>
                      <Col span={6}>
                        <img src={imageContact} width="50px" />
                      </Col>
                      <Col span={18}>
                        {/* <a onClick={() =>toDebtAction("contact")} style={{ fontSize: "16px", color: "#00B42A" }}> */}
                        <b style={{ fontSize: "15px", color: "#00B42A" }}>{`Contact`}</b>
                        <Row>
                          <Col span={12}>
                            <Row>
                              <Col span={24} style={rowStyle}>
                                {`A/C `}
                                <NumberFormat
                                  value={dataSumContact.sumCount}
                                  displayType={"text"}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`BAL `}
                                <NumberFormat
                                  value={dataSumContact.sumCnt}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <Row>
                              {/* <Col span={24} style={rowStyle}>
                              {dataSumContact.status}
                            </Col> */}
                              <Col span={24} style={rowStyle}>
                                {`%A/C `}
                                <NumberFormat
                                  value={dataSumContact.sumCntPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`%BAL `}
                                <NumberFormat
                                  value={dataSumContact.sumCountPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            }
          })}
          {dataSumNoContact.map((dataSumNoContact: any, index: any) => {
            if (dataSumNoContact.status === "noContact") {
              return (
                <Col span={8}>
                  <Card style={{ textAlign: "left", borderRadius: "10px" }}>
                    <Row gutter={8}>
                      <Col span={6}>
                        <img src={imageNocantact} width="50px" />
                      </Col>
                      <Col span={18}>
                        <b style={{ fontSize: "15px", color: "#DF3C54" }}>{`No Contact`}</b>
                        <Row>
                          <Col span={12}>
                            <Row>
                              <Col span={24} style={rowStyle}>
                                {`A/C `}
                                <NumberFormat
                                  value={dataSumNoContact.sumCount}
                                  displayType={"text"}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`BAL `}
                                <NumberFormat
                                  value={dataSumNoContact.sumCnt}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <Row>
                              {/* <b style={{ fontSize: "16px", color: "#FFFFFF" }}>
                              {dataSumNoContact.status}
                            </b> */}
                              <Col span={24} style={rowStyle}>
                                {`%A/C `}
                                <NumberFormat
                                  value={dataSumNoContact.sumCntPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`%BAL `}
                                <NumberFormat
                                  value={dataSumNoContact.sumCountPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            }
          })}
          {dataSumLetter.map((dataSumLetter: any, index: any) => {
            if (dataSumLetter.status === "letter") {
              return (
                <Col span={8}>
                  <Card style={{ textAlign: "left", borderRadius: "10px" }}>
                    <Row gutter={8}>
                      <Col span={6}>
                        <img src={imageLetter} width="40px" />
                      </Col>
                      <Col span={18}>
                        <b style={{ fontSize: "15px", color: "#5E8BFF" }}>{`Letter`}</b>
                        <Row>
                          <Col span={12}>
                            <Row>
                              <Col span={24} style={rowStyle}>
                                {`A/C `}
                                <NumberFormat
                                  value={dataSumLetter.sumCount}
                                  displayType={"text"}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`BAL `}
                                <NumberFormat
                                  value={dataSumLetter.sumCnt}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <Row>
                              {/* <b style={{ fontSize: "16px", color: "#FFFFFF" }}>
                              {dataSumLetter.status}
                            </b> */}
                              <Col span={24} style={rowStyle}>
                                {`%A/C `}
                                <NumberFormat
                                  value={dataSumLetter.sumCntPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`%BAL `}
                                <NumberFormat
                                  value={dataSumLetter.sumCountPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            }
          })}
          {dataSumOther.map((dataSumOther: any, index: any) => {
            if (dataSumOther.status === "other") {
              return (
                <Col span={8}>
                  <Card style={{ textAlign: "left", borderRadius: "10px" }}>
                    <Row gutter={8}>
                      <Col span={6}>
                        <img src={imageOther} width="50px" />
                      </Col>
                      <Col span={18}>
                        <b style={{ fontSize: "15px", color: "#FF7E35" }}>{`Other`}</b>
                        <Row>
                          <Col span={12}>
                            <Row>
                              <Col span={24} style={rowStyle}>
                                {`A/C `}
                                <NumberFormat
                                  value={dataSumOther.sumCount}
                                  displayType={"text"}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`BAL `}
                                <NumberFormat
                                  value={dataSumOther.sumCnt}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <Row>
                              {/* <b style={{ fontSize: "16px", color: "#FFFFFF" }}>
                              {dataSumOther.status}
                            </b> */}
                              <Col span={24} style={rowStyle}>
                                {`%A/C `}
                                <NumberFormat
                                  value={dataSumOther.sumCntPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                {`%`}
                              </Col>
                              <Col span={24} style={rowStyle}>
                                {`%BAL `}
                                <NumberFormat
                                  value={dataSumOther.sumCountPer}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                />
                                %
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            }
          })}
        </div>
      </Row>
      <Row gutter={8}>
        <div style={{ width: "auto", display: "flex", marginTop: "5px" }}>
          <Col span={8}>
            {dataSourceAll ? (
              dataSourceAll?.map((all: any, index: any) => {
                return (
                  <>
                    <Card
                      style={{
                        textAlign: "left",
                        borderRadius: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <Row gutter={8}>
                        <Col span={24}>
                          <a
                            onClick={() => toDebt(all.actionstatus)}
                            style={{
                              fontSize: "16px",
                              color: "#0056B4",
                              whiteSpace: "pre-wrap",
                              fontWeight: "bold",
                            }}
                          >
                            {all.actionstatus}
                          </a>
                        </Col>
                        <Col span={12} style={rowStyle}>
                          {`A/C `}
                          <NumberFormat value={all.cnt} displayType={"text"} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} />
                        </Col>
                        <Col span={12} style={rowStyle}>
                          {`%A/C `}
                          <NumberFormat value={all.cntPer} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                        </Col>
                        <Col span={12} style={rowStyle}>
                          {`BAL `}
                          <NumberFormat value={all.sumamt} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                        </Col>
                        <Col span={12} style={rowStyle}>
                          {`%BAL `}
                          <NumberFormat
                            value={all.sumamtPer}
                            displayType={"text"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </Col>

          <Col span={8}>
            {dataSourceByActionContact ? (
              dataSourceByActionContact.map((contact: any, index: any) => {
                return (
                  <Card
                    style={{
                      textAlign: "left",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <a
                          onClick={() => toDebtContact(contact.status_code, contact.status_name)}
                          style={{
                            fontSize: "16px",
                            color: "#2CAB40",
                            whiteSpace: "pre-wrap",
                            fontWeight: "bold",
                          }}
                        >
                          {contact.status_name}
                        </a>
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`A/C `}
                        <NumberFormat value={contact.count} displayType={"text"} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%A/C `}
                        <NumberFormat
                          value={contact.countPer}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`BAL `}
                        <NumberFormat value={contact.cnt} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%BAL `}
                        <NumberFormat
                          value={contact.cntPer}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <></>
            )}
          </Col>
          <Col span={8}>
            {dataSourceByActionNoContact ? (
              dataSourceByActionNoContact.map((noContact: any, index: any) => {
                return (
                  <Card
                    style={{
                      textAlign: "left",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <a
                          onClick={() => toDebtNoContact(noContact.status_code)}
                          style={{
                            fontSize: "16px",
                            color: "#DF3C54",
                            whiteSpace: "pre-wrap",
                            fontWeight: "bold",
                          }}
                        >
                          {noContact.status_name}
                        </a>
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`A/C `}
                        <NumberFormat
                          value={noContact.count}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%A/C `}
                        <NumberFormat
                          value={noContact.countPer}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`BAL `}
                        <NumberFormat value={noContact.cnt} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%BAL `}
                        <NumberFormat
                          value={noContact.cntPer}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <></>
            )}
          </Col>
          <Col span={8}>
            {dataSourceByActionLetter ? (
              dataSourceByActionLetter.map((letter: any, index: any) => {
                return (
                  <Card
                    style={{
                      textAlign: "left",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <a
                          onClick={() => toDebtLetter(letter.status_code)}
                          style={{
                            fontSize: "16px",
                            color: "#5E8BFF",
                            whiteSpace: "pre-wrap",
                            fontWeight: "bold",
                          }}
                        >
                          {letter.status_name}
                        </a>
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`A/C `}
                        <NumberFormat value={letter.count} displayType={"text"} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%A/C `}
                        <NumberFormat
                          value={letter.countPer}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`BAL `}
                        <NumberFormat value={letter.cnt} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%BAL `}
                        <NumberFormat value={letter.cntPer} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <></>
            )}
          </Col>

          <Col span={8}>
            {dataSourceByActionOther ? (
              dataSourceByActionOther.map((other: any, index: any) => {
                return (
                  <Card
                    style={{
                      textAlign: "left",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <a
                          onClick={() => toDebtOther(other.status_code)}
                          style={{
                            fontSize: "16px",
                            color: "#FF7E35",
                            whiteSpace: "pre-wrap",
                            fontWeight: "bold",
                          }}
                        >
                          {other.status_name}
                        </a>
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`A/C `}
                        <NumberFormat value={other.count} displayType={"text"} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%A/C `}
                        <NumberFormat
                          value={other.countPer}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Col>

                      <Col span={12} style={rowStyle}>
                        {`BAL `}
                        <NumberFormat value={other.cnt} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                      <Col span={12} style={rowStyle}>
                        {`%BAL `}
                        <NumberFormat value={other.cntPer} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <></>
            )}
          </Col>
        </div>
      </Row>
    </>
  );
};
