import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, message } from "antd";
import React, { useState } from "react";
import { SetPolicy } from "../service/policy.service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const appConfig = loadAppConfig();

export interface props {
  handleLoadingTable: (load: boolean) => void;
}

export const AddPolicy: React.FC<props> = ({ handleLoadingTable }) => {
  const { Option } = Select;
  const [formReset] = Form.useForm();
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    formReset.resetFields();
    setIsModalOpen(false);
  };
  const handlecrete = async (event: any) => {
    if (isNaN(event.policyAmt)) {
      message.warning("โปรดกรอกข้อมูลในส่วนของค่าตอบแทนเป็นตัวเลข");
      return false;
    }

    handleLoadingTable(true);
    const data: any = {
      data: {
        policyCode: event.policyText + event.policyNumber,
        policyDesc: event.policyDesc,
        policyAmt: event.policyAmt,
      },
    };

    Modal.confirm({
      title: "คุณต้องการเพิ่มข้อมูล",
      icon: <ExclamationCircleOutlined />,
      content: "โปรดตรวจสอบให้แน่ใจก่อนที่จะเพิ่มข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        await SetPolicy(apiBaseUrlDebt)
          .createdata(data)
          .then((result) => {
            if (result) {
              message.success("ทำการเพิ่มข้อมูลสำเร็จ");
              formReset.resetFields();
              setIsModalOpen(false);
              handleLoadingTable(false);
            }
          });
      },
      onCancel: () => {
        handleLoadingTable(false);
      },
    });
  };
  return (
    <>
      <Modal title="Add Commission Special" open={isModalOpen} onCancel={handleCancel} footer={false}>
        <Form name={"form"} layout="vertical" onFinish={handlecrete} form={formReset}>
          <Form.Item name={`special`} label={`เงื่อนไขพิเศษ`}>
            <Select defaultValue={"ตามมาตรการ"} placeholder={`กรุณาเลือก..`} allowClear>
              <Option value="ตามมาตรการ">{`ตามมาตรการ`}</Option>
            </Select>
          </Form.Item>
          <Form.Item label={`Policy Code `}>
            <Row>
              <Col span={11}>
                <Form.Item
                  label={`(a-z)`}
                  name={`policyText`}
                  rules={[
                    {
                      required: true,
                      message: "Please input text",
                    },
                  ]}
                >
                  <Input maxLength={1} />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  label={`(1-99)`}
                  name={`policyNumber`}
                  rules={[
                    {
                      required: true,
                      message: "Please input text",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    step="0.01"
                    stringMode
                    maxLength={2}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name={`policyDesc`}
            label={`มาตรการ`}
            rules={[
              {
                required: true,
                message: "Please input text",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={`policyAmt`}
            label={`ค่าตอบแทน`}
            rules={[
              {
                required: true,
                message: "Please input text number",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              step="0.01"
              stringMode
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item style={{ justifyContent: "center", display: "flex" }}>
            <Button type="primary" htmlType="submit">{`ยืนยัน`}</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={showModal}>
        {`Add Policy`}
      </Button>
    </>
  );
};
