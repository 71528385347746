import { Card, Row, Col, Input, message } from "antd";
import React, { useState } from "react";
import { HeadTeamManagement } from "./components/HeadTeamManagement";
import { FilterTeamManagement } from "./components/FilterTeamManagement";
import { TableTeamManagement } from "./components/TableTeamManagement";
// import { Icon } from "@iconify/react";
import { TeamOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export interface Props {
  userId: string;
  accessRole: any;
}

export const TeamDebtManagement: React.FC<Props> = ({ userId, accessRole }) => {
  const [fillterData, setFillterData] = useState({ type: "", text: "" });
  const [reloadTable, setReloadTable] = useState(0);

  const chkReload = (text: string) => {
    if (text === "1") {
      setReloadTable(reloadTable + 1);
    }
  };
  const filter = (type: string, text: string) => {
    if (type === "none" || type === undefined) {
      message.error("กรุณาเลือก Filter ก่อนค้นหา", 5);
    } else {
      setFillterData({ type: type, text: text });
    }
  };
  return (
    <React.Fragment>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            {" "}
            <TeamOutlined
              style={{ width: 36, height: 36, color: "#0056B4" }}
            />{" "}
            TEAM DEBT MANAGEMENT
          </b>
        </Card>
      </Row>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <FilterTeamManagement
              userId={userId}
              filter={filter}
              chkReload={chkReload}
              accessRole={accessRole}
            />
            <TableTeamManagement
              userId={userId}
              fillterData={fillterData}
              reloadTable={reloadTable}
              accessRole={accessRole}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
