import { Card, Row, Col, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FilterBankUser } from "./components/FilterBankUser";
import { TableBankUser } from "./components/TableBankUser";
import { BankOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { BankUserService } from "./services/service";
import { loadAppConfig } from "../../config/app-config";
import { IUser } from "../oaProfile/common";
const appConfig = loadAppConfig();

export interface IDataTable {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  contractName: string;
  contractId: string;
  os: string;
  contractDateStart: string;
  contractDateEnd: string;
  updatedBy: string;
  updatedDate: string;
  address: string;
  subOrganizationProfile: [];
  token: string;
  oaOrganize: string;
  oaCode: string;
}

interface Props {
  roles: string[];
}

export const BankUser: React.FC<Props> = ({ roles }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [organizeBank, setOrganizeBank] = useState<any>([]);
  const [subOrganize, setSubOrganize] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataUsers, setDataUsers] = useState<IDataTable[]>([]);
  const [dataUsersAll, setDataUsersAll] = useState<any[]>([]);
  const [countNoActive, setCountNoActive] = useState<number>(0);
  const [countInActive, setCountInActive] = useState<number>(0);
  const [proFile, setProfile] = useState<IUser>({
    id: 0,
    type: "",
    username: "",
    name: "",
    oaCode: "",
    capabilities: {},
    level: "",
    team: "",
    token: "",
    active: false,
    parentId: "",
    parentNode: "",
    phone: "",
    email: "",
    oaOrganize: "",
    subOrganizationProfile: [],
    extension: "",
  });
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resuserAll = await BankUserService(apiBaseUrlDebt).getUserAll();
    const resProfile = await BankUserService(apiBaseUrlUser).getProfile();
    const resOrganizeBank = await BankUserService(apiBaseUrlUser).getOrganizeBank();
    const resSubOrganize = await BankUserService(apiBaseUrlUser).getSubOrganize();
    const resUser = await BankUserService(apiBaseUrlUser).getUser({ type: "", text: "" });
    const mappingDataUser = resUser?.map((e: any, i: number) => {
      return {
        key: i + 1,
        id: e.id,
        name: e.name,
        email: e.email,
        phone: e.phone,
        contractName: e.contractName,
        contractId: e.contractId,
        os: e.os,
        contractDateStart: e.contractDateStart,
        contractDateEnd: e.contractDateEnd,
        updatedBy: e.updateBy,
        updatedDate: e.updateDate,
        address: e.address,
        subOrganizationProfile: e.subOrganizationProfile,
        token: e.token,
        oaOrganize: e.oaOrganize,
        oaCode: e.oaCode,
        active: e.active,
        updateDate: e.updateDate,
        updateBy: e.updateBy,
      };
    });

    const sortedMappingDataUser = mappingDataUser?.sort((a: any, b: any) => {
      const dateA = new Date(a.updateDate).getTime();
      const dateB = new Date(b.updateDate).getTime();
      return dateB - dateA;
    });
    setDataUsersAll(resuserAll);
    setCountNoActive(resUser.filter((item: any) => item.active !== true).length);
    setCountInActive(resUser.filter((item: any) => item.active === true).length);
    setDataUsers(sortedMappingDataUser);
    setProfile(resProfile[0]);
    setOrganizeBank(resOrganizeBank);
    setSubOrganize(resSubOrganize);
    setLoading(false);
  };

  const onSearch = async (type: string, value: string) => {
    if (type === undefined || (type === "" && value !== "")) {
      message.warning("Please select type");
    } else if (type === undefined || (type === "" && value === "")) {
      shearchFeachData("", "");
    } else {
      shearchFeachData(type, value);
    }
  };

  const syncData = async () => {
    setLoading(true);
    const resSyncData = await BankUserService(apiBaseUrlUser).syncData();
    if (resSyncData) {
      fetchData();
    }
  };

  const shearchFeachData = async (type: string, text: string) => {
    const resUser = await BankUserService(apiBaseUrlUser).getUser({ type: type, text: text });
    const mappingDataUser = resUser?.map((e: any, i: number) => {
      return {
        key: i + 1,
        id: e.id,
        name: e.name,
        email: e.email,
        phone: e.phone,
        contractName: e.contractName,
        contractId: e.contractId,
        os: e.os,
        contractDateStart: e.contractDateStart,
        contractDateEnd: e.contractDateEnd,
        updatedBy: e.updateBy,
        updatedDate: e.updateDate,
        address: e.address,
        subOrganizationProfile: e.subOrganizationProfile,
        token: e.token,
        oaOrganize: e.oaOrganize,
        oaCode: e.oaCode,
        active: e.active,
      };
    });
    setCountNoActive(resUser.filter((item: any) => item.active !== true).length);
    setCountInActive(resUser.filter((item: any) => item.active === true).length);
    setDataUsers(mappingDataUser);
  };

  return (
    <React.Fragment>
      <Spin tip="Loading..." spinning={loading}>
        <Row>
          <Card style={{ width: "100%", textAlign: "left" }}>
            <b style={{ color: "#0056B4", fontSize: "24px" }}>
              <BankOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
              {`Bank User`}
            </b>
          </Card>
        </Row>

        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <FilterBankUser
                proFile={proFile}
                onShearch={onSearch}
                syncData={syncData}
                roles={roles}
                shearchFeachData={shearchFeachData}
                subOrganize={subOrganize}
              />
              <TableBankUser
                proFile={proFile}
                dataUsers={dataUsers}
                setLoading={setLoading}
                countNoActive={countNoActive}
                countInActive={countInActive}
                organizeBank={organizeBank}
                subOrganize={subOrganize}
                fetchData={fetchData}
                dataUsersAll={dataUsersAll}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};
