import React, { useState, useEffect } from "react";
import { Button, Card, Space, Table, Typography, Input, Spin, Form, Modal, message } from "antd";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { BellOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();
import { NotiSettingService } from "./service/service";
import TextArea from "antd/lib/input/TextArea";

interface ITypeNotisetting {
  no?: number;
  createDate: string;
  id: number;
  name: string;
  schema: string;
  typeCondition: string;
  variable: string;
}

interface Props {
  accessRole: string;
}
export const NotificationSetting: React.FC<Props> = ({ accessRole }): React.ReactElement => {
  const [form] = Form.useForm();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getdataAll, setdataAll] = useState<ITypeNotisetting[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    DataResult();
  }, []);

  const showModal = (value: ITypeNotisetting) => {
    form.setFieldValue("id", value.id);
    form.setFieldValue("name", value.name);
    form.setFieldValue("variable", value.variable);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const DataUpdate = (e: ITypeNotisetting) => {
    const data = {
      condition: {
        id: e.id,
        variable: Number(e.variable),
        name: e.name,
      },
    };
    try {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const dataResult = await NotiSettingService(apiBaseUrlDebt).getdataUpdate(data);
          if (dataResult) {
            message.success("ทำการแก้ไขสำเร็จ");
            form.resetFields();
            setIsModalOpen(false);
            DataResult();
          }
        },
      });
    } catch {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const DataResult = async () => {
    setLoading(true);
    try {
      const res = await NotiSettingService(apiBaseUrlDebt).getDataAll();
      const data = res?.map((e: ITypeNotisetting, index: number) => {
        const mapData = {
          no: index + 1,
          createDate: e.createDate,
          id: e.id,
          name: e.name,
          schema: e.schema,
          typeCondition: e.typeCondition,
          variable: Number(e.variable),
        };
        return mapData;
      });
      setdataAll(data);
    } catch {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const Columns: ColumnsType<ITypeNotisetting> = [
    {
      title: "การแจ้งเตือน",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: "10%",
      render: (e: number, row: ITypeNotisetting) => {
        return (
          <>
            <Input key={row.id} style={{ textAlign: "center" }} value={e} disabled readOnly />
          </>
        );
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (e: string, row: ITypeNotisetting) => {
        return (
          <>
            <Input defaultValue={e} key={row.id} value={e} readOnly />
          </>
        );
      },
    },
    {
      title: "จำนวน",
      dataIndex: "variable",
      key: "variable",
      align: "center",
      width: "5%",
      render: (e: string, row: ITypeNotisetting) => {
        return (
          <>
            <Input defaultValue={e} key={row.id} value={e} readOnly />
          </>
        );
      },
    },
    {
      title: "ประเภท",
      dataIndex: "typeCondition",
      key: "typeCondition",
      width: "5%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "center",
      render: (e: number, row: ITypeNotisetting) => {
        return (
          <>
            <Button key={row.id} type="primary" onClick={() => showModal(row)}>
              {`แก้ไข`}
            </Button>
          </>
        );
      },
    },
  ];

  const ColumnsNoAction: ColumnsType<ITypeNotisetting> = [
    {
      title: "การแจ้งเตือน",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: "10%",
      render: (e: number, row: ITypeNotisetting) => {
        return (
          <>
            <Input key={row.id} style={{ textAlign: "center" }} value={e} disabled readOnly />
          </>
        );
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (e: string, row: ITypeNotisetting) => {
        return (
          <>
            <Input defaultValue={e} key={row.id} value={e} readOnly />
          </>
        );
      },
    },
    {
      title: "จำนวน",
      dataIndex: "variable",
      key: "variable",
      align: "center",
      width: "5%",
      render: (e: string, row: ITypeNotisetting) => {
        return (
          <>
            <Input defaultValue={e} key={row.id} value={e} readOnly />
          </>
        );
      },
    },
    {
      title: "ประเภท",
      dataIndex: "typeCondition",
      key: "typeCondition",
      width: "5%",
    },
  ];
  return (
    <>
      <Modal title="แก้ไขข้อมูล" open={isModalOpen} footer={false} onCancel={handleCancel}>
        <Form form={form} layout="vertical" onFinish={DataUpdate}>
          <Form.Item name={"name"} label="รายละเอียด">
            <TextArea showCount maxLength={250} />
          </Form.Item>
          <Form.Item name={"variable"} label="จำนวน">
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              แก้ไข
            </Button>
          </Form.Item>
          <Form.Item name={"id"}>
            <Input readOnly disabled hidden />
          </Form.Item>
        </Form>
      </Modal>
      <Card style={{ width: "100%", marginBottom: "20px" }}>
        <Typography
          style={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(0, 86, 180)",
          }}
        >
          <Space>
            <BellOutlined />
            {"NOTIFICATION SETTING"}
          </Space>
        </Typography>
      </Card>
      <Spin tip="Loading..." indicator={antIcon} spinning={getLoading} size="large">
        <Card title={"เงื่อนไขการแจ้งเตือน"} style={{ textAlign: "left" }}>
          <Table
            columns={checkAccessRole("ddebtSettingNotificationSettingAction") ? Columns : ColumnsNoAction}
            dataSource={getdataAll}
            size={"small"}
            rowKey={(record: ITypeNotisetting) => record.id}
          />
        </Card>
      </Spin>
    </>
  );
};
