import axios, { AxiosInstance } from "axios";
import { type } from "os";
import { loadAppConfig } from "../../../config/app-config";
// import { serviceBackendUser } from "../../../drivers/service/axiosBackend";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export interface IFilter {
  type: string;
  text: string;
}
export interface ITeamService {
  getSubOrganize: () => Promise<string>;
  getBankOrganize: () => Promise<any>;
  createUser: (data: any) => Promise<any>;
  getUser: (fillterData: IFilter) => Promise<any>;
  updateUser: (data: any) => Promise<any>;
  syncData: () => Promise<any>;
  getOrganizeBank: () => Promise<string>;
  getrolesBytoken: (token: string) => Promise<any>;
  getProfile: () => Promise<any>;
  getUserAll: () => Promise<any>;
}

export const BankUserService = (serviceBackendUser: any): ITeamService => ({
  getProfile: async () => {
    const result = await serviceBackendUser.get("/profile/user");
    return result?.data || {};
  },
  syncData: async () => {
    const result = await serviceBackendUser.post("/oa/sync/users");
    return result?.data || {};
  },
  getSubOrganize: async () => {
    const result = await serviceBackendUser.post("/bank/profile/subGroups", {});
    return result?.data || {};
  },
  getOrganizeBank: async () => {
    const result = await serviceBackendUser.get("/bank/organize");
    return result?.data || {};
  },
  getBankOrganize: async () => {
    const result = await serviceBackendUser.post("/bank/organize/roles", {});
    return result?.data || {};
  },
  createUser: async (data: any) => {
    const result = await serviceBackendUser.post("/Bank/user/create", data);
    return result?.data || {};
  },
  updateUser: async (data: any) => {
    const result = await serviceBackendUser.put("/bank/user/update", data);
    return result?.data || {};
  },
  getUser: async (filter: IFilter | {}) => {
    const result = await serviceBackendUser.post("/bank/user", filter);
    return result?.data || {};
  },
  getrolesBytoken: async (token) => {
    const result = await serviceBackendUser.get(`/bank/organize/${token}`);
    return result?.data || {};
  },
  getUserAll: async () => {
    const result = await serviceBackendUser.get("/debtor/getUserAll");
    return result?.data.result || {};
  },
});
