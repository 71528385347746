import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Dropdown, Form, Image, Layout, Menu, Modal, Result, Row, Space, Select, Typography, Tooltip } from "antd";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  CaretDownOutlined,
  // DashboardOutlined,
  // MenuFoldOutlined,
  // MenuUnfoldOutlined,
  UserOutlined,
  FileDoneOutlined,
  SnippetsFilled,
  SettingFilled,
  ProfileFilled,
  // FileAddFilled,
  TeamOutlined,
  DashboardFilled,
  DatabaseFilled,
  EditFilled,
  BellFilled,
  MailOutlined,
  UserSwitchOutlined,
  BankOutlined,
  ApartmentOutlined,
  TagsOutlined,
  SolutionOutlined,
  FolderOutlined,
  ProfileOutlined,
  WifiOutlined,
  FieldTimeOutlined,
  BellOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
  PhoneFilled,
} from "@ant-design/icons";
// import { Icon } from "@iconify/react";
import "./styles/space.css";
import { UserRole } from "../common-auth";
import jwt_decode from "jwt-decode";
import { MainMenu, MainMenuItem } from "./components/MainMenu";
import { MainRoute, MenuItem } from "./MainRoute";

import logo from "./images/ddebt_logo.770c74362dd43d7f83df.png";
import { OverviewDashboard } from "../screens/dashboard/OverviewDashboard";
import { OverviewNotiDashboard } from "../screens/notificationDashboard/OverviewNotiDashboard";
import { loadAppConfig } from "../config/app-config";
import { OverviewSettingModal } from "./components/OverviewSettingModal";
import { DataManagementRoute } from "../screens/datamanagement/DataManagementRoute";
import { LeadManagementRoute } from "../screens/leadmanagement/LeadManagementRoute";
import { DebtmanagementImport } from "../screens/datamanagementImport/DatamanagementImport";
import { TeamManagement } from "../screens/teamManagement/TeamManagement";
import { OaProfile } from "../screens/oaProfile/OaProfile";
import { DebtManagement } from "../screens/debtManagement/DebtManagement";
import { TeamDebtManagement } from "../screens/teamdebtmanagement/TeamDebtManagement";
import { useId24 } from "../drivers/id24/Id24-provider";
import { DataManageMentRouteOa } from "../screens/datamanagementoa/DataManageMentRouteOa";
import { ImporTrargetInFormationRoute } from "../screens/importmanagement/ImportManagementRoute";
import { OaOrganize } from "../screens/oaOrganize/OaOrganize";
import { BankOrganize } from "../screens/bankOrganize/BankOrganize";
import { OaUser } from "../screens/oaUser/OaUser";
import { BankUser } from "../screens/bankUser/BankUser";
// import { Notification } from "../screens/notification/Notification";
import { BatchProcessJob } from "../screens/batchProcessJob/BatchProcessJob";
import { BatchProcessFile } from "../screens/batchProcessFile/BatchProcessFile";
import { BatchProcessDailyFile } from "../screens/batchProcessDailyFile/BatchProcessDailyFile";
import { SetSessionTimeOut } from "../screens/setSessionTimeOut/SetSessionTimeOut";
import { TimeSetting } from "../screens/timesetting/TimeSetting";
import EmailRoute from "../screens/emailtemplate/EmailRoute";
import { useCookies } from "react-cookie";
import { NotificationSetting } from "../screens/NotificationSetting/NotificationSetting";
import { PolicyMain } from "../screens/policy/PolicyMain";
const { Header, Sider, Content, Footer } = Layout;
import { NotificationSection } from "notification-widget";
import Box from "./styles/Box";
import { NotificationList } from "../screens/notificationDashboard/Notification";
import { LeadDebtManagementRoute } from "../screens/leaddebtmanagement/LeadDebtManagementRoute";
import { Broadcast } from "../screens/broaadcast/Broadcast";
import { MainTaskImport } from "../screens/debtManagement/taskimport/MainTaskImport";
import { MainService } from "./services/service";

export const MainLayout: React.FC = (): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const appConfig = loadAppConfig();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const { Option } = Select;
  // const history = useHistory();
  // const [tokenIdTo, setTokenIdTo, removeTokenIdTo] = useCookies(["id24Token"]);
  // const [tokenRe, setTokenRe, removeTokenRe] = useCookies(["refresh-token"]);
  // const [tokenIdSes, setTokenSes, removeTokenIdSes] = useCookies(["id24Session"]);
  const [roles, setRoles] = useState<any>([]);
  const [extension, setExtension] = useState<string>("");
  const [sub, setSub] = useState<string | any>("");
  const [dateExpired, setDateExpired] = useState<any>([]);
  const [statusModel, setStatusModel] = useState<boolean>(false);
  const [accountNo, setAccountNo] = useState<string>("");
  const [Iconcolor, setIconcolor] = useState<{
    colorAstatus: string;
    colorPstatus: string;
    colorDialstatus: string;
  }>({
    colorAstatus: "#32CD32",
    colorPstatus: "#32CD32",
    colorDialstatus: "#32CD32",
  });

  const [Textstatus, setTextstatus] = useState<{
    textAstatus: string;
    textPstatus: string;
    textDialstatus: string;
  }>({
    textAstatus: "Idle",
    textPstatus: "Idle",
    textDialstatus: "Idle",
  });
  const [collapse, setCollapse] = React.useState(false);
  const [isModalVisibleResetPassword, setIsModalVisibleResetPassword] = useState(false);
  const [isModalVisibleProfile, setIsModalVisibleProfile] = useState(false);
  const [isModalVisibleOverviewSetting, setIsModalVisibleOverviewSetting] = useState(false);
  const [alias, setAlias] = React.useState<any>({
    name: "unknown",
    empId: "unknown",
    group: "unknown",
    email: "unknown",
  });

  function getCookie(key: string) {
    let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  const hasRole = (userRoles: UserRole[]): boolean => {
    const inputRoles = new Set(userRoles);
    const union = new Set(roles.filter((role: any) => inputRoles.has(role)));
    return union.size > 0;
  };

  const checkAccessRole = (role: string) => {
    if (role) {
      let result = roles.indexOf(role) > -1;
      if (result == true) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleMouseMove = (event: any) => {
    let processSessionTimeout: string | undefined = getCookie("processSessionTimeout");
    if (!processSessionTimeout) {
      processSessionTimeout = "5";
    }
    let conProcessSessionTimeout = Number(processSessionTimeout) - 1;
    const dIf = new Date();
    dIf.setTime(dIf.getTime() + +conProcessSessionTimeout * 60 * 1000);
    document.cookie = "autoTimeOut=" + dIf.toUTCString() + ";expires=" + dIf.toUTCString() + ";path=/";
  };

  useEffect(() => {
    (async () => {
      let datacon: any = [];
      if (tokenAccess) {
        await tokenAccess.userAccess.map((groupId) => {
          groupId.roles.forEach(function (value, i) {
            datacon.push(value);
          });
        });
      }
      const uniqueNames = datacon.filter((val: any, id: any, array: any) => {
        return array.indexOf(val) == id;
      });
      setRoles(uniqueNames);
    })();
  }, []);

  const menuItems: MenuItem[] = [
    {
      label: "",
      icon: <DashboardFilled />,
      path: "/",
      exact: true,
      showInMenu: false,
      target: "/",
      roles: [UserRole.ddebtDashboard],
      component: <Redirect to="/dashboard" />,
    },
    {
      label: "Dashboard",
      icon: <DashboardFilled />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtDashboard],
      component: <OverviewDashboard baseUrl={"/dashboard"} />,
    },
    {
      label: "Team Management",
      icon: <TeamOutlined />,
      path: "/teamManagement",
      showInMenu: true,
      target: "/teamManagement",
      roles: [UserRole.ddebtTeamManagement],
      component: <TeamManagement userId={"1"} accessRole={roles} />,
    },
    {
      label: "Data Management",
      icon: <DatabaseFilled />,
      path: "/datamanagement",
      showInMenu: true,
      target: "/datamanagement",
      roles: [UserRole.ddebtDataManagement],
      component: <DataManagementRoute baseUrl={"/datamanagement"} accessRole={roles} />,
    },
    {
      label: "Lead Management",
      icon: <EditFilled />,
      path: "/lead_management",
      showInMenu: true,
      target: "/lead_management",
      roles: [UserRole.ddebtLeadManagement],
      component: <LeadManagementRoute accessRole={roles} userprofile={alias} />,
    },
    {
      label: "Notification",
      icon: <BellFilled />,
      path: "/notification-dashboard",
      showInMenu: true,
      target: "/notification-dashboard",
      roles: [UserRole.ddebtNotificationDashboard],
      component: <OverviewNotiDashboard baseUrl={"/notification-dashboard"} />,
    },
    {
      label: "Debt Management",
      icon: <SnippetsFilled />,
      path: "/maindebtmanagement",
      showInMenu: true,
      target: "/maindebtmanagement",
      roles: [UserRole.ddebtDebtManagement],
      component: <DebtManagement userId={"1"} baseURL={"/maindebtmanagement"} accessRole={roles} />,
      subMenu: [
        {
          key: "1",
          label: "Debt",
          icon: <SnippetsFilled />,
          path: "/debtmanagement",
          showInMenu: true,
          target: "/debtmanagement",
          roles: [UserRole.ddebtDebtManagementDebt],
          component: <DebtManagement userId={"1"} baseURL={"/debtmanagement"} accessRole={roles} />,
        },
        {
          key: "2",
          label: "Import",
          icon: <FileDoneOutlined />,
          path: "/datamanagementimport",
          showInMenu: true,
          target: "/datamanagementimport",
          roles: [UserRole.ddebtDebtManagementImport],
          component: <DebtmanagementImport />,
        },
        {
          key: "3",
          label: "Team",
          icon: <TeamOutlined />,
          path: "/debtTeammanagement",
          showInMenu: true,
          target: "/debtTeammanagement",
          roles: [UserRole.ddebtDebtManagementTeam],
          component: <TeamDebtManagement userId={"1"} accessRole={roles} />,
        },
        {
          key: "4",
          label: "Data Management",
          icon: <DatabaseFilled />,
          path: "/debtdatamanagement/datamanagement",
          showInMenu: true,
          target: "/debtdatamanagement/datamanagement",
          roles: [UserRole.ddebtDebtManagementData],
          component: <DataManageMentRouteOa baseUrl={"/debtdatamanagement/datamanagement"} />,
        },
        {
          key: "5",
          label: "Lead Management",
          icon: <EditFilled />,
          path: "/debtleadmanagement/leadmanagement",
          showInMenu: true,
          target: "/debtleadmanagement/leadmanagement",
          roles: [UserRole.ddebtDebtManagementLead],
          component: <LeadDebtManagementRoute baseUrl={"/debtleadmanagement/leadmanagement"} accessRole={roles} dataResult={alias} />,
        },
        {
          key: "6",
          label: "Task Import&Export",
          icon: <FileTextOutlined />,
          path: "/debt/taskimport",
          showInMenu: true,
          target: "/debt/taskimport",
          roles: [UserRole.ddebtDebtManagementDebt],
          component: <MainTaskImport />,
        },
      ].filter((menu) => hasRole(menu.roles)),
    },
    {
      label: "Import Management",
      icon: <FileDoneOutlined />,
      path: "/importmanagement",
      showInMenu: true,
      target: "/importmanagement",
      roles: [UserRole.ddebtImportTargetInformation],
      component: <ImporTrargetInFormationRoute baseUrl="/importmanagement" />,
    },
    {
      label: "Notification",
      icon: <FileDoneOutlined />,
      path: "/notification-list",
      showInMenu: false,
      target: "/notification-list",
      roles: [UserRole.ddebtNotificationDashboardList],
      component: <NotificationList loading={true} baseUrl={"/notification-list"} />,
    },
    {
      label: "Setting",
      icon: <SettingFilled />,
      path: "/setting",
      showInMenu: true,
      target: "/setting",
      roles: [UserRole.ddebtSetting],
      component: <OaProfile userId={"1"} accessRole={roles} />,
      subMenu: [
        {
          key: "1",
          label: "OA Profile",
          icon: <UserSwitchOutlined />,
          path: "/oaProfile",
          showInMenu: true,
          target: "/oaProfile",
          roles: [UserRole.ddebtSettingOaProfile],
          component: <OaProfile userId={"1"} accessRole={roles} />,
        },
        {
          key: "2",
          label: "OA Organize",
          icon: <ApartmentOutlined />,
          path: "/oaOganize",
          showInMenu: true,
          target: "/oaOganize",
          roles: [UserRole.ddebtSettingOaOrganize],
          component: <OaOrganize userId={"1"} />,
        },
        {
          key: "3",
          label: "OA User",
          icon: <BankOutlined />,
          path: "/oaUser",
          showInMenu: true,
          target: "/oaUser",
          roles: [UserRole.ddebtSettingOaUser],
          component: <OaUser roles={roles} />,
        },
        {
          key: "4",
          label: "Bank User",
          icon: <BankOutlined />,
          path: "/bankUser",
          showInMenu: true,
          target: "/bankUser",
          roles: [UserRole.ddebtSettingBankUser],
          component: <BankUser roles={roles} />,
        },
        {
          key: "5",
          label: "Bank Organize",
          icon: <ApartmentOutlined />,
          path: "/bankOrganize",
          showInMenu: true,
          target: "/bankOrganize",
          roles: [UserRole.ddebtSettingBankOrganize],
          component: <BankOrganize userId={"1"} />,
        },
        {
          key: "7",
          label: "Job Process",
          icon: <TagsOutlined />,
          path: "/batchProcessJob",
          showInMenu: true,
          target: "/batchProcessJob",
          roles: [UserRole.ddebtSettingBatchProcessJob],
          component: <BatchProcessJob accessRole={roles} />,
        },
        {
          key: "8",
          label: "Daily Process",
          icon: <SolutionOutlined />,
          path: "/batchProcessDaily",
          showInMenu: true,
          target: "/batchProcessDaily",
          roles: [UserRole.ddebtSettingBatchProcessDaily],
          component: <BatchProcessDailyFile accessRole={roles} userId={"1"} />,
        },
        {
          key: "9",
          label: "File Process",
          icon: <FolderOutlined />,
          path: "/batchProcessFile",
          showInMenu: true,
          target: "/batchProcessFile",
          roles: [UserRole.ddebtSettingBatchProcessFile],
          component: <BatchProcessFile userId={"1"} accessRole={roles} />,
        },
        {
          key: "10",
          label: "Email Template",
          icon: <MailOutlined />,
          path: "/emailtemplate",
          showInMenu: true,
          target: "/emailtemplate",
          roles: [UserRole.ddebtSettingEmailtemplate],
          component: <EmailRoute userId={"1"} baseUrl="/emailtemplate" />,
        },
        {
          key: "11",
          label: "Set Session Timeout",
          icon: <ProfileOutlined />,
          path: "/sessiontimeout",
          showInMenu: true,
          target: "/sessiontimeout",
          roles: [UserRole.ddebtSettingSetSessionTimeout],
          component: <SetSessionTimeOut userId={"1"} accessRole={roles} />,
        },

        {
          key: "12",
          label: "Broadcast",
          icon: <WifiOutlined />,
          path: "/broadcast",
          showInMenu: true,
          target: "/broadcast",
          roles: [UserRole.ddebtSettingBroadcast],
          component: <Broadcast />,
        },
        {
          key: "13",
          label: "Time Set Use",
          icon: <FieldTimeOutlined />,
          path: "/timeset",
          showInMenu: true,
          target: "/timeset",
          roles: [UserRole.ddebtSettingTime],
          component: <TimeSetting accessRole={roles} />,
        },
        {
          key: "14",
          label: "Notification Setting",
          icon: <BellOutlined />,
          path: "/notificationsetting",
          showInMenu: true,
          target: "/notificationsetting",
          roles: [UserRole.ddebtSettingNotificationSetting],
          component: <NotificationSetting accessRole={roles} />,
        },
        {
          key: "15",
          label: "Policy",
          icon: <FileDoneOutlined />,
          path: "/policy",
          showInMenu: true,
          target: "/policy",
          roles: [UserRole.ddebtSettingPolicy],
          component: <PolicyMain accessRole={roles} />,
        },
      ].filter((menu) => hasRole(menu.roles)),
    },
  ].filter((menu) => hasRole(menu.roles));

  // const toggleCollapsed = () => {
  //   setCollapse(!collapse);
  // };

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  const onClick = async ({ key }: any) => {
    switch (key) {
      case "logout":
        const dIf = new Date();
        dIf.setTime(dIf.getTime() + -1 * 24 * 60 * 60 * 1000);
        document.cookie = "id24Token=0;expires=" + dIf.toUTCString() + ";path=/";
        document.cookie = "refresh-token=0;expires=" + dIf.toUTCString() + ";path=/";
        document.cookie = "autoTimeOut=0;expires=" + dIf.toUTCString() + ";path=/";
        let n = sessionStorage.length;
        while (n--) {
          let key: any = sessionStorage.key(n);
          if (/foo/.test(key)) {
            sessionStorage.removeItem(key);
          }
        }
        await localStorage.clear();
        await deleteAllCookies();
        logout().then(() => {
          login(window.location.origin + "/");
        });
        break;
      default:
    }
  };

  const handleOkResetPassword = () => {
    setIsModalVisibleResetPassword(false);
  };

  const handleCancelResetPassword = () => {
    setIsModalVisibleResetPassword(false);
  };

  const showModalProfile = () => {
    setIsModalVisibleProfile(true);
  };

  const handleOkProfile = () => {
    setIsModalVisibleProfile(false);
  };

  const handleCancelProfile = () => {
    setIsModalVisibleProfile(false);
  };

  const createMainMenuItem = (subMenu: MenuItem): MainMenuItem => ({
    label: subMenu.label,
    icon: subMenu.icon,
    path: subMenu.path,
  });

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="profile" onClick={showModalProfile}>
        Profile
      </Menu.Item>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  const handleClickMenu = (values: any) => {
    let host = window.location.host;
    let baseUrl = host;
    if (host === "localhost:3000") {
      baseUrl = "https://user.ddebtuat.gsb.or.th";
      // baseUrl = "https://id24-demo.osd.co.th";
      window.location.href = baseUrl + "/" + values.value;
    } else {
      window.location.href = "/" + values.value;
    }
  };

  useEffect(() => {
    const groupNameList: any[] = [];
    if (tokenAccess) {
      tokenAccess.userAccess.map((group: any) => {
        groupNameList.push(group.groupName);
      });
    }
    let headerConfig = {
      headers: { Authorization: `ee670412-bb71-4c7a-b928-9158c5d2176d` },
    };
    const fetchData = async () => {
      // const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
      // const profile = await apiBaseUrlUser.get(`/profile/user`);
      // setSub(profile.data[0].token);
      // var host = window.location.host;
      // let baseUrl = host;
      // if (host === "localhost:3000") {
      //   baseUrl = "https://user.ddebtuat.gsb";
      // } else {
      //   baseUrl = appConfig.apiBaseUrlDebtId24;
      // }
      // const apiCaller = id24Axios(baseUrl);
      // const result = await apiCaller.get(
      //   `/backend/api/v1/users/${profile.data[0].token}`,
      //   headerConfig
      // );
      // setAlias({
      // name: result.data.username,
      //   email: result.data.email,
      //   empId: result.data.id,
      //   group: String(groupNameList),
      // });
      const userProfile = id24Axios(appConfig.apiBaseUrlDebt);
      const profile = await userProfile.get("/debtor/userDetails");
      const dataResult = profile?.data?.result;
      await setSub(dataResult.token);
      await setAlias({
        name: dataResult.name,
        email: dataResult.email,
        empId: dataResult.id,
        group: String(groupNameList),
        oaCode: dataResult.token,
      });

      let tokenExpired = {
        userid: dataResult?.token,
      };
      const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
      const expiredDateS = await apiBaseUrlUser.post("/profile/getuserbyid", tokenExpired);
      setDateExpired(expiredDateS?.data?.result);
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const getStatusPhone = async () => {
      const checkColorphone = async (status: string, accountNo: string) => {
        let color = "" as string;
        if (status === "Idle") {
          color = "#32CD32";
        } else if ((status === "Ringing" || status === "RingInuse" || status === "InUse" || status === "Hold") && accountNo !== "") {
          color = "#FFAE42";
        } else {
          color = "#DC143C";
        }
        return color;
      };
      const checkTextphone = async (status: string, accountNo: string) => {
        let text = "" as string;
        if (status === "Idle") {
          text = "Ready";
        } else if ((status === "Ringing" || status === "RingInuse" || status === "InUse" || status === "Hold") && accountNo !== "") {
          text = "Ringing";
        } else {
          text = "Other";
        }
        return text;
      };
      const dataStatusPhone = await MainService(apiBaseUrlDebt).getStatusPhone(extension);
      checkColorphone(dataStatusPhone?.aStatus, dataStatusPhone?.refNo);
      const Astatuscolor = await checkColorphone(dataStatusPhone?.aStatus, dataStatusPhone?.refNo);
      const Pstatuscolor = await checkColorphone(dataStatusPhone?.pStatus, dataStatusPhone?.refNo);
      const Dialstatuscolor = await checkColorphone(dataStatusPhone?.dialPhone, dataStatusPhone?.refNo);
      const AstatusText = await checkTextphone(dataStatusPhone?.aStatus, dataStatusPhone?.refNo);
      const PstatusText = await checkTextphone(dataStatusPhone?.pStatus, dataStatusPhone?.refNo);
      const DialstatusText = await checkTextphone(dataStatusPhone?.dialPhone, dataStatusPhone?.refNo);
      const dataColor: any = {
        colorAstatus: Astatuscolor,
        colorPstatus: Pstatuscolor,
        colorDialstatus: Dialstatuscolor,
      };
      const dataText: any = {
        textAstatus: AstatusText,
        textPstatus: PstatusText,
        textDialstatus: DialstatusText,
      };
      setIconcolor(dataColor);
      setTextstatus(dataText);
      if (PstatusText === "Ringing" || PstatusText === "RingInuse" || PstatusText === "InUse" || PstatusText === "Hold") {
        setAccountNo(dataStatusPhone?.refNo);
        if (!dataStatusPhone?.refNo) {
          return;
        } else {
          localStorage.setItem("accountNo", dataStatusPhone?.refNo);
          Modal.confirm({
            title: "ต้องการไปที่หน้ารายละเอียดลูกหนี้หรือไม่ ?",
            icon: <ExclamationCircleOutlined />,
            content: "กดยืนยันเพื่อปที่หน้ารายละเอียดลูก",
            okText: "ยืนยัน",
            cancelText: "ยกเลิก",
            onOk: async () => {
              window.location.href = `${window.location.origin}/debtmanagement/acc/${dataStatusPhone?.refNo}`;
            },
            onCancel: async () => {
              setStatusModel(false);
            },
          });
        }
      }
    };
    if (checkAccessRole("checkringphone")) {
      getStatusPhone();
    }
    getDetailUser();
  }, [extension, statusModel, accountNo]);

  const getDetailUser = async () => {
    // const resultUserDetails: any = await MainService(apiBaseUrlDebt).getDetailUser();
    // const intervalId = setInterval(() => {
    //   setExtension(resultUserDetails?.result?.extension);
    // }, 5000);
    // return () => clearInterval(intervalId);
    const resultUserDetails: any = await MainService(apiBaseUrlDebt).getDetailUser();
    setExtension(resultUserDetails.result.extension);
    const intervalId = setInterval(() => {
      setExtension((prevExtension) => {
        return resultUserDetails.result.extension;
      });
    }, 5000);
    return () => clearInterval(intervalId);
  };

  return (
    <>
      <Box>
        <div className="mouseArea" onKeyPress={handleMouseMove} onMouseMove={handleMouseMove}>
          <OverviewSettingModal isVisible={isModalVisibleOverviewSetting} handleCancel={() => setIsModalVisibleOverviewSetting(false)} />
          <Router>
            <Modal title="Profile" open={isModalVisibleProfile} onOk={handleOkProfile} onCancel={handleCancelProfile} footer={false}>
              <Row>
                <Col span={24}>Full Name : {alias.name ? alias?.name : "-"}</Col>
                <Col span={24}>Email : {alias?.email ? alias?.email : "-"}</Col>
                <Col span={24}>Roles : {alias?.group ? alias?.group : "-"}</Col>
              </Row>
            </Modal>
            <Modal title="Reset password" open={isModalVisibleResetPassword} onOk={handleOkResetPassword} onCancel={handleCancelResetPassword}>
              <p>Reset your password</p>
            </Modal>
            <Layout hasSider>
              <Sider
                trigger={null}
                collapsible
                collapsed={collapse}
                style={{
                  backgroundColor: "white",
                  overflowX: "hidden",
                  overflowY: "auto",
                  height: "100vh",
                  position: "fixed",
                  left: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                <Row className="logo">
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Image preview={false} src={logo} style={{ padding: "20%" }} />
                  </Col>
                </Row>
                <MainMenu
                  inlineCollapsed={collapse}
                  items={menuItems
                    ?.filter((m) => m.showInMenu)
                    .map(({ label, icon, target, exact, subMenu }) => ({
                      label,
                      icon,
                      path: target,
                      exact,
                      subMenu: subMenu && subMenu.filter((menu) => menu.showInMenu).map((menu: MenuItem) => createMainMenuItem(menu)),
                    }))}
                />
              </Sider>
              <Layout className="site-layout" style={{ paddingLeft: 200 }}>
                <Header
                  className="header"
                  style={{
                    backgroundColor: "white",
                    padding: 2,
                    textAlign: "left",
                    minHeight: "80px",
                    height: "auto",
                  }}
                >
                  <Row style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Col style={{ textAlign: "left" }}>
                      <Select labelInValue placeholder="Select Menu" style={{ width: 140 }} bordered={false} onChange={handleClickMenu}>
                        {checkAccessRole("ddebtModule") ? <Option value="dashboard">Ddebt</Option> : ""}
                        {checkAccessRole("reportModule") ? <Option value="report">Report</Option> : ""}
                        {checkAccessRole("commissionModule") ? <Option value="commission">Commission</Option> : ""}
                        {checkAccessRole("notificationModule") ? <Option value="notification">Notification</Option> : ""}
                      </Select>
                    </Col>
                    <Col
                      style={{
                        marginRight: 5,
                        alignItems: "center",
                        flex: 1,
                        textAlign: "right",
                        width: "100%",
                      }}
                    >
                      <Space>
                        <div id="notification-widget">
                          <NotificationSection uid={sub} url={appConfig.apiBaseUrlNoti} ws={appConfig.notiUrl} />
                        </div>
                        <Avatar size="large" icon={<UserOutlined />} />
                        {checkAccessRole("checkringphone") ? (
                          <Tooltip placement="bottom" title={`${Textstatus?.textPstatus}`}>
                            <Avatar
                              size="large"
                              style={{
                                backgroundColor: `${Iconcolor?.colorPstatus}`,
                              }}
                              icon={<PhoneFilled />}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                        <div>
                          <Col
                            span={24}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              height: "20px",
                              textAlign: "left",
                              fontSize: "16px",
                              width: "100%",
                            }}
                          >
                            {alias.name}
                          </Col>
                          <Col
                            span={24}
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                              textAlign: "left",
                              fontSize: "10px",
                              color: "#979797",
                              width: "100",
                            }}
                          >
                            {alias.group}
                          </Col>
                        </div>
                      </Space>
                    </Col>
                    <Col style={{ marginRight: 20 }}>
                      <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                          <CaretDownOutlined />
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                </Header>
                <Content
                  className="site-layout-background"
                  style={{
                    paddingBottom: 24,
                    paddingLeft: 24,
                    paddingRight: 24,
                    margin: "0px 16px",
                    minHeight: "80vh",
                    overflow: "initial",
                  }}
                >
                  <MainRoute menuItems={[...menuItems]} />
                </Content>
                <Footer style={{ textAlign: "center", color: "rgba(0,0,0,.45)" }}>
                  <p>D-Debt | Version: {appConfig.ddebtVersion}</p>
                  <p>Copyright ©2022 OSD</p>
                </Footer>
              </Layout>
            </Layout>
          </Router>
        </div>
      </Box>
    </>
  );
};
