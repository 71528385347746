import React, { useState, useEffect } from "react";
import { Card, Row, Col, Collapse, Tag, Avatar, Spin } from "antd";
import { DownCircleOutlined, LoadingOutlined, RightCircleOutlined, UserOutlined } from "@ant-design/icons";
import { DashboardService } from "./services/dashboard-service";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();

interface Props {
  baseUrl: string;
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;

export const DashboardManagerOa: React.FC<Props> = ({ baseUrl }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const frontStyleCard = {
    textAlign: "left",
    fontSize: "12px",
  } as any;

  const fontStyleHerder = {
    fontSize: "18px",
    color: "#FFFFFF",
  } as any;

  const fontStyleTap = {
    fontSize: "14px",
    borderRadius: "10px",
  } as any;

  const frontStyleInCard = {
    textAlign: "left",
    fontSize: "10px",
  } as any;

  const history = useHistory();

  // function getCookie(key: string) {
  //   var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  //   return b ? b.pop() : "";
  // }

  // const decodedCookie = getCookie("id24Token");
  const [dataSourceNew, setDataSourceNew] = useState([]);
  const [dataSourceAction, setDataSourceAction] = useState([]);
  const [dataSourceNoAction, setDataSourceNoAction] = useState([]);

  const onChange = (key: string | string[]) => {
    if (evenExtra == false) {
      setEvenextra(true);
    } else {
      setEvenextra(false);
    }
  };

  const { id24Axios } = useId24();

  // function integrateDashboardNew(data: any) {
  //   let listNew: any = [];
  //   let supList: any = [];
  //   let dataSource = [];
  //   data.map((x1: any) => {
  //     if (x1.actionstatus === "new") {
  //       let list = {
  //         listname: x1.listname,
  //         collid: x1.collid,
  //         collname: x1.collname,
  //         cnt: x1.cnt,
  //         sumamt: x1.sumamt,
  //         sumcurbal: x1.sumcurbal,
  //         sumos: x1.sumos,
  //         sumstmtamt: x1.sumstmtamt,
  //       };
  //       listNew.push(list);

  //       let team = {
  //         supname: x1.supname,
  //         supid: x1.supid,
  //         listname: x1.listname,
  //       };
  //       supList.push(team);
  //     }
  //   });
  //   const groupByList = (listNew: any) =>
  //     Object.values(
  //       listNew.reduce(
  //         (
  //           data: any,
  //           {
  //             listname,
  //             collid,
  //             collname,
  //             cnt,
  //             sumamt,
  //             sumcurbal,
  //             sumstmtamt,
  //             sumos,
  //           }: any,
  //         ) => {
  //           if (data[listname]) {
  //             let oaDetails = {
  //               collid: collid,
  //               collname: collname,
  //               cnt: cnt,
  //               sumamt: sumamt,
  //               sumcurbal: sumcurbal,
  //               sumstmtamt: sumstmtamt,
  //               sumos: sumos,
  //             };
  //             data[listname].userlist.push(oaDetails);
  //           } else {
  //             let oaDetails = {
  //               collid: collid,
  //               collname: collname,
  //               cnt: cnt,
  //               sumamt: sumamt,
  //               sumcurbal: sumcurbal,
  //               sumstmtamt: sumstmtamt,
  //               sumos: sumos,
  //             };
  //             data[listname] = { listname, userlist: [oaDetails] };
  //           }
  //           return data;
  //         },
  //         {},
  //       ),
  //     );

  //   const groupByTeamsForCheck = (supList: any) =>
  //     Object.values(
  //       supList.reduce((data: any, { supname, listname }: any) => {
  //         if (data[supname]) {
  //           if (
  //             !data[supname].list.find((listcheck: any) => {
  //               return listcheck.listname === listname;
  //             })
  //           ) {
  //             let userList = groupByList(listNew).find(
  //               (list: any) => list.listname === listname,
  //             );
  //             const list = {
  //               listname: listname,
  //               userList: userList,
  //             };
  //             data[supname].list.push(list);
  //           }
  //         } else {
  //           let userList = groupByList(listNew).find(
  //             (list: any) => list.listname === listname,
  //           );
  //           const list = {
  //             listname: listname,
  //             userList: userList,
  //           };
  //           data[supname] = { supname, list: [list] };
  //         }

  //         return data;
  //       }, {}),
  //     );

  //   const groupByTeams = (supList: any) =>
  //     Object.values(
  //       supList.reduce((data: any, { supname, supid, listname }: any) => {
  //         if (data[supname]) {
  //           if (
  //             !data[supname].list.find((listcheck: any) => {
  //               return listcheck.listname === listname;
  //             })
  //           ) {
  //             let userList: any = groupByList(listNew).find(
  //               (list: any) => list.listname === listname,
  //             );
  //             let ac = 0;
  //             let os = 0;
  //             let bal = 0;
  //             let cur = 0;
  //             let stmt = 0;
  //             userList.userlist.map((item: any) => {
  //               ac += item.cnt;
  //               os += item.sumos;
  //               bal += item.sumamt;
  //               cur += item.sumcurbal;
  //               stmt += item.sumstmtamt;
  //             });
  //             const list = {
  //               listname: listname,
  //               userList: userList,
  //               ac: ac,
  //               os: os,
  //               bal: bal,
  //               cur: cur,
  //               stmt: stmt,
  //             };
  //             data[supname].list.push(list);
  //           }
  //         } else {
  //           let userList: any = groupByList(listNew).find(
  //             (list: any) => list.listname === listname,
  //           );
  //           let ac = 0;
  //           let os = 0;
  //           let bal = 0;
  //           let cur = 0;
  //           let stmt = 0;
  //           userList.userlist.map((item: any) => {
  //             ac += item.cnt;
  //             os += item.sumos;
  //             bal += item.sumamt;
  //             cur += item.sumcurbal;
  //             stmt += item.sumstmtamt;
  //           });
  //           const list: any = {
  //             listname: listname,
  //             userList: userList,
  //             ac: ac,
  //             os: os,
  //             bal: bal,
  //             cur: cur,
  //             stmt: stmt,
  //           };

  //           let acTeam: number = 0;
  //           let osTeam = 0;
  //           let balTeam = 0;
  //           let stmtTeam = 0;
  //           let curTeam = 0;
  //           let supListCeck: any = groupByTeamsForCheck(supList).find(
  //             (team: any) => team.supname === supname,
  //           );
  //           supListCeck.list.map((oaCount: any) => {
  //             oaCount.userList.userlist.map((oa: any) => {
  //               acTeam += oa.cnt;
  //               osTeam += oa.sumos;
  //               balTeam += oa.sumamt;
  //               stmtTeam += oa.sumstmtamt;
  //               curTeam += oa.sumcurbal;
  //             });
  //           });
  //           data[supname] = {
  //             supname,
  //             supid: supid,
  //             list: [list],
  //             ac: acTeam,
  //             os: osTeam,
  //             bal: balTeam,
  //             cur: curTeam,
  //             stmt: stmtTeam,
  //           };
  //         }
  //         return data;
  //       }, {}),
  //     );
  //   const newObject = {
  //     statusName: "new",
  //     supList: groupByTeams(supList),
  //   };
  //   dataSource.push(newObject);
  //   return dataSource;
  // }
  function integrateDashboardNew(data: any) {
    let listNew: any = [];
    let supList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionstatus === "new") {
        let list = {
          listname: x1.listname,
          collid: x1.collid,
          collname: x1.collname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
          supname: x1.supname,
          manid: x1.manid,
        };
        listNew.push(list);
        let team = {
          supname: x1.supname,
          supid: x1.supid,
          listname: x1.listname,
          manid: x1.manid,
        };
        supList.push(team);
      }
    });
    const groupBySup = listNew.reduce((group: any, groupList: any) => {
      const { supname, listname } = groupList;
      const getGroup = group.find((x: any) => x.supname === supname && x.listname === listname);
      if (getGroup) {
        getGroup.userlist.push(groupList);
      } else {
        let listGroup = {
          supname,
          listname: listname,
          userlist: [groupList],
        };
        group.push(listGroup);
      }
      return group;
    }, []);
    const groupByTeamsForCheck = (supList: any) =>
      Object.values(
        supList.reduce((data: any, { supname, listname }: any) => {
          if (data[supname]) {
            if (
              !data[supname].list.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let userlist = groupBySup.find((list: any) => {
                return list.listname === listname && list.supname === supname;
              });
              const list = {
                listname: listname,
                userlist: userlist,
              };
              data[supname].list.push(list);
            }
          } else {
            let userlist = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
            const list = {
              listname: listname,
              userlist: userlist,
            };
            data[supname] = { supname, list: [list] };
          }
          return data;
        }, {})
      );
    const groupByTeams = (supList: any) =>
      Object.values(
        supList.reduce((data: any, { supname, supid, listname, manid }: any) => {
          if (data[supname]) {
            if (
              !data[supname].list.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let userlist: any = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
              let ac = 0;
              let os = 0;
              let bal = 0;
              let cur = 0;
              let stmt = 0;
              userlist.userlist.map((item: any) => {
                ac += item.cnt;
                os += item.sumos;
                bal += item.sumamt;
                cur += item.sumcurbal;
                stmt += item.sumstmtamt;
              });
              const list = {
                listname: listname,
                userlist: userlist,
                manid: manid,
                ac: ac,
                os: os,
                bal: bal,
                cur: cur,
                stmt: stmt,
              };
              data[supname].list.push(list);
            }
          } else {
            let userlist: any = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
            let ac = 0;
            let os = 0;
            let bal = 0;
            let cur = 0;
            let stmt = 0;
            userlist.userlist.map((item: any) => {
              // console.log();

              ac += item.cnt;
              os += item.sumos;
              bal += item.sumamt;
              cur += item.sumcurbal;
              stmt += item.sumstmtamt;
            });
            const list: any = {
              listname: listname,
              userlist: userlist,
              manid: manid,
              ac: ac,
              os: os,
              bal: bal,
              cur: cur,
              stmt: stmt,
            };
            let acTeam: number = 0;
            let osTeam = 0;
            let balTeam = 0;
            let stmtTeam = 0;
            let curTeam = 0;
            let supListCeck: any = groupByTeamsForCheck(supList).find((team: any) => team.supname === supname);
            supListCeck.list.map((oaCount: any) => {
              oaCount.userlist.userlist.map((oa: any) => {
                acTeam += oa.cnt;
                osTeam += oa.sumos;
                balTeam += oa.sumamt;
                stmtTeam += oa.sumstmtamt;
                curTeam += oa.sumcurbal;
              });
            });
            data[supname] = {
              supname,
              supid: supid,
              list: [list],
              ac: acTeam,
              os: osTeam,
              bal: balTeam,
              cur: curTeam,
              stmt: stmtTeam,
              manid: manid,
            };
          }
          return data;
        }, {})
      );
    const newObject = {
      statusName: "new",
      supList: groupByTeams(supList),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  function integrateDashboardAction(data: any) {
    let listNew: any = [];
    let supList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionstatus === "action") {
        let list = {
          listname: x1.listname,
          collid: x1.collid,
          collname: x1.collname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
          supname: x1.supname,
          manid: x1.manid,
        };
        listNew.push(list);
        let team = {
          supname: x1.supname,
          supid: x1.supid,
          listname: x1.listname,
          manid: x1.manid,
        };
        supList.push(team);
      }
    });
    const groupBySup = listNew.reduce((group: any, groupList: any) => {
      const { supname, listname } = groupList;
      const getGroup = group.find((x: any) => x.supname === supname && x.listname === listname);
      if (getGroup) {
        getGroup.userlist.push(groupList);
      } else {
        let listGroup = {
          supname,
          listname: listname,
          userlist: [groupList],
        };
        group.push(listGroup);
      }
      return group;
    }, []);
    const groupByTeamsForCheck = (supList: any) =>
      Object.values(
        supList.reduce((data: any, { supname, listname }: any) => {
          if (data[supname]) {
            if (
              !data[supname].list.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let userlist = groupBySup.find((list: any) => {
                return list.listname === listname && list.supname === supname;
              });
              const list = {
                listname: listname,
                userlist: userlist,
              };
              data[supname].list.push(list);
            }
          } else {
            let userlist = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
            const list = {
              listname: listname,
              userlist: userlist,
            };
            data[supname] = { supname, list: [list] };
          }
          return data;
        }, {})
      );
    const groupByTeams = (supList: any) =>
      Object.values(
        supList.reduce((data: any, { supname, supid, listname, manid }: any) => {
          if (data[supname]) {
            if (
              !data[supname].list.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let userlist: any = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
              let ac = 0;
              let os = 0;
              let bal = 0;
              let cur = 0;
              let stmt = 0;
              userlist.userlist.map((item: any) => {
                ac += item.cnt;
                os += item.sumos;
                bal += item.sumamt;
                cur += item.sumcurbal;
                stmt += item.sumstmtamt;
              });
              const list = {
                listname: listname,
                userlist: userlist,
                manid: manid,
                ac: ac,
                os: os,
                bal: bal,
                cur: cur,
                stmt: stmt,
              };
              data[supname].list.push(list);
            }
          } else {
            let userlist: any = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
            let ac = 0;
            let os = 0;
            let bal = 0;
            let cur = 0;
            let stmt = 0;
            userlist.userlist.map((item: any) => {
              ac += item.cnt;
              os += item.sumos;
              bal += item.sumamt;
              cur += item.sumcurbal;
              stmt += item.sumstmtamt;
            });
            const list: any = {
              listname: listname,
              userlist: userlist,
              manid: manid,
              ac: ac,
              os: os,
              bal: bal,
              cur: cur,
              stmt: stmt,
            };
            let acTeam: number = 0;
            let osTeam = 0;
            let balTeam = 0;
            let stmtTeam = 0;
            let curTeam = 0;
            let supListCeck: any = groupByTeamsForCheck(supList).find((team: any) => team.supname === supname);
            supListCeck.list.map((oaCount: any) => {
              oaCount.userlist.userlist.map((oa: any) => {
                acTeam += oa.cnt;
                osTeam += oa.sumos;
                balTeam += oa.sumamt;
                stmtTeam += oa.sumstmtamt;
                curTeam += oa.sumcurbal;
              });
            });
            data[supname] = {
              supname,
              supid: supid,
              list: [list],
              ac: acTeam,
              os: osTeam,
              bal: balTeam,
              cur: curTeam,
              stmt: stmtTeam,
              manid: manid,
            };
          }
          return data;
        }, {})
      );
    const newObject = {
      statusName: "action",
      supList: groupByTeams(supList),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  // function integrateDashboardAction(data: any) {
  //   let listNew: any = [];
  //   let supList: any = [];
  //   let dataSource = [];
  //   data.map((x1: any) => {
  //     if (x1.actionstatus === "action") {
  //       let list = {
  //         listname: x1.listname,
  //         collid: x1.collid,
  //         collname: x1.collname,
  //         cnt: x1.cnt,
  //         sumamt: x1.sumamt,
  //         sumcurbal: x1.sumcurbal,
  //         sumstmtamt: x1.sumstmtamt,
  //         sumos: x1.sumos,
  //       };
  //       listNew.push(list);

  //       let team = {
  //         supname: x1.supname,
  //         supid: x1.supid,
  //         listname: x1.listname,
  //       };
  //       supList.push(team);
  //     }
  //   });
  //   const groupByList = (listNew: any) =>
  //     Object.values(
  //       listNew.reduce(
  //         (
  //           data: any,
  //           {
  //             listname,
  //             collid,
  //             collname,
  //             cnt,
  //             sumamt,
  //             sumcurbal,
  //             sumstmtamt,
  //             sumos,
  //           }: any,
  //         ) => {
  //           if (data[listname]) {
  //             let oaDetails = {
  //               collid: collid,
  //               collname: collname,
  //               cnt: cnt,
  //               sumamt: sumamt,
  //               sumcurbal: sumcurbal,
  //               sumstmtamt: sumstmtamt,
  //               sumos: sumos,
  //             };
  //             data[listname].userlist.push(oaDetails);
  //           } else {
  //             let oaDetails = {
  //               collid: collid,
  //               collname: collname,
  //               cnt: cnt,
  //               sumamt: sumamt,
  //               sumcurbal: sumcurbal,
  //               sumstmtamt: sumstmtamt,
  //               sumos: sumos,
  //             };
  //             data[listname] = { listname, userlist: [oaDetails] };
  //           }
  //           return data;
  //         },
  //         {},
  //       ),
  //     );

  //   const groupByTeamsForCheck = (supList: any) =>
  //     Object.values(
  //       supList.reduce((data: any, { supname, listname }: any) => {
  //         if (data[supname]) {
  //           if (
  //             !data[supname].list.find((listcheck: any) => {
  //               return listcheck.listname === listname;
  //             })
  //           ) {
  //             let userList = groupByList(listNew).find(
  //               (list: any) => list.listname === listname,
  //             );
  //             const list = {
  //               listname: listname,
  //               userList: userList,
  //             };
  //             data[supname].list.push(list);
  //           }
  //         } else {
  //           let userList = groupByList(listNew).find(
  //             (list: any) => list.listname === listname,
  //           );
  //           const list = {
  //             listname: listname,
  //             userList: userList,
  //           };
  //           data[supname] = { supname, list: [list] };
  //         }

  //         return data;
  //       }, {}),
  //     );

  //   const groupByTeams = (supList: any) =>
  //     Object.values(
  //       supList.reduce((data: any, { supname, supid, listname }: any) => {
  //         if (data[supname]) {
  //           if (
  //             !data[supname].list.find((listcheck: any) => {
  //               return listcheck.listname === listname;
  //             })
  //           ) {
  //             let userList: any = groupByList(listNew).find(
  //               (list: any) => list.listname === listname,
  //             );
  //             let ac = 0;
  //             let os = 0;
  //             let bal = 0;
  //             let cur = 0;
  //             let stmt = 0;
  //             userList.userlist.map((item: any) => {
  //               ac += item.cnt;
  //               os += item.sumos;
  //               bal += item.sumamt;
  //               cur += item.sumcurbal;
  //               stmt += item.sumstmtamt;
  //             });
  //             const list = {
  //               listname: listname,
  //               userList: userList,
  //               ac: ac,
  //               os: os,
  //               bal: bal,
  //               cur: cur,
  //               stmt: stmt,
  //             };
  //             data[supname].list.push(list);
  //           }
  //         } else {
  //           let userList: any = groupByList(listNew).find(
  //             (list: any) => list.listname === listname,
  //           );
  //           let ac = 0;
  //           let os = 0;
  //           let bal = 0;
  //           let cur = 0;
  //           let stmt = 0;
  //           userList.userlist.map((item: any) => {
  //             ac += item.cnt;
  //             os += item.sumos;
  //             bal += item.sumamt;
  //             cur += item.sumcurbal;
  //             stmt += item.sumstmtamt;
  //           });
  //           const list: any = {
  //             listname: listname,
  //             userList: userList,
  //             ac: ac,
  //             os: os,
  //             bal: bal,
  //             cur: cur,
  //             stmt: stmt,
  //           };

  //           let acTeam: number = 0;
  //           let osTeam = 0;
  //           let balTeam = 0;
  //           let stmtTeam = 0;
  //           let curTeam = 0;
  //           let supListCeck: any = groupByTeamsForCheck(supList).find(
  //             (team: any) => team.supname === supname,
  //           );
  //           supListCeck.list.map((oaCount: any) => {
  //             oaCount.userList.userlist.map((oa: any) => {
  //               acTeam += oa.cnt;
  //               osTeam += oa.sumos;
  //               balTeam += oa.sumamt;
  //               stmtTeam += oa.sumstmtamt;
  //               curTeam += oa.sumcurbal;
  //             });
  //           });
  //           data[supname] = {
  //             supname,
  //             supid: supid,
  //             list: [list],
  //             ac: acTeam,
  //             os: osTeam,
  //             bal: balTeam,
  //             cur: curTeam,
  //             stmt: stmtTeam,
  //           };
  //         }
  //         return data;
  //       }, {}),
  //     );
  //   const newObject = {
  //     statusName: "action",
  //     supList: groupByTeams(supList),
  //   };
  //   dataSource.push(newObject);
  //   return dataSource;
  // }

  function integrateDashboardNoAction(data: any) {
    let listNew: any = [];
    let supList: any = [];
    let dataSource = [];
    data.map((x1: any) => {
      if (x1.actionstatus === "noAction") {
        let list = {
          listname: x1.listname,
          collid: x1.collid,
          collname: x1.collname,
          cnt: x1.cnt,
          sumamt: x1.sumamt,
          sumcurbal: x1.sumcurbal,
          sumos: x1.sumos,
          sumstmtamt: x1.sumstmtamt,
          supname: x1.supname,
          manid: x1.manid,
        };
        listNew.push(list);
        let team = {
          supname: x1.supname,
          supid: x1.supid,
          listname: x1.listname,
          manid: x1.manid,
        };
        supList.push(team);
      }
    });
    const groupBySup = listNew.reduce((group: any, groupList: any) => {
      const { supname, listname } = groupList;
      const getGroup = group.find((x: any) => x.supname === supname && x.listname === listname);
      if (getGroup) {
        getGroup.userlist.push(groupList);
      } else {
        let listGroup = {
          supname,
          listname: listname,
          userlist: [groupList],
        };
        group.push(listGroup);
      }
      return group;
    }, []);
    const groupByTeamsForCheck = (supList: any) =>
      Object.values(
        supList.reduce((data: any, { supname, listname }: any) => {
          if (data[supname]) {
            if (
              !data[supname].list.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let userlist = groupBySup.find((list: any) => {
                return list.listname === listname && list.supname === supname;
              });
              const list = {
                listname: listname,
                userlist: userlist,
              };
              data[supname].list.push(list);
            }
          } else {
            let userlist = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
            const list = {
              listname: listname,
              userlist: userlist,
            };
            data[supname] = { supname, list: [list] };
          }
          return data;
        }, {})
      );
    const groupByTeams = (supList: any) =>
      Object.values(
        supList.reduce((data: any, { supname, supid, listname, manid }: any) => {
          if (data[supname]) {
            if (
              !data[supname].list.find((listcheck: any) => {
                return listcheck.listname === listname;
              })
            ) {
              let userlist: any = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
              let ac = 0;
              let os = 0;
              let bal = 0;
              let cur = 0;
              let stmt = 0;
              userlist.userlist.map((item: any) => {
                ac += item.cnt;
                os += item.sumos;
                bal += item.sumamt;
                cur += item.sumcurbal;
                stmt += item.sumstmtamt;
              });
              const list = {
                listname: listname,
                userlist: userlist,
                manid: manid,
                ac: ac,
                os: os,
                bal: bal,
                cur: cur,
                stmt: stmt,
              };
              data[supname].list.push(list);
            }
          } else {
            let userlist: any = groupBySup.find((list: any) => list.listname === listname && list.supname === supname);
            let ac = 0;
            let os = 0;
            let bal = 0;
            let cur = 0;
            let stmt = 0;
            userlist.userlist.map((item: any) => {
              ac += item.cnt;
              os += item.sumos;
              bal += item.sumamt;
              cur += item.sumcurbal;
              stmt += item.sumstmtamt;
            });
            const list: any = {
              listname: listname,
              userlist: userlist,
              manid: manid,
              ac: ac,
              os: os,
              bal: bal,
              cur: cur,
              stmt: stmt,
            };
            let acTeam: number = 0;
            let osTeam = 0;
            let balTeam = 0;
            let stmtTeam = 0;
            let curTeam = 0;
            let supListCeck: any = groupByTeamsForCheck(supList).find((team: any) => team.supname === supname);
            supListCeck.list.map((oaCount: any) => {
              oaCount.userlist.userlist.map((oa: any) => {
                acTeam += oa.cnt;
                osTeam += oa.sumos;
                balTeam += oa.sumamt;
                stmtTeam += oa.sumstmtamt;
                curTeam += oa.sumcurbal;
              });
            });
            data[supname] = {
              supname,
              supid: supid,
              list: [list],
              ac: acTeam,
              os: osTeam,
              bal: balTeam,
              cur: curTeam,
              stmt: stmtTeam,
              manid: manid,
            };
          }
          return data;
        }, {})
      );
    const newObject = {
      statusName: "noAction",
      supList: groupByTeams(supList),
    };
    dataSource.push(newObject);
    return dataSource;
  }

  const getSupName = (sup: string) => {
    if (sup) {
      return sup;
    } else {
      return "Unassigned";
    }
  };

  const toDebt = (status: string, supName: string, supId: string, list: string, collId: string, collName: string, manId: string) => {
    if (status) {
      let mi = manId;
      if (supId) {
        mi = "";
      }
      let all = {
        type: "OA",
        statusName: status,
        supName: supName,
        supId: supId,
        listName: list,
        collName: collName,
        collId: collId,
        manId: mi,
      };
      history.push("/debtmanagement", all);
    }
  };

  useEffect(() => {
    async function fetchDataForSearch() {
      setLoading(true);
      const apiCaller = id24Axios(appConfig.apiBaseUrlDebt);
      const res: any = await DashboardService(apiCaller).getOaManager();
      let conDataNew: any = await integrateDashboardNew(res.result);
      let conDataAction: any = await integrateDashboardAction(res.result);
      let conDataNoAction: any = await integrateDashboardNoAction(res.result);
      await setDataSourceNew(conDataNew);
      await setDataSourceAction(conDataAction);
      await setDataSourceNoAction(conDataNoAction);
      setLoading(false);
    }
    fetchDataForSearch();
  }, []);

  const [evenExtra, setEvenextra] = useState<boolean>(true);
  const genExtra = () => (
    <>
      {evenExtra === false ? (
        <DownCircleOutlined
          style={{
            fontSize: "20px",
            color: "#1890FF",
            backgroundColor: "#E6F7FF",
            borderRadius: "100%",
          }}
          onClick={(event) => {
            setEvenextra(true);
          }}
        />
      ) : (
        <RightCircleOutlined
          style={{
            fontSize: "20px",
            color: "#1890FF",
            backgroundColor: "#E6F7FF",
            borderRadius: "100%",
          }}
          onClick={(event) => {
            setEvenextra(false);
          }}
        />
      )}
    </>
  );
  return loading ? (
    <>
      <div style={{ justifyContent: "center", display: "flex", alignItems: "center", minHeight: "30vh" }}>
        <Spin spinning={loading} tip="Loading" indicator={antIcon} />
      </div>
    </>
  ) : (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Col span={8}>
          <Row>
            {dataSourceNew.map((newstatus: any, index: any) => {
              if (newstatus.statusName === "new") {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#0056B4",
                        borderRadius: "5px",
                      }}
                    >
                      <b style={fontStyleHerder}>New</b>
                      {"   "}
                      <Tag style={fontStyleTap} color="#1890FF">
                        <NumberFormat
                          value={newstatus.supList.length}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Tag>
                    </Card>

                    {newstatus.supList.map((sup: any, indexSup: any) => {
                      return (
                        <Col>
                          <Collapse
                            defaultActiveKey={["0"]}
                            onChange={onChange}
                            expandIconPosition="end"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#FFFFFF",
                            }}
                            bordered={false}
                            ghost
                          >
                            <Panel
                              showArrow={true}
                              header={
                                <Col span={24}>
                                  <Row gutter={[0, 24]}>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={{ textAlign: "left" }}>
                                      <a
                                        onClick={() => toDebt("new", sup.supname, sup.supid, "", "", "", sup.manid)}
                                        style={{
                                          fontSize: "18px",
                                          color: "#108ee9",
                                        }}
                                      >
                                        <b>{getSupName(sup.supname)}</b>
                                      </a>{" "}
                                      <Tag
                                        style={{
                                          fontSize: "16px",
                                          borderRadius: "10px",
                                        }}
                                        color="#108ee9"
                                      >
                                        <NumberFormat
                                          value={sup.list.length}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Tag>
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col span={4} style={frontStyleCard}>
                                      <Avatar
                                        style={{ backgroundColor: "#E1EDF9" }}
                                        shape="square"
                                        icon={
                                          <UserOutlined
                                            style={{
                                              color: "#035BBD",
                                            }}
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col span={18} style={frontStyleCard}>
                                      A/C{" "}
                                      <NumberFormat
                                        value={sup.ac}
                                        displayType={"text"}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}>
                                      {/* {genExtra()} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      BAL{" "}
                                      <NumberFormat
                                        value={sup.bal}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      OS{" "}
                                      <NumberFormat
                                        value={sup.os}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>{" "}
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      CUR{" "}
                                      <NumberFormat
                                        value={sup.cur}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>{" "}
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      STMT{" "}
                                      <NumberFormat
                                        value={sup.stmt}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                </Col>
                              }
                              key="1"
                            >
                              <Collapse defaultActiveKey={["0"]}>
                                {sup.list.map((list: any, indexList: any) => {
                                  return (
                                    <Panel
                                      header={
                                        <>
                                          <Col style={frontStyleInCard} span={8}>
                                            <a
                                              style={{ color: "#000000" }}
                                              onClick={() => toDebt("new", sup.supname, sup.supid, list.listname, "", "", list.manid)}
                                            >
                                              {list.listname}
                                            </a>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            A/C{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.ac}
                                                displayType={"text"}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            BAL{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.bal}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            OS{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.os}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            CUR{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.cur}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            STMT{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.stmt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                        </>
                                      }
                                      key={indexList}
                                    >
                                      {list.userlist.userlist.map((collector: any, index: any) => {
                                        return (
                                          <Row style={frontStyleInCard} key={index}>
                                            <Col span={7} offset={1}>
                                              <a
                                                style={{ color: "#000000" }}
                                                onClick={() =>
                                                  toDebt(
                                                    "new",
                                                    sup.supname,
                                                    sup.supid,
                                                    list.listname,
                                                    collector.collid,
                                                    collector.collname,
                                                    collector.manid
                                                  )
                                                }
                                              >
                                                {getSupName(collector.collname)}
                                              </a>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              A/C{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.cnt}
                                                  displayType={"text"}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              BAL{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumamt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={1}>
                                              OS{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumos}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={3}>
                                              CUR{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumcurbal}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              STMT{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumstmtamt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </Panel>
                          </Collapse>
                          <br></br>
                        </Col>
                      );
                    })}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {dataSourceAction.map((newstatus: any, index: any) => {
              if (newstatus.statusName === "action") {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#70C787",
                        borderRadius: "5px",
                      }}
                    >
                      <b style={fontStyleHerder}>Action</b>
                      {"   "}
                      <Tag style={fontStyleTap} color="#52C41A">
                        <NumberFormat
                          value={newstatus.supList.length}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Tag>
                    </Card>

                    {newstatus.supList.map((sup: any, indexSup: any) => {
                      return (
                        <Col>
                          <Collapse
                            defaultActiveKey={["0"]}
                            onChange={onChange}
                            expandIconPosition="end"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#FFFFFF",
                            }}
                            bordered={false}
                            ghost
                          >
                            <Panel
                              showArrow={true}
                              header={
                                <Col span={24}>
                                  <Row gutter={[0, 24]}>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={{ textAlign: "left" }}>
                                      <a
                                        onClick={() => toDebt("action", sup.supname, sup.supid, "", "", "", sup.manid)}
                                        style={{
                                          fontSize: "18px",
                                          color: "#52C41A",
                                        }}
                                      >
                                        <b>{getSupName(sup.supname)}</b>
                                      </a>{" "}
                                      <Tag
                                        style={{
                                          fontSize: "16px",
                                          borderRadius: "10px",
                                        }}
                                        color="#52C41A"
                                      >
                                        <NumberFormat
                                          value={sup.list.length}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Tag>
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col span={4} style={frontStyleCard}>
                                      <Avatar
                                        style={{ backgroundColor: "#DEFFCD" }}
                                        shape="square"
                                        icon={
                                          <UserOutlined
                                            style={{
                                              color: "#52C41A",
                                            }}
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col span={18} style={frontStyleCard}>
                                      A/C{" "}
                                      <NumberFormat
                                        value={sup.ac}
                                        displayType={"text"}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}>
                                      {/* {genExtra()} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      BAL{" "}
                                      <NumberFormat
                                        value={sup.bal}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      OS{" "}
                                      <NumberFormat
                                        value={sup.os}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      CUR{" "}
                                      <NumberFormat
                                        value={sup.cur}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      STMT{" "}
                                      <NumberFormat
                                        value={sup.stmt}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                </Col>
                              }
                              key="1"
                            >
                              <Collapse defaultActiveKey={["0"]}>
                                {sup.list.map((list: any, indexList: any) => {
                                  return (
                                    <Panel
                                      header={
                                        <>
                                          <Col style={frontStyleInCard} span={8}>
                                            <a
                                              style={{ color: "#000000" }}
                                              onClick={() => toDebt("action", sup.supname, sup.supid, list.listname, "", "", list.manid)}
                                            >
                                              {list.listname}
                                            </a>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            A/C{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.ac}
                                                displayType={"text"}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            BAL{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.bal}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            OS{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.os}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>{" "}
                                          <Col style={frontStyleInCard} span={8}>
                                            CUR{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.cur}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>{" "}
                                          <Col style={frontStyleInCard} span={8}>
                                            STMT{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.stmt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                        </>
                                      }
                                      key={indexList}
                                    >
                                      {list.userlist.userlist.map((collector: any, index: any) => {
                                        return (
                                          <Row style={frontStyleInCard} key={index}>
                                            <Col span={7} offset={1}>
                                              <a
                                                style={{ color: "#000000" }}
                                                onClick={() =>
                                                  toDebt(
                                                    "action",
                                                    sup.supname,
                                                    sup.supid,
                                                    list.listname,
                                                    collector.collid,
                                                    collector.collname,
                                                    collector.manid
                                                  )
                                                }
                                              >
                                                {getSupName(collector.collname)}
                                              </a>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              A/C{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.cnt}
                                                  displayType={"text"}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              BAL{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumamt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={1}>
                                              OS{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumos}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={3}>
                                              CUR{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumcurbal}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              STMT{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumstmtamt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </Panel>
                          </Collapse>
                          <br></br>
                        </Col>
                      );
                    })}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {dataSourceNoAction.map((newstatus: any, index: any) => {
              if (newstatus.statusName === "noAction") {
                return (
                  <Col span={24} key={index}>
                    <Card
                      style={{
                        backgroundColor: "#EE5F77",
                        borderRadius: "5px",
                      }}
                    >
                      <b style={fontStyleHerder}>No-Action</b>
                      {"   "}
                      <Tag style={fontStyleTap} color="#FF4D4F">
                        <NumberFormat
                          value={newstatus.supList.length}
                          displayType={"text"}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                        />
                      </Tag>
                    </Card>

                    {newstatus.supList.map((sup: any, indexSup: any) => {
                      return (
                        <Col>
                          <Collapse
                            defaultActiveKey={["0"]}
                            onChange={onChange}
                            expandIconPosition="end"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#FFFFFF",
                            }}
                            bordered={false}
                            ghost
                          >
                            <Panel
                              showArrow={true}
                              header={
                                <Col span={24}>
                                  <Row gutter={[0, 24]}>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={{ textAlign: "left" }}>
                                      <a
                                        onClick={() => toDebt("noAction", sup.supname, sup.supid, "", "", "", sup.manid)}
                                        style={{
                                          fontSize: "18px",
                                          color: "#FF4D4F",
                                        }}
                                      >
                                        <b>{getSupName(sup.supname)}</b>
                                      </a>{" "}
                                      <Tag
                                        style={{
                                          fontSize: "16px",
                                          borderRadius: "10px",
                                        }}
                                        color="#FF4D4F"
                                      >
                                        <NumberFormat
                                          value={sup.list.length}
                                          displayType={"text"}
                                          decimalScale={0}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                        />
                                      </Tag>
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col span={4} style={frontStyleCard}>
                                      <Avatar
                                        style={{ backgroundColor: "#e98b9b" }}
                                        shape="square"
                                        icon={
                                          <UserOutlined
                                            style={{
                                              color: "#FF4D4F",
                                            }}
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col span={18} style={frontStyleCard}>
                                      A/C{" "}
                                      <NumberFormat
                                        value={sup.ac}
                                        displayType={"text"}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}>
                                      {/* {genExtra()} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      BAL{" "}
                                      <NumberFormat
                                        value={sup.bal}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      OS{" "}
                                      <NumberFormat
                                        value={sup.os}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      CUR{" "}
                                      <NumberFormat
                                        value={sup.cur}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      span={4}
                                      style={{
                                        textAlign: "left",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    ></Col>
                                    <Col span={18} style={frontStyleCard}>
                                      STMT{" "}
                                      <NumberFormat
                                        value={sup.stmt}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                    <Col span={2} style={frontStyleCard}></Col>
                                  </Row>
                                </Col>
                              }
                              key="1"
                            >
                              <Collapse defaultActiveKey={["0"]}>
                                {sup.list.map((list: any, indexList: any) => {
                                  return (
                                    <Panel
                                      header={
                                        <>
                                          <Col style={frontStyleInCard} span={8}>
                                            <a
                                              style={{ color: "#000000" }}
                                              onClick={() => toDebt("noAction", sup.supname, sup.supid, list.listname, "", "", list.manid)}
                                            >
                                              {list.listname}
                                            </a>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            A/C{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.ac}
                                                displayType={"text"}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            BAL{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.bal}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            OS{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.os}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            CUR{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.cur}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                          <Col style={frontStyleInCard} span={8}>
                                            STMT{" "}
                                            <b
                                              style={{
                                                color: "#0056B4",
                                              }}
                                            >
                                              <NumberFormat
                                                value={list.stmt}
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                              />
                                            </b>
                                          </Col>
                                        </>
                                      }
                                      key={indexList}
                                    >
                                      {list.userlist.userlist.map((collector: any, index: any) => {
                                        return (
                                          <Row style={frontStyleInCard} key={index}>
                                            <Col span={7} offset={1}>
                                              <a
                                                style={{ color: "#000000" }}
                                                onClick={() =>
                                                  toDebt(
                                                    "noAction",
                                                    sup.supname,
                                                    sup.supid,
                                                    list.listname,
                                                    collector.collid,
                                                    collector.collname,
                                                    collector.manid
                                                  )
                                                }
                                              >
                                                {getSupName(collector.collname)}
                                              </a>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              A/C{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.cnt}
                                                  displayType={"text"}
                                                  decimalScale={0}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              BAL{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumamt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              OS{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumos}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              CUR{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumcurbal}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                            <Col span={6} offset={2}>
                                              STMT{" "}
                                              <b
                                                style={{
                                                  color: "#0056B4",
                                                }}
                                              >
                                                <NumberFormat
                                                  value={collector.sumstmtamt}
                                                  displayType={"text"}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  thousandSeparator={true}
                                                />
                                              </b>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </Panel>
                          </Collapse>
                          <br></br>
                        </Col>
                      );
                    })}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
};
