import React, { useEffect } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter } from "react-router-dom";
import { Button, Result, ConfigProvider } from "antd";
import { Id24Provider, useId24 } from "./drivers/id24/Id24-provider";
import { AuthorizationController } from "./layouts/AuthorizationController";
import { TokenInfo } from "./drivers/id24/models/token-info";
import { Id24Instance } from "./drivers/id24/Id24";
import { Id24InstanceProvider } from "./drivers/id24/Id24-instance-provider";
import { configuredLocale } from "./utils/index";
var host = window.location.host;
export type AuthenticationHelper = {
  authorize: (redirectUrl: string) => void;
  renewAccessToken: () => Promise<TokenInfo | null>;
  logout: () => Promise<void>;
  reloadPage: () => void;
};

type Props = {
  id24Instance: Id24Instance;
  authenticationHelper: AuthenticationHelper;
};

function Id24User() {
  const { authenticated, login } = useId24();
  const codeVerifier = localStorage.getItem("challenge");
  // const codeVerifier = sessionStorage.getItem("challenge");
  if (!authenticated || !codeVerifier) {
    login(window.location.href);
    return (
      <>
        <Result
          status="403"
          title="403"
          subTitle="ท่านยังไม่มีสิทธิ์ในการเข้าถึงระบบ กรุณาตรวจสอบสิทธิ์ในการเข้าถึงกับผู้ดูแลระบบ."
          extra={
            <Button type="primary" onClick={() => login(window.location.href)}>
              Click to Login
            </Button>
          }
        />
      </>
    );
  }
  return (
    <div className="App">
      <AuthorizationController />
    </div>
  );
}

const App: React.FC<Props> = ({ id24Instance, authenticationHelper }) => {
  return (
    <div className="App">
      <ConfigProvider locale={configuredLocale}>
        <Id24InstanceProvider instance={id24Instance} authenticationHelper={authenticationHelper}>
          <BrowserRouter basename={"/"}>
            <Id24Provider
              config={{
                refreshTokenIntervalInSeconds: 60,
                resourceApiBaseUrl: `https://${host}/public/api`,
              }}
            >
              <Id24User />
            </Id24Provider>
          </BrowserRouter>
        </Id24InstanceProvider>
      </ConfigProvider>
    </div>
  );
};

export default App;
