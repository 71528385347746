import React from "react";
import { Row } from "antd";
import { DatamanagementDraftTable } from "./components/DatamanagementTable";

interface Props {
  baseUrl: string;
  accessRole: object;
}
export const OverviewDataManagement: React.FC<Props> = ({ baseUrl, accessRole }) => {
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <DatamanagementDraftTable baseUrl={baseUrl} accessRole={accessRole} />
      </Row>
    </>
  );
};
