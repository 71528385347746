import { DatamanageMent, ICenter, IuserProfile, typeTeam } from "../../datamanagement/models/models-datamanagement";
import { TStatusDatamanagement, IMasterDataOfBranch } from "../../datamanagement/common";

export interface IDatamanagementService {
  getProductCode: () => Promise<[]>;
  getUserInGroupDatamanagement: () => Promise<[]>;
  getDraftDataManagementByid: (userAssign: string) => Promise<DatamanageMent>;
  updateConditionByid: (AssignUserNew: any, dataBody: any) => Promise<number>;
  createCondition: (rulesCreate: any) => Promise<number>;
  getDataManagementRuleBaseByname: (AssignUser: string) => Promise<any>;
  getRuleBaseByStatus: (AssignUser: string, status: string, task: number, skip: number) => Promise<any>;
  countRuleBase: (AssignUser: string, status: string) => Promise<any>;
  setExecuteRuleBase: (CleardistNew: any) => Promise<string>;
  getUserDetail: () => Promise<IuserProfile>;
  setSimulateRuleBase: (CleardistNew: any) => Promise<any>;
  clearDist: (CleardistNew: any) => Promise<typeTeam[]>;
  getTeambyuser: (profileid: string) => Promise<any>;
  assignConditions: (CleardistNew: any) => Promise<any>;
  getTeamAll: () => Promise<any>;
  getCenterList: () => Promise<ICenter[]>;
  getUserAll: () => Promise<any>;
  simulateAssign: (dataProfile: IuserProfile) => Promise<any>;
  updateConditionByseq: (seq: any, AssignUserNew: string, status: string) => Promise<number>;
  simulateStatus: (dataProfile: IuserProfile) => Promise<TStatusDatamanagement>;
  executeStatus: (userid: number) => Promise<TStatusDatamanagement>;
  assignStatus: (userid?: number | string) => Promise<TStatusDatamanagement>;
  masterDataCenterBranch: () => Promise<IMasterDataOfBranch[]>;
}

export const DatamanagementServiceOa = (serviceBackendApi: any): IDatamanagementService => ({
  getProductCode: async () => {
    const result = await serviceBackendApi.get(`/productCode/search`);
    return result.data.result;
  },

  getUserInGroupDatamanagement: async () => {
    const result = await serviceBackendApi.get(`/debtor/userList`);
    return result.data.result;
  },

  getDraftDataManagementByid: async (userAssign) => {
    const result = await serviceBackendApi.get(`/rule-base/${userAssign}`);
    return result.data.result;
  },

  updateConditionByid: async (AssignUserNew, dataBody) => {
    const result = await serviceBackendApi.put(`/rule-base/${AssignUserNew}`, dataBody);
    return result.status;
  },

  createCondition: async (rulesCreate: any) => {
    const databody = rulesCreate[0];
    const result = await serviceBackendApi.post("/rule-base", databody);
    return result.status;
  },

  getDataManagementRuleBaseByname: async (AssignUser) => {
    const result = await serviceBackendApi.get(`/rule-base/${AssignUser}`);
    return result.data.result;
  },
  getRuleBaseByStatus: async (userAssign, status, task, skip) => {
    const result = await serviceBackendApi.get(`/rule-base/${userAssign}/${status}/${task}/${skip}`);
    return result?.data?.result;
  },
  countRuleBase: async (name, status) => {
    const result = await serviceBackendApi.get(`/rule-base/count/${name}/${status}`);
    return result.data.result;
  },
  setExecuteRuleBase: async (CleardistNew) => {
    const dataBody = { userId: CleardistNew };
    const result = await serviceBackendApi.post(`/rule-base/execute`, dataBody);
    return result.data.result;
  },
  getUserDetail: async () => {
    const result = await serviceBackendApi.get(`/debtor/userDetails`);
    return result.data.result;
  },
  setSimulateRuleBase: async (CleardistNew) => {
    const dataBody = { userId: CleardistNew };
    const result = await serviceBackendApi.post(`/debt/simulate`, dataBody);
    return result.data.result;
  },
  clearDist: async (CleardistNew) => {
    const dataBody = { userId: CleardistNew };
    const result = await serviceBackendApi.post(`/debtor/clearDist`, dataBody);
    return result.data.result;
  },
  getTeambyuser: async (profileid) => {
    const dataBody = { condition: { user: String(profileid) } };
    const result = await serviceBackendApi.post(`/user/teambyuser`, dataBody);

    return result.data.result;
  },
  assignConditions: async (CleardistNew) => {
    const dataBody = {
      userId: CleardistNew,
      assignType: "Auto",
    };
    const result = await serviceBackendApi.post("/debt/submit", dataBody);
    return result.data.result || {};
  },
  getTeamAll: async () => {
    const result = await serviceBackendApi.get("/dist/all");
    return result.data.result;
  },
  updateConditionByseq: async (seq, AssignUserNew, status) => {
    const databody = {
      seq: seq,
      status: status,
    };
    const result = await serviceBackendApi.put(`/rule-base/seq-status/${AssignUserNew}`, databody);
    return result.status;
  },
  getCenterList: async () => {
    const result = await serviceBackendApi.get("/search/centerBranch");
    return result?.data.result || {};
  },
  getUserAll: async () => {
    const data = {};
    const result = await serviceBackendApi.get("/debtor/getUserAll");
    return result?.data.result || {};
  },
  simulateAssign: async (dataProfile) => {
    const data = {
      userId: dataProfile.id,
    };
    const result = await serviceBackendApi.post(`/debt/simulateAssign`, data);
    return result?.data || {};
  },
  simulateStatus: async (dataProfile) => {
    const data = {
      userId: dataProfile.id,
    };
    const result = await serviceBackendApi.post(`/datamanagement/status/simulateStatus`, data);
    return result.data || {};
  },
  assignStatus: async (userid) => {
    const data = {
      userId: userid,
    };
    const result = await serviceBackendApi.post(`/datamanagement/status/assignStatus`, data);
    return result.data || {};
  },
  executeStatus: async (CleardistNew) => {
    const data = {
      userId: CleardistNew,
    };
    const result = await serviceBackendApi.post(`/datamanagement/status/executeStatus`, data);
    return result.data || {};
  },
  masterDataCenterBranch: async () => {
    const result = await serviceBackendApi.get(`/centerBranch`);
    return result?.data || {};
  },
});
