// import { serviceBackendUser } from "../../../drivers/service/axiosBackend";

import { IOrganize, ISuborganize, IUser } from "../common";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

interface IFilter {
  type: string;
  text: string;
}

export interface IOaProfile {
  getOaProfile: (filter: IFilter) => Promise<any>;
  updateOaProfile: (data: any) => Promise<any>;
  syncData: () => Promise<any>;
  getDataUserAll: () => Promise<any>;
  getuserByoacode: (oaCode: string) => Promise<any>;
  createContract: (oaCode: string, data: any) => Promise<string>;
  getOaContract: (oaId: string, oaCode: string) => Promise<any>;
  updateContact: (data: any) => Promise<string>;
  tranferData: (data: any) => Promise<any>;
  findOrganize: (oaid: string) => Promise<IOrganize[]>;
  findsuborganize: (oaid: string) => Promise<ISuborganize[]>;
  updateUser: (data: any) => Promise<string>;
  createUser: (data: any) => Promise<any>;
  getProfile: () => Promise<IUser[]>;
}

export const OaProfileService = (serviceBackendUser: any): IOaProfile => ({
  getProfile: async () => {
    const result = await serviceBackendUser.get("/profile/user");
    return result?.data || {};
  },
  updateContact: async (data: any) => {
    const result = await serviceBackendUser.put("/oa/profile/contract", data);
    return result?.data || {};
  },
  getOaProfile: async (filter: IFilter | {}) => {
    // let data = {};
    // if (filter.type != "" && filter.text != "") {
    //   data = filter;
    // }
    const result = await serviceBackendUser.post("/oa/profile", filter);
    return result?.data || {};
  },
  updateOaProfile: async (data: any) => {
    let headerConfig = {
      data: data,
    };
    const result = await serviceBackendUser.put("/oa/profile", headerConfig);
    return result?.data || {};
  },
  syncData: async () => {
    const result = await serviceBackendUser.post("/oa/sync/profile");
    return result?.data || {};
  },
  createContract: async (dataOaCode: string, data: any) => {
    let headerConfig = {
      data: {
        oaCode: dataOaCode,
        ...data,
      },
    };
    const result = await serviceBackendUser.post("/oa/profile/contract", headerConfig);
    return result?.data || {};
  },
  getOaContract: async (oaId: string, oaCode: string) => {
    let headerConfig = {
      oaCode: oaCode,
      oa_id: oaId,
    };

    const result = await serviceBackendUser.post("/oa/profile/contract/data", headerConfig);
    return result?.data || {};
  },
  getDataUserAll: async () => {
    const result = await serviceBackendUser.get(`/debtor/getUserAll`);
    return result?.data || {};
  },
  getuserByoacode: async (oaCode: string) => {
    const result = await serviceBackendUser.post(`oa/userManager`, {
      oaCode: oaCode,
    });
    return result?.data || {};
  },
  tranferData: async (data) => {
    const result = await serviceBackendUser.post(`/user/transferData`, data);
    return result?.data || {};
  },
  findOrganize: async (oaid) => {
    const result = await serviceBackendUser.get(`/bank/find/Organize/${oaid}`);
    return result?.data || {};
  },
  findsuborganize: async (oaid) => {
    const result = await serviceBackendUser.get(`/bank/find/Suborganize/${oaid}`);
    return result?.data || {};
  },
  updateUser: async (data: any) => {
    const result = await serviceBackendUser.put("/oa/user/update", data);
    return result?.data || {};
  },
  createUser: async (data: any) => {
    const result = await serviceBackendUser.post("/oa/user/create", data);
    return result?.data || {};
  },
});
