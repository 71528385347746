import { IUser } from "../../oaProfile/common";
export interface IFilter {
  type: string;
  text: string;
}
export interface ITeamService {
  getSubOrganize: () => Promise<any>;
  getOaOrganize: () => Promise<any>;
  createUser: (data: any) => Promise<string>;
  getUser: (fillterData: IFilter) => Promise<any>;
  updateUser: (data: any) => Promise<any>;
  updateUserPassword: (data: any) => Promise<any>;
  getProfileOaById: (data: any) => Promise<string>;
  getUserAll: () => Promise<string>;
  syncData: () => Promise<any>;
  getProfile: () => Promise<IUser[]>;
}

export const OaUserService = (serviceBackendUser: any): ITeamService => ({
  getProfile: async () => {
    const result = await serviceBackendUser.get("/profile/user");
    return result?.data || {};
  },
  getProfileOaById: async (data) => {
    const result = await serviceBackendUser.get(`/oa/getprofileoabyid/${data}`);
    return result?.data || {};
  },
  getSubOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/profile/subGroups", {});
    return result?.data || {};
  },
  getOaOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/organize/roles", {});
    return result?.data || {};
  },
  createUser: async (data: any) => {
    const result = await serviceBackendUser.post("/oa/user/create", data);
    return result?.data || {};
  },
  updateUser: async (data: any) => {
    const result = await serviceBackendUser.put("/oa/user/update", data);
    return result?.data || {};
  },
  updateUserPassword: async (data: any) => {
    const result = await serviceBackendUser.put("/oa/user/updatepassword", data);
    return result?.data || {};
  },
  syncData: async () => {
    const result = await serviceBackendUser.post("/oa/sync/usersoa");
    return result?.data || {};
  },
  getUser: async (filter: IFilter) => {
    let data = {};
    if (filter.type != "" && filter.text != "") {
      data = filter;
    }
    const result = await serviceBackendUser.post("/oa/user", data);
    return result?.data || {};
  },
  getUserAll: async () => {
    const result = await serviceBackendUser.get("/debtor/getUserAll");
    return result?.data.result || {};
  },
});
