import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tabs, Table, Tag, Spin, Button, message } from "antd";
import { ClockCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { DebtService } from "../services/debt-service";
import { scheduleexportcsv } from "../common";
import dayjs from "dayjs";
const appConfig = loadAppConfig();

export const Taskimport: React.FC = ({}): React.ReactElement => {
  const debtService = DebtService;
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [loading, setloading] = useState<boolean>(true);
  const [Datataskexportimport, setDatataskexportimport] = useState<scheduleexportcsv[]>([]);

  useEffect(() => {
    loaddata();
  }, []);

  const loaddata = async () => {
    const resultData = await debtService(apiBaseUrlDebt).findtaskimport();
    setDatataskexportimport(resultData?.result);
    setloading(false);
  };

  const onClickDownload = async (idfile: string, namefile: string) => {
    try {
      const resultLookupfile = await debtService(apiBaseUrlDebt).lookupfile(idfile, namefile);
      if (resultLookupfile.data === "") {
        return message.error("ไม่พบไฟล์");
      }
      const blob = new Blob([resultLookupfile.data], { type: "text/plain" });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = namefile;
      link.click();

      URL.revokeObjectURL(downloadUrl);
      link.remove();
    } catch (error: any) {
      console.error("Error downloading the file:", error.message);
    }
  };

  const columnsDatataskexportimport = [
    {
      title: "Id",
      id: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      id: "status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => {
        return text === "success" ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            success
          </Tag>
        ) : text === "error" ? (
          <Tag icon={<CloseCircleOutlined />} color="error">
            error
          </Tag>
        ) : (
          <Tag icon={<ClockCircleOutlined />} color="default">
            waiting
          </Tag>
        );
      },
    },
    {
      title: "Namefile",
      id: "namefile",
      dataIndex: "namefile",
      key: "namefile",
    },
    {
      title: "Type",
      id: "type",
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "export-unlist",
          value: "export-unlist",
        },
        {
          text: "import-target",
          value: "import-target",
        },
        {
          text: "export",
          value: "export",
        },
      ],
      onFilter: (value: string, record: any) => record.type.indexOf(value) === 0,
    },
    {
      title: "Created",
      id: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (e: any) => {
        if (e) {
          return dayjs(e).add(543, "year").format("DD-MM-YYYY HH:mm:ss น.");
        }
      },
    },
    {
      title: "Action",
      id: "idfile",
      dataIndex: "idfile",
      key: "id",
      render: (text: string, data: scheduleexportcsv) => {
        if (data.status === "success") {
          return <Button onClick={() => onClickDownload(data.idfile, data.namefile)}>Download File</Button>;
        } else if (data.status === "error") {
          return (
            <Tag icon={<CloseCircleOutlined />} color="error">
              error
            </Tag>
          );
        } else {
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              processing
            </Tag>
          );
        }
      },
    },
  ];
  return loading ? (
    <Spin tip="Loading..." spinning={true}></Spin>
  ) : (
    <>
      <Row style={{ width: "100%", marginTop: "2rem" }}>
        <Col span={24}>
          <Card style={{ width: "100%", textAlign: "left" }} title={"ดาวน์โหลด"}>
            <Table rowKey={(record: any) => record?.id} size="small" dataSource={Datataskexportimport} columns={columnsDatataskexportimport as any} />
          </Card>
        </Col>
      </Row>
    </>
  );
};
