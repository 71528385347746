import { Button, Card, Col, Row, Table, Modal, Input, Space, Form, message, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import type { ColumnsType } from "antd/es/table";
import { useHistory } from "react-router-dom";
import { EmailTemplateServiece } from "../serviece/EmailTemplateServiece";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
const appConfig = loadAppConfig();
let dayjs = require("dayjs");

interface DataType {
  mtId: React.Key;
  no: number;
  mtName: string;
  createAt: string;
  updateAt: string;
  mailTo: string;
  bodyContent: string;
  subject: string;
}
interface Props {
  baseUrl: string;
}
const OverviewEmailTemplate: React.FC<Props> = ({ baseUrl }): React.ReactElement => {
  const [getAlias, setAlias] = React.useState<any>({
    name: "unknown",
    empId: "unknown",
    email: "unknown",
  });
  const EmailTemplateServieces = EmailTemplateServiece;
  const { push } = useHistory();
  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlDebtlink = id24Axios(appConfig.apiBaseUrlDatalink);
  const [formAction] = Form.useForm();
  const [editform] = Form.useForm();

  const [getName, setNameValue] = useState<string>("");
  const [getUserId, setUserIdValue] = useState<number>(0);
  const [getcreate, setcreateAdd] = useState<string>("");
  const [getupdated, setupdatedAdd] = useState<string>("");

  // workflow new
  // const [getdataWorkflow, setDataWorkflow] = useState<any>([]);
  //
  const toTemplate = (path: any, id: any) => {
    let all = {
      alltem: getdataAll,
      idtem: id,
    };
    push(path, all);
  };

  const [getModal, setModal] = useState(false);
  const [getmodalEdit, setModalEdit] = useState(false);
  const [getmodalEditworkFlow, setModalEditworkFlow] = useState(false);
  const [getmodalWorkflow, setModalWorkflow] = useState(false);
  const [getdataAll, setgetdataAll] = useState([]);
  const [getfilterName, setFilterNmae] = useState<any>([]);

  useEffect(() => {
    async function fetchDataForSearch() {
      const userProfile = id24Axios(appConfig.apiBaseUrlDebt);
      const profile = await userProfile.get("/debtor/userDetails");
      setAlias({
        name: profile.data.result.name,
        email: profile.data.result.email,
        empId: profile.data.result.id,
      });
      getDataTable();

      const resSearch = (await EmailTemplateServieces(apiBaseUrlDebtlink).gettemplateAllNew()) as any;
      let filterSearchName: any = [];
      let NameFilter = resSearch.filter((ele: any, ind: any) => ind === resSearch.findIndex((elem: any) => elem.mtName === ele.mtName));
      NameFilter.map((event: any) => {
        filterSearchName.push({
          text: event.mtName,
          value: event.mtName,
        });
      });

      await setFilterNmae(filterSearchName);
    }

    fetchDataForSearch();
  }, []);

  // const madalWorkFlow = () => {
  //   setModalWorkflow(true);
  // };
  const madalEditWorkFlow = () => {
    setModalEditworkFlow(true);
  };
  const modalAdd = () => {
    setModal(true);
  };
  const modalEdit = (form: any) => {
    if (form) {
      setcreateAdd(form.createAt);
      setupdatedAdd(form.updateAt);
      setUserIdValue(form.mtId);
      setNameValue(form.mtName);
      setModalEdit(true);
    }
  };
  //   Add Template
  const handleOk = async (value: any) => {
    if (value) {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <PlusOutlined />,
        content: "ยืนยัน sequence Condition List นี้ใช่หรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const AddData = {
            data: {
              name: value.name,
              description: value.description,
              createdBy: getAlias.name,
              updatedBy: "",
            },
          };
          (await EmailTemplateServieces(apiBaseUrlDebt)
            .createtemplate(AddData)
            .then((response: any) => {
              message.success("เพิ่ม template เรียบร้อย");
            })) as any;
          setModal(false);
          getDataTable();
        },
        onCancel: () => {},
      });
    }
  };
  const handleCancel = () => {
    setModal(false);
    formAction.resetFields();
  };
  //   end Add template

  //  Edit Template
  const handleOkEdit = async (form: any) => {
    // console.log(form);
    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <PlusOutlined />,
      content: "ยืนยัน sequence Condition List นี้ใช่หรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        let id = getUserId;
        let data = {
          mtName: form.name != undefined ? form.name : getName,
        };

        (await EmailTemplateServieces(apiBaseUrlDebtlink)
          .updatetemplateNew(data, id)
          .then(() => {
            message.success("เพิ่ม template เรียบร้อย");
          })) as any;
        setModalEdit(false);
        getDataTable();
      },
      onCancel: () => {},
    });
  };
  const handleCancelEdit = () => {
    setModalEdit(false);
    editform.resetFields();
  };
  //   end edit template
  const handleCancelWorkFlow = () => {
    setModalWorkflow(false);
  };
  const handleCancelEditworkflow = () => {
    setModalEditworkFlow(false);
  };
  //   Remove template
  const removeTemplate = async (id: any) => {
    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <PlusOutlined />,
      content: "ยืนยัน sequence Condition List นี้ใช่หรือไม่",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        await EmailTemplateServieces(apiBaseUrlDebt)
          .deletetemplate(id)
          .then(() => {
            message.success("ลบข้อมูลสำเร็จ");
          });
        getDataTable();
      },
      onCancel: () => {},
    });
  };

  const getDataTable = async (): Promise<any> => {
    const res = (await EmailTemplateServieces(apiBaseUrlDebtlink).gettemplateAllNew()) as any;
    // const getdata = (await EmailTemplateServieces(
    //   apiBaseUrlDebt
    // ).gettemplateAll()) as any;

    const data = (await res.map(
      (
        x: {
          mtId: string;
          mtName: string;
          mailTo: string;
          bodyContent: string;
          subject: string;
          createAt: string;
          updateAt: string;
        },
        index: number,
      ) => {
        const mapData = {
          mtId: x.mtId,
          no: index + 1,
          mtName: x.mtName,
          mailTo: x.mailTo,
          bodyContent: x.bodyContent,
          subject: x.subject,
          createAt: x.createAt,
          updateAt: x.updateAt,
        };
        return mapData;
      },
    )) as any;
    setgetdataAll([]);
    setgetdataAll(data);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "No.",
      dataIndex: "no",
      width: "5%",
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "mtName",
      width: "30%",
      filters: getfilterName,
      onFilter: (value: any, record) => {
        return String(record.mtName)?.startsWith(String(value));
      },
      filterSearch: true,
      render: (text: string, record: any, index: number) => {
        // console.log(record);

        return text ? (
          <a key={record.mtId} onClick={() => toTemplate(`${baseUrl}/editemailtemplate/${record.mtId}`, record.mtId)}>
            {text}
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createAt",
      width: "20%",
      render: (event: any, row: any, index: number) => {
        if (event) {
          let date = dayjs(event);
          return dayjs(date).add(543, "year").format("DD-MM-YYYY HH:mm:ss");
        }
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updateAt",
      width: "20%",
      render: (event: any, row: any, index: number) => {
        if (event) {
          let date = dayjs(event);
          return dayjs(date).add(543, "year").format("DD-MM-YYYY HH:mm:ss");
        }
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   width: "7%",
    //   fixed: "right",
    //   align: "center",
    //   render: (event: string, row: any, index: number) => {
    //     // console.log(row);
    //     return (
    //       <>
    //         <div style={{ textAlign: "center" }}>
    //           <Space>
    //             <Button
    //               key={row.id}
    //               type="primary"
    //               style={{ width: "80px" }}
    //               onClick={() => {
    //                 modalEdit(row);
    //               }}
    //             >
    //               Edit
    //             </Button>
    //           </Space>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem", width: "100%" }}
      >
        <Col span={24}>
          <Card
            style={{ width: "100%", textAlign: "left" }}
            title={
              <>
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Template
                </Typography>
              </>
            }
          >
            <Table size="small" columns={columns} dataSource={getdataAll} scroll={{ x: "calc(700px + 50%)" }} />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Add Template"
        open={getModal}
        onOk={modalAdd}
        onCancel={handleCancel}
        footer={[
          <Button style={{ backgroundColor: "red", color: "white" }} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button form="formfield" type="primary" htmlType="submit" onClick={modalAdd}>
            Save
          </Button>,
        ]}
      >
        <Form initialValues={{ remember: true }} form={formAction} name="formfield" id="formfield" layout="vertical" onFinish={handleOk}>
          <Form.Item label={"Name"} id="name" name={"name"} required>
            <Input placeholder="Text" name="name" />
          </Form.Item>
          <Form.Item label={"Description"} id="description" name={"description"} required>
            <TextArea placeholder="Text" maxLength={100} name="description" />
          </Form.Item>
        </Form>
      </Modal>

      {/* <Modal
        title="Workflow"
        visible={getmodalWorkflow}
        onCancel={handleCancelWorkFlow}
        footer={false}
        width={1000}
      >
        <Form
          initialValues={{ remember: true }}
          form={editform}
          autoComplete="off"
          layout="vertical"
          name="workflow"
          // onFinish={handleOkEdit}
        >
          <Form.Item style={{ textAlign: "right" }}>
            <Table columns={columnsWorkFlow} dataSource={dataMockup} />
            <Button
              style={{ backgroundColor: "red", color: "white" }}
              onClick={handleCancelWorkFlow}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
      {/* <Modal
        title="Add Template"
        visible={getModal}
        onOk={modalAdd}
        onCancel={handleCancel}
        footer={[
          <Button
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            form="formfield"
            type="primary"
            htmlType="submit"
            onClick={modalAdd}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          initialValues={{ remember: true }}
          form={formAction}
          name="formfield"
          id="formfield"
          layout="vertical"
          onFinish={handleOk}
        >
          <Form.Item label={"Name"} id="name" name={"name"} required>
            <Input placeholder="Text" name="name" />
          </Form.Item>
          <Form.Item
            label={"Description"}
            id="description"
            name={"description"}
            required
          >
            <TextArea placeholder="Text" maxLength={100} name="description" />
          </Form.Item>
        </Form>
      </Modal> */}

      <Modal title="Edit Template" visible={getmodalEdit} onCancel={handleCancelEdit} footer={false}>
        <Form initialValues={{ remember: true }} form={editform} autoComplete="off" layout="vertical" name="editform" onFinish={handleOkEdit}>
          <Form.Item label="Name" name="name">
            <Input key={getUserId} placeholder="Text" name="name" value={getName} defaultValue={getName} />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Space>
              <Button style={{ backgroundColor: "red", color: "white" }} onClick={handleCancelEdit}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OverviewEmailTemplate;
