import axios, { AxiosInstance } from "axios";
import { type } from "os";
import { loadAppConfig } from "../../../config/app-config";
// import { serviceBackendUser } from "../../../drivers/service/axiosBackend";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export interface IFilter {
  type: string;
  text: string;
}
export interface ITeamService {
  getSubOrganize: () => Promise<string>;
  getOaOrganize: () => Promise<string>;
  getAllRunjob: () => Promise<string>;
  getUser: (fillterData: IFilter) => Promise<string>;
  getProfileOaById: (data: any) => Promise<string>;
  getAllFile: () => Promise<string>;
  getFileAlllog: () => Promise<string>;
  getFileByID: (id: any) => Promise<string>;
  addFile: (data: any) => Promise<string>;
  deleteFile: (data: any) => Promise<string>;
  deleteJobRun: (id: any) => Promise<string>;
  editFile: (data: any, id: number) => Promise<string>;
}

export const BatchProcessFileService = (
  serviceBackendUser: any
): ITeamService => ({
  editFile: async (data, id) => {
    const result = await serviceBackendUser.put(
      `/link/v1/file-process/${id}`,
      data
    );
    return result?.data || {};
  },
  deleteFile: async (data) => {
    const result = await serviceBackendUser.delete(
      `/link/v1/file-process/${data}`
    );
    return result?.data || {};
  },

  deleteJobRun: async (id) => {
    const result = await serviceBackendUser.delete(
      `/link/v1/job-running/${id}`
    );
    return result?.data || {};
  },
  addFile: async (data) => {
    const result = await serviceBackendUser.post(`/link/v1/file-process`, data);
    return result?.data || {};
  },
  getAllFile: async () => {
    let headerConfig = {
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const result = await serviceBackendUser.get(
      `/link/v1/file-process`,
      headerConfig
    );
    return result?.data || {};
  },

  getProfileOaById: async (data) => {
    const result = await serviceBackendUser.get(`/oa/getprofileoabyid/${data}`);
    return result?.data || {};
  },
  getSubOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/profile/subGroups", {});
    return result?.data || {};
  },
  getOaOrganize: async () => {
    const result = await serviceBackendUser.post("/oa/organize/roles", {});
    return result?.data || {};
  },
  getUser: async (filter: IFilter) => {
    let data = {};
    if (filter.type != "" && filter.text != "") {
      data = filter;
    }
    const result = await serviceBackendUser.post("/oa/user", data);
    return result?.data || {};
  },
  getFileByID: async (id) => {
    const result = await serviceBackendUser.get(
      `/link/v1/process-log/psid/${id}`
    );
    return result?.data || {};
  },
  getFileAlllog: async () => {
    const result = await serviceBackendUser.get(`/link/v1/process-log`);
    return result?.data || {};
  },
  getAllRunjob: async () => {
    const result = await serviceBackendUser.get(`/link/v1/job-running`);
    return result?.data || {};
  },
});
