import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { Icon } from "@iconify/react";
import { FileDoneOutlined, UploadOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import { ImportFileTarget } from "./components/ImportFileTarget";
const appConfig = loadAppConfig();

interface Props {
  baseUrl: string;
}
const { Option } = Select;
export const ImporTrargetInFormationRoute: React.FC<Props> = ({
  baseUrl,
}): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const { path } = useRouteMatch();

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <FileDoneOutlined /> IMPORT FILE TARGET
          </b>
        </Card>
      </Row>
      <ImportFileTarget baseUrl={baseUrl} />
    </>
  );
};
