import axios, { AxiosInstance } from "axios";
import { loadAppConfig } from "../../../config/app-config";
import { serviceBackend, serviceBackendDebt } from "../../../drivers/service/axiosBackend";
import { user } from "../common";
import { IUser } from "../../oaProfile/common";

const appConfig = loadAppConfig();
function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
export interface ITeamService {
  getTeamList: () => Promise<any>;
  getTeamListBank: (type: string) => Promise<any>;
  updateTeamById: (data: any) => Promise<string>;
  createTeam: (data: any) => Promise<string>;
  getOaList: (data: any) => Promise<any>;
  getUserBankList: () => Promise<user[]>;
  getDetailUser: () => Promise<any>;
  getUserList: () => Promise<string>;
  updateCondition: (userBank: any, idDistribution: string, expression: []) => Promise<any>;
  checkCondition: (data: any) => Promise<any>;
  findbankuser: () => Promise<any>;
  searchuserbyid: (userId: number) => Promise<any>;
  checkdeleteoa: (oaCode: string, teamId: string) => Promise<any>;
  getProfile: () => Promise<IUser[]>;
}

export const TeamService = (serviceBackendApi: any): ITeamService => ({
  getTeamList: async () => {
    const result = await serviceBackendApi.get("/dist/all");
    return result?.data || {};
  },
  getTeamListBank: async (type) => {
    const result = await serviceBackendApi.get(`/dist/all/${type}`);
    return result?.data?.result || {};
  },
  updateTeamById: async (data: any) => {
    // serviceBackend
    const result = await serviceBackendApi.put("/dist/" + data.id, data);
    return result?.data || {};
  },

  createTeam: async (data: any) => {
    // serviceBackend
    const result = await serviceBackendApi.post("/dist/", data);
    return result?.data || {};
  },

  getOaList: async (data: any) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/search/oaBytoken");
    return result?.data || {};
  },
  getUserBankList: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userBankAll");
    return result?.data?.result || {};
  },
  getDetailUser: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userDetails");
    return result?.data || {};
  },
  getUserList: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userList");
    return result?.data || {};
  },
  updateCondition: async (userBank, idDistribution, expression) => {
    // serviceBackendDebt
    const newuserBank = {
      data: userBank,
      id: idDistribution,
    };
    const result = await serviceBackendApi.put("/debtor/updateTeamCondition", newuserBank);
    return result?.data?.result || {};
  },
  checkCondition: async (dataoa) => {
    // serviceBackendDebt
    const newData = {
      data: dataoa,
    };
    const result = await serviceBackendApi.post("/search/checkCondition", newData);
    return result?.data?.result || {};
  },
  findbankuser: async () => {
    const result = await serviceBackendApi.post("/search/bankUser");
    return result?.data?.result || {};
  },
  searchuserbyid: async (userId) => {
    const result = await serviceBackendApi.get(`/debtor/userDetailsByid/${userId}`);
    return result?.data?.result || {};
  },
  checkdeleteoa: async (oaCode: string, teamId: string) => {
    const result = await serviceBackendApi.post(`/user/teamDelOa`, {
      oaCode: oaCode,
      teamId: teamId,
    });
    return result?.data || {};
  },
  getProfile: async () => {
    const result = await serviceBackendApi.get("/profile/user");
    return result?.data || {};
  },
});
