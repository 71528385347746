import React, { useState, useRef } from "react";
import type { ColumnsType } from "antd/lib/table";
import { Card, Button, Select, Modal, Table, Radio, Collapse, Form, message, Spin } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { LeadManagementService } from "../services/LeadManagementService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";

const appConfig = loadAppConfig();

interface TDataLead {
  id: string;
  cif: string;
  account_no: string;
  list_name_real: string;
  account_level: string;
  overdue_amt: string;
  noactiondate: number;
  oa_code: string;
  action_code_id: string;
  cust_name: string;
  cust_last_name: string;
  oa_name: string;
  name: string;
  assign_time: string;
  closepayamt?: string;
  account_status: string;
  channel: string;
}

export interface Props {
  accountData: any;
  filterCollector: any;
  onclickCollectorAssignData: (condition: any) => void;
  collectorSelect: any;
}

const { Panel } = Collapse;
const { Option } = Select;

export const LeadManagementAdd: React.FC<Props> = ({ accountData, filterCollector, onclickCollectorAssignData, collectorSelect }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const [isModalVisible, setIsModalTeam] = useState(false);
  const [showcardteam, setShowCardTeam] = useState(true);
  const [showcardoa, setShowCardOA] = useState(true);
  const [panelShow, setPanelShow] = useState(false);
  const [teamValue, setTeamValue] = useState<any[]>([]);
  const [panelAction, setPanelAction] = useState("1");
  const [OnCollector, setOnCollector] = useState("");
  const [collectorValue, setCollectorValue] = useState<Number>(0);
  const [loading, setLoading] = useState(false);
  const refCollector = useRef<any>(null);
  const [GroupData, setGroupData] = useState<any[]>([]);
  const [form] = Form.useForm();

  const handleCancelTeam = () => {
    setIsModalTeam(false);
  };

  const onClickCollectorAssign = async () => {
    if (accountData.length > 0 || accountData != "") {
      if (collectorValue) {
        Modal.confirm({
          title: "คุณแน่ใจแล้วใช่ไหม",
          icon: <ExclamationCircleOutlined />,
          content: "กดยืนยันเพื่อ Assign",
          okText: "ยืนยัน",
          cancelText: "ยกเลิก",
          onOk: async () => {
            const resDataLead = await LeadManagementService(apiBaseUrlDebt).getDataForSearch(accountData);
            const condition = {
              condition: {
                id: {
                  in: await resDataLead.map((x: TDataLead) => {
                    return +x.id;
                  }),
                },
              },
              adminOaId: Number(collectorValue),
            };
            onclickCollectorAssignData(condition);
          },
        });
      } else {
        message.warning("กรุณาเลือก OA");
      }
    } else {
      message.warning("กรุณาเลือกบัญชีลูกหนี้");
    }
    setLoading(false);
  };

  const Team: React.ReactNode[] = [];
  for (let i = 1; i < 10; i++) {
    Team.push(
      <Option key={"TeamKey" + i} value={"Team" + i}>
        {"Team" + i}
      </Option>,
    );
  }

  const handleChangeCollector = async (value: number) => {
    setCollectorValue(value);
  };

  // const data = GroupData.map((record: any) => ({
  //   key: record,
  //   list: "List",
  //   team: record,
  //   oa: "Green",
  //   account_old: "Green",
  //   account_new: "Green",
  //   account_total: "Green",
  //   outstanding_old: "Green",
  //   outstanding_new: "Green",
  //   outstanding_total: "Green",
  // }));

  const genExtra = () => (
    <>
      {panelShow === false ? (
        <PlusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(true);
            setPanelAction("1");
          }}
        />
      ) : (
        <MinusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(false);
            setPanelAction("0");
          }}
        />
      )}
    </>
  );

  // const columns: ColumnsType<any> = [
  //   {
  //     title: "List",
  //     dataIndex: "list",
  //     key: "List",
  //   },
  //   {
  //     title: "Team",
  //     dataIndex: "team",
  //     key: "Team",
  //   },
  //   {
  //     title: "OA",
  //     dataIndex: "oa",
  //     key: "OA",
  //   },
  //   {
  //     title: "Account(old)",
  //     dataIndex: "account_old",
  //     key: "Account(old)",
  //   },
  //   {
  //     title: "Account(new)",
  //     dataIndex: "account_new",
  //     key: "Account(new)",
  //   },
  //   {
  //     title: "Account(total)",
  //     dataIndex: "account_total",
  //     key: "Account(total)",
  //   },
  //   {
  //     title: "Outstanding(old)",
  //     dataIndex: "outstanding_old",
  //     key: "Outstanding(old)",
  //   },
  //   {
  //     title: "Outstanding(new)",
  //     dataIndex: "outstanding_new",
  //     key: "Outstanding(new)",
  //   },
  //   {
  //     title: "Outstanding(total)",
  //     dataIndex: "outstanding_total",
  //     key: "Outstanding(total)",
  //   },
  // ];

  return (
    <>
      <Form>
        <Collapse>
          <Panel
            forceRender={true}
            extra={genExtra()}
            showArrow={false}
            header="Assign To"
            key="1"
            style={{
              backgroundColor: "white",
              width: "100%",
              textAlign: "left",
            }}
          >
            <Radio.Group>
              <Form.Item>
                <Radio
                  value="OA"
                  ref={refCollector}
                  onClick={() => {
                    if (filterCollector === "" || filterCollector === undefined) {
                      refCollector.current.focus();
                      setOnCollector("");
                      message.warning("กรุณาเลือก Collector");
                      return false;
                    } else {
                      setShowCardTeam(true), setShowCardOA(false), setOnCollector("");
                    }
                  }}
                >
                  Collector
                </Radio>
              </Form.Item>
            </Radio.Group>
          </Panel>
        </Collapse>
      </Form>
      <Card title="Collector List :" bordered={false} hidden={showcardoa} style={{ width: "100%", textAlign: "left", marginTop: "10px" }}>
        <Form>
          <Form.Item>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
              style={{ width: "100%" }}
              placeholder="Please Select"
              onChange={handleChangeCollector}
            >
              {collectorSelect.map((collector: any, index: number) => {
                return filterCollector != collector.id ? (
                  <Option key={index} value={collector.id}>
                    {collector.name}
                  </Option>
                ) : (
                  <></>
                );
              })}
            </Select>
            <Button
              type="primary"
              style={{
                marginTop: "20px",
              }}
              onClick={onClickCollectorAssign}
            >
              Assign
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {/* <Modal
        title="Simulate"
        open={isModalVisible}
        onCancel={handleCancelTeam}
        width={1200}
        footer={[
          <Button key="back" onClick={handleCancelTeam}>
            Close
          </Button>,
        ]}
      >
        <Table columns={columns} dataSource={teamValue} pagination={false} />
      </Modal> */}
      {/* <Modal
        title="Simulate"
        open={isModalVisibleoa}
        onCancel={handleCancelOa}
        confirmLoading={loading}
        width={1200}
        footer={[
          <Button key="back" onClick={handleCancelOa}>
            Close
          </Button>,
        ]}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </Modal> */}
    </>
  );
};
