import React, { useState, useEffect } from "react";
import { Card, Row, Col, Table, Typography, Input, Select, Space } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { PlayCircleOutlined } from "@ant-design/icons";
import { BatchProcessFileService } from "../services/service";
// import notification, { NotificationPlacement } from "antd/lib/notification";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import dayjs from "dayjs";
const appConfig = loadAppConfig();

export const TableBatchProcessLog: React.FC = (): React.ReactElement => {
  // const { Search } = Input;
  const location: any = useLocation();
  // const { Option } = Select;
  let history = useHistory();

  const [getid, setId] = useState<any>(location.state.id.psId);

  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);

  const [dataTable, setDatatable] = useState<any>([]);
  const [FilenameFilter, setFilenameFilter] = useState<any>([]);
  const [ActivityFilter, setActivityFilter] = useState<any>([]);
  const [MassageFilter, setMassageFilter] = useState<any>([]);
  const [CreateAtFilter, setCreateFilter] = useState<any>([]);

  useEffect(() => {
    async function getDatafilter() {
      const res1 = (await BatchProcessFileService(
        apiBaseUrlDatalink,
      ).getFileAlllog()) as any;

      let filenameFilters: any = [];
      filenameFilters = res1.filter((contract: any) => contract.psId === getid);
      let filenameFilter: any = [];
      let fileJobIDArray = filenameFilters.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.fileName === ele.fileName),
      );
      fileJobIDArray.map((data: any) => {
        filenameFilter.push({
          text: data.fileName,
          value: data.fileName,
        });
      });
      await setFilenameFilter(filenameFilter);

      let fileactivityFilters: any = [];
      fileactivityFilters = res1.filter(
        (contract: any) => contract.psId === getid,
      );
      let fileactivityFilter: any = [];
      let fileactivityArray = fileactivityFilters.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.activity === ele.activity),
      );
      fileactivityArray.map((data: any) => {
        fileactivityFilter.push({
          text: data.activity,
          value: data.activity,
        });
      });
      await setActivityFilter(fileactivityFilter);

      let fileMassageFilters: any = [];
      fileMassageFilters = res1.filter(
        (contract: any) => contract.psId === getid,
      );
      let fileMassageFilter: any = [];
      let fileMassageArray = fileMassageFilters.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.message === ele.message),
      );
      fileMassageArray.map((data: any) => {
        fileMassageFilter.push({
          text: data.message,
          value: data.message,
        });
      });
      await setMassageFilter(fileMassageFilter);

      let fileCreateFilters: any = [];
      fileCreateFilters = res1.filter(
        (contract: any) => contract.psId === getid,
      );
      let fileCreateFilter: any = [];
      let fileCreateFilterArray = fileCreateFilters.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.createAt === ele.createAt),
      );
      fileCreateFilterArray.map((data: any) => {
        fileCreateFilter.push({
          text:
            data.createAt === null ||
            data.createAt === undefined ||
            data.createAt === "" ? (
              <></>
            ) : (
              dayjs(data.createAt)
                .add(543, "year")
                .format("DD-MM-YYYY HH:MM:ss")
            ),
          value: data.createAt,
        });
      });
      await setCreateFilter(fileCreateFilter);
    }
    getDatafilter();
    getDataTable();
  }, []);

  const getDataTable = async () => {
    const res = (await BatchProcessFileService(apiBaseUrlDatalink).getFileByID(
      getid,
    )) as any;
    let convertData: any = [];
    await res.map((e: any, i: number) => {
      convertData.push({
        no: i + 1,
        psId: e.psId,
        activity: e.activity,
        fileName: e.fileName,
        jobId: e.jobId,
        createAt: e.createAt,
        message: e.message,
        pslId: e.pslId,
      });
    });
    setDatatable(convertData);
  };
  const onSearch = (value: string) => console.log(value);

  const columns: any = [
    {
      title: "PsID",
      dataIndex: "psId",
      key: "psId",
      width: "10%",
      sorter: (a: any, b: any, key: number) => a.psId - b.psId,
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      width: "35%",
      filters: FilenameFilter,
      onFilter: (value: any, record: any, key: number) =>
        record.fileName.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      width: "20%",
      filters: ActivityFilter,
      onFilter: (value: any, record: any, key: number) =>
        record.activity.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Massage",
      dataIndex: "message",
      key: "message",
      width: "20%",
      filters: MassageFilter,
      onFilter: (value: any, record: any, key: number) =>
        record.message.startsWith(value),
      filterSearch: true,
    },

    {
      title: "Create At",
      dataIndex: "createAt",
      key: "createAt",
      width: "20%",
      filters: CreateAtFilter,
      onFilter: (value: any, record: any, key: number) =>
        record.createAt.startsWith(value),
      filterSearch: true,
      render: (e: any) => {
        if (e === null || e === undefined || e === "") {
          return <></>;
        } else {
          return <>{dayjs(e).add(543, "year").format("DD-MM-YYYY HH:mm:ss")}</>;
        }
      },
    },
  ];
  return (
    <div>
      <Card
        title={
          <>
            <Row>
              <Col span={24} style={{ textAlign: "left" }}>
                <Space>
                  <PlayCircleOutlined
                    style={{
                      width: 24,
                      height: 24,
                      // color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    rotate={180}
                    onClick={() => history.goBack()}
                  />
                  <Typography style={{ textAlign: "left", fontWeight: "bold" }}>
                    Process Log
                  </Typography>
                  <Typography style={{ color: "grey" }}>
                    ({location.state.id.fileName})
                  </Typography>
                </Space>
              </Col>
            </Row>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey={(record: any) => record.id}
        />
      </Card>
    </div>
  );
};
