import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Modal, Form, Input, message, TablePaginationConfig } from "antd";
import { DebtService } from "../../services/debt-service";
import { CheckCircleOutlined } from "@ant-design/icons";
import { loadAppConfig } from "../../../../config/app-config";
import { useId24 } from "../../../../drivers/id24/Id24-provider";

const validatePhone = (number: string) => {
  if (!number) {
    return true;
  }
  return /^[0-9]{1,10}$/.test(number);
};

const appConfig = loadAppConfig();
export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  type: string;
  cifId: string;
  debtContact: any;
}

export interface TPagination {
  current: number;
  pageSize: number;
}

export interface IDatatype {
  key: string;
  contactId: string;
  custName: string;
  idCard: string;
  custStatus: string;
  work: string;
  homePhone: string;
  officePhone: string;
  mobilePhone: string;
  add1: string;
  add2: string;
  add3: string;
  add4: string;
  dist: string;
  city: string;
  state: string;
  accountNo: string;
}

export const TableByOa: React.FC<Props> = ({ accountNo, cifId, debtContact }): React.ReactElement => {
  const debtService = DebtService;
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [createFormContact] = Form.useForm();
  const [editFormContact] = Form.useForm();
  const [resData, setResData] = useState<any[]>([]);
  const [visibleModal1, setVisibleModal1] = useState<boolean>(false);
  const [visibleModal2, setVisibleModal2] = useState<boolean>(false);
  const [disabledbutton, setDisabled] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    getDataTable();
  }, [cifId]);

  function onChange(pagination: TablePaginationConfig) {
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
  }

  const getDataTable = async () => {
    const result = await debtService(apiBaseUrlDebt).getDebtContactOa(cifId);
    const data = await result.map((x: any, index: number) => {
      const mapData = {
        key: index,
        contactId: x.id,
        custName: debtContact.custName + " " + debtContact.custLastName,
        idCard: debtContact.idCard,
        custStatus: debtContact.status,
        work: debtContact.work,
        homePhone: x.homePhone,
        officePhone: x.officePhone,
        mobilePhone: x.mobilePhone,
        add1: x.add1,
        add2: x.add2,
        add3: x.add3,
        add4: x.add4,
        dist: x.dist,
        city: x.city,
        state: x.state,
        accountNo: x.accountNo,
      };
      return mapData;
    });
    data.sort((a: any, b: any) => a.contactId - b.contactId);
    setResData(data);
  };

  const columns: any = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (event: string, row: IDatatype, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "ชื่อ - นามสกุล",
      dataIndex: "custName",
      key: "custName",
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
    },
    {
      title: "อาชีพ",
      dataIndex: "work",
      key: "work",
    },
    {
      title: "เบอร์โทรศัพท์บ้าน",
      dataIndex: "homePhone",
      key: "homePhone",
    },
    {
      title: "เบอร์โทรศัพท์ที่ทำงาน",
      dataIndex: "officePhone",
      key: "officePhone",
    },
    {
      title: "เบอร์มือถือ",
      dataIndex: "mobilePhone",
      key: "mobilePhone",
    },
    {
      title: "ที่อยู่",
      dataIndex: "add1",
      key: "add1",
      render: (e: string, row: any, index: number) => {
        return <>{`${row.add1} ${row.add2} ${row.add3} ${row.add4}`}</>;
      },
    },
    {
      title: "ตำบล",
      dataIndex: "dist",
      key: "dist",
    },
    {
      title: "อำเภอ",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "จังหวัด",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Action",
      dataIndex: "contactId",
      key: "contactId",
      align: "center",
      render: (id: number, row: any) => {
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <a
                onClick={() => {
                  showModal2(row);
                }}
              >
                แก้ไข
              </a>
              <a
                style={{ marginLeft: 20 }}
                onClick={() => {
                  deleteContact(id);
                }}
              >
                ลบ
              </a>
            </div>
          </>
        );
      },
    },
  ];

  const showModal1 = () => {
    setVisibleModal1(true);
    createFormContact.resetFields();
  };
  const handleCancel1 = () => {
    setVisibleModal1(false);
    createFormContact.resetFields();
  };
  const showModal2 = (form: any) => {
    if (form) {
      editFormContact.setFieldValue("contactId", form.contactId);
      editFormContact.setFieldValue("add1", form.add1);
      editFormContact.setFieldValue("add2", form.add2);
      editFormContact.setFieldValue("add3", form.add3);
      editFormContact.setFieldValue("add4", form.add4);
      editFormContact.setFieldValue("dist", form.dist);
      editFormContact.setFieldValue("city", form.city);
      editFormContact.setFieldValue("state", form.state);
      editFormContact.setFieldValue("officePhone", form.officePhone != "" ? form.officePhone : "");
      editFormContact.setFieldValue("mobilePhone", form.mobilePhone);
      editFormContact.setFieldValue("homePhone", form.homePhone != "" ? form.homePhone : "");
      setVisibleModal2(true);
    }
  };
  const handleCancel2 = () => {
    setVisibleModal2(false);
    editFormContact.resetFields();
  };

  const checkNullvalueOA = (changedValues: any, allValues: any) => {
    if (changedValues) {
      if (
        (changedValues.add1 != undefined && changedValues.add1 != "") ||
        changedValues.add2 != undefined ||
        changedValues.add3 != undefined ||
        changedValues.add4 != undefined ||
        (changedValues.dist != undefined && changedValues.dist != "") ||
        (changedValues.city != undefined && changedValues.city != "") ||
        (changedValues.state != undefined && changedValues.state != "") ||
        (changedValues.homePhone != undefined && changedValues.homePhone != "") ||
        (changedValues.mobilePhone != undefined && changedValues.mobilePhone != "") ||
        (changedValues.officePhone != undefined && changedValues.officePhone != "")
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      if (
        (allValues.add1 != undefined && allValues.add1 != "") ||
        allValues.add2 != undefined ||
        allValues.add3 != undefined ||
        allValues.add4 != undefined ||
        (allValues.dist != undefined && allValues.dist != "") ||
        (allValues.city != undefined && allValues.city != "") ||
        (allValues.state != undefined && allValues.state != "") ||
        (allValues.homePhone != undefined && allValues.homePhone != "") ||
        (allValues.mobilePhone != undefined && allValues.mobilePhone != "") ||
        (allValues.officePhone != undefined && allValues.officePhone != "")
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const addContactbtOa = async (formData: any) => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการเพิ่มข้อมูลติดต่อ",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        let addDetail = {
          cif: cifId,
          accountNo: accountNo,
          chn: "OA",
          add1: formData.add1 != undefined ? formData.add1 : "",
          add2: formData.add2 != undefined ? formData.add2 : "",
          add3: formData.add3 != undefined ? formData.add3 : "",
          add4: formData.add4 != undefined ? formData.add4 : "",
          homePhone: formData.homePhone != undefined ? formData.homePhone : "",
          mobilePhone: formData.mobilePhone != undefined ? formData.mobilePhone : "",
          officePhone: formData.officePhone != undefined ? formData.officePhone : "",
          dist: formData.dist != undefined ? formData.dist : "",
          city: formData.city != undefined ? formData.city : "",
          state: formData.state != undefined ? formData.state : "",
        };
        (await debtService(apiBaseUrlDebt)
          .createDebtContactOa(addDetail)
          .then(() => {
            message.success("เพิ่มข้อมูลเรียบร้อย");
            createFormContact.resetFields();
            setDisabled(true);
          })) as any;
        setVisibleModal1(false);
        getDataTable();
      },
    });
  };

  const editContactbtOa = async (form: any) => {
    if (form) {
      Modal.confirm({
        title: "ยืนยัน",
        icon: <CheckCircleOutlined />,
        content: "ยืนยันการแก้ไขข้อมูลติดต่อ",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          let id = Number(form.contactId);
          let cif = cifId;
          let data = {
            id: id,
            data: {
              cif: cif,
              accountNo: accountNo,
              chn: "OA",
              add1: form.add1,
              add2: form.add2,
              add3: form.add3,
              add4: form.add4,
              homePhone: form.homePhone,
              mobilePhone: form.mobilePhone,
              officePhone: form.officePhone,
              dist: form.dist,
              city: form.city,
              state: form.state,
              updateDate: new Date(),
            },
          };
          (await debtService(apiBaseUrlDebt)
            .updateDebtContactOa(data)
            .then(() => {
              message.success("แก้ไขข้อมูลเรียบร้อย");
              setDisabled(true);
              editFormContact.resetFields();
            })
            .catch((err: any) => {
              console.log(err);
            })) as any;
          setVisibleModal2(false);
          getDataTable();
        },
      });
    }
  };
  const deleteContact = async (id: number) => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการลบข้อมูลติดต่อ",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        id = Number(id);
        (await debtService(apiBaseUrlDebt)
          .deleteDebtContactOa(id)
          .then(() => {
            message.success("ลบข้อมูลเรียบร้อย");
          })) as any;
        getDataTable();
      },
    });
  };

  return (
    <>
      <Row>
        <Col span={24} style={{ textAlign: "right", marginBottom: 20 }}>
          <Button type="primary" onClick={showModal1}>
            เพิ่มข้อมูลติดต่อ
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        sortDirections={["descend", "ascend"]}
        columns={columns}
        dataSource={resData}
        rowKey={(record) => record.key}
        scroll={{ x: "calc(500px + 100%)" }}
        pagination={{ pageSize: 5 }}
        onChange={onChange}
      />
      <Modal
        style={{ top: 30 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        title="เพิ่มข้อมูลติดต่อของ OA"
        open={visibleModal1}
        footer={false}
        onCancel={handleCancel1}
      >
        <Form
          form={createFormContact}
          autoComplete="off"
          layout="vertical"
          name="actionForm"
          onFinish={addContactbtOa}
          onValuesChange={checkNullvalueOA}
        >
          <Form.Item label="ข้อมูลที่อยู่ 1 : " name="add1">
            <Input placeholder="text" name="add1" />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 2 : " name="add2">
            <Input placeholder="text" name="add2" />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 3 : " name="add3">
            <Input placeholder="text" name="add3" />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 4 : " name="add4">
            <Input placeholder="text" name="add4" />
          </Form.Item>
          <Form.Item label="ตำบล : " name="dist">
            <Input placeholder="text" name="dist" />
          </Form.Item>
          <Form.Item label="อำเภอ : " name="city">
            <Input placeholder="text" name="city" />
          </Form.Item>
          <Form.Item label="จังหวัด : " name="state">
            <Input placeholder="text" name="state" />
          </Form.Item>
          <Form.Item
            label="เบอร์โทรศัพท์ที่ทำงาน : "
            name="officePhone"
            rules={[
              {
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์"));
                },
              },
            ]}
          >
            <Input maxLength={10} placeholder="number" />
          </Form.Item>
          <Form.Item
            label="เบอร์โทรศัพท์มือถือ : "
            name="mobilePhone"
            rules={[
              {
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์"));
                },
              },
            ]}
          >
            <Input maxLength={10} placeholder="number" />
          </Form.Item>
          <Form.Item
            label="เบอร์โทรศัพท์บ้าน : "
            name="homePhone"
            rules={[
              {
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์"));
                },
              },
            ]}
          >
            <Input maxLength={10} placeholder="number" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button key="back" onClick={handleCancel1}>
              ยกเลิก
            </Button>

            <Button type="primary" htmlType="submit" style={{ marginLeft: 5 }} disabled={disabledbutton}>
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="แก้ไขข้อมูลติดต่อของ OA" open={visibleModal2} footer={false} onCancel={handleCancel2}>
        <Form
          form={editFormContact}
          autoComplete="off"
          layout="vertical"
          name="actionForm"
          onFinish={editContactbtOa}
          onValuesChange={checkNullvalueOA}
        >
          <Form.Item name="contactId" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 1 : " name="add1">
            <Input placeholder="text" name="add1" />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 2 : " name="add2">
            <Input placeholder="text" name="add2" />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 3 : " name="add3">
            <Input placeholder="text" name="add3" />
          </Form.Item>
          <Form.Item label="ข้อมูลที่อยู่ 4 : " name="add4">
            <Input placeholder="text" name="add4" />
          </Form.Item>
          <Form.Item label="ตำบล : " name="dist">
            <Input placeholder="text" name="dist" />
          </Form.Item>
          <Form.Item label="อำเภอ : " name="city">
            <Input placeholder="text" name="city" />
          </Form.Item>
          <Form.Item label="จังหวัด : " name="state">
            <Input placeholder="text" name="state" />
          </Form.Item>
          <Form.Item
            label="เบอร์โทรศัพท์ที่ทำงาน : "
            name="officePhone"
            rules={[
              {
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์"));
                },
              },
            ]}
          >
            <Input maxLength={10} placeholder="number" />
          </Form.Item>
          <Form.Item
            label="เบอร์โทรศัพท์มือถือ : "
            name="mobilePhone"
            rules={[
              {
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์"));
                },
              },
            ]}
          >
            <Input maxLength={10} placeholder="number" />
          </Form.Item>
          <Form.Item
            label="เบอร์โทรศัพท์บ้าน : "
            name="homePhone"
            rules={[
              {
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์"));
                },
              },
            ]}
          >
            <Input maxLength={10} placeholder="number" />
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button key="back" onClick={handleCancel2}>
              ยกเลิก
            </Button>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 5 }} disabled={disabledbutton}>
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
