import { Card, Row, Col, Input, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FilterTeamManagement } from "./components/FilterTeamManagement";
import { TableTeamManagement } from "./components/TableTeamManagement";
import { LoadingOutlined, TeamOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { IUser } from "../oaProfile/common";
import { TeamService } from "./services/team-service";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();

export interface Props {
  userId: string;
  accessRole: any;
}
export interface DataType {
  key: number;
  no: number;
  user: string;
  description: string;
  name: string;
  updateBy: string;
  updateDate: any;
}
export interface IDataTable {
  id: string;
  name: string;
  description: string;
  user: string;
  assignBy: any;
  updateBy: any;
  updateDate: string;
  createDate: string;
  cifGrouping: boolean;
  assignMode: string;
  createBy: string;
  detail: any[];
}
export interface OaType {
  id: number;
  name: string;
}

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const TeamManagement: React.FC<Props> = ({ userId, accessRole }) => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [proFile, setProfile] = useState<IUser>({
    id: 0,
    type: "",
    username: "",
    name: "",
    oaCode: "",
    capabilities: {},
    level: "",
    team: "",
    token: "",
    active: false,
    parentId: "",
    parentNode: "",
    phone: "",
    email: "",
    oaOrganize: "",
    subOrganizationProfile: [],
    extension: "",
  });
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const [countDataTable, setCountDataTable] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [listUsers, setListUsers] = useState<OaType[]>([]);
  const [detailUser, setDetailUser] = useState<any[]>([]);
  const [oaList, setOalist] = useState<OaType[]>([]);

  useEffect(() => {
    featchData();
  }, []);

  const featchData = async () => {
    setLoading(true);
    const resultTeam = await TeamService(apiBaseUrl).getTeamListBank("BANK");
    const resProfile = await TeamService(apiBaseUrlUser).getProfile();
    const resListUser = await TeamService(apiBaseUrlDebt).findbankuser();
    const resDetailUser = await TeamService(apiBaseUrlDebt).getDetailUser();
    const resOaList = await TeamService(apiBaseUrlDebt).getOaList({});
    const filterOalist = resOaList.result
      .map((x: { admin_user_id: number; oa_name: string }) => {
        return {
          id: Number(x.admin_user_id),
          name: x.oa_name,
        };
      })
      .filter((adminuserID: any) => adminuserID.id !== 0);
    const sortTeam = resultTeam.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
    setOalist(filterOalist);
    setDetailUser(resDetailUser);
    setListUsers(resListUser);
    setDataTable(sortTeam);
    setCountDataTable(resultTeam.length);
    setProfile(resProfile[0]);
    setLoading(false);
  };

  const onSearch = async (type: string, text: string) => {
    if (type === "none" || type === undefined) {
      message.error("กรุณาเลือก Filter ก่อนค้นหา");
    } else {
      if (type === "TeamName") {
        text === "" ? featchData() : setDataTable(dataTable.filter((i: any) => i.name.match(text)));
      } else if (type == "TeamDescription") {
        text === "" ? featchData() : setDataTable(dataTable.filter((i: any) => i.description.match(text)));
      } else if (type == "UpdateBy") {
        text === "" ? featchData() : setDataTable(dataTable.filter((i: any) => i.updateBy.match(text)));
      }
    }
  };

  return (
    <React.Fragment>
      <Spin tip="Loading..." spinning={loading} indicator={antIcon}>
        <Row>
          <Card style={{ width: "100%", textAlign: "left" }}>
            <b style={{ color: "#0056B4", fontSize: "24px" }}>
              {" "}
              <TeamOutlined style={{ color: "#0056B4" }} />
              TEAM MANAGEMENT
            </b>
          </Card>
        </Row>

        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <FilterTeamManagement proFile={proFile} onSearch={onSearch} accessRole={accessRole} featchData={featchData} />
              <TableTeamManagement
                proFile={proFile}
                dataTable={dataTable}
                countDataTable={countDataTable}
                listUsers={listUsers}
                detailUser={detailUser}
                oaList={oaList}
                accessRole={accessRole}
                featchData={featchData}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};
