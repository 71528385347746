import React, { useState, useEffect } from "react";
import { Button, Modal, Select, Table } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import type { ColumnsType, TableProps } from "antd/es/table";
import { IFilter, BatchProcessServiceDailyFile } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();
const { Option } = Select;

// New dayjs
import dayjs from "dayjs";
import "dayjs/locale/th";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style";

const DatePicker = generatePicker(dayjsGenerateConfig);
var buddhistEra = require("dayjs/plugin/buddhistEra");
dayjs.extend(buddhistEra);
const { RangePicker } = DatePicker;
dayjs.locale("th");
const format = "HH:mm";

interface IDataTable {
  key: React.Key;
  no: number;
  fcId: number;
  rmId: number;
  fileName: string;
  categoryService: string;
  details: string;
  timeOption: string;
  active: boolean;
  createAt: string;
}
interface Props {
  fillterData: IFilter;
  userId: string;
  reloadTable: number;
  loading: boolean;
  handleLoadingTable: (load: boolean) => void;
  accessRole: string;
}

export const TableBatchProcessDailyFile: React.FC<Props> = ({
  fillterData,
  userId,
  reloadTable,
  loading,
  handleLoadingTable,
  accessRole,
}) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  // const handleCancelReset = () => {
  //   setIsModalResetVisible(false);
  // };

  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isModalResetVisible, setIsModalResetVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  // const [editFormContact] = Form.useForm();

  const [fileNameFilter, setFileNameFilter] = useState<any>([]);
  const [fileCategoryServiceFilter, setFileCategoryServiceFilter] =
    useState<any>([]);
  const [fileDetailFilter, setFileDetailFilter] = useState<any>([]);
  const [fileTimeOptionFilter, setFileTimeOptionFilter] = useState<any>([]);
  const [fileCreateDateFilter, setFileCreateDateFilter] = useState<any>([]);

  // const initialValuesTable: IDataTable[] = [
  //   {
  //     key: 0,
  //     no: 0,
  //     fcId: 0,
  //     rmId: 0,
  //     fileName: "",
  //     categoryService: "",
  //     details: "",
  //     timeOption: "",
  //     active: true,
  //     createAt: "",
  //   },
  // ];

  const [dataSource, setDataSource] = useState<IDataTable[]>([]);
  const columns: ColumnsType<IDataTable> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      fixed: "left",
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: "Name",
      dataIndex: "fileName",
      key: "fileName",
      filters: fileNameFilter,
      onFilter: (value: any, record) => record.fileName?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Category Service",
      dataIndex: "categoryService",
      key: "categoryService",
      filters: fileCategoryServiceFilter,
      onFilter: (value: any, record) =>
        record.categoryService?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      filters: fileDetailFilter,
      onFilter: (value: any, record) => record.details?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Run Time",
      dataIndex: "timeOption",
      key: "timeOption",
      filters: fileTimeOptionFilter,
      onFilter: (value: any, record) => record.timeOption?.startsWith(value),
      filterSearch: true,
      render: (data: any, row: any, index: number) => {
        if (data === "null") {
          return "-";
        } else {
          let obj: any = JSON.parse("[" + data + "]");
          let date = obj[0]?.psDate;
          let conDate = "";
          if (date) {
            conDate = dayjs(date).add(543, "year").format("DD-MM-YYYY");
          }
          // let startHour = obj[0]?.start?.hour;
          // let startMinute = obj[0]?.start?.minute;
          // let endHour = obj[0]?.end?.hour;
          // let endMinute = obj[0]?.end?.minute;
          // let convert: any = 0;
          let startHour = obj[0]?.start?.hour;
          let lengthstartHour =
            startHour.toString().length === 1 ? "0" + startHour : startHour;
          let startMinute = obj[0]?.start?.minute;
          let lengthsstartMinute =
            startMinute.toString().length === 1
              ? "0" + startMinute
              : startMinute;
          let endHour = obj[0]?.end?.hour;
          let lengthsendHour =
            endHour.toString().length === 1 ? "0" + endHour : endHour;
          let endMinute = obj[0]?.end?.minute;
          let lengthsendMinute =
            endMinute.toString().length === 1 ? "0" + endMinute : endMinute;
          let convert: any = 0;
          convert = JSON.stringify(
            lengthstartHour +
              ":" +
              lengthsstartMinute +
              " - " +
              lengthsendHour +
              ":" +
              lengthsendMinute +
              " น.",
          );

          return convert.toString().replace(/"/g, "");
        }
      },
    },
    {
      title: "Update Date",
      dataIndex: "createAt",
      key: "createAt",
      filters: fileCreateDateFilter,
      onFilter: (value: any, record) => record.createAt.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY HH:mm:ss")
          : "-";
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      fixed: "right",
      align: "center",
      render: (data: boolean, row: any, index: number) => {
        if (data === true) {
          return (
            <div style={{ textAlign: "center" }}>
              <Button
                onChange={(number: any) => setSwitchChecked(number)}
                onClick={() => {
                  onClickChecked(data, row, index);
                }}
                style={{
                  border: "none",
                  backgroundColor: "#1890ff",
                  color: "white",
                  borderRadius: "20px",
                }}
              >
                On
              </Button>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              <Button
                onChange={(number: any) => setSwitchChecked(number)}
                onClick={() => {
                  onClickChecked(data, row, index);
                }}
                style={{
                  border: "none",
                  backgroundColor: "#ff4d4f",
                  color: "white",
                  borderRadius: "20px",
                }}
              >
                Off
              </Button>
            </div>
          );
        }
      },
    },
  ];

  const columnsNoAction: ColumnsType<IDataTable> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      fixed: "left",
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: "Name",
      dataIndex: "fileName",
      key: "fileName",
      filters: fileNameFilter,
      onFilter: (value: any, record) => record.fileName?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "categoryService",
      dataIndex: "categoryService",
      key: "categoryService",
      filters: fileCategoryServiceFilter,
      onFilter: (value: any, record) =>
        record.categoryService?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "details",
      dataIndex: "details",
      key: "details",
      filters: fileDetailFilter,
      onFilter: (value: any, record) => record.details?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data != "null" ? data : "-";
      },
    },
    {
      title: "Run Time",
      dataIndex: "timeOption",
      key: "timeOption",
      filters: fileTimeOptionFilter,
      onFilter: (value: any, record) => record.timeOption?.startsWith(value),
      filterSearch: true,
      render: (data: any, row: any, index: number) => {
        if (data === "null") {
          return "-";
        } else {
          let obj: any = JSON.parse("[" + data + "]");
          let date = obj[0]?.psDate;
          let conDate = "";
          if (date) {
            conDate = dayjs(date).add(543, "year").format("DD-MM-YYYY");
          }
          // let startHour = obj[0]?.start?.hour;
          // let startMinute = obj[0]?.start?.minute;
          // let endHour = obj[0]?.end?.hour;
          // let endMinute = obj[0]?.end?.minute;
          // let convert: any = 0;
          let startHour = obj[0]?.start?.hour;
          let lengthstartHour =
            startHour.toString().length === 1 ? "0" + startHour : startHour;
          let startMinute = obj[0]?.start?.minute;
          let lengthsstartMinute =
            startMinute.toString().length === 1
              ? "0" + startMinute
              : startMinute;
          let endHour = obj[0]?.end?.hour;
          let lengthsendHour =
            endHour.toString().length === 1 ? "0" + endHour : endHour;
          let endMinute = obj[0]?.end?.minute;
          let lengthsendMinute =
            endMinute.toString().length === 1 ? "0" + endMinute : endMinute;
          let convert: any = 0;
          convert = JSON.stringify(
            lengthstartHour +
              ":" +
              lengthsstartMinute +
              " - " +
              lengthsendHour +
              ":" +
              lengthsendMinute +
              " น.",
          );

          return convert.toString().replace(/"/g, "");
        }
      },
    },
    {
      title: "Create Date",
      dataIndex: "createAt",
      key: "createAt",
      filters: fileCreateDateFilter,
      onFilter: (value: any, record) => record.createAt.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY HH:mm:ss")
          : "-";
      },
    },
  ];

  // const handleDelete = async (record: any) => {
  //   const newData = dataSource?.filter((item) => item.key !== record.key);
  //   const res: any = await BatchProcessServiceDailyFile(
  //     apiBaseUrlDatalink,
  //   ).deleteDaily(record.jobId);
  //   if (res) {
  //     message.success("ลบข้อมูลสำเร็จ");
  //   }
  //   setDataSource(newData);
  // };

  const [switchChecked, setSwitchChecked] = useState<boolean>();

  // const onChangeSwitchChecked = async (check: boolean) => {
  //   await setSwitchChecked(check);
  // };

  const onClickChecked = async (data: any, row: any, index: any) => {
    handleLoadingTable(true);
    Modal.confirm({
      title: "ยืนยันการเปลี่ยนแปลง",
      icon: <ExclamationCircleOutlined />,
      content: "คุณต้องการเปลี่ยนแปลงการ Active ใช่ไหม ?",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        const resGetById: any = await BatchProcessServiceDailyFile(
          apiBaseUrlDatalink,
        ).getDailyById(row.fcId);

        if (resGetById) {
          console.log(resGetById.active);
          let active: boolean = true;
          if (resGetById.active === true) {
            active = false;
          } else {
            active = true;
          }
          let data = {
            active: active,
          };
          const resUpdate: any = await BatchProcessServiceDailyFile(
            apiBaseUrlDatalink,
          ).updateDailyById(row.fcId, data);
          if (resUpdate) {
            handleLoadingTable(false);
          }
        }
      },
      onCancel: async () => {
        handleLoadingTable(false);
      },
    });
  };

  const [count, setCount] = useState(dataSource?.length);

  useEffect(() => {
    async function fetchData() {
      const res1: any = await BatchProcessServiceDailyFile(
        apiBaseUrlDatalink,
      ).getAllDaily();

      let Filejobs = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.fileName === ele.fileName),
      );
      let fileNameArray: any = [];
      Filejobs.map((data: any) => {
        fileNameArray.push({
          text: data.fileName != null ? data.fileName : "-",
          value: data.fileName,
        });
      });
      await setFileNameFilter(fileNameArray);

      let filecategoryService: any = [];
      let FilecategoryService = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex(
            (elem: any) => elem.categoryService === ele.categoryService,
          ),
      );
      FilecategoryService.map((data: any) => {
        filecategoryService.push({
          text: data.categoryService != null ? data.categoryService : "-",
          value: data.categoryService,
        });
      });

      let fileDetails: any = [];
      let FileDetails = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.details === ele.details),
      );
      FileDetails.map((data: any) => {
        fileDetails.push({
          text: data.details != null ? data.details : "-",
          value: data.details,
        });
      });

      let fileRunTimArray: any = [];
      let FileTimeOption = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex((elem: any) => elem.timeOption === ele.timeOption),
      );
      FileTimeOption.map((data: any) => {
        let obj: any = JSON.parse("[" + data.timeOption + "]");
        let startHour = obj[0]?.start?.hour;
        let startMinute = obj[0]?.start?.minute;
        let endHour = obj[0]?.end?.hour;
        let endMinute = obj[0]?.end?.minute;
        let convert: any = JSON.stringify(
          startHour + ":" + startMinute + " - " + endHour + ":" + endMinute,
        );
        fileRunTimArray.push({
          text:
            data.timeOption != null
              ? convert.toString().replace(/"/g, "")
              : "-",
          value: data.timeOption,
        });
      });
      let fileCreateDate: any = [];
      let FileCreatedate = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.createAt === ele.createAt),
      );
      FileCreatedate.map((data: any) => {
        fileCreateDate.push({
          text: dayjs(data.createAt)
            .add(543, "year")
            .format("DD-MM-YYYY HH:mm:ss"),
          value: data.createAt,
        });
      });
      await setFileCategoryServiceFilter(filecategoryService);
      await setFileDetailFilter(fileDetails);
      await setFileTimeOptionFilter(fileRunTimArray);
      await setFileCreateDateFilter(fileCreateDate);

      let convertData: any = [];
      await res1.map((e: any, i: number) => {
        convertData.push({
          key: i,
          no: i + 1,
          fcId: e.fcId,
          rmId: e.rmId,
          fileName: e.fileName,
          categoryService: e.categoryService,
          details: e.details,
          timeOption: String(e.timeOption),
          active: Boolean(e.active),
          createAt: e.createAt,
        });
      });
      await setDataSource(convertData);
      await setCount(convertData.length);
    }
    fetchData();
  }, [loading, tableLoading, fillterData]);
  return (
    <>
      <Table
        loading={loading ? loading : tableLoading}
        columns={
          checkAccessRole("ddebtSettingBatchProcessDailyAction")
            ? columns
            : columnsNoAction
        }
        dataSource={dataSource}
        size={"small"}
        rowKey={(record: any) => record.id}
      />
    </>
  );
};
