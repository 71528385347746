import "./index.css";
import ReactDOM from "react-dom/client";
import { Id24 } from "./drivers/id24/Id24";
import { AuthService } from "./drivers/id24/services/auth-service";
import axios from "axios";
import { AuthenticationHelper } from "./drivers/id24/models/authentication-helper";
import { Id24State } from "./drivers/id24/models/Id24-state";
import App from "./App";
import { CookiesProvider } from "react-cookie";

import { loadAppConfig } from "./config/app-config";
const appConfig = loadAppConfig();
(async () => {
  var host = window.location.host;
  let baseUrl = host;
  if (host === "localhost:3000") {
    baseUrl = "https://user.ddebtuat.gsb.or.th";
    // baseUrl = "https://id24-demo.osd.co.th";
  } else {
    baseUrl = appConfig.apiBaseUrlDebtId24;
  }
  const authUrl = baseUrl;
  const clientId: any = process.env.REACT_APP_CLIENT_ID;
  const id24 = Id24(
    window,
    // localStorage,
    sessionStorage,
    AuthService(
      axios.create({
        baseURL: authUrl,
        withCredentials: true,
      }),
    ),
    authUrl,
    clientId,
  );
  const id24Instance = await id24.init();
  const { authorize, renewAccessToken, logout, reloadPage } = id24;
  const authenticationHelper: AuthenticationHelper = {
    authorize,
    renewAccessToken: () =>
      renewAccessToken()
        .then((id24Instance) => {
          return id24Instance.state === Id24State.Authorized
            ? {
                tokenAccess: id24Instance.tokenAccess,
                rawAccessToken: id24Instance.rawAccessToken,
              }
            : null;
        })
        .catch(() => {
          return null;
        }),
    logout,
    reloadPage,
  };

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <CookiesProvider>
      <App id24Instance={id24Instance} authenticationHelper={authenticationHelper}></App>
    </CookiesProvider>,
  );
})();
