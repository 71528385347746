import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Card, Form, Row, Input, Radio, Table, Select, Col, Button, InputNumber, Typography, Modal, message, SelectProps } from "antd";
import { AdvancePage } from "./AdvancePage";
import { BasicPage } from "./BasicPage";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DatamanagementService } from "../../services/datamanagement-service";

import localizedFormat from "dayjs/plugin/localizedFormat";
import { IuserProfile } from "../../models/models-datamanagement";
import { useId24 } from "../../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../../config/app-config";
import { Role } from "../../common";
import { UserAccess } from "../../../../drivers/id24/models/user-access";
const appConfig = loadAppConfig();
dayjs.extend(localizedFormat);
interface Props {
  baseUrl: string;
  accessRole: any;
}

interface DatamanageMent {
  name: number;
  detail: [];
}

const { TextArea } = Input;

const teams: SelectProps["options"] = [];

export const AddPageRoute: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { tokenAccess, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const { push } = useHistory();
  const refConditionListName = useRef<any>(null);
  const refDescription = useRef<any>(null);
  const createDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });

  const [getConditionListName, setConditionListName] = useState<any>("");
  const [getDescription, setDescription] = useState<any>("");
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<string | number>();
  const [permissionId24, setPermissionId24] = useState<any>([]);

  function multipleInArray(arr: string[], values: Role[]) {
    return values.some((value) => {
      return arr.includes(value);
    });
  }

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  useEffect(() => {
    try {
      mapRoleAccess().then(async (resultmap) => {
        setPermissionId24(resultmap);
        const userAssign = () => {
          let AssignUserNew;
          multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
            ? (AssignUserNew = "AssignUser")
            : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
          setAssignUserNew(AssignUserNew);
          return AssignUserNew;
        };
        const userCleardist = () => {
          let AssignUserNew;
          multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
            ? (AssignUserNew = 0)
            : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
          setCleardistNew(AssignUserNew);
          return AssignUserNew;
        };
        userCleardist();
        userAssign();
      });
    } catch {}
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resualProfile = await DatamanagementService(apiBaseUrlDebt).getUserDetail();
      setUserprofile(resualProfile);
      resolve(resualProfile);
    });
  };

  const byPassDataToMainBasic = (
    statusSave: string,
    getConditionLoantype: string,
    getConditionAccountStatus: string,
    getConditionProductType: string,
    getConditionAccountLevel: string,
    getConditionLocation: string,
    getConditionAmount: string,
    getConditionStmtAmt: string,
    getConditionCurBal: string,
    getDataLoanTypeSubmit: any,
    getDataAccountStatusSubmit: any,
    getDataProductTypeSubmit: any,
    getDataAccountLevelSubmit: any,
    getDataLocationSubmit: any,
    getDataAmountSubmit: any,
    getDataStmtAmtSubmit: any,
    getDataCurBalSubmit: any,
    getExpireday: number,
    getValueTeam: string,
    getAssignType: string,
    getconditionPrincipalAmount: string,
    getDataPrincipalAmount: string,
    getExpridedType: string,
    getFristBetweenPrincipalAmount: string,
    getEndBetweenPrincipalAmount: string,
    getFristBetweenOverduleAmount: string,
    getEndBetweenOverduleAmount: string,
    getFristBetweenStmtAmt: string,
    getEndBetweenStmtAmt: string,
    getFristBetweenCurBal: string,
    getEndBetweenCurBal: string,
    getDataBillingCycleSubmit: string
  ) => {
    let fieldsInstan: any = {};
    let channel: any;
    getDataLoanTypeSubmit.length === 0
      ? (channel = null)
      : ((channel = {
          op: "in",
          type: "STRING",
          value: getDataLoanTypeSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          channel,
        }));

    let accountStatus: any;
    getDataAccountStatusSubmit.length === 0
      ? (accountStatus = null)
      : ((accountStatus = {
          op: "in",
          type: "STRING",
          value: getDataAccountStatusSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          accountStatus,
        }));

    let billCycle: any;
    getDataBillingCycleSubmit.length === 0
      ? (billCycle = null)
      : ((billCycle = {
          op: "in",
          type: "STRING",
          value: getDataBillingCycleSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          billCycle,
        }));

    let productCode: any;
    getDataProductTypeSubmit.length === 0 || getDataProductTypeSubmit[0] === ""
      ? (productCode = null)
      : ((productCode = {
          op: "in",
          type: "STRING",
          value: getDataProductTypeSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          productCode,
        }));
    let accountLevel: any;
    getDataAccountLevelSubmit.length === 0
      ? (accountLevel = null)
      : ((accountLevel = {
          op: "in",
          type: "STRING",
          value: getDataAccountLevelSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          accountLevel,
        }));

    let centerBranch: any;
    getDataLocationSubmit.length === 0
      ? (centerBranch = null)
      : ((centerBranch = {
          op: "in",
          type: "STRING",
          value: getDataLocationSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          centerBranch,
        }));

    const regex = /,/i;
    let principleAmt: any;
    getconditionPrincipalAmount === "between"
      ? ((principleAmt = {
          op: getconditionPrincipalAmount,
          type: "STRING",
          value: [getFristBetweenPrincipalAmount.replace(regex, ""), getEndBetweenPrincipalAmount.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          principleAmt,
        }))
      : getDataPrincipalAmount !== "" && getDataPrincipalAmount != undefined
      ? ((principleAmt = {
          op: getconditionPrincipalAmount,
          type: "NUMBER",
          value: getDataPrincipalAmount.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          principleAmt,
        }))
      : (principleAmt = null);

    let overdueAmt: any;
    getConditionAmount === "between"
      ? ((overdueAmt = {
          op: getConditionAmount,
          type: "STRING",
          value: [getFristBetweenOverduleAmount.replace(regex, ""), getEndBetweenOverduleAmount.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          overdueAmt,
        }))
      : getDataAmountSubmit !== "" && getDataAmountSubmit != undefined
      ? ((overdueAmt = {
          op: getConditionAmount,
          type: "NUMBER",
          value: getDataAmountSubmit.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          overdueAmt,
        }))
      : (overdueAmt = null);

    let stmtAmt: any;
    getConditionStmtAmt === "between"
      ? ((stmtAmt = {
          op: getConditionStmtAmt,
          type: "STRING",
          value: [getFristBetweenStmtAmt.replace(regex, ""), getEndBetweenStmtAmt.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          stmtAmt,
        }))
      : getDataStmtAmtSubmit !== "" && getDataStmtAmtSubmit != undefined
      ? ((stmtAmt = {
          op: getConditionStmtAmt,
          type: "NUMBER",
          value: getDataStmtAmtSubmit.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          stmtAmt,
        }))
      : (stmtAmt = null);

    let curBal: any;
    getConditionCurBal === "between"
      ? ((curBal = {
          op: getConditionCurBal,
          type: "STRING",
          value: [getFristBetweenCurBal.replace(regex, ""), getEndBetweenCurBal.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          curBal,
        }))
      : getDataCurBalSubmit !== "" && getDataCurBalSubmit != undefined
      ? ((curBal = {
          op: getConditionCurBal,
          type: "NUMBER",
          value: getDataCurBalSubmit.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          curBal,
        }))
      : (curBal = null);

    if (statusSave === "DRAFT") {
      if (getConditionListName === "") {
        refConditionListName.current.focus();
        message.error("กรุณากรอก Condition List Name");
        return false;
      }
    } else {
      if (getConditionListName === "") {
        refConditionListName.current.focus();
        message.error("กรุณากรอก Condition List Name");
        return false;
      }
    }
    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <ExclamationCircleOutlined />,
      content: "กดยืนยันเพื่อบันทึกข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        const getRulebaseSeq = await DatamanagementService(apiBaseUrl).findConditionByname(AssignUserNew);
        let newSeqsave = 0;
        if (getRulebaseSeq === undefined || getRulebaseSeq.detail.length === 0) {
          newSeqsave = 0;
        } else {
          for (let i = 0; i < getRulebaseSeq.detail.length; i++) {
            if (getRulebaseSeq.detail[i].seq > newSeqsave) {
              newSeqsave = getRulebaseSeq.detail[i].seq;
            }
          }
        }
        if (newSeqsave >= 1) {
          let seqPlust = newSeqsave + 1;
          let dataStringBasicUpdate: any = [];
          dataStringBasicUpdate = [
            {
              seq: seqPlust,
              days: getExpireday,
              type: "BASIC",
              fields: fieldsInstan,
              status: statusSave,
              listname: getConditionListName,
              updateBy: userProfile.name,
              createBy: userProfile.id,
              assignType: getAssignType,
              createDate: createDate,
              description: getDescription,
              distribution: getValueTeam,
            },
          ];
          const resual = await DatamanagementService(apiBaseUrl).updateConditionByid(AssignUserNew, {
            name: AssignUserNew,
            detail: dataStringBasicUpdate,
          });
          if (resual === 200) {
            message.success("Success");
            push(`/datamanagement`);
          }
        } else {
          const rulesCreateBasic = [];
          let dataStringBasic = [];
          dataStringBasic = [
            {
              seq: 1,
              days: getExpireday,
              type: "BASIC",
              fields: fieldsInstan,
              status: statusSave,
              listname: getConditionListName,
              updateBy: userProfile.name,
              createBy: userProfile.id,
              assignType: getAssignType,
              createDate: createDate,
              description: getDescription,
              distribution: getValueTeam,
              getExpridedType: getExpridedType,
            },
          ];
          rulesCreateBasic.push({
            name: AssignUserNew,
            detail: dataStringBasic,
          });
          const resual = await DatamanagementService(apiBaseUrl).createCondition(rulesCreateBasic);
          if (resual === 201) {
            message.success("Success");
            push(`/datamanagement`);
          }
        }
      },
      onCancel: () => {},
    });
  };

  const byPassDataToMainAdvance = (getCondition: any, getExpireday: any, getAssignType: any, getTeam: any, statusButton: string) => {
    if (getConditionListName === "") {
      refConditionListName.current.focus();
      message.error("กรุณากรอก Condition List Name");
      return false;
    } else if (getDescription === "") {
      refDescription.current.focus();
      message.error("กรุณากรอก Description ให้เรียบร้อย");
      return false;
    }
    // Modal.confirm({
    //   title: "คุณแน่ใจแล้วใช่ไหม",
    //   icon: <ExclamationCircleOutlined />,
    //   content: "กดยืนยันเพื่อบันทึกข้อมูล",
    //   okText: "ยืนยัน",
    //   cancelText: "ยกเลิก",
    //   onOk: async () => {
    //     const getRulebaseSeq = await DatamanagementService(
    //       apiBaseUrl
    //     ).getDataRulebase();
    //     const resultRulebaseSeqAssignUser = await getRulebaseSeq.filter(
    //       (x: any) => x.name === "AssignUser"
    //     );
    //     const seqNew = await resultRulebaseSeqAssignUser[0]?.detail.filter(
    //       (x: any, index: number) => {
    //         if (
    //           Number(index) ===
    //           Number(resultRulebaseSeqAssignUser[0]?.detail.length - 1)
    //         ) {
    //           return x;
    //         }
    //       }
    //     );

    //     const newSeqsave = seqNew[0]["seq"];
    //     if (newSeqsave >= 1) {
    //       let seqPlust = newSeqsave + 1;
    //       const rulesUpdateAdvance = [];
    //       let dataStringAdvanceUpdate: any = [];
    //       dataStringAdvanceUpdate = [
    //         {
    //           seq: seqPlust,
    //           type: "ADVANCE",
    //           distribution: getTeam,
    //           expression: getCondition,
    //           days: getExpireday,
    //           assignType: getAssignType,
    //           listname: getConditionListName,
    //           description: getDescription,
    //           createDate: createDate,
    //           status: statusButton,
    //         },
    //       ];
    //       resultRulebaseSeqAssignUser[0]?.detail.map((x: any) => {
    //         dataStringAdvanceUpdate = [x, ...dataStringAdvanceUpdate];
    //       });
    //       rulesUpdateAdvance.push({
    //         name: `User:${userProfile.id}:AssignUser`,
    //         detail: dataStringAdvanceUpdate,
    //       });

    //       const resultUpdateAdvance = await DatamanagementService(
    //         apiBaseUrl
    //       ).updateConditionByid(AssignUserNew, rulesUpdateAdvance);

    //       if (resultUpdateAdvance === 200) {
    //         message.success("Success");
    //         push(`/datamanagement`);
    //       }
    //     } else {
    //       const rulesCreateAdvance = [];
    //       let dataStringAdvanceCreact = [];
    //       dataStringAdvanceCreact = [
    //         {
    //           seq: 1,
    //           type: "ADVANCE",
    //           distribution: getTeam,
    //           expression: getCondition,
    //           days: getExpireday,
    //           assignType: getAssignType,
    //           listname: getConditionListName,
    //           description: getDescription,
    //           createDate: createDate,
    //           status: statusButton,
    //         },
    //       ];
    //       rulesCreateAdvance.push({
    //         name: `User:${userProfile.id}:AssignUser`,
    //         detail: dataStringAdvanceCreact,
    //       });
    //       const resultUpdateAdvance = await DatamanagementService(
    //         apiBaseUrl
    //       ).createCondition(rulesCreateAdvance);

    //       if (resultUpdateAdvance === 200) {
    //         message.success("Success");
    //         push(`/datamanagement`);
    //       }
    //     }
    //   },
    //   onCancel: () => {},
    // });
  };

  const onChangConditionLisName = (e: any) => {
    setConditionListName(e.target.value);
    // byPassConditionListName()
  };

  const onChangDescription = (e: any) => {
    setDescription(e.target.value);
  };

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Card style={{ width: "100%", textAlign: "left" }} title="Add Condition List">
          <Form labelCol={{ span: 4 }}>
            <Form.Item label="Condition List Name :" required>
              <Input maxLength={100} ref={refConditionListName} value={getConditionListName} onChange={onChangConditionLisName} />
            </Form.Item>
            <Form.Item label="Description :">
              <TextArea rows={4} ref={refDescription} value={getDescription} onChange={onChangDescription} />
            </Form.Item>

            <Switch>
              {/* <Route path={`${baseUrl}/advance`}>
                <AdvancePage byPassDataToMainAdvance={byPassDataToMainAdvance} />
              </Route> */}
              <Route path={`${baseUrl}/basic`}>
                <BasicPage byPassDataToMainBasic={byPassDataToMainBasic} />
              </Route>
            </Switch>
          </Form>
        </Card>
      </Row>
    </>
  );
};
