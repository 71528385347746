export interface IOaPolicy {
  getalldata: () => Promise<string>;
  createdata: (data: any) => Promise<string>;
  deltepolicy: (id: any) => Promise<string>;
  updatepolicy: (data: any) => Promise<string>;
}

export const SetPolicy = (servicePolicy: any): IOaPolicy => ({
  getalldata: async () => {
    const result = await servicePolicy.get("/policy/search");
    return result?.data || {};
  },

  createdata: async (data: any) => {
    const result = await servicePolicy.post("/policy/create", data);
    return result?.data || {};
  },

  deltepolicy: async (id: any) => {
    const result = await servicePolicy.delete("/policy/" + id);
    return result?.data || {};
  },
  updatepolicy: async (data: any) => {
    const result = await servicePolicy.put("/policy/update", data);
    return result?.data || {};
  },
});
