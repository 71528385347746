import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { IDebtService, DebtService } from "../../services/debt-service";
import { loadAppConfig } from "../../../../config/app-config";
import { useId24 } from "../../../../drivers/id24/Id24-provider";
import { numberWithCommas, dateCheck, DateStamp } from "../../../../utils/index";
const appConfig = loadAppConfig();
export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  type: string;
  cifId: string;
  debtContact: any;
}

export interface Ipayment {
  account_no: string;
  approve_trn: boolean;
  card_no: string;
  chn: string;
  cif: string;
  create_date: string;
  cus_no: string;
  data_date: string;
  end_bal: string;
  id: string;
  pay_chn: string;
  pay_desc: string;
  pay_flag: string;
  pay_method: string;
  trn_amt: string;
  trn_amt_approve: number;
  trn_code: string;
  trn_date: string;
  trn_ef_date: string;
  trn_seq: string;
  trn_time: string;
  user_id: number;
}

export const TablePay: React.FC<Props> = ({ caption, accountNo, baseURL, loading, type, cifId, debtContact }): React.ReactElement => {
  const debtService = DebtService;
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [resData, setResData] = useState<Ipayment[]>([]);
  const [typeTable, setTypeTable] = useState<string>("");
  const [getloading, setloading] = useState<boolean>(false);

  useEffect(() => {
    getDataTable(accountNo, cifId);
  }, [accountNo, cifId, typeTable]);

  const getDataTable = async (accountNo: string, cifId: string): Promise<any> => {
    setloading(true);
    await debtService(apiBaseUrlDebt)
      .getDebtHeader(accountNo, cifId)
      .then((res: any) => {
        setTypeTable(res?.result[0]?.channel);
        const CusNo: string = res.result[0].customerNo;
        const CHL: string = res?.result[0]?.channel;
        if (CusNo !== "" && CHL !== "") {
          debtService(apiBaseUrlDebt)
            .getDebtPayment(accountNo, CHL, CusNo, cifId)
            .then((resultpayment: any) => {
              setResData(resultpayment?.result);
              setloading(false);
            });
        }
      });
  };
  const columnsloanod: any = [
    {
      title: "Channel",
      dataIndex: "chn",
      key: "chn",
    },
    {
      title: "Transaction Date",
      dataIndex: "trn_date",
      key: "trn_date",
      render: (event: string, row: Ipayment) => {
        return dateCheck(event);
      },
    },
    {
      title: "Transaction Code",
      dataIndex: "trn_code",
      key: "trn_code",
    },
    {
      title: "Transaction Amount",
      dataIndex: "trn_amt",
      key: "trn_amt",
      render: (event: string, row: Ipayment) => {
        if (event === null) {
          return "";
        } else {
          return numberWithCommas(String(event));
        }
      },
    },
    {
      title: "Effective Date",
      dataIndex: "trn_ef_date",
      key: "trn_ef_date",
      align: "right" as const,
      render: (event: string, row: Ipayment) => {
        if (event === null) {
          return "";
        } else {
          return dateCheck(event);
        }
      },
    },
  ];

  const columnscredit: any = [
    {
      title: "Channel",
      dataIndex: "chn",
      key: "chn",
    },
    {
      title: "วันที่",
      dataIndex: "trn_date",
      key: "trn_date",
      render: (event: string, row: Ipayment) => {
        return dateCheck(event);
      },
    },
    {
      title: "Transaction Code",
      dataIndex: "trn_code",
      key: "trn_code",
    },
    {
      title: "Transaction Amount",
      dataIndex: "trn_amt",
      key: "trn_amt",
      align: "right" as const,
      render: (event: number, row: Ipayment) => {
        return numberWithCommas(String(event));
      },
    },
    {
      title: "Pay Flag",
      dataIndex: "pay_flag",
      key: "pay_flag",
    },
    {
      title: "Pay Channel",
      dataIndex: "pay_chn",
      key: "pay_chn",
    },
    {
      title: "Pay Method",
      dataIndex: "pay_method",
      key: "pay_method",
    },
    {
      title: "Pay Description",
      dataIndex: "pay_desc",
      key: "pay_desc",
    },
  ];

  if (typeTable === "LOAN" || typeTable === "OD") {
    return (
      <>
        <Table loading={getloading} size="small" rowKey={(record: any) => record.id} columns={columnsloanod} dataSource={resData} />
      </>
    );
  } else {
    return (
      <>
        <Table loading={getloading} size="small" rowKey={(record: any) => record.id} columns={columnscredit} dataSource={resData} />
      </>
    );
  }
};
