import React from "react";
import { Card, Row } from "antd";
import { Switch, Route } from "react-router-dom";
import { OverviewDataManagement } from "./OverviewDataManagement";
import { AddPageRoute } from "./components/addpage/AddPageRoute";
import { EditPageDraft } from "./components/editPage/EditPageDraft";
import { EditPageWattingApprove } from "./components/editPage/EditPageWattingApprove";
import { DataManageShowDetail } from "./components/DataManageShowDetail";
import { UnlistPage } from "./components/unlistpage/UnlistPage";
import { DatabaseFilled } from "@ant-design/icons";
// import { EditPageDraft } from "./components/editPage/edit-draft";

interface Props {
  baseUrl: string;
  accessRole: any;
}
export const DataManagementRoute: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <DatabaseFilled /> DATA MANAGEMENT
          </b>
        </Card>
      </Row>
      <Switch>
        <Route path={`${baseUrl}/editcondition/showdetail`}>
          <DataManageShowDetail baseUrl={`${baseUrl}/editcondition/showdetail`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}/editcondition/draft`}>
          <EditPageDraft baseUrl={`${baseUrl}/editcondition/draft`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}/editcondition/wattingapprove`}>
          <EditPageWattingApprove baseUrl={`${baseUrl}/editcondition/wattingapprove`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}/addcondition/advance`}>
          <AddPageRoute baseUrl={`${baseUrl}/addcondition`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}/addcondition/advance`}>
          <AddPageRoute baseUrl={`${baseUrl}/addcondition`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}/addcondition/basic`}>
          <AddPageRoute baseUrl={`${baseUrl}/addcondition`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}/unlist`}>
          <UnlistPage baseUrl={`${baseUrl}/addcondition`} accessRole={accessRole} />
        </Route>
        <Route path={`${baseUrl}`}>
          <OverviewDataManagement baseUrl={`${baseUrl}`} accessRole={accessRole} />
        </Route>
      </Switch>
    </>
  );
};
