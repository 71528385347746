import axios, { AxiosInstance } from "axios";
import { loadAppConfig } from "./../../config/app-config";
import { serviceBackend, serviceBackendDebt } from "./../../drivers/service/axiosBackend";

const appConfig = loadAppConfig();
function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

interface IPhoneStatus {
  aStatus: string;
  pStatus: string;
  dialPhone: string;
  refNo: string;
}
export interface IMainService {
  getDetailUser: () => Promise<string>;
  getStatusPhone: (extension: string) => Promise<IPhoneStatus>;
}

export const MainService = (serviceBackendApi: any): IMainService => ({
  getDetailUser: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userDetails");
    return result?.data || {};
  },
  getStatusPhone: async (extension) => {
    const result = await serviceBackendApi.post(`https://dphonedemo.osd.co.th/public/api/dialer/api/v1/agent/status/${extension}`);
    return result?.data || {};
  },
});
