import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Card,
  Col,
  Form,
  Select,
  Input,
  Button,
  TreeSelect,
  Modal,
  Badge,
  Space,
  message,
  Table,
  Typography,
} from "antd";
import {
  ExclamationCircleOutlined,
  WifiOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import {
  IBroadcastServiece,
  BroadcastServiece,
} from "./serviece/BroadcastServiece";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();

// New dayjs
import dayjs from "dayjs";
import thTH from "antd/lib/locale-provider/th_TH";
import "dayjs/locale/th";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style";

const DatePicker = generatePicker(dayjsGenerateConfig);
let buddhistEra = require("dayjs/plugin/buddhistEra");
dayjs.extend(buddhistEra);
dayjs.locale("th");

export const Broadcast: React.FC = (): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);

  const { Option } = Select;
  const { TextArea } = Input;
  const { Paragraph } = Typography;

  const [getOaAll, setOaAll] = useState<any>([]);
  const [value, setValue] = useState<any>([]);
  const [getLoading, setLoading] = useState<boolean>(false);
  const [ellipsis, setEllipsis] = useState<boolean>(true);

  const [getDataProfile, setDataProfile] = useState<string>("");
  const [getDataLog, setDataLog] = useState<any>([]);
  const [loadData, setLoadData] = useState(false);
  const refsendTo = useRef<any>(null);
  const refexpire = useRef<any>(null);
  const refpriority = useRef<any>(null);
  const refTitle = useRef<any>(null);
  const refDetails = useRef<any>(null);
  const [formData] = Form.useForm();
  const [loading, setloading] = useState<any>(false);

  useEffect(() => {
    const fetchDataForSearch = async () => {
      try {
        // setloading(false);
        const resultLog: any = await BroadcastServiece(
          apiBaseUrlDebt,
        ).logBroadcast();
        const dataAllLog: any = resultLog?.map(
          (
            e: {
              body: string;
              groups: string;
              id: string;
              priority: string;
              ref: string;
              subject: string;
              updateBy: string;
              updateDate: string;
              expireDate: string;
            },
            index: number,
          ) => {
            const mapData = {
              no: index + 1,
              body: e.body,
              groups: e.groups,
              id: e.id,
              priority: e.priority,
              ref: e.ref,
              subject: e.subject,
              updateBy: e.updateBy,
              updateDate: dayjs(e.updateDate)
                .add(543, "year")
                .format("DD-MM-YYYY"),
              expireDate: dayjs(e.expireDate)
                .add(543, "year")
                .format("DD-MM-YYYY"),
            };
            return mapData;
          },
        );
        setDataLog(dataAllLog);
      } catch (err) {
        console.log(err);
        message.error("ไม่พบข้อมูล");
      }
      // finally {
      //   setloading(true);
      // }
    };
    const dataUserdetail = async () => {
      const userProfile = id24Axios(appConfig.apiBaseUrlDebt);
      const profile = await userProfile.get("/debtor/userDetails");
      setDataProfile(profile?.data?.result?.name);

      const resultOa: any = await BroadcastServiece(
        apiBaseUrlUser,
      ).getDataAll();
      const dataresult = resultOa?.map((e: any, row: any, number: any) => {
        const data = {
          title: e.oaName,
          value: e.oaCode,
          key: e.oaCode,
        };
        return data;
      });
      setOaAll(dataresult);
    };
    fetchDataForSearch();
    dataUserdetail();
  }, [loadData]);

  const onFinish = async (e: any) => {
    if (e.sendto === undefined || e.sendto == "") {
      refsendTo.current.focus();
      message.warning("กรุณาเลือก Send To !!");
      return false;
    }
    if (e.priority === undefined || e.priority == "") {
      refpriority.current.focus();
      message.warning("กรุณาเลือก Priority !!");
      return false;
    }
    if (e.expireDate === undefined || e.expireDate == null) {
      refexpire.current.focus();
      message.warning("กรุณาเลือกวันที่ Expire!!");
      return false;
    }
    if (e.title === undefined || e.title == "") {
      refTitle.current.focus();
      message.warning("กรุณาเขียน Title !!");
      return false;
    }
    if (e.detail === undefined || e.detail == "") {
      refDetails.current.focus();
      message.warning("กรุณาเขียน Detail !!");
      return false;
    }
    if (e) {
      setLoadData(true);
      const data = {
        groups: e.sendto,
        priority: e.priority,
        subject: e.title,
        body: e.detail,
        ref: "broadcast",
        createBy: getDataProfile,
        expireDate: dayjs(e.expireDate).format("YYYY-MM-DD"),
      };
      Modal.confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: "แน่ใจที่จะทำรายการแล้วใช่ไหม ?",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const resultBank: any = await BroadcastServiece(
            apiBaseUrlDebt,
          ).createBroadcast(data);
          if (resultBank) {
            message.success("success");
            formData.resetFields();
            setLoadData(false);
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const onChangTreevalue = (newValue: any) => {
    setValue(newValue);
  };

  const onChange = (expandable: any) => {
    setEllipsis(expandable);
  };

  const treeData = [
    {
      title: "เลือกทั้งหมด",
      value: "001",
      key: "001",
      children: getOaAll,
    },
  ];
  const tProps = {
    treeData,
    value: value,
    onChange: onChangTreevalue,
    treeCheckable: true,
    allowClear: true,
    placeholder: "Please Select",
    style: {
      width: "100%",
    },
  };

  const columns: any = [
    { title: "No.", dataIndex: "no", key: "no" },
    {
      title: "Update By",
      dataIndex: "updateBy",
      width: "15%",
      key: "updateBy",
    },
    {
      title: "Send To",
      dataIndex: "groups",
      width: "15%",
      key: "groups",
      textWrap: "wrap",
      render: (z: any, index: number) => {
        let DatapushOA: any = [];
        let DatPushGrp: any = [];
        getOaAll?.map((oa: any) => {
          DatapushOA.push({
            value: oa.value,
            name: oa.title,
          });
        });
        z?.map((e: any) => {
          DatapushOA.map((ex: any) => {
            if (ex.value === e) {
              DatPushGrp += ex.name + ",";
            }
            setLoading(true);
          });
        });
        if (getLoading) {
          let result: any = DatPushGrp.replace(/,/g, "\n");
          return (
            <>
              <Paragraph
                style={{
                  whiteSpace: "pre-line",
                  margin: 0,
                }}
                ellipsis={
                  ellipsis
                    ? {
                        rows: 2,
                        expandable: true,
                        symbol: "more",
                      }
                    : false
                }
              >
                {result}
              </Paragraph>
            </>
          );
        }
      },
    },
    { title: "Priority", dataIndex: "priority", key: "priority" },
    { title: "Title", dataIndex: "subject", key: "subject" },
    { title: "Detail", dataIndex: "body", key: "body" },
    {
      title: "Expire Date",
      dataIndex: "expireDate",
      width: "10%",
      key: "expireDate",
    },
    {
      title: "Create Date",
      dataIndex: "updateDate",
      width: "10%",
      key: "updateDate",
    },
  ];

  return (
    <>
      <Row>
        <Card
          style={{ width: "100%", textAlign: "left", marginBottom: "10px" }}
        >
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <WifiOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
            BROADCAST
          </b>
        </Card>
      </Row>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Col span={24}>
          <Card
            title={
              <div style={{ fontWeight: "bold" }}>
                Create Notifications Broadcast
              </div>
            }
            style={{ textAlign: "left" }}
          >
            <Form
              form={formData}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              onFinish={onFinish}
            >
              <Form.Item name="sendto" label="Send To" required>
                <TreeSelect
                  treeNodeFilterProp="title"
                  showArrow
                  ref={refsendTo}
                  treeDefaultExpandAll
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  {...tProps}
                  maxTagCount={"responsive"}
                />
              </Form.Item>
              <Form.Item name="priority" label="Priority" required>
                <Select
                  allowClear
                  placeholder={"Please Select"}
                  ref={refpriority}
                >
                  <Option key={0} disabled>
                    Please Select
                  </Option>
                  <Option key={1} value={"high"} style={{ color: "red" }}>
                    <Space>
                      <Badge status="error" />
                      High
                    </Space>
                  </Option>
                  <Option key={2} value={"medium"} style={{ color: "orange" }}>
                    <Space>
                      <Badge status="warning" /> Medium
                    </Space>
                  </Option>
                  <Option
                    key={3}
                    value={"general"}
                    style={{ color: "rgb(45, 183, 245)" }}
                  >
                    <Space>
                      <Badge color="blue" /> General
                    </Space>
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item name="expireDate" label="Expire Date" required>
                <DatePicker
                  disabledDate={(current: any) => {
                    return dayjs().add(-1, "days") >= current;
                  }}
                  ref={refexpire}
                  id="expireDate"
                  name="expireDate"
                  style={{ width: "100%" }}
                  format={"DD-MM-BBBB"}
                />
              </Form.Item>
              <Form.Item name="title" label="Title" required>
                <Input
                  ref={refTitle}
                  placeholder={"Input"}
                  maxLength={150}
                  showCount
                />
              </Form.Item>
              <Form.Item name="detail" label="Detail" required>
                <TextArea
                  ref={refDetails}
                  maxLength={200}
                  showCount
                  placeholder={"Text"}
                  style={{
                    height: "150px",
                    maxHeight: "150px",
                    minHeight: "150px",
                  }}
                />
              </Form.Item>
              <Form.Item label="">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "335px" }}
                >
                  Send
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            title={
              <div style={{ border: "none" }}>
                <Space>
                  <CloudSyncOutlined />
                  <Typography style={{ fontWeight: "bold" }}>
                    Broadcast Log All
                  </Typography>
                </Space>
              </div>
            }
            style={{ textAlign: "left" }}
          >
            <Table
              size="small"
              dataSource={getDataLog}
              columns={columns}
              rowKey={(record: any) => record.no}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
