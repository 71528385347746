import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Table,
  Typography,
  Input,
  Select,
  AutoComplete,
} from "antd";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { BatchProcessServiceJob } from "../services/service";
import dayjs from "dayjs";
const appConfig = loadAppConfig();
dayjs;

interface Props {
  userId: string;
}
export const BatchProcessJobLog: React.FC<Props> = ({
  userId,
}): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  //   const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);
  const [dataSource, setDataSource] = useState<any>([]);
  const [fileCreateDateFilter, setFileCreateDateFilter] = useState<any>([]);
  const [fileJobIDFilter, setFileJobIDFilter] = useState<any>([]);
  const [fileActivityFilter, setFileActivityFilter] = useState<any>([]);

  const { Search } = Input;
  const { Option } = Select;

  useEffect(() => {
    async function fetchData() {
      const res1: any = await BatchProcessServiceJob(
        apiBaseUrlDatalink,
      ).getAllJobLog();
      //   console.log(res1);

      let fileJobID: any = [];
      let fileJobIDArray = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.jobId === ele.jobId),
      );
      fileJobIDArray.map((data: any) => {
        fileJobID.push({
          text: data.jobId,
          value: data.jobId,
        });
      });
      await setFileJobIDFilter(fileJobID);

      let fileActivity: any = [];
      let fileActivityArray = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.activity === ele.activity),
      );
      fileActivityArray.map((data: any) => {
        fileActivity.push({
          text: data.activity === undefined ? "" : data.activity,
          value: data.activity,
        });
      });
      await setFileActivityFilter(fileActivity);

      let fileCreateDate: any = [];
      let fileCreateDateArray = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.createAt === ele.createAt),
      );
      fileCreateDateArray.map((data: any) => {
        fileCreateDate.push({
          text: dayjs(data.createAt)
            .add(543, "year")
            .format("DD-MM-YYYY HH:mm:ss"),
          value: data.createAt,
        });
      });
      await setFileCreateDateFilter(fileCreateDate);

      let convertData: any = [];
      await res1.map((e: any, i: number) => {
        convertData.push({
          no: i + 1,
          id: e.id,
          jobId: String(e.jobId),
          detail: e.detail,
          createAt: e.createAt,
          activity: String(e.activity),
        });
      });

      await setDataSource(convertData);
      //   console.log(convertData);
    }
    fetchData();
  }, []);

  const onSearch = (value: string) => console.log(value);

  const columns: any = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: "7%",
      fixed: "left",
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
      width: "7%",
      filters: fileJobIDFilter,
      onFilter: (value: any, record: any) => record.jobId.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      width: "15%",
      filters: fileActivityFilter,
      onFilter: (value: any, record: any) => record.activity.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data === "undefined" || data === null || data === "" ? "" : data;
      },
    },
    {
      title: "Create At",
      dataIndex: "createAt",
      key: "createAt",
      width: "15%",
      filters: fileCreateDateFilter,
      onFilter: (value: any, record: any) => record.createAt.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY HH:mm:ss")
          : "-";
      },
    },
    { title: "Detail", dataIndex: "detail", key: "detail" },
  ];
  return (
    <div>
      <Row style={{ marginBottom: "40px" }}>
        <Col span={24}>
          <Typography
            style={{ textAlign: "left", fontWeight: "bold", fontSize: "16px" }}
          >
            Job Process Log
          </Typography>
        </Col>
      </Row>
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        rowKey={(record: any) => record.id}
      />
    </div>
  );
};
