import {
  serviceBackend,
  serviceBackendDebt,
} from "../../../drivers/service/axiosBackend";

export interface IEmailTemplateServiece {
  createtemplate: (data: any) => Promise<string>;
  updatetemplate: (data: any, id: any) => Promise<string>;
  deletetemplate: (id: any) => Promise<string>;
  gettemplateByID: (id: any) => Promise<any>;
  gettemplateByIDNew: (id: any) => Promise<string>;
  gettemplateAll: () => Promise<string>;
  gettemplateAllNew: () => Promise<string>;
  updatetemplateNew: (data: any, id: any) => Promise<any>;
}

export const EmailTemplateServiece = (
  urlService: any
): IEmailTemplateServiece => ({
  createtemplate: async (data: any) => {
    // serviceBackend
    const result = await urlService.post("/email/create", data);
    return result?.data || {};
  },
  updatetemplate: async (data: any, id: any) => {
    // serviceBackend
    const result = await urlService.put("/email/template/" + id, data);
    return result?.data || {};
  },
  deletetemplate: async (id: any) => {
    // serviceBackend
    const result = await urlService.delete("/email/template/" + id);
    return result?.data || {};
  },

  gettemplateByID: async (id: any) => {
    // serviceBackend
    const result = await urlService.get("/email/template/" + id);
    return result?.data || {};
  },

  gettemplateAll: async () => {
    // serviceBackend
    const result = await urlService.get("/email/template");
    return result?.data || {};
  },
  gettemplateAllNew: async () => {
    // serviceBackend
    const result = await urlService.get("/link/v1/mail");
    return result?.data || {};
  },

  gettemplateByIDNew: async (id: any) => {
    // serviceBackend
    const result = await urlService.get("/link/v1/mail/" + id);
    return result?.data || {};
  },

  updatetemplateNew: async (data: any, id: any) => {
    // serviceBackend
    const result = await urlService.put("/link/v1/mail/" + id, data);
    return result?.data || {};
  },
});
