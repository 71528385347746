import {
  Card,
  Row,
  Col,
  Input,
  message,
  Tree,
  Button,
  Modal,
  Space,
  Form,
  Switch,
  Checkbox,
  Select,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
// import { Icon } from "@iconify/react";
import { CarryOutOutlined } from "@ant-design/icons";
import type { DataNode, TreeProps } from "antd/es/tree";
import { v4 as uuidv4 } from "uuid";
import { BankOrganizeService } from "./service/service";
import { ProfileFilled, ApartmentOutlined } from "@ant-design/icons";
import { useId24 } from "./../../drivers/id24/Id24-provider";
import { loadAppConfig } from "./../../config/app-config";
const appConfig = loadAppConfig();

export interface Props {
  userId: string;
}

export const BankOrganize: React.FC<Props> = ({ userId }) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isClosemodal, setClosemodal] = useState(false);
  const [bankId, setBankId] = useState();
  const [showLine, setShowLine] = useState<boolean | { showLeafIcon: boolean }>(
    true
  );
  const [parentId, setParentId] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [name, setName] = useState("");
  const [selectKey, setSelectKey] = useState(false);
  const [pos, setPos] = useState(0);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    async function fetchDataForSearch() {
      const res: any = await BankOrganizeService(
        apiBaseUrlUser
      ).getBankOrganize();
      setBankId(res[0].key);
      setData(res);
    }
    fetchDataForSearch();
  }, [loadData]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    const create = {
      key: values.id,
      title: values.name,
      bankId: bankId,
      parentId: parentId,
    };
    setLoadData(true);
    const res: any = await BankOrganizeService(
      apiBaseUrlUser
    ).createBankOrganize(create);
    res.error ? error(res.error) : success(res.success);
    setLoadData(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const getParentTitle = (key: any, tree: any): any => {
    let parentTitle;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentTitle = node.title;
        } else if (getParentTitle(key, node.children)) {
          parentTitle = getParentTitle(key, node.children);
        }
      }
    }
    return parentTitle;
  };

  const onSelect: TreeProps["onSelect"] = async (
    selectedKeys: any,
    info: any
  ) => {
    const pos = info.node.pos.split("-");
    setPos(pos.length);
    await setParentId(info.node.key);
    await setName(info.node.title);
    selectedKeys.length == 0 ? setSelectKey(true) : setSelectKey(false);
  };

  const deleteBankOrganize = async () => {
    setLoadData(true);
    const res: any = await BankOrganizeService(
      apiBaseUrlUser
    ).deleteBankOrganize(parentId);
    res.message ? error(res.message) : success("delete success!");

    setLoadData(false);
    form.resetFields();
  };

  const success = (msg: string) => {
    message.success(msg);
  };

  const error = (err: string) => {
    message.error(err);
  };

  return (
    <React.Fragment>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <ApartmentOutlined
              style={{ width: 36, height: 36, color: "#0056B4" }}
            />
            Bank Organize
          </b>
        </Card>
      </Row>

      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <>
              <Modal
                title="Add Bank"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button
                    form="formfield"
                    type="primary"
                    htmlType="submit"
                    onClick={handleOk}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <Form
                  form={form}
                  id="formfield"
                  name="basic"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 14 }}
                  initialValues={{ bankId: bankId }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  fields={[
                    {
                      name: ["id"],
                      value: uuidv4(),
                    },
                  ]}
                >
                  <Form.Item label="ID" name="id" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Name" name="name">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Bank" name="Bank" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item label="bankId" name="bankId" hidden>
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            </>
            <Row style={{ marginLeft: 100 }}>
              <Col span="12">
                <Tree
                  showLine={showLine}
                  defaultExpandedKeys={["0-0-0", "0-0-0"]}
                  defaultSelectedKeys={["0-0-0", "0-0-0"]}
                  defaultCheckedKeys={["0-0-0", "0-0-1"]}
                  onSelect={onSelect}
                  treeData={data}
                />
              </Col>
              <Col span="12">
                <Space>
                  <Button
                    type="primary"
                    onClick={showModal}
                    disabled={
                      name || parentId
                        ? parentId === bankId || selectKey || pos > 5
                          ? true
                          : false
                        : true
                    }
                  >
                    + Add Bank Organize
                  </Button>
                  <Button
                    type="primary"
                    danger
                    disabled={
                      name || parentId
                        ? parentId === bankId || selectKey
                          ? true
                          : false
                        : true
                    }
                    onClick={deleteBankOrganize}
                  >
                    Delete Bank Organize
                  </Button>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
