import { Card, Row, Col, Input, message } from "antd";
import React, { useState } from "react";
// import { Icon } from "@iconify/react";
import { FilterBatchProcessDailyFile } from "./components/FilterBatchProcessDailyFile";
import { TableBatchProcessDailyFile } from "./components/TableBatchProcessDailyFile";
import { IFilter } from "./services/service";
import { ProfileFilled, SolutionOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export interface Props {
  userId: string;
  accessRole: string;
}

export const BatchProcessDailyFile: React.FC<Props> = ({
  accessRole,
  userId,
}) => {
  const [fillterData, setFillterData] = useState<IFilter>({
    type: "",
    text: "",
  });
  const [reloadTable, setReloadTable] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const chkReload = (text: string) => {
    if (text === "1") {
      setReloadTable(reloadTable + 1);
    }
  };
  const filter = (type: string, text: string) => {
    if (type === "" || type === undefined) {
      message.warning("Please select type");
    } else {
      setFillterData({ type: type, text: text });
    }
  };

  const handleLoadingTable = (load: boolean) => {
    setTableLoading(load);
  };

  return (
    <React.Fragment>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          {/* {tabResult} */}
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            {" "}
            <SolutionOutlined
              style={{ width: 36, height: 36, color: "#0056B4" }}
            />
            Daily Process
          </b>
        </Card>
      </Row>

      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <FilterBatchProcessDailyFile
              userId={"userId"}
              filter={filter}
              chkReload={chkReload}
              handleLoadingTable={handleLoadingTable}
            />
            <TableBatchProcessDailyFile
              accessRole={accessRole}
              userId={"userId"}
              fillterData={fillterData}
              reloadTable={reloadTable}
              loading={tableLoading}
              handleLoadingTable={handleLoadingTable}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
