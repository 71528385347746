import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Space,
  Modal,
  message,
} from "antd";
import {
  ProfileFilled,
  PlayCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EmailTemplateServiece } from "../serviece/EmailTemplateServiece";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
const appConfig = loadAppConfig();

export interface Props {
  baseUrl: string;
  userId: string;
}
const EditEmailTemplate: React.FC<Props> = ({
  baseUrl,
  userId,
}): React.ReactElement => {
  const location: any = useLocation();
  let history = useHistory();

  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlDebtlink = id24Axios(appConfig.apiBaseUrlDatalink);

  const [getid, setId] = useState<any>(Number(location.state.idtem));

  const [formAction] = Form.useForm();

  const [getContent, setgetContent] = useState<string>("");
  const [getSubject, setgetSubject] = useState<string>("");
  const [getHeader, setgetHeader] = useState<string>("");
  // const [getFooter, setgetFooter] = useState<string>("");
  const [getdataByID, setgetdataByID] = useState<any>([]);
  const [getAlias, setAlias] = React.useState<any>({
    name: "unknown",
    empId: "unknown",
    email: "unknown",
  });

  useEffect(() => {
    async function fetchDataForSearch() {
      const userProfile = id24Axios(appConfig.apiBaseUrlDebt);
      const profile = await userProfile.get("/debtor/userDetails");
      setAlias({
        name: profile.data.result.name,
        email: profile.data.result.email,
        empId: profile.data.result.id,
      });
      getDataTable();
    }
    fetchDataForSearch();
  }, []);

  const getDataTable = async (): Promise<any> => {
    const resData1 = (await EmailTemplateServiece(
      apiBaseUrlDebtlink,
    ).gettemplateByIDNew(getid)) as any;
    // console.log(resData1);
    const resData = {
      mtName: resData1.mtName,
      mtId: getid,
      bodyContent: resData1.bodyContent,
      mailTo: resData1.mailTo,
      subject: resData1.subject,
    } as any;
    setgetdataByID([]);
    setgetdataByID(resData);
  };

  // start save
  const saveModal = async (event: any) => {
    // console.log(event);
    if (event) {
      setgetSubject(event.subject);
      setgetHeader(event.mailTo);
      setgetContent(event.bodyContent);
      let id = getid;
      const data = {
        mtId: id,
        subject:
          event.subject != undefined || event.subject != ""
            ? event.subject
            : getSubject,
        mailTo:
          event.mailTo != undefined || event.mailTo != ""
            ? event.mailTo
            : getHeader,
        bodyContent:
          event.bodyContent != undefined || event.bodyContent != null
            ? event.bodyContent
            : getContent,
      };
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        content: "ยืนยันการบันทึกการฟอร์มแบบฟอร์มอีเมล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          (await EmailTemplateServiece(apiBaseUrlDebtlink)
            .updatetemplateNew(data, id)
            .then(() => {
              message.success("บันทึกฟอร์มสำเร็จ");
            })) as any;
          getDataTable();
        },
        onCancel: () => {},
      });
    }
  };
  // end save

  return (
    <>
      <Row style={{ marginTop: "2rem", width: "100%" }} gutter={16}>
        <Col span={24}>
          {getdataByID.mtId === getid ? (
            <Card
              title={
                <>
                  <Row gutter={16}>
                    <Col>
                      <Space>
                        <PlayCircleOutlined
                          style={{
                            width: 24,
                            height: 24,
                            color: "green",
                            fontSize: "20px",
                          }}
                          rotate={180}
                          onClick={() => history.goBack()}
                        />
                        <Typography
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          Form Template
                        </Typography>
                      </Space>
                    </Col>
                    <Col>
                      <Typography
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          color: "grey",
                        }}
                      >
                        {` ( ${getdataByID.mtName} )`}
                      </Typography>
                    </Col>
                  </Row>
                </>
              }
            >
              <Form
                initialValues={{
                  subject: getdataByID.subject,
                  mailTo: getdataByID.mailTo,
                  bodyContent: getdataByID.bodyContent,
                }}
                form={formAction}
                autoComplete="off"
                name="formfield"
                layout="vertical"
                onFinish={saveModal}
              >
                <Form.Item label="Subject" name="subject">
                  <Input name="subject" value={getdataByID.subject} />
                </Form.Item>
                <Form.Item label="Mail To" name="mailTo">
                  <Input name="mailTo" value={getdataByID.mailTo} />
                </Form.Item>
                <Form.Item label="body Content" name="bodyContent">
                  <ReactQuill
                    theme="snow"
                    style={{ textAlign: "left" }}
                    value={getdataByID.bodyContent}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default EditEmailTemplate;
