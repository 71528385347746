import { Card, Row, Col, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FilterOaUser } from "./components/FilterOaUser";
import { TableOaUser } from "./components/TableOaUser";
import { ProfileFilled } from "@ant-design/icons";
import { IUser } from "../oaProfile/common";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { OaUserService } from "./services/service";
import { loadAppConfig } from "../../config/app-config";

const appConfig = loadAppConfig();
export interface Props {
  roles: string[];
}
export interface IDataTable {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  contractName: string;
  contractId: string;
  os: string;
  contractDateStart: string;
  contractDateEnd: string;
  updatedBy: string;
  updatedDate: string;
  address: string;
  subOrganizationProfile: [];
  token: string;
  oaOrganize: string;
  oaCode: string;
}

export const OaUser: React.FC<Props> = ({ roles }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [proFile, setProfile] = useState<IUser>({
    id: 0,
    type: "",
    username: "",
    name: "",
    oaCode: "",
    capabilities: {},
    level: "",
    team: "",
    token: "",
    active: false,
    parentId: "",
    parentNode: "",
    phone: "",
    email: "",
    oaOrganize: "",
    subOrganizationProfile: [],
    extension: "",
  });
  const [oaOrganize, setOaOrganize] = useState<any>([]);
  const [subOrganize, setSubOrganize] = useState<any>([]);
  const [dataUsers, setDataUsers] = useState<IDataTable[]>([]);
  const [countNoActive, setCountNoActive] = useState<number>(0);
  const [countInActive, setCountInActive] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resProfile = await OaUserService(apiBaseUrlUser).getProfile();
    console.log(resProfile);

    const resOaOrganize = await OaUserService(apiBaseUrlUser).getOaOrganize();
    const resSubOrganize = await OaUserService(apiBaseUrlUser).getSubOrganize();
    const resUser = await OaUserService(apiBaseUrlUser).getUser({ type: "", text: "" });
    const mappingDataUser = resUser
      ?.map((e: any, i: number) => {
        return {
          key: i + 1,
          id: e.id,
          name: e.name,
          email: e.email,
          phone: e.phone,
          contractName: e.contractName,
          contractId: e.contractId,
          os: e.os,
          contractDateStart: e.contractDateStart,
          contractDateEnd: e.contractDateEnd,
          address: e.address,
          subOrganizationProfile: e.subOrganizationProfile,
          token: e.token,
          oaOrganize: e.oaOrganize,
          oaCode: e.oaCode,
          active: e.active,
          updateDate: e.updateDate,
          updateBy: e.updateBy,
        };
      })
      .sort((a: any, b: any) => {
        const dateA = new Date(a.updateDate).getTime();
        const dateB = new Date(b.updateDate).getTime();
        return dateB - dateA;
      });
    setCountNoActive(resUser.filter((item: any) => item.active !== true).length);
    setCountInActive(resUser.filter((item: any) => item.active === true).length);
    setDataUsers(mappingDataUser);
    setOaOrganize(resOaOrganize);
    setSubOrganize(resSubOrganize);
    setProfile(resProfile[0]);
    setLoading(false);
  };

  const submitSearch = async (type: string, text: string) => {
    const resUser = await OaUserService(apiBaseUrlUser).getUser({ type: type, text: text });
    const mappingDataUser = resUser
      ?.map((e: any, i: number) => {
        return {
          key: i + 1,
          id: e.id,
          name: e.name,
          email: e.email,
          phone: e.phone,
          contractName: e.contractName,
          contractId: e.contractId,
          os: e.os,
          contractDateStart: e.contractDateStart,
          contractDateEnd: e.contractDateEnd,
          // updatedBy: e.updateBy,
          // updatedDate: e.updateDate,
          address: e.address,
          subOrganizationProfile: e.subOrganizationProfile,
          token: e.token,
          oaOrganize: e.oaOrganize,
          oaCode: e.oaCode,
          active: e.active,
          updateDate: e.updateDate,
          updateBy: e.updateBy,
        };
      })
      .sort((a: any, b: any) => a.key - b.key);
    setCountNoActive(resUser.filter((item: any) => item.active !== true).length);
    setCountInActive(resUser.filter((item: any) => item.active === true).length);
    setDataUsers(mappingDataUser);
  };

  const onSearch = async (type: string, text: string) => {
    if (type === "" || type === "none") {
      message.warning("Please select type");
    } else {
      submitSearch(type, text);
    }
  };

  const syncData = async () => {
    setLoading(true);
    const resSyncData = await OaUserService(apiBaseUrlUser).syncData();
    if (resSyncData) {
      fetchData();
    }
  };
  return (
    <React.Fragment>
      <Spin tip="Loading..." spinning={loading}>
        <Row>
          <Card style={{ width: "100%", textAlign: "left" }}>
            <b style={{ color: "#0056B4", fontSize: "24px" }}>
              <ProfileFilled style={{ width: 36, height: 36, color: "#0056B4" }} />
              OA User
            </b>
          </Card>
        </Row>

        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <FilterOaUser
                roles={roles}
                proFile={proFile}
                onSearch={onSearch}
                oaOrganize={oaOrganize}
                subOrganize={subOrganize}
                fetchData={fetchData}
                syncData={syncData}
              />
              <TableOaUser
                proFile={proFile}
                fetchData={fetchData}
                countNoActive={countNoActive}
                countInActive={countInActive}
                dataUsers={dataUsers}
                setLoading={setLoading}
                oaOrganize={oaOrganize}
                subOrganize={subOrganize}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};
