import React, { useState, useEffect } from "react";
import { Card, Row, Col, Table } from "antd";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import { IDebtService, DebtService } from "../services/debt-service";
import { IuserProfile } from "../../datamanagement/models/models-datamanagement";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { TablePaginationConfig } from "antd/es/table/interface";
const appConfig = loadAppConfig();

export interface TPagination {
  current: number;
  pageSize: number;
}

export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  cifId: string;
  addActionStatus: string;
}
interface IDataTable {
  policyDesc: IPolycy;
  account_no: string;
  action_code_id: string;
  action_code_name: string;
  appointment_date: string;
  assign_time_after_assign: string;
  cif: string;
  coll_name: string;
  contact: string;
  create_date: string;
  id: string;
  measure: string;
  payment_amt: string;
  payment_date: string;
  phone: string;
  remark: string;
  status_code_id: string;
  status_code_name: string;
  user_id: string;
}

interface IPolycy {
  id: string;
  policyCode: string;
  policyDesc: string;
  policyAmt: string;
  updateDate: string;
}

export const DebtTrackHistory: React.FC<Props> = ({ caption, accountNo, baseURL, loading, cifId, addActionStatus }): React.ReactElement => {
  const debtService = DebtService;
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [resData, setResData] = useState([]);
  const [userProfile, setUserprofile] = useState<any>([]);
  const [getPolicyRead, setPolicyRead] = useState<any>([]);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    getUserProfile().then(async (data) => {
      setUserprofile(data.result);
      await getTrackHistory();
    });
    policydesc();
  }, [cifId, addActionStatus, accountNo]);

  const getUserProfile = async () => {
    return new Promise<any>(async (resolve, reject) => {
      const resultProfile = await debtService(apiBaseUrlDebt).getUserList();
      resolve(resultProfile);
    });
  };

  const policydesc = async () => {
    const data = await debtService(apiBaseUrlDebt).getPolicyList();
    setPolicyRead(data);
  };

  const getTrackHistory = async () => {
    const result = await debtService(apiBaseUrlDebt).getDebtWrapup(cifId, accountNo);
    const data = await result.map((x: IDataTable, index: number) => {
      const mapData = {
        no: index + 1,
        contact: x.contact,
        action_code_name: x.action_code_name,
        status_code_name: x.status_code_name,
        payment_date: x.payment_date,
        payment_amt: x.payment_amt,
        appointment_date: x.appointment_date,
        remark: x.remark,
        user_id: x.user_id,
        create_date: x.create_date,
        coll_name: x.coll_name,
        measure: x.measure,
        phone: x.phone,
      };
      return mapData;
    });
    data.sort((a: any, b: any) => {
      const dateA = new Date(a.create_date);
      const dateB = new Date(b.create_date);
      return dateA.getTime() - dateB.getTime();
    });

    setResData(data);
  };

  function onChange(pagination: TablePaginationConfig) {
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
  }

  const columns: any = [
    {
      title: "ลำดับ",
      dataIndex: "no",
      key: "no",
      width: "3%",
      fixed: "left",
      render: (event: string, row: IDataTable, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "วันที่ติดตาม",
      dataIndex: "create_date",
      key: "create_date",
      render: (date: string) => {
        return dayjs(date).add(543, "year").format("DD-MM-YYYY");
      },
    },
    {
      title: "เวลาที่ติดตาม",
      dataIndex: "create_date",
      key: "create_date",
      render: (time: string) => {
        return `${dayjs(time).locale("th").format("HH:mm")} น.`;
      },
    },
    {
      title: "บุคคลที่ติดต่อ",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "เบอร์ที่ติดต่อ",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Action Code",
      dataIndex: "action_code_name",
      key: "action_code_name",
    },
    {
      title: "Status Code",
      dataIndex: "status_code_name",
      key: "status_code_name",
    },
    {
      title: "วันที่นัดชำระ",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (text: string) => (text !== "" && text !== null ? dayjs(text).locale("th").add(543, "year").format("DD-MM-YYYY") : "-"),
    },
    {
      title: "จำนวนเงินที่นัดชำระ",
      dataIndex: "payment_amt",
      key: "payment_amt",
      align: "right" as const,
      render: (text: string, record: any, index: number) => (
        <NumberFormat value={text} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
      ),
    },
    {
      title: "วันที่นัดหมาย",
      dataIndex: "appointment_date",
      key: "appointment_date",
      render: (text: string) => (text !== "" && text !== null ? dayjs(text).locale("th").add(543, "year").format("DD-MM-YYYY") : "-"),
    },
    {
      title: "รายงานผลการติดตามหนี้",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "มาตรการ",
      dataIndex: "measure",
      key: "measure",
      render: (event: IPolycy) => {
        const data = getPolicyRead?.result?.find((eventpolicydesc: any) => eventpolicydesc?.policyCode === event);
        return <>{event === null ? "-" : data.policyDesc}</>;
      },
    },
    {
      title: "Collector",
      dataIndex: "coll_name",
      key: "coll_name",
      fixed: "right",
    },
  ];
  return (
    <>
      <h1>ประวัติการติดตามของพนักงาน </h1>
      <Table size="small" dataSource={resData} columns={columns} rowKey={(record: any) => record.no} onChange={onChange} />
    </>
  );
};
