import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Card, Form, Row, Input, Radio, Select, Col, Button, InputNumber, Typography, Modal, message, Spin, Tooltip } from "antd";
import { Consditions, DataType, ICenter, IuserProfile, typeTeam, ValueInDetail } from "../models/models-datamanagement";
import { DatamanagementService } from "../services/datamanagement-service";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import NumberFormat from "react-number-format";
import moment from "moment";
import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { UserAccess } from "../../../drivers/id24/models/user-access";
import { IMasterDataOfBranch, Role } from "../common";

const appConfig = loadAppConfig();
const DatePicker = generatePicker(dayjsGenerateConfig);
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
interface Props {
  baseUrl: string;
  accessRole: any;
}

export const DataManageShowDetail: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { tokenAccess, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const location: any = useLocation();
  const { push } = useHistory();
  const refValuePrincipalAmount = useRef<any>(null);
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const [getDataAll, setDataAll] = useState<Consditions>();
  const [getRadiocheckBasic, setRadiocheckBasic] = useState<boolean>(false);
  const [getRadiocheckAdvance, setRadiocheckAdvance] = useState<boolean>(false);
  const [getRadiocheckManual, setRadiocheckManual] = useState<boolean>(false);
  const [getRadiocheckAuto, setRadiocheckAuto] = useState<boolean>(false);
  const [getTeam, setTeam] = useState<typeTeam[]>([]);
  const [getSelectProductType, setSelectProductType] = useState<string[]>([]);
  const [getValueTeam, setValueTeam] = useState<string>("");
  const [getSpinningState, setSpinningState] = useState<boolean>(true);
  const [getconditionPrincipalAmount, setconditionPrincipalAmount] = useState<string>("");
  const [getDataPrincipalAmount, setDataPrincipalAmount] = useState<string>("");
  const [getSelectLocation, setSelectLocation] = useState<any[]>([]);
  const [getSelectLocationNew, setSelectLocationNew] = useState<IMasterDataOfBranch[]>([]);
  const [getmaxTagCount, setmaxTagCount] = useState<any>("responsive");
  const [getValueExpireday, setValueExpireday] = useState<any>();
  const [getResualDatamanagementDetail, setResualDatamanagementDetail] = useState<any>();
  const [getResualDatamanagement, setResualDatamanagement] = useState<any>();
  const refgetValueExpireday = useRef<any>(null);
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [getDetailProductLoan, setDetailProductLoan] = useState<ValueInDetail[]>([]);

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  function multipleInArray(arr: string[], values: Role[]) {
    return values.some((value) => {
      return arr.includes(value);
    });
  }

  useEffect(() => {
    tokenAccess?.userAccess.map((group: UserAccess) => {
      permissionId24.push(group.groupName);
    });

    getCenter().then((e: any) => {
      getData(e);
    });
    const getData = async (dataLocation: any) => {
      try {
        getUserProfile().then((data: any) => {
          if (data) {
            fetchDataManagementByid();
          }
        });
        const getData = await location.state;
        setDataAll(getData);
        await getProductCode();
        {
          getData?.type === "BASIC" ? setRadiocheckBasic(true) : setRadiocheckAdvance(true);
        }
        {
          getData?.assignType === "Manual" ? setRadiocheckManual(true) : setRadiocheckAuto(true);
        }
        setSelectProductType(getData?.fields?.productCode?.value === undefined ? [] : getData?.fields?.productCode?.value);
        setSelectLocation(getData?.fields?.centerBranch?.value === undefined ? [] : getData?.fields?.centerBranch?.value);
        getData?.fields?.centerBranch?.value;
        const MasterDataCenterOfBranch = await DatamanagementService(apiBaseUrlDebt).masterDataCenterBranch();
        setSelectLocationNew(MasterDataCenterOfBranch);
      } catch {}
    };
    getTeamAllInPageBasic();
    mapRole();
  }, []);

  const mapRole = async () => {
    mapRoleAccess().then(async (resultmap) => {
      setPermissionId24(resultmap);
      const userAssign = () => {
        let AssignUserNew;
        multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
          ? (AssignUserNew = "AssignUser")
          : (AssignUserNew = `User:${userProfile.id}:AssignUser`);
        setAssignUserNew(AssignUserNew);
        return AssignUserNew;
      };
      userAssign();
    });
  };

  async function getCenter() {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resual: any = await DatamanagementService(apiBaseUrlDebt).getCenterList();
      resolve(resual);
    });
  }

  const fetchDataManagementByid = async () => {
    const resual = await DatamanagementService(apiBaseUrl).getDraftDataManagementByid(AssignUserNew);
    // const resualRulebaseAll = await DatamanagementService(apiBaseUrl).getDataRulebase();
    // const datafilterRule = await resualRulebaseAll.filter((x: any) => x.name === "AssignUser");
    setResualDatamanagement(resual);
    setResualDatamanagementDetail(resual?.detail);
  };

  const editSelectLocation = (e: any) => {
    setSelectLocation(e);
  };

  const getProductCode = async () => {
    const resual: any = await DatamanagementService(apiBaseUrlDebt).getProductCode();
    const setResult = await resual.map((x: any, index: number) => {
      getDetailProductLoan.push({
        title: x.productDesc,
        value: x.productCode,
        key: x.productCode,
      });
    });
    if (setResult) {
      setSpinningState(false);
    }
  };

  const getUserProfile = async () => {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resualProfile = await DatamanagementService(apiBaseUrlDebt).getUserDetail();
      setUserprofile(resualProfile);
      resolve(resualProfile);
    });
  };

  const getTeamAllInPageBasic = async () => {
    const resual = await DatamanagementService(apiBaseUrlDebt).getTeamByUser();
    setTeam(resual);
  };
  const basicRadioCheckBox = (e: string) => {
    setRadiocheckBasic(true);
    setRadiocheckAdvance(false);
  };

  const advanceRadioCheckBox = (e: string) => {
    setRadiocheckAdvance(true);
    setRadiocheckBasic(false);
  };
  const assignManualRadioCheckBox = (e: string) => {
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };

  const assignAutoRadioCheckBox = (e: string) => {
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };

  const editSelectProductType = (e: any) => {
    setSelectProductType(e);
  };

  const checkConditionPrincipal = () => {
    if (getconditionPrincipalAmount === "select") {
      refValuePrincipalAmount.current.focus();
      message.error("โปรดเลือก Condition ของ เงินต้นคงเหลือ");
      return;
    }
  };

  const createDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const onClickUpdate = async (status: string) => {
    if (status === "DISABLED") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const resual = await DatamanagementService(apiBaseUrl).updateConditionByseq(getDataAll?.seq, AssignUserNew, status);
          if (resual) {
            message.success("Success");
            push(`/datamanagement`);
          }
        },
        onCancel: () => {},
      });
    } else if (status === "APPROVED") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const rulesCreateBasicPush = [];
          let dataStringBasicUpdate: any = [];
          dataStringBasicUpdate = [
            {
              seq: getDataAll?.seq,
              days: getValueExpireday != null || getValueExpireday != undefined || getValueExpireday != "" ? getValueExpireday : getDataAll?.days,
              type: "BASIC",
              fields: getDataAll?.fields,
              status: getDataAll?.status,
              listname: getDataAll?.listname,
              updateBy: userProfile?.name,
              createBy: getDataAll?.createBy,
              assignType: getDataAll?.assignType,
              createDate: createDate,
              description: getDataAll?.description,
              distribution: getDataAll?.distribution,
            },
          ];

          const resual = await DatamanagementService(apiBaseUrl).updateConditionByid(AssignUserNew, {
            name: AssignUserNew,
            detail: dataStringBasicUpdate,
          });
          if (resual === 200) {
            message.success("Success");
            push(`/datamanagement`);
          }
        },
        onCancel: () => {},
      });
    }
  };
  const [getcheckButtonShowProduct, setcheckButtonShowProduct] = useState<boolean>(true);
  const fnSetmaxTagCount = (e: boolean) => {
    if (e == true) {
      const countDetailProductLoan = getDetailProductLoan.length;
      setcheckButtonShowProduct(false);
      setmaxTagCount(countDetailProductLoan);
    } else {
      setcheckButtonShowProduct(true);
      setmaxTagCount("responsive");
    }
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  function isDecimal(n: any) {
    var result = n - Math.floor(n) !== 0;
    if (result) return true;
    else return false;
  }

  // dataBody
  return (
    <Spin indicator={antIcon} spinning={getSpinningState}>
      <>
        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Card style={{ width: "100%", textAlign: "left" }} title="Condition List">
            <Form labelCol={{ span: 4 }}>
              <Form.Item label="Condition List Name :">
                <Input value={getDataAll?.listname} readOnly />
              </Form.Item>
              <Form.Item label="Description :">
                <Input value={getDataAll?.description} readOnly />
              </Form.Item>
            </Form>
            {getDataAll?.type === "BASIC" ? (
              <>
                <Row gutter={10}>
                  <Col span={2}>No.</Col>
                  <Col span={10}>Field</Col>
                  <Col span={12}>Value</Col>
                </Row>
                {/* ? */}
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>1</Col>
                  <Col span={10}>
                    <Input value="Loan Type" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.channel?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>2</Col>
                  <Col span={10}>
                    <Input value="Account Status" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.accountStatus?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>3</Col>
                  <Col span={10}>
                    <Input value="Product Type" readOnly />
                  </Col>

                  <Col span={9}>
                    <Select
                      mode="multiple"
                      disabled
                      style={{ width: "100%" }}
                      value={getDataAll?.fields?.productCode?.value}
                      maxTagCount={getmaxTagCount}
                    >
                      {getDetailProductLoan.map((value: any, index: number) => {
                        return (
                          <Option key={index} value={value.key}>
                            {value.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  {getDataAll?.fields?.productCode?.value ? (
                    <Button type="link" onClick={() => fnSetmaxTagCount(getcheckButtonShowProduct)}>
                      แสดงทั้งหมด
                    </Button>
                  ) : (
                    <></>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>4</Col>
                  <Col span={10}>
                    <Input value="Billing Cycle" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.billCycle?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>5</Col>
                  <Col span={10}>
                    <Input value="Account Level" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.accountLevel?.value}></Select>
                  </Col>
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>6</Col>
                  <Col span={10}>
                    <Input value="Location" readOnly />
                  </Col>
                  <Col span={12}>
                    <Select mode="multiple" style={{ width: "100%" }} onChange={(e) => editSelectLocation(e)} value={getSelectLocation} disabled>
                      {getSelectLocationNew?.map((value: IMasterDataOfBranch, index: number) => {
                        return (
                          <Option key={value.team_group_id} value={String(value.team_group_id)}>
                            {value.desc_local}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>

                {/* row 6 */}
                <br></br>
                <Row gutter={10}>
                  <Col span={2}>
                    <Typography>7</Typography>
                  </Col>
                  <Col span={5}>
                    <Input value={"เงินต้นคงเหลือ"} readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.principleAmt?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.principleAmt?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.principleAmt?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.principleAmt?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.principleAmt?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.principleAmt?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        className="ant-input"
                        thousandSeparator={true}
                        disabled
                        value={getDataAll?.fields?.principleAmt?.value}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.principleAmt?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>8</Col>
                  <Col span={5}>
                    <Input value="เงินงวดค้างชำระ" readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.overdueAmt?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.overdueAmt?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.overdueAmt?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.overdueAmt?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        value={getDataAll?.fields?.overdueAmt?.value}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value) == true ? "" : ".00"}
                        onClick={checkConditionPrincipal}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                          setDataPrincipalAmount(value);
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>9</Col>
                  <Col span={5}>
                    <Input value="ยอดเรียกเก็บตาม statement" readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.stmtAmt?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.stmtAmt?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.stmtAmt?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.stmtAmt?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        value={getDataAll?.fields?.stmtAmt?.value}
                        min={0}
                        decimalScale={2}
                        suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <Row style={{ marginTop: "10px" }} gutter={10}>
                  <Col span={2}>10</Col>
                  <Col span={5}>
                    <Input value="จำนวนการใช้เงินของบัตร ณ ปัจจุบัน" readOnly />
                  </Col>
                  <Col span={5}>
                    <Input value={getDataAll?.fields?.curBal?.op} disabled />
                  </Col>
                  {getDataAll?.fields?.curBal?.op === "between" ? (
                    <>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.curBal?.value[0]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.curBal?.value[0]}
                        />
                      </Col>
                      <Col span={2}>
                        <Input value={"ถึง"} readOnly />
                      </Col>
                      <Col span={5}>
                        <NumberFormat
                          disabled
                          className="ant-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          min={0}
                          suffix={isDecimal(getDataAll?.fields?.curBal?.value[1]) == true ? "" : ".00"}
                          value={getDataAll?.fields?.curBal?.value[1]}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={12}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        value={getDataAll?.fields?.curBal?.value}
                        min={0}
                        decimalScale={2}
                        suffix={isDecimal(getDataAll?.fields?.curBal?.value) == true ? "" : ".00"}
                        onValueChange={(values: any) => {
                          const { formattedValue, value } = values;
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <>
                <Row gutter={10}>
                  <Col span={3} style={{ textAlign: "right" }}>
                    No.
                  </Col>
                  <Col offset={1} span={20} style={{ textAlign: "left" }}>
                    Condition
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px", textAlign: "right" }} gutter={10}>
                  <Col span={3}>1</Col>
                  <Col offset={1} span={20}>
                    <Input value={getDataAll?.expression} readOnly />
                  </Col>
                </Row>
                <br></br>
              </>
            )}

            <br></br>
            <Row>
              <Col span={4} style={{ textAlign: "right" }}>
                Expire Day :
              </Col>
              <Col offset={1} span={19}>
                {getDataAll ? (
                  getDataAll?.fields?.channel?.value.length === 1 ? (
                    getDataAll?.fields.channel.value[0] === "Card Link" && getDataAll?.fields?.accountStatus?.value.length === 1 ? (
                      getDataAll?.fields?.accountStatus?.value[0] === "Non-Npls" ? (
                        getDataAll?.status === "APPROVED" ? (
                          <DatePicker
                            ref={refgetValueExpireday}
                            defaultValue={dayjs(getDataAll?.days)}
                            disabledDate={(current: any) => {
                              return moment().add(-1, "days") >= current;
                            }}
                            style={{ width: "20%" }}
                            format={"DD-MM-BBBB"}
                            onChange={(e: any) => {
                              if (e) {
                                setValueExpireday(e);
                              } else {
                                setValueExpireday("");
                              }
                            }}
                          />
                        ) : (
                          <DatePicker
                            disabled
                            defaultValue={dayjs(getDataAll?.days)}
                            disabledDate={(current: any) => {
                              return moment().add(-1, "days") >= current;
                            }}
                            style={{ width: "20%" }}
                            format={"DD-MM-BBBB"}
                          />
                        )
                      ) : getDataAll?.status === "APPROVED" ? (
                        <InputNumber
                          ref={refgetValueExpireday}
                          type="number"
                          min={1}
                          defaultValue={getDataAll?.days}
                          onChange={(e: any) => {
                            if (e) {
                              setValueExpireday(e);
                            }
                          }}
                        />
                      ) : (
                        <InputNumber type="number" value={getDataAll?.days} readOnly />
                      )
                    ) : getDataAll?.status === "APPROVED" ? (
                      <InputNumber
                        ref={refgetValueExpireday}
                        type="number"
                        min={1}
                        defaultValue={getDataAll?.days}
                        onChange={(e: any) => {
                          if (e) {
                            setValueExpireday(e);
                          }
                        }}
                      />
                    ) : (
                      <InputNumber type="number" value={getDataAll?.days} readOnly />
                    )
                  ) : getDataAll?.status === "APPROVED" ? (
                    <InputNumber
                      ref={refgetValueExpireday}
                      type="number"
                      min={1}
                      defaultValue={getDataAll?.days}
                      onChange={(e: any) => {
                        if (e) {
                          setValueExpireday(e);
                        }
                      }}
                    />
                  ) : (
                    <InputNumber type="number" value={getDataAll?.days} readOnly />
                  )
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col span={4} style={{ textAlign: "right" }}>
                Assign Type
              </Col>
              <Col offset={1} span={19} style={{ textAlign: "left" }}>
                <Radio value={"Manual"} onChange={(e: any) => assignManualRadioCheckBox(e.target.value)} checked={getRadiocheckManual} disabled>
                  Manual
                </Radio>
                <Radio value={"Manual"} onChange={(e: any) => assignAutoRadioCheckBox(e.target.value)} checked={getRadiocheckAuto} disabled>
                  Auto
                </Radio>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col span={4} style={{ textAlign: "right" }}>
                Team
              </Col>
              <Col offset={1} span={19} style={{ textAlign: "left" }}>
                <Select
                  style={{ width: "20%" }}
                  placeholder="Please select"
                  // onChange={onChangeTeam}
                  // value={getTeam}
                  value={getDataAll?.distribution}
                  disabled
                >
                  {getTeam?.map((x: typeTeam, index: number) => {
                    return (
                      <Option value={x.id} key={index + 1}>
                        {x.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                {getDataAll?.status === "APPROVED" ? (
                  <div
                    style={{
                      textAlign: "left",
                      marginLeft: "282px",
                      marginBottom: "10px",
                    }}
                  >
                    <Tooltip title={"หากต้องการแก้ไขข้อมูล ให้เปลี่ยนวัน Expire Day"}>
                      <Button
                        type="primary"
                        disabled={getValueExpireday === undefined || getValueExpireday === "" || getValueExpireday === null ? true : false}
                        onClick={() => onClickUpdate("APPROVED")}
                      >
                        แก้ไข
                      </Button>
                    </Tooltip>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                {" "}
                {checkAccessRole("ddebtDataManagementDisableRuleBase") && getDataAll?.status !== "DISABLED" ? (
                  <Row>
                    <Col span={4} style={{ textAlign: "left" }}></Col>
                    <Col offset={1} span={19} style={{ textAlign: "left" }}>
                      <Button type="primary" danger onClick={() => onClickUpdate("DISABLED")}>
                        Disable
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Card>
        </Row>
      </>
    </Spin>
  );
};
