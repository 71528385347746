/* eslint-disable @typescript-eslint/consistent-type-assertions */
import dotenv from "dotenv";

export interface AppConfig {
  apiBaseUrl: string;
  apiBaseUrlDebt: string;
  authUrl: string;
  mockResponse: boolean;
  language?: string;
  secret: string;
  appVersion: string;
  auth24BaseUrl: string;
  appClientId: string;
  boostrapData: boolean;
  purposeProfileId: string;
  channelId: string;
  xAccessToken: string;
  featurePublicConsentEnable: false;
  apiBaseUrlDebtId24: string;
  apiUrlDebtId24: string;
  apiBaseUrlUser: string;
  notiUrl: string;
  apiBaseUrlNoti: string;
  apiBaseUrlDatalink: string;
  ddebtVersion: string;
  apiBaseUrlBroadcast: string;
  apiBaseUrlExport: string;
}

export const loadAppConfig = (): AppConfig => {
  var hostRr = window.location.origin;
  var protocol = window.location.protocol;
  var host = window.location.host;
  dotenv.config();
  const {
    REACT_APP_API_BASE_URL,
    REACT_APP_API_BASE_URL_DEBT,
    REACT_APP_MOCK_RESPONSE,
    REACT_APP_AUTH_URL,
    REACT_APP_VERSION,
    REACT_APP_BOOTSTRAP_DATA,
    REACT_APP_CLIENT_ID,
    REACT_APP_PURPOSE_PROFILE_ID,
    REACT_APP_CHANNEL_ID,
    REACT_APP_X_ACCESS_TOKEN,
    REACT_APP_FEATURE_PUBLIC_CONSENT,
    REACT_APP_ID24_BASE_URL,
    REACT_APP_ID24_URL,
    REACT_APP_API_BASE_URL_DEV,
    REACT_APP_API_BASE_URL_DEV_DEBT,
    REACT_APP_API_BASE_URL_DEV_USER,
    REACT_APP_ID24_BASE_URL_DEV,
    REACT_APP_ID24_URL_DEV,
    REACT_APP_NOTI_SERVER,
    REACT_APP_API_BASE_URL_DEV_NOTI,
    REACT_APP_DDEBT_VERSION,
    REACT_APP_NOTI_SERVER_PROTOCOL,
    REACT_APP_NOTI_SERVER_DEV,
    REACT_APP_API_BASE_URL_USER,
    REACT_APP_API_BASE_URL_DEV_DATALINK,
    REACT_APP_API_BASE_URL_DATALINK,
    REACT_APP_API_BASE_URL_DEV_BROADCAST,
    REACT_APP_API_BASE_URL_BROADCAST,
    REACT_APP_API_BASE_URL_EXPORT,
    REACT_APP_API_BASE_URL_DEV_EXPORT,
  } = process.env;

  const appConfig = {};
  Object.assign(appConfig, { language: "TH" });
  Object.assign(appConfig, { secret: "25E74932-8B8C-4D55-A585-2B31E6EB30E0" });

  if (REACT_APP_ID24_BASE_URL) {
    Object.assign(appConfig, {
      apiBaseUrlDebtId24: protocol + REACT_APP_ID24_BASE_URL + host,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlDebtId24: REACT_APP_ID24_BASE_URL_DEV,
    });
  }
  if (REACT_APP_ID24_URL) {
    Object.assign(appConfig, {
      apiUrlDebtId24: protocol + REACT_APP_ID24_URL + host,
    });
  } else {
    Object.assign(appConfig, { apiUrlDebtId24: REACT_APP_ID24_URL_DEV });
  }

  // if (REACT_APP_ID24_BASE_URL) {
  //   Object.assign(appConfig, {
  //     apiBaseUrlDebtId24: protocol + REACT_APP_ID24_BASE_URL + "osd.co.th",
  //   });
  // } else {
  //   Object.assign(appConfig, {
  //     apiBaseUrlDebtId24: REACT_APP_ID24_BASE_URL_DEV,
  //   });
  // }
  // if (REACT_APP_ID24_URL) {
  //   Object.assign(appConfig, {
  //     apiUrlDebtId24: protocol + REACT_APP_ID24_URL + "osd.co.th",
  //   });
  // } else {
  //   Object.assign(appConfig, { apiUrlDebtId24: REACT_APP_ID24_URL_DEV });
  // }

  if (REACT_APP_API_BASE_URL) {
    Object.assign(appConfig, { apiBaseUrl: hostRr + REACT_APP_API_BASE_URL });
  } else {
    Object.assign(appConfig, { apiBaseUrl: REACT_APP_API_BASE_URL_DEV });
  }
  if (REACT_APP_API_BASE_URL_USER) {
    Object.assign(appConfig, {
      apiBaseUrlUser: hostRr + REACT_APP_API_BASE_URL_USER,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlUser: REACT_APP_API_BASE_URL_DEV_USER,
    });
  }
  if (REACT_APP_API_BASE_URL_DEBT) {
    Object.assign(appConfig, {
      apiBaseUrlDebt: hostRr + REACT_APP_API_BASE_URL_DEBT,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlDebt: REACT_APP_API_BASE_URL_DEV_DEBT,
    });
  }
  if (REACT_APP_API_BASE_URL_EXPORT) {
    Object.assign(appConfig, {
      apiBaseUrlExport: hostRr + REACT_APP_API_BASE_URL_EXPORT,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlExport: REACT_APP_API_BASE_URL_DEV_EXPORT,
    });
  }
  if (REACT_APP_DDEBT_VERSION) {
    Object.assign(appConfig, {
      ddebtVersion: REACT_APP_DDEBT_VERSION,
    });
  }

  if (REACT_APP_MOCK_RESPONSE) {
    Object.assign(appConfig, {
      mockResponse: REACT_APP_MOCK_RESPONSE === "true",
    });
  }

  if (REACT_APP_AUTH_URL) {
    Object.assign(appConfig, { authUrl: REACT_APP_AUTH_URL });
  }

  if (REACT_APP_VERSION) {
    Object.assign(appConfig, { appVersion: REACT_APP_VERSION });
  }

  if (REACT_APP_CLIENT_ID) {
    Object.assign(appConfig, { appClientId: REACT_APP_CLIENT_ID });
  }

  if (REACT_APP_CHANNEL_ID) {
    Object.assign(appConfig, { channelId: REACT_APP_CHANNEL_ID });
  }

  if (REACT_APP_PURPOSE_PROFILE_ID) {
    Object.assign(appConfig, {
      purposeProfileId: REACT_APP_PURPOSE_PROFILE_ID,
    });
  }

  if (REACT_APP_X_ACCESS_TOKEN) {
    Object.assign(appConfig, { xAccessToken: REACT_APP_X_ACCESS_TOKEN });
  }

  if (REACT_APP_FEATURE_PUBLIC_CONSENT) {
    Object.assign(appConfig, {
      featurePublicConsentEnable: REACT_APP_FEATURE_PUBLIC_CONSENT,
    });
  }

  if (REACT_APP_BOOTSTRAP_DATA) {
    Object.assign(appConfig, {
      boostrapData: REACT_APP_BOOTSTRAP_DATA === "true",
    });
  }
  if (REACT_APP_NOTI_SERVER) {
    Object.assign(appConfig, {
      notiUrl: REACT_APP_NOTI_SERVER_PROTOCOL + host + REACT_APP_NOTI_SERVER,
    });
  } else {
    Object.assign(appConfig, {
      notiUrl: REACT_APP_NOTI_SERVER_DEV,
    });
  }

  if (REACT_APP_API_BASE_URL_DEV_NOTI) {
    Object.assign(appConfig, {
      apiBaseUrlNoti: REACT_APP_API_BASE_URL_DEV_NOTI,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlNoti: hostRr + REACT_APP_NOTI_SERVER,
    });
  }

  if (REACT_APP_API_BASE_URL_DEV_BROADCAST) {
    Object.assign(appConfig, {
      apiBaseUrlBroadcast: REACT_APP_API_BASE_URL_DEV_BROADCAST,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlBroadcast: hostRr + REACT_APP_API_BASE_URL_BROADCAST,
    });
  }
  if (REACT_APP_API_BASE_URL_DATALINK) {
    Object.assign(appConfig, {
      apiBaseUrlDatalink: hostRr + REACT_APP_API_BASE_URL_DATALINK,
    });
  } else {
    Object.assign(appConfig, {
      apiBaseUrlDatalink: REACT_APP_API_BASE_URL_DEV_DATALINK,
    });
  }

  return <AppConfig>appConfig;
};
