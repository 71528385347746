import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Input, Modal, Select, Space, Table, Row, Tooltip, message, notification, Switch, Typography, Tag } from "antd";
import { FormOutlined, EllipsisOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { OaUserService } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { TablePaginationConfig } from "antd/es/table/interface";
import { dateCheckTime } from "../../../utils";
import { IDataTable, IUser } from "../../oaProfile/common";
const appConfig = loadAppConfig();
const { Option } = Select;

export interface TPagination {
  current: number;
  pageSize: number;
}

interface Props {
  proFile: IUser;
  fetchData: () => void;
  countNoActive: number;
  countInActive: number;
  dataUsers: IDataTable[];
  setLoading: (status: boolean) => void;
  oaOrganize: any;
  subOrganize: any;
}

export const TableOaUser: React.FC<Props> = ({
  proFile,
  fetchData,
  countNoActive,
  countInActive,
  dataUsers,
  setLoading,
  oaOrganize,
  subOrganize,
}) => {
  const { tokenAccess, id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const refTitle = useRef<any>(null);
  const refTitletwo = useRef<any>(null);
  const refTitlettree = useRef<any>(null);
  const [dataoaCode, setDataoaCode] = useState("");
  const [dataToken, setDataToken] = useState("");
  const [dataId, setDataId] = useState("");
  const [dataName, setDataName] = useState("");
  const [dataAddress, setAddress] = useState("");
  const [dataEmail, setEmail] = useState("");
  const [orCode, setOrCode] = useState("");
  const [active, setActive] = useState<boolean>(false);
  const [dataPhoneNumber, setPhoneNumber] = useState("");
  const [statusModalEdit, setStatusModalEdit] = useState(false);
  const [statusModalResetPass, setStatusModalResetPass] = useState(false);
  const [organizeProfile, setOrganizeProfile] = useState<any>([]);
  const [editFormContact] = Form.useForm();
  const [formReset] = Form.useForm();
  const [getUserAll, setUserAll] = useState<any>([]);
  const [dataResetPassword, setDataResetPassword] = useState("");
  const [password, setPassword] = useState("");
  const [tokenForResetPassword, setTokenForResetPassword] = useState("");
  const [resetpasswordInitialValues, setResetpasswordInitialValues] = useState("");
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });

  const validatePhone = (number: string) => {
    if (!number) {
      return true;
    }
    return /^[0-9]{1,10}$/.test(number);
  };

  const columns: any = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (event: string, row: IDataTable, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "update date",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (event: string, row: IDataTable, index: number) => dateCheckTime(event),
    },
    {
      title: "update by",
      dataIndex: "updateBy",
      key: "updateBy",
      render: (event: string) => {
        const userupdateName = getUserAll?.find((value: any) => value.id === Number(event));
        return userupdateName?.username;
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "In Active",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.active === value,
      render: (event: boolean, row: any, index: number) => (event === true ? <Tag color="green">{`Active`}</Tag> : <Tag>{`InActive`}</Tag>),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (e: string, record: any) => {
        return (
          <>
            <Row gutter={10}>
              {/* modal edit OA */}
              <Col>
                <Tooltip placement="topLeft" title="Edit">
                  <Button
                    type="default"
                    onClick={() => {
                      editModal(record);
                    }}
                  >
                    <FormOutlined />
                  </Button>
                </Tooltip>
              </Col>
              {/* modal reset password */}
              <Col>
                <Tooltip placement="topRight" title="Reset Password">
                  <Button
                    type="default"
                    onClick={() => {
                      resetPassword(record);
                    }}
                  >
                    <EllipsisOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
  const editModal = (e: any) => {
    console.log(e);
    setStatusModalEdit(true);
    setDataoaCode(e.oaCode);
    setDataToken(e.token);
    setDataId(e.id);
    setDataName(e.name);
    setAddress(e.address);
    setEmail(e.email);
    setPhoneNumber(e.phone);
    setOrganizeProfile(e.oaOrganize);
    setOrCode(e.subOrganizationProfile);
    setActive(e.active);
  };
  const resetPassword = (e: any) => {
    setTokenForResetPassword(e.token);
    setStatusModalResetPass(true);
    setDataId(e.id);
    setDataResetPassword(e.resetpassword);
    setResetpasswordInitialValues("");
  };

  useEffect(() => {
    const userList = async () => {
      const data: any = await OaUserService(apiBaseUrlDebt).getUserAll();
      const result = data?.map((event: any) => {
        return {
          id: event.id,
          username: event.username,
        };
      });
      setUserAll(result);
    };
    userList();
  }, []);

  const onFinishEditOa = async (values: any) => {
    console.log(values);
    Modal.confirm({
      title: "ยืนยัน",
      icon: <CheckCircleOutlined />,
      content: "ยืนยันการแก้ไขข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        const resUpdate = await OaUserService(apiBaseUrlUser).updateUser(values);
        if (resUpdate) {
          if (!resUpdate.message) {
            message.success("ทำการแก้ไขสำเร็จ");
            fetchData();
            setStatusModalEdit(false);
          } else {
            message.warning(`${resUpdate.message}`);
          }
        }
      },
    });
  };

  const onFinishReset = async (values: any) => {
    Modal.confirm({
      title: "ยืนยันการเปลี่ยนแปลงรหัสผ่าน",
      icon: <FormOutlined />,
      content: "คุณต้องการเปลี่ยนรหัสผ่านใช่ไหม ?",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        const resUpdate = await OaUserService(apiBaseUrlUser).updateUserPassword(values);
        console.log(resUpdate);
        if (resUpdate) {
          if (!resUpdate.message) {
            message.success("ได้ทำการเปลี่ยนรหัสผ่านสำเร็จ");
            fetchData();
            setStatusModalResetPass(false);
          } else {
            if (resUpdate.reasons.length > 0) {
              resUpdate.reasons.map((message: string) =>
                notification.error({
                  duration: 5,
                  placement: "top",
                  message: ` ${message} `,
                  description: ` ${message}`,
                  style: {
                    width: 600,
                    marginLeft: 335 - 600,
                    whiteSpace: "pre-line",
                  },
                }),
              );
            }
          }
        }
      },
      onCancel: () => {
        formReset.resetFields();
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handlePassword = (passwordValue: any) => {
    setPassword(passwordValue);
  };

  const handleCancel = () => {
    setStatusModalEdit(false);
  };

  const handleCancelReset = () => {
    setStatusModalResetPass(false);
    formReset.resetFields();
  };

  function onChange(pagination: TablePaginationConfig) {
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
  }

  return (
    <>
      <div style={{ width: "100%", justifyContent: "end", display: "flex", margin: "10px 10px 10px -10px" }}>
        <Row gutter={[32, 0]}>
          <Col>
            <Typography>{`Active : ${countInActive}`}</Typography>
          </Col>
          <Col>
            <Typography>{`In-Active : ${countNoActive}`}</Typography>
          </Col>
        </Row>
      </div>
      <Table columns={columns} dataSource={dataUsers} scroll={{ x: "calc(800px + 30%)" }} rowKey={(record: any) => record.id} onChange={onChange} />

      {/* Edit OA */}
      <Modal title="Edit OA" open={statusModalEdit} onCancel={handleCancel} footer={[]} width={1200}>
        <Form
          initialValues={{
            id: dataId,
            token: dataToken,
            oaCode: dataoaCode,
            name: dataName,
            address: dataAddress,
            email: dataEmail,
            phone: dataPhoneNumber,
            setorganize: organizeProfile,
            setroles: orCode,
            active: active,
            updateby: proFile.id,
          }}
          form={editFormContact}
          name="editProfile"
          autoComplete="off"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 17 }}
          onFinish={onFinishEditOa}
          onFinishFailed={onFinishFailed}
          fields={[
            {
              name: ["id"],
              value: dataId,
            },
            {
              name: ["token"],
              value: dataToken,
            },
            {
              name: ["oaCode"],
              value: dataoaCode,
            },
            {
              name: ["name"],
              value: dataName,
            },
            {
              name: ["address"],
              value: dataAddress,
            },
            {
              name: ["email"],
              value: dataEmail,
            },
            {
              name: ["phone"],
              value: dataPhoneNumber,
            },
            {
              name: ["setorganize"],
              value: organizeProfile,
            },
            {
              name: ["setroles"],
              value: orCode,
            },
            { name: ["active"], value: active },
            { name: ["updateby"], value: String(proFile.id) },
          ]}
        >
          <Form.Item hidden={true} label="updateby" name="updateby">
            <Input placeholder="id" style={{ width: "90%" }} hidden />
          </Form.Item>
          <Form.Item hidden={true} label="ID" name="id">
            <Input placeholder="id" style={{ width: "90%" }} readOnly />
          </Form.Item>

          <Form.Item hidden={true} label="Token" name="token">
            <Input placeholder="token" style={{ width: "90%" }} readOnly />
          </Form.Item>
          <Form.Item hidden={true} label="oaCode" name="oaCode">
            <Input placeholder="oaCode" style={{ width: "90%" }} readOnly />
          </Form.Item>

          <Form.Item name={"active"} label="Active" valuePropName={"checked"}>
            <Switch ref={refTitlettree} checkedChildren={"Active"} unCheckedChildren={"No Active"} />
          </Form.Item>

          <Form.Item
            label="Username"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input id="name" placeholder="Username" style={{ width: "90%" }} onChange={(e: any) => setDataName(e.target.value)} disabled={true} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input Email or is not valid E-mail!",
              },
            ]}
          >
            <Input id="email" placeholder="Email" style={{ width: "90%" }} onChange={(e: any) => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                validator: async (_, storeValue) => {
                  if (validatePhone(storeValue)) {
                    return Promise.resolve(storeValue);
                  }
                  return Promise.reject(new Error("Please Input Phone Number"));
                },
              },
            ]}
          >
            <Input
              maxLength={10}
              id="phone"
              placeholder="Phone Number"
              style={{ width: "90%" }}
              onChange={(e: any) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>

          <Col>
            <Form.Item
              label="Set organize"
              name="setorganize"
              rules={[
                {
                  required: true,
                  message: "Please Select Organize",
                },
              ]}
            >
              <Select placeholder="Please Select Organize" style={{ width: 200 }} ref={refTitle}>
                {oaOrganize?.map((e: any) => {
                  return <Option key={e.key}>{e.title}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Set roles"
              name="setroles"
              rules={[
                {
                  required: true,
                  message: "Please Select Roles",
                },
              ]}
            >
              <Select placeholder="Please Select Roles" style={{ width: 200 }} ref={refTitletwo}>
                {subOrganize?.map((x: any, row: any, index: number) => {
                  return (
                    <Option value={x.id} key={x.id}>
                      {x.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
              Save
            </Button>
            <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
      <Modal title="Reset Password" open={statusModalResetPass} onCancel={handleCancelReset} footer={null} width={1200}>
        <Form
          name="editPassword"
          form={formReset}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 17 }}
          onFinish={onFinishReset}
          onFinishFailed={onFinishFailed}
          initialValues={{ resetpassword: resetpasswordInitialValues }}
          fields={[
            {
              name: ["id"],
              value: dataId,
            },
            {
              name: ["Reset Password"],
              value: dataResetPassword,
            },
          ]}
        >
          <Form.Item hidden={true} label="ID" name="id">
            <Input placeholder="id" style={{ width: "90%" }} />
          </Form.Item>
          <Col>
            <Form.Item
              label="Reset Password"
              name="resetpassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password",
                },
              ]}
            >
              <Input.Password
                id="resetpassword"
                defaultValue={resetpasswordInitialValues}
                placeholder="New Password"
                style={{ width: "90%" }}
                onChange={(e: any) => handlePassword(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
              Confirm
            </Button>
            <Button key="cancel" onClick={handleCancelReset} style={{ width: "80px" }}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
