import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Row,
  Tooltip,
  message,
  TimePicker,
  Tag,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import type { ColumnsType, TableProps } from "antd/es/table";
import { IFilter, BatchProcessFileService } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();
const { Option } = Select;
import dayjs from "dayjs";
// New dayjs
import thTH from "antd/lib/locale-provider/th_TH";
import "dayjs/locale/th";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style";
const DatePicker = generatePicker(dayjsGenerateConfig);
var buddhistEra = require("dayjs/plugin/buddhistEra");
dayjs.extend(buddhistEra);
const { RangePicker } = DatePicker;
dayjs.locale("th");
const format = "HH:mm";

// interface Item {
//   key: string;
//   name: string;
//   age: string;
//   address: string;
//   role: string;
// }

interface IDataTable {
  key: React.Key;
  no: number;
  psId: number;
  rmId: number;
  lgId: number;
  fileName: string;
  extension: string;
  psDate: string;
  processType: string;
  jobs: string;
  flagStatus: number;
  loadStatus: number;
  status: string;
  details: string;
  timeOption: string;
  createAt: string;
  updateAt: string;
}
interface Props {
  fillterData: IFilter;
  userId: string;
  reloadTable: number;
  loading: boolean;
  handleLoadingTable: (load: boolean) => void;
  accessRole: string;
}

// interface timeOption {
//   psDate: string;
//   start: {
//     hour: number,
//     minute: number,
//   };
//   end: {
//     hour: number,
//     minute: number,
//   };
// }

export const TableBatchProcessFile: React.FC<Props> = ({
  fillterData,
  // userId,
  // reloadTable,
  loading,
  accessRole,

  handleLoadingTable,
}) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancelReset = () => {
    setIsModalResetVisible(false);
  };
  // const [dataEdit, setDataEdit] = useState<any>("");
  // const [dataoaCode, setDataoaCode] = useState("");
  // const [dataToken, setDataToken] = useState("");
  const [dataId, setDataId] = useState("");
  // const [dataName, setDataName] = useState("");
  // const [dataAddress, setAddress] = useState("");
  // const [dataEmail, setEmail] = useState("");
  // const [oaCode, setOaCode] = useState("");
  // const [orCode, setOrCode] = useState("");
  // const [dataPhoneNumber, setPhoneNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalResetVisible, setIsModalResetVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  // const [organize, setOrganize] = useState<any>([]);
  // const [subGroup, setSubGroup] = useState<any>([]);
  const [fileNameFilter, setFileNameFilter] = useState<any>([]);
  const [filepsDateFilter, setFilepsDateFilter] = useState<any>([]);
  const [fileFlagStatusFilter, setFileFlagStatusFilter] = useState<any>([]);
  const [fileLoadingFilter, setFileLoadingFilter] = useState<any>([]);
  const [fileTimeFilter, setFileTimeFilter] = useState<any>([]);
  const [fileCreateFilter, setFileCreateFilter] = useState<any>([]);

  // const [trackDate, setTrackDate] = useState<any>("");
  // const [trackTime, setTrackTime] = useState<any>("");

  const [editFormContact] = Form.useForm();

  // new state to check strong password
  const [dataResetPassword, setDataResetPassword] = useState("");
  const [password, setPassword] = useState("");
  const [tokenForResetPassword, setTokenForResetPassword] = useState("");
  const [resetpasswordInitialValues, setResetpasswordInitialValues] =
    useState("");

  const [dataFmId, setDataFmId] = useState("");
  const [dataFileName, setDataFileName] = useState("");
  const [dataExtension, setDataExtension] = useState("");
  const [dataProcessType, setDataProcessType] = useState("");
  const [dataLocalDir, setDataLocalDir] = useState("");
  const [dataJobs, setDataJobs] = useState("");
  const [flagStatus, setFlagStatus] = useState("");
  const [dataStatus, setDataStatus] = useState("");
  const [dataDetails, setDataDetails] = useState("");
  const [dataPsId, setDataPsId] = useState("");

  const initialValuesTable: IDataTable[] = [
    {
      key: 0,
      no: 0,
      psId: 0,
      rmId: 0,
      lgId: 0,
      fileName: "",
      extension: "",
      psDate: "",
      processType: "",
      jobs: "",
      flagStatus: 0,
      loadStatus: 0,
      status: "",
      details: "",
      timeOption: "",
      createAt: "",
      updateAt: "",
    },
  ];

  const { push } = useHistory();
  const ToDatasend = (path: any, id: number) => {
    let dataAll = {
      allItem: dataSource,
      id: id,
    };
    push(path, dataAll);
  };

  const [dataSource, setDataSource] = useState<IDataTable[]>([]);
  const columns: ColumnsType<IDataTable> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: "5%",
      fixed: "left",
      sorter: (a: any, b: any) => a.no - b.no,
    },

    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      width: "30%",
      filters: fileNameFilter,
      onFilter: (value: any, record) => {
        return record.fileName.startsWith(value);
      },
      filterSearch: true,
      render: (text: string, row: any, index: number) => {
        // console.log(row);
        return text ? (
          <a
            key={row.psId}
            onClick={() =>
              ToDatasend(
                `/batchProcessFile/batchProfilelog/id:${row.psId}`,
                row,
              )
            }
          >
            {text}
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Process Date",
      dataIndex: "psDate",
      key: "psDate",
      width: "10%",
      filters: filepsDateFilter,
      onFilter: (value: any, record) =>
        record.psDate?.startsWith(String(value)),
      filterSearch: true,

      render: (data: string, row: any, index: number) => {
        return data != "null"
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Download",
      dataIndex: "flagStatus",
      key: "flagStatus",
      width: "10%",
      filters: fileFlagStatusFilter,
      onFilter: (value: any, record) => {
        return String(record.flagStatus)?.startsWith(String(value));
      },
      filterSearch: true,

      render: (data: any, row: any, index: number) => {
        // console.log(data);

        if (data == 1) {
          return (
            <>
              <Tag color="success">Success</Tag>
            </>
          );
        } else if (data == 0) {
          return (
            <>
              <Tag color="blue">New</Tag>
            </>
          );
        } else {
          return (
            <>
              <Tag color="volcano">Fail</Tag>
            </>
          );
        }
      },
    },
    {
      title: "Import",
      dataIndex: "loadStatus",
      key: "loadStatus",
      width: "10%",
      filters: fileLoadingFilter,
      onFilter: (value: any, record) =>
        String(record.loadStatus)?.startsWith(String(value)),
      filterSearch: true,
      render: (data: any, row: any, index: number) => {
        if (data === 1) {
          return (
            <>
              <Tag color="success">Success</Tag>
            </>
          );
        } else if (data === 0) {
          return (
            <>
              <Tag color="blue">New</Tag>
            </>
          );
        } else {
          return (
            <>
              <Tag color="volcano">Fail</Tag>
            </>
          );
        }
      },
    },
    {
      title: "Run Time",
      dataIndex: "timeOption",
      key: "timeOption",
      width: "10%",
      filters: fileTimeFilter,
      onFilter: (value: any, record) => {
        return record.timeOption?.startsWith(value);
      },
      filterSearch: true,
      render: (data: any, row: any, index: number) => {
        // console.log(data);

        if (data === "null") {
          return "-";
        } else {
          let obj: any = JSON.parse("[" + data + "]");
          let date = obj[0]?.psDate;
          let conDate = "";
          if (date) {
            conDate = dayjs(date).add(543, "year").format("DD-MM-YYYY");
          }
          let startHour = obj[0]?.start?.hour;
          let lengthstartHour =
            startHour.toString().length === 1 ? "0" + startHour : startHour;
          let startMinute = obj[0]?.start?.minute;
          let lengthsstartMinute =
            startMinute.toString().length === 1
              ? "0" + startMinute
              : startMinute;
          let endHour = obj[0]?.end?.hour;
          let lengthsendHour =
            endHour.toString().length === 1 ? "0" + endHour : endHour;
          let endMinute = obj[0]?.end?.minute;
          let lengthsendMinute =
            endMinute.toString().length === 1 ? "0" + endMinute : endMinute;
          let convert: any = 0;
          convert = JSON.stringify(
            lengthstartHour +
              ":" +
              lengthsstartMinute +
              " - " +
              lengthsendHour +
              ":" +
              lengthsendMinute +
              " น.",
          );

          return convert.toString().replace(/"/g, "");
        }
      },
    },
    {
      title: "Create Date",
      dataIndex: "createAt",
      key: "createAt",
      width: "15%",
      filters: fileCreateFilter,
      onFilter: (value: any, record) => record.createAt?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY HH:mm")
          : "-";
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "5%",
      fixed: "right",
      align: "center" as const,
      render: (_: any, record: { key: React.Key }) => {
        if (dataSource.length >= 1) {
          return (
            <>
              <Row
                gutter={10}
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Col>
                  <Tooltip placement="topLeft" title="Edit">
                    <Button
                      style={{
                        border: "none",
                        background: "none",
                        color: "#108ee9",
                      }}
                      type="default"
                      onClick={() => {
                        editModal(record);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const columnsNo: ColumnsType<IDataTable> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: "5%",
      fixed: "left",
      sorter: (a: any, b: any) => a.no - b.no,
    },

    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      width: "30%",
      filters: fileNameFilter,
      onFilter: (value: any, record) => {
        return record.fileName.startsWith(value);
      },
      filterSearch: true,
      render: (text: string, row: any, index: number) => {
        // console.log(row);
        return text ? (
          <a
            key={row.psId}
            onClick={() =>
              ToDatasend(
                `/batchProcessFile/batchProfilelog/id:${row.psId}`,
                row,
              )
            }
          >
            {text}
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Process Date",
      dataIndex: "psDate",
      key: "psDate",
      width: "10%",
      filters: filepsDateFilter,
      onFilter: (value: any, record) =>
        record.psDate?.startsWith(String(value)),
      filterSearch: true,

      render: (data: string, row: any, index: number) => {
        return data != "null"
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Download",
      dataIndex: "flagStatus",
      key: "flagStatus",
      width: "10%",
      filters: fileFlagStatusFilter,
      onFilter: (value: any, record) => {
        return String(record.flagStatus)?.startsWith(String(value));
      },
      filterSearch: true,

      render: (data: any, row: any, index: number) => {
        // console.log(data);

        if (data == 1) {
          return (
            <>
              <Tag color="success">Success</Tag>
            </>
          );
        } else if (data == 0) {
          return (
            <>
              <Tag color="blue">New</Tag>
            </>
          );
        } else {
          return (
            <>
              <Tag color="volcano">Fail</Tag>
            </>
          );
        }
      },
    },
    {
      title: "Import",
      dataIndex: "loadStatus",
      key: "loadStatus",
      width: "10%",
      filters: fileLoadingFilter,
      onFilter: (value: any, record) =>
        String(record.loadStatus)?.startsWith(String(value)),
      filterSearch: true,
      render: (data: any, row: any, index: number) => {
        if (data === 1) {
          return (
            <>
              <Tag color="success">Success</Tag>
            </>
          );
        } else if (data === 0) {
          return (
            <>
              <Tag color="blue">New</Tag>
            </>
          );
        } else {
          return (
            <>
              <Tag color="volcano">Fail</Tag>
            </>
          );
        }
      },
    },
    {
      title: "Run Time",
      dataIndex: "timeOption",
      key: "timeOption",
      width: "10%",
      filters: fileTimeFilter,
      onFilter: (value: any, record) => {
        return record.timeOption?.startsWith(value);
      },
      filterSearch: true,
      render: (data: any, row: any, index: number) => {
        if (data === "null") {
          return "-";
        } else {
          let obj: any = JSON.parse("[" + data + "]");
          let date = obj[0]?.psDate;
          let conDate = "";
          if (date) {
            conDate = dayjs(date).add(543, "year").format("DD-MM-YYYY");
          }
          let startHour = obj[0]?.start?.hour;
          let lengthstartHour =
            startHour.toString().length === 1 ? "0" + startHour : startHour;
          let startMinute = obj[0]?.start?.minute;
          let lengthsstartMinute =
            startMinute.toString().length === 1
              ? "0" + startMinute
              : startMinute;
          let endHour = obj[0]?.end?.hour;
          let lengthsendHour =
            endHour.toString().length === 1 ? "0" + endHour : endHour;
          let endMinute = obj[0]?.end?.minute;
          let lengthsendMinute =
            endMinute.toString().length === 1 ? "0" + endMinute : endMinute;
          let convert: any = 0;
          convert = JSON.stringify(
            lengthstartHour +
              ":" +
              lengthsstartMinute +
              " - " +
              lengthsendHour +
              ":" +
              lengthsendMinute +
              " น.",
          );
          return convert.toString().replace(/"/g, "");
        }
      },
    },
    {
      title: "Create Date",
      dataIndex: "createAt",
      key: "createAt",
      width: "15%",
      filters: fileCreateFilter,
      onFilter: (value: any, record) => record.createAt?.startsWith(value),
      filterSearch: true,
      render: (data: string, row: any, index: number) => {
        return data
          ? dayjs(data).add(543, "year").format("DD-MM-YYYY HH:mm")
          : "-";
      },
    },
  ];

  const handleDelete = async (record: any) => {
    const newData = dataSource.filter((item) => item.key !== record.key);
    const res: any = await BatchProcessFileService(
      apiBaseUrlDatalink,
    ).deleteFile(record.psId);
    if (res) {
      message.success("ลบข้อมูลสำเร็จ");
    }
    setDataSource(newData);
  };

  const onChangeChecked = (checked: boolean, row: any, index: Number) => {
    // console.log(`switch to ${checked}`);
    // console.log(`row to ${row}`);
    // console.log(`index to ${index}`);
  };

  // check edit Modal
  const editModal = (e: any) => {
    // console.log(e);

    setIsModalVisible(true);
    setDataFmId(e.rmId);
    setDataFileName(e.fileName);
    setDataExtension(e.extension);
    setDataProcessType(e.processType);
    setDataLocalDir(e.localDir);
    setDataJobs(e.jobs);
    setFlagStatus(e.flagStatus);
    setDataStatus(e.status);
    setDataDetails(e.details);

    setDataPsId(e.psId);

    // setTrackDate(e.psDate);
    // setTrackTime(e.psDate);
  };
  // check state Modal
  const ResetModal = (e: any) => {
    setTokenForResetPassword(e.token);
    setIsModalResetVisible(true);
    setDataId(e.id);
    setDataResetPassword(e.resetpassword);
    setResetpasswordInitialValues("");
  };

  const [count, setCount] = useState(dataSource.length);

  useEffect(() => {
    async function fetchData() {
      const res1 = (await BatchProcessFileService(
        apiBaseUrlDatalink,
      ).getAllFile()) as any;
      // console.log(res1);

      let fileNameArray: any = [];
      let filepsDateArray: any = [];
      let fileFlagStatusArray: any = [];
      let fileLoadStatusArray: any = [];
      let fileTimeoptionsArray: any = [];
      let fileCreatedAtArray: any = [];

      let FileName = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.fileName === ele.fileName),
      );
      FileName.map((x: any) => {
        fileNameArray.push({
          text: x.fileName,
          value: x.fileName,
        });
      });

      let PsDate = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.psDate === ele.psDate),
      );
      // console.log(PsDate);
      PsDate.map((x: any) => {
        filepsDateArray.push({
          text:
            x.psDate === null
              ? "-"
              : dayjs(x.psDate).add(543, "year").format("DD-MM-YYYY"),
          value: x.psDate,
        });
      });

      let FlagStatus = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex((elem: any) => elem.flagStatus === ele.flagStatus),
      );
      let FlagStatuss = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex(
            (elem: any) =>
              elem.flagStatus != 100 ||
              (ele.flagStatus != 100 && elem.flagStatus != 0) ||
              ele.flagStatus != 0,
          ),
      );

      FlagStatus.map((x: any) => {
        // console.log(x.flagStatus);
        if (x.flagStatus === 100) {
          fileFlagStatusArray.push({
            text: "Success",
            value: 1,
          });
        } else if (x.flagStatus === 0) {
          fileFlagStatusArray.push({
            text: "New",
            value: 0,
          });
        }
      });
      FlagStatuss.map((x: any) => {
        if (x.flagStatus != 100 || x.flagStatus != 0) {
          fileFlagStatusArray.push({
            text: "Fail",
            value: 2,
          });
        }
      });
      let loadStatus = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex((elem: any) => elem.loadStatus === ele.loadStatus),
      );

      let loadStatuss = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex(
            (elem: any) =>
              elem.loadStatus != 200 ||
              (ele.loadStatus != 200 && elem.loadStatus != 0) ||
              ele.loadStatus != 0,
          ),
      );

      loadStatus.map((x: any) => {
        if (x.loadStatus === 200) {
          fileLoadStatusArray.push({
            text: "Success",
            value: 1,
          });
        } else if (x.loadStatus === 0) {
          fileLoadStatusArray.push({
            text: "New",
            value: 0,
          });
        }
      });

      loadStatuss.map((x: any) => {
        if (x.flagStatus != 200 || x.flagStatus != 0) {
          fileLoadStatusArray.push({
            text: "Fail",
            value: 2,
          });
        }
      });

      let Timeoptions = res1.filter(
        (ele: any, ind: any) =>
          ind ===
          res1.findIndex((elem: any) => elem.timeOption === ele.timeOption),
      );
      Timeoptions.map((x: any) => {
        let obj: any = JSON.parse("[" + x.timeOption + "]");
        let startHour = obj[0]?.start?.hour;
        let startMinute = obj[0]?.start?.minute;
        let endHour = obj[0]?.end?.hour;
        let endMinute = obj[0]?.end?.minute;
        let convert: any = JSON.stringify(
          startHour + ":" + startMinute + " - " + endHour + ":" + endMinute,
        );
        fileTimeoptionsArray.push({
          text:
            x.timeOption != "null" ? convert.toString().replace(/"/g, "") : "-",
          value: x.timeOption,
        });
      });

      let CreatedAt = res1.filter(
        (ele: any, ind: any) =>
          ind === res1.findIndex((elem: any) => elem.createAt === ele.createAt),
      );
      CreatedAt.map((data: any) => {
        fileCreatedAtArray.push({
          text: dayjs(data.createAt)
            .add(543, "year")
            .format("DD-MM-YYYY HH:mm:ss"),
          value: data.createAt,
        });
      });

      await setFileNameFilter(fileNameArray);
      await setFilepsDateFilter(filepsDateArray);
      await setFileFlagStatusFilter(fileFlagStatusArray);
      await setFileLoadingFilter(fileLoadStatusArray);
      await setFileTimeFilter(fileTimeoptionsArray);
      await setFileCreateFilter(fileCreatedAtArray);

      let convertData: any = [];
      await res1.map((e: any, i: number) => {
        convertData.push({
          key: i,
          no: i + 1,
          psId: e.psId,
          rmId: e.rmId,
          lgId: e.lgId,
          fileName: e.fileName,
          extension: e.extension,
          psDate: String(e.psDate) === null ? "" : String(e.psDate),
          processType: e.processType,
          jobs: e.jobs,
          flagStatus:
            String(e.flagStatus) === "100"
              ? 1
              : String(e.flagStatus) === "0"
              ? 0
              : 2,
          loadStatus:
            String(e.loadStatus) === "200"
              ? 1
              : String(e.loadStatus) === "0"
              ? 0
              : 2,
          status: e.status,
          details: e.details,
          timeOption: String(e.timeOption),
          createAt: e.createAt,
          updateAt: e.updateAt,
        });
      });
      await setDataSource(convertData);
      await setCount(convertData.length);
    }
    fetchData();
  }, [loading, tableLoading, fillterData]);

  const onFinish = async (values: any) => {
    handleLoadingTable(true);
    let date = dayjs(values.trackDate)
      .locale("th")
      .add(0, "year")
      .format("YYYY-MM-DD");
    let hh = dayjs(values.trackTime).format("HH");
    let mm = dayjs(values.trackTime).format("mm");

    let data: any = {
      timeOption: {
        psDate: date,
        start: {
          hour: hh,
          minute: mm,
        },
        end: {
          hour: hh,
          minute: mm,
        },
      },
    };
    let id: number = values.psId;

    const res: any = await BatchProcessFileService(apiBaseUrlDatalink).editFile(
      data,
      id,
    );

    if (res) {
      message.success("แก้ไขข้อมูลเรียบร้อย");
      setIsModalVisible(false);
      handleLoadingTable(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handlePassword = (passwordValue: any) => {
    setPassword(passwordValue);
  };

  const onChange: TableProps<IDataTable>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {};

  return (
    <>
      <Table
        size="small"
        loading={loading ? loading : tableLoading}
        columns={
          checkAccessRole("ddebtSettingBatchProcessFileAction")
            ? columns
            : columnsNo
        }
        dataSource={dataSource}
        rowKey={(record: any) => record.id}
      />

      <Modal
        title="Edit File"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <Form
          initialValues={{ remember: true }}
          form={editFormContact}
          name="editProfile"
          autoComplete="off"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 17 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={[
            {
              name: ["rmId"],
              value: dataFmId,
            },
            {
              name: ["psId"],
              value: dataPsId,
            },
            {
              name: ["fileName"],
              value: dataFileName,
            },
            {
              name: ["extension"],
              value: dataExtension,
            },
            {
              name: ["processType"],
              value: dataProcessType,
            },
            {
              name: ["localDir"],
              value: dataLocalDir,
            },
            {
              name: ["jobs"],
              value: dataJobs,
            },
            {
              name: ["flagStatus"],
              value: flagStatus,
            },
            {
              name: ["status"],
              value: dataStatus,
            },
            {
              name: ["details"],
              value: dataDetails,
            },
          ]}
        >
          <Form.Item hidden={true} label="ID" name="rmId">
            <Input placeholder="rmId  " style={{ width: "90%" }} readOnly />
          </Form.Item>
          <Form.Item hidden={true} label="psId" name="psId">
            <Input placeholder="psId  " style={{ width: "90%" }} readOnly />
          </Form.Item>
          {/* <Form.Item
            label="rmId"
            name="rmId"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="rmId"
              placeholder="rmId"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataFmId(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="fileName"
            name="fileName"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="fileName"
              placeholder="fileName"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataFileName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="extension"
            name="extension"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="extension"
              placeholder="extension"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataExtension(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="processType"
            name="processType"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="processType"
              placeholder="processType"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataProcessType(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="localDir"
            name="localDir"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="localDir"
              placeholder="localDir"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataLocalDir(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="jobs"
            name="jobs"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="jobs"
              placeholder="jobs"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataJobs(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="flagStatus"
            name="flagStatus"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="flagStatus"
              placeholder="flagStatus"
              style={{ width: "90%" }}
              onChange={(e: any) => setFlagStatus(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="status"
            name="status"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="status"
              placeholder="status"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataStatus(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="details"
            name="details"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input
              id="details"
              placeholder="details"
              style={{ width: "90%" }}
              onChange={(e: any) => setDataDetails(e.target.value)}
            />
          </Form.Item> */}
          <Form.Item
            initialValue={dayjs()}
            label="วันที่ทำงาน Job"
            name="trackDate"
            rules={[
              {
                required: true,
                message: "Please input Date",
              },
            ]}
          >
            <DatePicker format={"DD-MM-BBBB"} style={{ width: "30%" }} />
          </Form.Item>
          <Form.Item
            label="เวลาทำงาน Job"
            name="trackTime"
            rules={[
              {
                required: true,
                message: "Please input Time",
              },
            ]}
            initialValue={dayjs()}
          >
            <TimePicker format={format} />
          </Form.Item>
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              style={{ width: "80px" }}
            >
              Save
            </Button>
            <Button
              key="cancel"
              onClick={handleCancel}
              style={{ width: "80px" }}
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
