import axios from "axios";
import {
  serviceBackend,
  serviceBackendDebt,
} from "../../../drivers/service/axiosBackend";
export interface INotiSettingService {
  getDataAll: () => Promise<any>;
  getdataUpdate: (event: any) => Promise<any>;
}
export const NotiSettingService = (
  serviceBackendApi: any,
): INotiSettingService => ({
  getDataAll: async () => {
    const result = await serviceBackendApi.get("/notification/all");
    return result?.data || {};
  },
  getdataUpdate: async (event: any) => {
    const result = await serviceBackendApi.post("/notification/setting", event);
    return result?.data || {};
  },
});
