import React, { useContext, useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Table, Modal, message, Button, Form, Space, Typography, Input, Select, InputNumber, Radio, Popconfirm } from "antd";
import type { InputRef, TablePaginationConfig } from "antd";
import type { FormInstance } from "antd/lib/form";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { TeamService } from "../services/team-service";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { IUser } from "../../oaProfile/common";
import { DataType, OaType } from "../TeamManagement";
const appConfig = loadAppConfig();

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

export interface TPagination {
  current: number;
  pageSize: number;
}

interface AccountType {
  key: React.Key;
  UserId: number;
  name: string;
  share: number;
  userBank: number;
}
interface Props {
  proFile: IUser;
  dataTable: DataType[];
  countDataTable: number;
  listUsers: OaType[];
  detailUser: any;
  oaList: OaType[];
  accessRole: string;
  featchData: () => void;
}

export const TableTeamManagement: React.FC<Props> = ({
  proFile,
  dataTable,
  countDataTable,
  listUsers,
  detailUser,
  oaList,
  accessRole,
  featchData,
}) => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [form] = Form.useForm();
  const [statusModalEdit, setStatusModalEdit] = useState<boolean>(false);
  const [dataId, setDataId] = useState("");
  const [dataCreateBy, setDataCreateBy] = useState();
  const [hidden, setHidden] = useState(true);
  const [checkOa, setCheckOa] = useState("none");
  const [checkSum, setCheckSum] = useState("none");
  const [checkStatus, setCheckStatus] = useState("none");
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "OA",
      dataIndex: "UserId",
      width: "50%",
      render: (data: string, row: any, index: number) => {
        if (data) {
          return (
            <Select
              value={row.UserId}
              placeholder="Please Select"
              size="small"
              style={{ width: "100%" }}
              defaultValue={data}
              key={uuidv4()}
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
              onChange={(e: any, option: any) => {
                if (e) {
                  selectChange(option, row, index);
                }
              }}
            >
              <Option key={-1} value={-1} disabled>
                <div style={{ color: "grey" }}> Plese Select</div>
              </Option>
              {oaList?.map((opt, num) => {
                return (
                  <Option key={uuidv4()} value={opt.id}>
                    {opt.name}
                  </Option>
                );
              })}
            </Select>
          );
        }
      },
    },
    {
      title: "%",
      dataIndex: "share",
      render: (data: number, row: any, index: number) => {
        return (
          <InputNumber
            value={data}
            onChange={(e: any) => {
              accountChange(e, row, index);
            }}
            size="small"
            style={{ width: 80 }}
            min={0}
            max={100}
            maxLength={3}
            placeholder="0-100"
            defaultValue={data}
          />
        );
      },
    },
    {
      title: "User",
      dataIndex: "userBank",
      width: "30%",
      render: (data: string, row: any, index: number) => {
        return (
          <Select
            value={data}
            placeholder="Please Select"
            size="small"
            style={{ width: "100%" }}
            defaultValue={data}
            key={uuidv4()}
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
            onChange={(e: any, option: any) => {
              if (e) {
                bankChange(option, row, index);
              }
            }}
          >
            <Option key={uuidv4()} value={-1} disabled>
              <div style={{ color: "grey" }}> Plese Select</div>
            </Option>
            {listUsers.map((opt, index) => (
              <Option key={index} value={opt.id}>
                {opt.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record: any) => {
        return (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key, record)}>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const [dataSource, setDataSource] = useState<AccountType[]>([
    {
      key: 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: -1,
    },
  ]);

  const openModalEdit = async (e: any) => {
    let oaShow = [];
    for (let i = 1; i <= e.detail.length; i++) {
      oaShow.push({
        key: i,
        UserId: e.detail[i - 1].UserId,
        name: "",
        share: e.detail[i - 1].share,
        userBank: e.detail[i - 1].userBank,
      });
    }
    setDataSource(oaShow);
    setDataId(e.id);
    setDataCreateBy(e.createBy);
    form.setFieldValue("id", e.id);
    form.setFieldValue("name", e.name);
    form.setFieldValue("description", e.description);
    form.setFieldValue("user", e.user);
    form.setFieldValue("cifGrouping", e.cifGrouping);
    form.setFieldValue("assignBy", e.assignBy);
    form.setFieldValue("assignMode", e.assignMode);
    form.setFieldValue("detail", oaShow);
    setStatusModalEdit(true);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      render: (event: string, row: any, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (e: string) => dayjs(e).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      defaultSortOrder: "ascend" as const,
      render: (e: string) => dayjs(e).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (e: string, record: any) => {
        return <a onClick={() => openModalEdit(record)}>Edit</a>;
      },
    },
  ];

  const columnsManager: any = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      render: (event: string, row: any, index: number) => {
        let skip = (pagination.current - 1) * pagination.pageSize;
        return index + skip + 1;
      },
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (e: string) => dayjs(e).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      defaultSortOrder: "ascend" as const,
      render: (e: string) => dayjs(e).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm"),
    },
  ];

  const onFinishEditTeam = async (values: any) => {
    let validate = true;
    dataSource.map((opt) => {
      if (
        !opt.UserId ||
        opt.UserId === undefined ||
        !opt.share ||
        opt.UserId === -1 ||
        opt.userBank === -1 ||
        opt.userBank === undefined ||
        !opt.userBank
      ) {
        validate = false;
        return;
      } else {
        setCheckOa("none");
        setHidden(true);
      }
    });
    if (validate) {
      setCheckOa("none");
      let userStatus = "true";
      dataSource.forEach((data1) => {
        dataSource.forEach((data2) => {
          if (data1.UserId === data2.UserId && data1.key != data2.key) {
            userStatus = "false";
          }
        });
      });
      if (userStatus === "false") {
        setCheckStatus("block");
        setCheckSum("none");
        setHidden(false);
      } else {
        setCheckStatus("none");

        let newValue;
        if (values.description === undefined || values.description === "") {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: "",
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
        } else {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: values.description,
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
        }

        const result = dataSource.reduce((sum, number) => {
          return sum + number.share;
        }, 0);
        if (result === 100) {
          let detail: any[] = [];
          dataSource.map((row: any) => {
            detail.push({
              UserId: Number(row.UserId) as number,
              share: row.share as number,
              userBank: Number(row.userBank) as number,
            });
          });
          const teamsCreate = {
            ...newValue,
            detail: detail,
            user: "BANK",
            updateBy: detailUser.name,
            createBy: dataCreateBy,
          };
          await TeamService(apiBaseUrl)
            .updateTeamById(teamsCreate)
            .then((result) => {
              form.resetFields();
              message.success("This is a success message");
              setDataSource([
                {
                  key: 1,
                  UserId: -1,
                  name: "",
                  share: 0,
                  userBank: -1,
                },
              ]);
              featchData();
              setStatusModalEdit(false);
            })
            .catch((error) => {
              message.error(error);
            });
        } else {
          setCheckSum("block");
          setHidden(false);
        }
      }
    } else {
      setCheckOa("block");
      setCheckStatus("none");
      setCheckSum("none");
      setHidden(false);
    }
  };

  function onChange(pagination: TablePaginationConfig) {
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
  }

  const onFinishFailed = (errorInfo: any) => {};

  const handleCancel = () => {
    setStatusModalEdit(false);
  };

  const handleAddModal = () => {
    const neyKey: any = [];
    const lastKey = dataSource.map((rank: any, i: any, row: any) => {
      neyKey.push({
        key: i,
        UserId: rank.UserId,
        name: rank.name,
        share: rank.share,
        userBank: rank.userBank,
      });
    });

    const newData: AccountType = {
      key: dataSource.length + 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: -1,
    };
    setDataSource([...neyKey, newData]);
    // setCount(count + 1);
  };

  const handleSave = (row: AccountType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columnsModal = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AccountType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  const handleDelete = async (key: React.Key, record: any) => {
    if (record.UserId === -1) {
      setDataSource(dataSource.filter((item) => item.key !== key));
      return;
    } else {
      const resultUser = await TeamService(apiBaseUrlDebt)
        .searchuserbyid(record.UserId)
        .then(async (resultFinduser) => {
          return await TeamService(apiBaseUrlDebt).checkdeleteoa(resultFinduser.oaCode, dataId);
        });
      if (resultUser?.result === 0) {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
      } else {
        message.warning("ไม่สามารถลบได้เนื่องจากมีงานค้างอยู่ใน OA");
        return;
      }
    }
  };

  const accountChange = (share: any, row: any, index: number) => {
    let newArr = [...dataSource];
    newArr[index].share = share;
    setTimeout(() => {
      setDataSource(newArr);
    }, 1000);
  };

  const selectChange = (oa: any, row: any, index: number) => {
    let UserId = oa.value;
    let name = oa.children;
    let newArr = [...dataSource];
    newArr[index].UserId = UserId;
    newArr[index].name = name;
    setDataSource(newArr);
  };

  const bankChange = (e: any, row: any, index: number) => {
    let userBank = e.value;
    let newArr = [...dataSource];
    newArr[index].userBank = userBank;
    setDataSource(newArr);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Table
        columns={checkAccessRole("ddebtTeamManagementEditteam") ? columns : columnsManager}
        dataSource={dataTable}
        style={{ marginTop: 30, width: "100%" }}
        rowKey={(record: any) => record.id}
        pagination={{
          defaultPageSize: 10,
          total: countDataTable,
          showSizeChanger: true,
        }}
        onChange={onChange}
      />

      <Modal
        width={1200}
        title="Edit Team"
        open={statusModalEdit}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 17 }}
          onFinish={onFinishEditTeam}
          onFinishFailed={onFinishFailed}
          // fields={[
          //   {
          //     name: ["id"],
          //     value: dataId,
          //   },
          //   {
          //     name: ["name"],
          //     value: dataName,
          //   },
          //   {
          //     name: ["description"],
          //     value: dataDescription,
          //   },
          //   {
          //     name: ["user"],
          //     value: dataUser,
          //   },
          //   {
          //     name: ["cifGrouping"],
          //     value: dataCIFGrouping,
          //   },
          //   {
          //     name: ["assignBy"],
          //     value: dataAssignBy,
          //   },
          //   {
          //     name: ["assignMode"],
          //     value: dataOA,
          //   },
          //   {
          //     name: ["detail"],
          //     value: dataSource,
          //   },
          // ]}
        >
          <Form.Item hidden={true} label="id" name="id">
            <Input hidden={true} id="id" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input id="name" placeholder="Team 1" style={{ width: "90%" }} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea rows={4} placeholder="Text" style={{ width: "90%" }} />
          </Form.Item>
          <Form.Item label="Choose OA" name="detail">
            <Table
              size="small"
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnsModal as ColumnTypes}
              pagination={false}
              style={{ width: "90%" }}
              rowKey={(record: any) => {
                return record.key;
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 7 }} hidden={hidden}>
            <Text type="danger" style={{ display: checkOa }}>
              Please fill out the information completely.
            </Text>
            <Text type="danger" style={{ display: checkSum }}>
              Not 100 %.
            </Text>
            <Text type="danger" style={{ display: checkStatus }}>
              OA ซ้ำกัน
            </Text>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 11,
            }}
          >
            <Button onClick={handleAddModal} type="primary">
              Add
            </Button>
          </Form.Item>
          <Form.Item
            label="CIF Grouping"
            name="cifGrouping"
            rules={[
              {
                required: true,
                message: "Please input CIF Grouping!",
              },
            ]}
          >
            <Radio.Group name="cifGroupingRadio">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Assign By"
            name="assignBy"
            rules={[
              {
                required: true,
                message: "Please input Assign By!",
              },
            ]}
          >
            <Radio.Group name="assignByRadio">
              <Radio value={"OS_BAL"}>Outstanding Balance</Radio>
              <Radio value={"ACCOUNT"}>Account</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="OA"
            name="assignMode"
            rules={[
              {
                required: true,
                message: "Please input OA!",
              },
            ]}
          >
            <Radio.Group
              name="assignModeRadio"
              options={[
                { label: "Prefer New", value: "NEW_USER" },
                { label: "Prefer Previous", value: "PREVIOUS_USER" },
                { label: "Random", value: "RANDOM_USER" },
              ]}
            />
          </Form.Item>
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
              Save
            </Button>
            <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
