import axios from "axios";
import { serviceBackend, serviceBackendDebt } from "../../../drivers/service/axiosBackend";
import { IuserProfile } from "../../datamanagement/models/models-datamanagement";
import { IContactInFormation, TeamOA, TeamOANew } from "../model/debt";
import { scheduleexportcsv } from "../common";
export interface IDebtService {
  getUserDetail: () => Promise<IuserProfile>;
  getDebtList: (filter: any) => Promise<any>;
  getCountDebt: (filter: any) => Promise<string>;
  getAccountList: (accountNo: any) => Promise<string>;
  getDebtHeader: (accountNo: string, cifId: any) => Promise<string>;
  getDebtTarget: (accountNo: string, cifId: string) => Promise<string>;
  getDebtContactBank: (cif: string, accountNo: string) => Promise<string>;
  getDebtContactOa: (cif: string) => Promise<IContactInFormation[]>;
  createDebtContactOa: (form: any) => Promise<string>;
  updateDebtContactOa: (form: any) => Promise<string>;
  deleteDebtContactOa: (id: number) => Promise<string>;
  addAction: (form: any) => Promise<string>;
  getDebtWrapup: (cif: string, accountNo: string) => Promise<any>;
  getActionCode: () => Promise<string>;
  getContactType: () => Promise<string>;
  getPolicyList: () => Promise<string>;
  getUserList: () => Promise<string>;
  getDetailUser: () => Promise<string>;
  getProductCode: () => Promise<any>;
  getOa: () => Promise<TeamOANew[]>;
  getListNameByOa: (userId: any) => Promise<any>;
  getSupAllByUser: () => Promise<any>;
  getTeamByUser: (dataProfile: any) => Promise<any>;
  getDebtPayment: (accountNo: string, CHL: string, CusNo: string, cifId: string) => Promise<string>;
  getPointdetailupdate: (accountNo: any) => Promise<any>;
  getPointdetailcreate: (accountNo: any) => Promise<any>;
  getPointdetailsearch: (data: any) => Promise<any>;
  getAccountStatus: () => Promise<any>;
  exportDebt: (accountNo: string) => Promise<any>;
  getlistnameBytoken: () => Promise<any>;
  findtaskimport: () => Promise<any>;
  exportfilecsv: (idfile: string, namefile: string) => Promise<any>;
  lookupfile: (idfile: string, namefile: string) => Promise<any>;
  getTeamListBank: (type: string) => Promise<any>;
  getProfile: () => Promise<any>;
}

export const DebtService = (serviceBackendApi: any): IDebtService => ({
  getTeamListBank: async (type) => {
    const result = await serviceBackendApi.get(`/dist/all/${type}`);
    return result?.data?.result || {};
  },
  getAccountStatus: async () => {
    const result = await serviceBackendApi.get("/search/getActionStatus");
    return result?.data || {};
  },
  getTeamByUser: async (dataProfile) => {
    // serviceBackendDebt
    const data = {};
    const result = await serviceBackendApi.post("/user/teambyuser", data);
    return result?.data || {};
  },

  getSupAllByUser: async () => {
    // serviceBackendDebt
    const conditions = {};
    const result = await serviceBackendApi.post("/user/userByConditions", conditions);
    return result?.data || {};
  },

  getListNameByOa: async (userId: any) => {
    // serviceBackend
    const result = await serviceBackendApi.get(`/rule-base/User:${userId}:AssignUser`);
    return result?.data || {};
  },

  getDebtList: async (filter) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/search/debtSearch", filter);
    return result?.data || {};
  },

  getCountDebt: async (filter: any) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/search/countDebtSearch", filter);
    return result?.data || {};
  },

  getAccountList: async (data: any) => {
    // serviceBackendDebt
    // console.log(data);
    // let accountNo

    const result1 = await serviceBackendApi.post("/search/debtCust", {
      condition: { accountNo: data.accountNo, cif: data.cif },
    });
    const cif: any = result1.data.result[0].cif as any;
    const result2 = await serviceBackendApi.post("/search/debtCust", {
      condition: { cif: cif },
    });
    return result2?.data || {};
  },

  getDebtHeader: async (accountNo: string, cifId: string) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/search/debtCust", {
      condition: { accountNo: accountNo, cif: cifId },
    });
    return result?.data || {};
  },

  getDebtTarget: async (accountNo: string, cifId: string) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/point/search", {
      condition: { accountNo: accountNo, cif: cifId },
    });
    return result?.data || {};
  },

  getDebtContactBank: async (cif: string, accountNo: string) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/search/address", {
      condition: {
        cif: cif,
        NOT: { chn: "OA" },
        accountNo: { in: ["", accountNo] },
      },
    });
    // console.log(result.data);
    return result?.data || {};
  },
  getDebtPayment: async (accountNo: string, CHL: string, CusNo: string, cifId: string) => {
    // serviceBackendDebt
    if (CHL === "LOAN" || CHL === "OD") {
      const resultLOANOD = await serviceBackendApi.post("/payment/search", {
        condition: { accountNo: accountNo, cif: cifId },
      });
      return resultLOANOD?.data || {};
    } else {
      const resultCARD = await serviceBackendApi.post("/payment/search", {
        condition: { accountNo: accountNo, cusNo: CusNo, cif: cifId },
      });
      return resultCARD?.data || {};
    }
  },

  getDebtContactOa: async (cif) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/search/addressoa", {
      condition: { cif: cif, chn: "OA" },
    });
    return result?.data.result || {};
  },

  createDebtContactOa: async (form: any) => {
    // serviceBackendDebt
    // console.log(form);

    const result = await serviceBackendApi.post("/address/create", {
      data: form,
    });
    // return form;
    return result?.data || {};
  },

  updateDebtContactOa: async (form: any) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.put("/address/update", form);
    return result?.data || {};
  },

  deleteDebtContactOa: async (id: number) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.delete("/address/" + id);
    return result?.data || {};
  },

  getDebtWrapup: async (cif, accountNo) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/wrapup/search", {
      condition: { cif: cif, accountNo: accountNo },
    });
    return result?.data.result || {};
  },

  addAction: async (form: any) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post("/wrapup/save", { data: form });
    return result?.data || {};
  },

  getActionCode: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/search/actioncode");
    return result?.data || {};
  },

  getContactType: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/search/contactType");
    return result?.data || {};
  },

  getPolicyList: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/policy/search");
    return result?.data || {};
  },

  getUserList: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userList");
    return result?.data || {};
  },

  getDetailUser: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/debtor/userDetails");
    return result?.data || {};
  },

  getProductCode: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/productCode/search");
    return result?.data || {};
  },

  getOa: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get("/search/oaBytoken");
    return result?.data.result || {};
  },

  getUserDetail: async () => {
    // serviceBackendDebt
    const result = await serviceBackendApi.get(`/debtor/userDetails`);
    return result.data.result;
  },

  // New API ข้อมูลชี้เป้า
  getPointdetailupdate: async (data) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post(`/pointdetail/update`, data);
    return result?.data.result || {};
  },

  getPointdetailcreate: async (data) => {
    // serviceBackendDebt
    const result = await serviceBackendApi.post(`/pointdetail/create`, data);
    return result?.data.result || {};
  },

  getPointdetailsearch: async (data) => {
    // serviceBackendDebt
    const nameData = { accountNo: data };
    const result = await serviceBackendApi.post(`/pointdetail/search`, nameData);
    return result.data.result;
  },

  exportDebt: async (data) => {
    const result = await serviceBackendApi.post("/export-service/debt-search", data);
    return result?.data || {};
  },
  findtaskimport: async () => {
    serviceBackend;
    const result = await serviceBackendApi.post(`/search/findtaskimport`);
    return result?.data || {};
  },

  getlistnameBytoken: async () => {
    serviceBackend;
    const result = await serviceBackendApi.post(`/search/findListNameByUserId`);
    return result?.data || {};
  },
  exportfilecsv: async (idfile, namefile) => {
    serviceBackend;
    const result = await serviceBackendApi.get(`/search/exportfilecsv/${idfile}/${namefile}`, {
      responseType: "blob",
    });
    return result?.data || {};
  },
  lookupfile: async (idfile, namefile) => {
    serviceBackend;
    const result = await serviceBackendApi.get(`/search/lookupfile/${idfile}/${namefile}`);
    return result || {};
  },
  getProfile: async () => {
    const result = await serviceBackendApi.get("/profile/user");
    return result?.data || {};
  },
});
