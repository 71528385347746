import React, { useContext, useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { Row, Table, Space, Col, Typography, Modal, Button, Select, Input, AutoComplete, Form, Radio, InputNumber, Popconfirm, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import type { FormInstance } from "antd/lib/form";

import { TeamService } from "../services/team-debt-service";
import axios, { AxiosInstance } from "axios";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

interface AccountType {
  key: React.Key;
  UserId: number;
  name: string;
  share: number;
  userBank: number;
}
interface UserType {
  id: number;
  name: string;
}
interface Props {
  filter: (type: string, text: string) => void;
  chkReload: (type: string) => void;
  userId: string;
  accessRole: any;
}

export const FilterTeamManagement: React.FC<Props> = ({ filter, userId, chkReload, accessRole }) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [radioCIF, setRadioCIF] = useState("");
  const [radioAssignBy, setRadioAssignBy] = useState("");
  const [radioOA, setRadioOA] = useState("");

  const onChangeRadioCIF = (e: any) => {
    setRadioCIF(e.target.value);
  };
  const onChangeRadioAssignBy = (e: any) => {
    setRadioAssignBy(e.target.value);
  };
  const onChangeRadioOA = (e: any) => {
    setRadioOA(e.target.value);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [form] = Form.useForm();

  const [count, setCount] = useState(2);

  const [hidden, setHidden] = useState(true);

  const [checkOa, setCheckOa] = useState("none");
  const [checkSum, setCheckSum] = useState("none");
  const [checkStatus, setCheckStatus] = useState("none");
  const [fillType, setFillType] = useState("none");
  const [fillText, setFillText] = useState("");
  const [userList, setUserlist] = useState<UserType[]>([
    {
      id: 1,
      name: "OA 1",
    },
  ]);
  const [userUsed, setUserUsed] = useState<any>();

  useEffect(() => {
    getUserList();
  }, []);
  const getUserList = async () => {
    const userDetail: any = await TeamService(apiBaseUrlDebt).getDetailUser();
    setUserUsed(userDetail.result);

    const resultfindoauser = await TeamService(apiBaseUrlDebt).findoauser();
    const dataUserlist = resultfindoauser?.map((x: { id: number; name: string; level: string }) => {
      const mapData = {
        id: Number(x.id),
        name: x.name,
        level: x.level,
      };
      return mapData;
    });
    setUserlist(dataUserlist);
  };

  const onFinish = (values: any) => {
    let validate = true;
    // dataSource.map((opt) => {
    //   // console.log(opt);

    //   if (
    //     !opt.UserId ||
    //     opt.UserId === undefined ||
    //     !opt.share ||
    //     opt.UserId === -1
    //   ) {
    //     validate = false;
    //     return;
    //   } else {
    //     setCheckOa("none");
    //     setHidden(true);
    //   }
    // });
    if (validate) {
      setCheckOa("none");
      let userStatus = "true";
      dataSource.forEach((data1) => {
        dataSource.forEach((data2) => {
          if (data1.UserId === data2.UserId && data1.key !== data2.key) {
            userStatus = "false";
          }
        });
      });
      if (userStatus === "false") {
        setCheckStatus("block");
        setCheckSum("none");
        setHidden(false);
      } else {
        setCheckStatus("none");

        let newValue;
        if (values.description === undefined || values.description === "") {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: "",
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
          // console.log("1");
        } else {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: values.description,
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
          // console.log("2");
        }
        const result = dataSource.reduce((sum, number) => {
          return sum + number.share;
        }, 0);
        if (result === 100) {
          let detail: any[] = [];
          dataSource.map((row: any) => {
            detail.push({
              UserId: Number(row.UserId) as number,
              share: row.share as number,
              userBank: Number(row.userBank) as number,
            });
          });

          const teamsCreate = {
            ...newValue,
            user: String(userUsed.oaCode) as string,
            detail: detail,
            updateBy: userUsed.name,
            createBy: String(userUsed.oaCode),
          };
          form.resetFields();
          // console.log(teamsCreate);

          try {
            TeamService(apiBaseUrl)
              .createTeam(teamsCreate)
              .then((res) => {
                message.success("This is a success message");
                setDataSource([
                  {
                    key: 1,
                    UserId: -1,
                    name: "",
                    share: 0,
                    userBank: 0,
                  },
                ]);
                setCount(2);
                setIsModalVisible(false);
                getUserList();
                chkReload("1");
                form.resetFields();
              })
              .catch((error) => {
                message.error(error);
                form.resetFields();
              });
          } catch (error: any) {
            message.error(error);
          }
        } else {
          setCheckSum("block");
          setHidden(false);
        }
      }
    } else {
      setCheckOa("block");
      setCheckStatus("none");
      setCheckSum("none");
      setHidden(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const [dataSource, setDataSource] = useState<AccountType[]>([
    {
      key: 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: 0,
    },
  ]);

  const accountChange = (share: any, row: any, index: number) => {
    let newArr = [...dataSource];
    newArr[index].share = share;
    setDataSource(newArr);
  };

  const userChange = (oa: any, row: any, index: number) => {
    let userBank = oa.value;
    let newArr = [...dataSource];
    newArr[index].userBank = userBank;
    setDataSource(newArr);
  };

  const selectChange = (oa: any, row: any, index: number) => {
    let UserId = oa.value;
    let name = oa.children;
    let newArr = [...dataSource];
    newArr[index].UserId = UserId;
    newArr[index].name = name;
    setDataSource(newArr);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Collector",
      dataIndex: "oa",
      width: "50%",
      key: "key",
      render: (data: string, row: any, index: number) => {
        return (
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            placeholder="Please Select"
            size="small"
            style={{ width: "100%" }}
            value={data}
            onChange={(e: any, option: any) => {
              if (e) {
                selectChange(option, row, index);
              }
            }}
          >
            {userList.map((opt, index) => (
              <Option key={index} value={opt.id}>
                {opt.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "%",
      dataIndex: "share",
      key: "key",
      render: (data: number, row: any, index: number) => {
        return (
          <InputNumber
            onChange={(e: any) => {
              accountChange(e, row, index);
            }}
            size="small"
            style={{ width: 80 }}
            min={0}
            placeholder="0-100"
            value={data}
          />
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record: any) => {
        return (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const handleSave = (row: AccountType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AccountType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();

        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        // console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  const handleAddModal = async () => {
    const newData: AccountType = {
      key: count,
      UserId: -1,
      name: "",
      share: 0,
      userBank: 0,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleDelete = (key: React.Key) => {
    if (dataSource.length > 1) {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    }
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            Team Lists
          </Typography.Title>
        </Col>
        <Col span={16}>
          <div style={{ textAlign: "right" }}>
            <Space size={"small"}>
              <Select
                allowClear
                placeholder="Filter"
                style={{ width: 160 }}
                bordered={false}
                onChange={(event: any) => {
                  setFillType(event);
                }}
              >
                <Option value="none" disabled>
                  Please Select
                </Option>
                <Option value="TeamName">Team Name</Option>
                <Option value="TeamDescription">Team Description</Option>
                <Option value="UpdateBy">Update By</Option>
              </Select>
              <AutoComplete>
                <Input.Search
                  size="middle"
                  placeholder="input search text"
                  onSelect={(event: any) => {
                    setFillText(event.target.value);
                  }}
                  onSearch={() => {
                    const lowercaseFiletext: any = fillText.toLowerCase().indexOf(fillText.toLowerCase());
                    lowercaseFiletext >= 0 ? filter(fillType, fillText) : <></>;
                  }}
                />
              </AutoComplete>
              {/* older */}
              {checkAccessRole("ddebtTeamManagementAddteam") ? (
                <Button type="primary" onClick={showModal}>
                  + Add Team
                </Button>
              ) : (
                <></>
              )}

              <Modal title="Add Team" open={isModalVisible} onCancel={handleCancel} footer={[]} width={1200}>
                <Form
                  form={form}
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 17 }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  fields={[
                    {
                      name: ["id"],
                      value: uuidv4(),
                    },
                  ]}
                >
                  <Form.Item hidden={true} label="id" name="id">
                    <Input placeholder="Text" style={{ width: "90%" }} />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Name",
                      },
                    ]}
                  >
                    <Input id="name" placeholder="Text" style={{ width: "90%" }} />
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <TextArea rows={4} placeholder="Text" style={{ width: "90%" }} />
                  </Form.Item>
                  {/* <Form.Item
                    label="User"
                    name="user"
                    hidden
                    rules={[
                      {
                        required: true,
                        message: "Please input User",
                      },
                    ]}
                  >
                    <Select placeholder={"Select"} style={{ width: "90%" }} value="">
                      <Option value="Sup1">Sup 1</Option>
                      <Option value="Sup2">Sup 2</Option>
                      <Option value="Sup3">Sup 3</Option>
                    </Select>
                  </Form.Item> */}
                  <Form.Item label="Choose Collector" name="detail">
                    <Table
                      size="small"
                      rowClassName={() => "editable-row"}
                      bordered
                      dataSource={dataSource}
                      columns={columns as ColumnTypes}
                      pagination={false}
                      style={{ width: "90%" }}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 7 }} hidden={hidden}>
                    <Text type="danger" style={{ display: checkOa }}>
                      Please fill out the information completely.
                    </Text>
                    <Text type="danger" style={{ display: checkSum }}>
                      ไม่เท่ากับ 100 %.
                    </Text>
                    <Text type="danger" style={{ display: checkStatus }}>
                      Collector ซ้ำกัน
                    </Text>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 11,
                    }}
                  >
                    <Button onClick={handleAddModal} type="primary">
                      Add
                    </Button>
                  </Form.Item>
                  <Form.Item
                    label="CIF Grouping"
                    name="cifGrouping"
                    rules={[
                      {
                        required: true,
                        message: "Please input CIF Grouping!",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChangeRadioCIF} value={radioCIF}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="Assign By"
                    name="assignBy"
                    rules={[
                      {
                        required: true,
                        message: "Please input Assign By!",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChangeRadioAssignBy} value={radioAssignBy}>
                      <Radio value={"OS_BAL"}>Outstanding Balance</Radio>
                      <Radio value={"ACCOUNT"}>Account</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="OA"
                    name="assignMode"
                    rules={[
                      {
                        required: true,
                        message: "Please input assignMode!",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChangeRadioOA} value={radioOA}>
                      <Radio value={"NEW_USER"}>Prefer New</Radio>
                      <Radio value={"PREVIOUS_USER"}>Prefer Previous</Radio>
                      <Radio value={"RANDOM_USER"}>Random</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                      Save
                    </Button>
                    <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
                      Cancel
                    </Button>
                  </Space>
                </Form>
              </Modal>
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};
