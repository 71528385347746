import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { IDebtService, DebtService } from "../services/debt-service";
import NumberFormat from "react-number-format";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { checktext, dateCheck, DateStamp, numberWithCommas } from "../../../utils/index";
const appConfig = loadAppConfig();

export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  cifId: string;
}

export const DebtTabHeaderCollector: React.FC<Props> = ({ caption, accountNo, baseURL, loading, cifId }): React.ReactElement => {
  const debtService = DebtService;

  const [headerData, setHeaderData] = useState<any[]>([]);
  const { id24Axios } = useId24();
  // const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const styleTextHeader = {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#696969",
  } as any;

  const styleTextDetail = {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "28px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: "8px",
  } as any;

  useEffect(() => {
    getHeader();
  }, [accountNo, cifId]);

  const getHeader = async () => {
    const result = (await debtService(apiBaseUrlDebt).getDebtHeader(accountNo, cifId)) as any;
    setHeaderData(result.result);
  };

  return (
    <>
      {headerData.map((data: any, index: number) => {
        if (data.channel === "LOAN") {
          return (
            <div key={index}>
              <Row key="1" style={styleTextHeader} gutter={5}>
                <Col span={4}>Channel :</Col>
                <Col span={4}>เลขบัญชีสินเชื่อ :</Col>
                <Col span={4}>รหัสลูกค้า :</Col>
                <Col span={4}>เลขบัตรประชาชน :</Col>
                <Col span={4}>ชื่อ - นามสกุล :</Col>
                <Col span={4}>รหัสประเภทสินเชื่อย่อย :</Col>
              </Row>
              <Row key="2" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.channel)}</Col>
                <Col span={4}>{checktext(data.accountNo)}</Col>
                <Col span={4}>{checktext(data.cif)}</Col>
                <Col span={4}>{checktext(data.Cus.custIdCard)}</Col>
                <Col span={4}>
                  {data.Cus.custName} {data.Cus.custLastName}
                </Col>
                <Col span={4}>{checktext(data.detail.productCode)}</Col>
              </Row>

              <Row key="3" style={styleTextHeader} gutter={5}>
                <Col span={4}>รายละเอียดประเภทสินเชื่อย่อย :</Col>
                <Col span={4}>รหัส Market Code :</Col>
                <Col span={4}>รายละเอียดของ Market Code :</Col>
                <Col span={4}>วันที่ลงนาม :</Col>
                <Col span={4}>วันที่สิ้นสุดสัญญา :</Col>
                <Col span={4}>วงเงินอนุมัติ :</Col>
              </Row>

              <Row key="4" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.productDesc)}</Col>
                <Col span={4}>{checktext(data.detail.subProductCode)}</Col>
                <Col span={4}>{checktext(data.detail.subProductDesc)}</Col>
                <Col span={4}>{dateCheck(data.detail.contractSignDate)}</Col>
                <Col span={4}>{dateCheck(data.detail.contractEndDate)}</Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.approveAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <Row key="5" style={styleTextHeader} gutter={5}>
                <Col span={4}>ค่างวด :</Col>
                <Col span={4}>งวดปัจจุบัน :</Col>
                <Col span={4}>จำนวนงวด :</Col>
                <Col span={4}>วันที่ชำระล่าสุด :</Col>
                <Col span={4}>จำนวนเงินค้างชำระ :</Col>
                <Col span={4}>จำนวนงวดที่ชำระเงินแล้ว :</Col>
              </Row>

              <Row key="6" style={styleTextDetail} gutter={5}>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.installmentAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{checktext(data.detail.schInstallment)}</Col>
                <Col span={4}>{checktext(data.detail.period)}</Col>
                <Col span={4}>{dateCheck(data.detail.lastPaymentDate)}</Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.overdueAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{checktext(data.detail.paidPeriod)}</Col>
              </Row>

              <Row key="7" style={styleTextHeader} gutter={5}>
                <Col span={4}>ความถี่ในการชำระ :</Col>
                <Col span={4}>วันที่ครบกำหนดชำระหนี้ :</Col>
                <Col span={4}>อัตราดอกเบี้ยปกติ :</Col>
                <Col span={4}>อัตราดอกเบี้ยผิดนัด :</Col>
                <Col span={4}>อัตราดอกเบี้ยปัจจุบัน :</Col>
                <Col span={4}>สถานะอัตราดอกเบี้ย :</Col>
              </Row>

              <Row key="8" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.paySeq)}</Col>
                <Col span={4}>{dateCheck(data?.detail?.nextDueDate)}</Col>
                <Col span={4}>{checktext(data.detail.int)}</Col>
                <Col span={4}>{checktext(data.detail.intDefault)}</Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.intNow)}
                    // value={data.detail.intNow}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{checktext(data.detail.intStatus)}</Col>
              </Row>

              <Row key="9" style={styleTextHeader} gutter={5}>
                <Col span={4}>เงินต้นคงเหลือ :</Col>
                <Col span={4}>ดอกเบี้ยค้างรับ :</Col>
                <Col span={4}>ดอกเบี้ยปรับ :</Col>
                <Col span={4}>ยอดชำระปิดบัญชี :</Col>
                <Col span={4}>ดอกเบี้ยวันเพิ่มเติมยอดปิดบัญชี :</Col>
                <Col span={4}>วันที่คำนวณยอดปิดบัญชี :</Col>
              </Row>

              <Row key="10" style={styleTextDetail} gutter={5}>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.principleAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.intAccrued)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  {" "}
                  <NumberFormat
                    value={numberWithCommas(data.detail.intCharge)}
                    // value={data.detail.closePayAmt}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.closePayAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.closeIntAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{dateCheck(data.detail.calCloseDate)}</Col>
              </Row>

              <Row key="11" style={styleTextHeader} gutter={5}>
                <Col span={4}>สถานะตัดหนี้สูญ :</Col>
                <Col span={4}>ชั้นหนี้ :</Col>
                <Col span={4}>วันที่ตัดหนี้สูญ : </Col>
                <Col span={4}>สถานะ Sub Account :</Col>
                <Col span={4}>Master Account :</Col>
                <Col span={4}>สถานะบัญชี :</Col>
              </Row>

              <Row key="12" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.writeoffFlag)}</Col>
                <Col span={4}>{checktext(data.detail.provcat)}</Col>
                <Col span={4}>{dateCheck(data.detail.writeoffDate)}</Col>
                <Col span={4}>{checktext(data.detail.subAccStatus)}</Col>
                <Col span={4}>{checktext(data.detail.masterAcc)}</Col>
                <Col span={4}>{checktext(data.detail.accountStatus)}</Col>
              </Row>

              <Row key="13" style={styleTextHeader} gutter={5}>
                <Col span={4}>รหัสสาขาเจ้าของบัญชี :</Col>
                <Col span={4}>รายละเอียดสาขาเจ้าของบัญชี :</Col>
                <Col span={4}>วันที่รับงาน :</Col>
                <Col span={4}>วันที่ดึงงานกลับ :</Col>
                <Col span={4}>วันที่ส่ง SMS ของธนาคาร :</Col>
                <Col span={4}>OA Code :</Col>
              </Row>

              <Row key="14" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.ownerBranchCode)}</Col>
                <Col span={4}>{checktext(data.detail.ownerBranchDesc)}</Col>
                <Col span={4}>{DateStamp(data.assignTimeAfterAssign)}</Col>
                <Col span={4}>{DateStamp(data.expiredTimeAfterAssign)}</Col>
                <Col span={4}>{"N/A"}</Col>
                <Col span={4}>{checktext(data.User.organizationProfile.agentcyId)}</Col>
              </Row>
            </div>
          );
        } else if (data.channel === "OD") {
          // OD
          return (
            <div key={index}>
              <Row key="1" style={styleTextHeader} gutter={5}>
                <Col span={4}>Channel :</Col>
                <Col span={4}>เลขที่บัญชี OD :</Col>
                <Col span={4}>รหัสลูกค้า :</Col>
                <Col span={4}>เลขบัตรประชาชน :</Col>
                <Col span={4}>ชื่อ - นามสกุล :</Col>
                <Col span={4}>อัตราดอกเบี้ย :</Col>
              </Row>

              <Row key="2" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.channel)}</Col>
                <Col span={4}>{checktext(data.accountNo)}</Col>
                <Col span={4}>{checktext(data.cif)}</Col>
                <Col span={4}>{checktext(data.Cus.custIdCard)}</Col>
                <Col span={4}>
                  {data.Cus.custName} {data.Cus.custLastName}
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.IRN)}
                    // value={data.detail.IRN}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <Row key="3" style={styleTextHeader} gutter={5}>
                <Col span={4}>วันที่ชำระล่าสุด :</Col>
                <Col span={4}>สถานะตัดหนี้สูญ :</Col>
                <Col span={4}>วันที่ตัดหนี้สูญ :</Col>
                <Col span={4}>สถานะบัญชี :</Col>
                <Col span={4}>รายละเอียดสถานะบัญชี :</Col>
                <Col span={4}>จำนวนเงินคงเหลือ :</Col>
              </Row>

              <Row key="4" style={styleTextDetail} gutter={5}>
                <Col span={4}>{dateCheck(data.detail.TLD)}</Col>
                <Col span={4}>{checktext(data.detail.ZFWOS)}</Col>
                <Col span={4}>{dateCheck(data.detail.ZFWOD)}</Col>
                <Col span={4}>{checktext(data.detail.ZSTATCD)}</Col>
                <Col span={4}>{checktext(data.detail.ZSTATCD_DESC)}</Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.overdueAmt)}
                    // value={data.detail.IRN}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <Row key="5" style={styleTextHeader}>
                <Col span={4}>วงเงินอนุมัติ :</Col>
                <Col span={4}>จำนวนเดือนค้างของดอกเบี้ย :</Col>
                <Col span={4}>จำนวนเดือนค้างของเงินต้น :</Col>
                <Col span={4}>จำนวนเดือนค้างของบัญชี :</Col>
                <Col span={4}>วันที่สิ้นสุดวงเงินเก่าที่สุด :</Col>
                <Col span={4}>ดอกเบี้ยค้างจ่าย :</Col>
              </Row>

              <Row key="6" style={styleTextDetail} gutter={5}>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.ZCLTOT)}
                    // value={data.detail.ZCLTOT}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.ZINTDEL)}
                    // value={data.detail.ZINTDEL}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.ZDELPRIN)}
                    // value={data.detail.ZDELPRIN}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.ZDLPRD)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{dateCheck(data.detail.ZEXPDT)}</Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.ACR)}
                    // value={data.detail.ACR}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <Row key="7" style={styleTextHeader} gutter={5}>
                <Col span={4}>ดอกเบี้ยตัวแดงที่เบิกได้ภายในวงเงิน :</Col>
                <Col span={4}>ดอกเบี้ยตัวแดงค้างรับจ่าย :</Col>
                <Col span={4}>ระดับจัดชั้นหนี้ :</Col>
                <Col span={4}>ระดับจัดชั้นหนี้ override :</Col>
                <Col span={4}>จัดชั้นหนี้ :</Col>
                <Col span={4}>รหัสสาขาเจ้าของบัญชี : </Col>
              </Row>

              <Row key="8" style={styleTextDetail} gutter={5}>
                <Col span={4}>{numberWithCommas(data.detail.NEGACR)}</Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.NEGACRUN)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{checktext(data.detail.DARCLS)}</Col>
                <Col span={4}>{checktext(data.detail.DARCOVR)}</Col>
                <Col span={4}>{checktext(data.detail.PROVCAT)}</Col>
                <Col span={4}>{checktext(data.centerOfBranch.brcd)}</Col>
              </Row>

              <Row key="9" style={styleTextHeader} gutter={5}>
                <Col span={4}>รายละเอียดสาขา :</Col>
                <Col span={4}>OA Code :</Col>
              </Row>

              <Row key="10" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.centerOfBranch.brDesc)}</Col>
                <Col span={4}>{checktext(data.User.organizationProfile.agentcyId)}</Col>
              </Row>
            </div>
          );
        } else {
          return (
            <div key={index}>
              <Row key="1" style={styleTextHeader} gutter={5}>
                <Col span={4}>วันที่ส่งข้อมูล :</Col>
                <Col span={4} offset={2}>
                  ประเภทผลิตภัณฑ์ :
                </Col>
                <Col span={4} offset={1}>
                  CIF :
                </Col>
                <Col span={4}>Customer No. :</Col>
                <Col span={4} offset={1}>
                  Account No. :
                </Col>
              </Row>

              <Row key="2" style={styleTextDetail} gutter={5}>
                <Col span={4}>{dateCheck(data.detail.updateDataDate)}</Col>
                <Col span={4} offset={2}>
                  {checktext(data.detail.sub_pruduct_desc)}
                </Col>
                <Col span={4} offset={1}>
                  {checktext(data.Cus.cif)}
                </Col>
                <Col span={4}>{checktext(data.customerNo)}</Col>
                <Col span={4} offset={1}>
                  {checktext(data.accountNo)}
                </Col>
              </Row>

              <Row key="3" style={styleTextHeader} gutter={5}>
                <Col span={4}>Card No. :</Col>
                <Col span={4} offset={2}>
                  ประเภทของบัตร :
                </Col>
                <Col span={4} offset={1}>
                  ชนิดของบัตร :
                </Col>
                <Col span={4}>รอบวันที่ตัดบัตร :</Col>
                <Col span={4} offset={1}>
                  รอบการชําระ :
                </Col>
              </Row>

              <Row key="4" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.cardNo)}</Col>
                <Col span={4} offset={2}>
                  {checktext(data.detail.product_code)}
                </Col>
                <Col span={4} offset={1}>
                  {checktext(data.detail.product_desc)}
                </Col>
                <Col span={4}>
                  <NumberFormat value={checktext(data.detail.billCycle)} displayType={"text"} thousandSeparator={true} />
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat value={checktext(data.detail.cycleDue)} displayType={"text"} thousandSeparator={true} />
                </Col>
              </Row>

              <Row key="5" style={styleTextHeader} gutter={5}>
                <Col span={4}>Block Code :</Col>
                <Col span={4} offset={2}>
                  Due Date :
                </Col>
                <Col span={4} offset={1}>
                  วงเงินบัตร :
                </Col>
                <Col span={4}>ยอดเรียกเก็บรอบปัจจุบัน (Statement) :</Col>
                <Col span={4} offset={1}>
                  จํานวนการใช้เงินบัตร ณ ปัจจุบัน :
                </Col>
              </Row>

              <Row key="6" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.blockCode)}</Col>
                <Col span={4} offset={2}>
                  {dateCheck(data.detail.dueDate)}
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.crLimit)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.stmtAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.curBal)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <Row key="7" style={styleTextHeader} gutter={5}>
                <Col span={4}>จํานวนเงินต้นค้างชําระ :</Col>
                <Col span={4} offset={2}>
                  จํานวนดอกเบี้ยค้างชําระ :
                </Col>
                <Col span={4} offset={1}>
                  ค่าธรรมเนียมค้างชําระ :
                </Col>
                <Col span={4}>ดอกเบี้ยที่ยังไม่ถึงกำหนดเรียกเก็บ :</Col>
                <Col span={4} offset={1}>
                  จํานวนเงินค้างชําระท้ังหมด :
                </Col>
              </Row>

              <Row key="8" style={styleTextDetail} gutter={5}>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.principalAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={2}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.intAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.feeAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.intAccrued)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.overdueAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <Row key="9" style={styleTextHeader} gutter={5}>
                <Col span={4}>ขั้นต่ำในการชําระ :</Col>
                <Col span={4} offset={2}>
                  จำนวนวันค้างชำระ :
                </Col>
                <Col span={4} offset={1}>
                  วันที่ชำระล่าสุด :
                </Col>
                <Col span={4}>จํานวนเงินชําระล่าสุด :</Col>
                <Col span={4} offset={1}>
                  Account Status :{" "}
                </Col>
              </Row>

              <Row key="10" style={styleTextDetail} gutter={5}>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.minPayAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={2}>
                  {checktext(data.dpd)}
                </Col>
                <Col span={4} offset={1}>
                  {dateCheck(data.detail.lastPymtDate)}
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.lastPymtAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={1}>
                  {checktext(data.detail.accountStatus)}
                </Col>
              </Row>

              <Row key="11" style={styleTextHeader} gutter={5}>
                <Col span={4}>ชั้นหนี้ :</Col>
                <Col span={4} offset={2}>
                  NPL date :
                </Col>
                <Col span={4} offset={1}>
                  Write Off date :
                </Col>
                <Col span={4}>วันที่ได้รับงาน :</Col>
                <Col span={4} offset={1}>
                  วันที่ดึงงานคืน :
                </Col>
              </Row>

              <Row key="12" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.accountLevel)}</Col>
                <Col span={4} offset={2}>
                  {dateCheck(data.detail.nplDate)}
                </Col>
                <Col span={4} offset={1}>
                  {data.detail.woDate == "-" ? "-" : dateCheck(data.detail.woDate)}
                </Col>
                <Col span={4}>{DateStamp(data.assignTimeAfterAssign)}</Col>
                <Col span={4} offset={1}>
                  {DateStamp(data.expiredTimeAfterAssign)}
                </Col>
              </Row>

              <Row key="13" style={styleTextHeader} gutter={5}>
                <Col span={4}>วันเกิด :</Col>
                <Col span={4} offset={2}>
                  เพศ :
                </Col>
                <Col span={4} offset={1}>
                  คํานําหน้า :
                </Col>
                <Col span={4}>ชื่อ - นามสกุล :</Col>
                <Col span={4} offset={1}>
                  อาชีพ :
                </Col>
              </Row>

              <Row key="14" style={styleTextDetail} gutter={5}>
                <Col span={4}>{dateCheck(data.Cus.dob)}</Col>
                <Col span={4} offset={2}>
                  {data.Cus.sex == "M" ? "ชาย" : "หญิง"}
                </Col>
                <Col span={4} offset={1}>
                  {checktext(data.Cus.cusTitle)}
                </Col>
                <Col span={4}>
                  {data.Cus.custName} {data.Cus.custLastName}
                </Col>
                <Col span={4} offset={1}>
                  {checktext(data.Cus.occ1Desc)}
                </Col>
              </Row>

              <Row key="15" style={styleTextHeader} gutter={5}>
                <Col span={4}>เลขบัตรประชาชน :</Col>
                <Col span={4} offset={2}>
                  เบอร์บ้าน :
                </Col>
                <Col span={4} offset={1}>
                  เบอร์ที่ทำงาน :
                </Col>
                <Col span={4}>เบอร์มือถือ :</Col>
                <Col span={4} offset={1}>
                  Branch Owner :
                </Col>
              </Row>

              <Row key="16" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.Cus.custIdCard)}</Col>
                <Col span={4} offset={2}>
                  {checktext(data.Cus.homePhone)}
                </Col>
                <Col span={4} offset={1}>
                  {checktext(data.Cus.officePhone)}
                </Col>
                <Col span={4}>{checktext(data.Cus.mobilePhone)}</Col>
                <Col span={4} offset={1}>
                  {checktext(data.detail.ownerBr)}
                </Col>
              </Row>
              <Row key="17" style={styleTextHeader} gutter={5}>
                <Col span={4}>Staff Flag :</Col>
                <Col span={4} offset={2}>
                  Provision Flag Cur :
                </Col>
                <Col span={4} offset={1}>
                  Past Due :
                </Col>
                <Col span={4}>Delq 30days :</Col>
                <Col span={4} offset={1}>
                  Delq 60days :
                </Col>
              </Row>
              <Row key="18" style={styleTextDetail} gutter={5}>
                <Col span={4}>{checktext(data.detail.staffFlg)}</Col>
                <Col span={4} offset={2}>
                  {checktext(data.detail.provFlg)}
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.pastDue)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.delq30)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.delq60)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>
              <Row key="19" style={styleTextHeader} gutter={5}>
                <Col span={4}>จํานวนเงินขั้นต่ำ ณ ปัจจุบัน :</Col>
                <Col span={4} offset={2}>
                  ยอดชําระสุทธิ กรณีลูกค้าชําระหลังวัน cut date :
                </Col>
                <Col span={4} offset={1}>
                  ยอดชําระทั้งหมดหลัง cut date :
                </Col>
                <Col span={4}>วันที่ส่ง SMS ของธนาคาร :</Col>
                <Col span={4} offset={1}>
                  OA Code :
                </Col>
              </Row>
              <Row key="20" style={styleTextDetail} gutter={5}>
                <Col span={4}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.curDue)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={2}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.curTotAmtDue)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4} offset={1}>
                  <NumberFormat
                    value={numberWithCommas(data.detail.paidAmt)}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                  />
                </Col>
                <Col span={4}>{"N/A"}</Col>
                <Col span={4} offset={1}>
                  {checktext(data.User.organizationProfile.agentcyId)}
                </Col>
              </Row>
            </div>
          );
        }
      })}
    </>
  );
};
