import { Table, Modal, Switch, message, Typography, Spin } from "antd";
import { FormOutlined } from "@ant-design/icons";

import React, { useState, useRef } from "react";
import { OaProfileService } from "../services/oa-profile-service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { IUser } from "../common";
const appConfig = loadAppConfig();
interface Props {
  proFile: IUser;
  dataUserOaList: any;
  oaCode: string;
  dataadminID: string;
  setLoading: (status: boolean) => void;
  fetchData: () => void;
}
export const TableaddnewOalist: React.FC<Props> = ({ proFile, dataUserOaList, oaCode, dataadminID, setLoading, fetchData }): React.ReactElement => {
  const { id24Axios } = useId24();
  const refcheckedadminID = useRef<any>(null);
  const [skip, setSkip] = useState(0);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);

  const handleTableChange = (pagination: any) => {
    const newSkip = (pagination.current - 1) * pagination.pageSize;
    setSkip(newSkip);
  };

  const updateactivestatus = async (data: any) => {
    if (data?.id === dataadminID && data?.active === true) {
      refcheckedadminID.current.focus();
      message.error("เนื่องจาก User นี้สถานะเป็นผู้ดูแลอยู่ไม่สามารถ disabled ได้");
      return;
    }
    if (data) {
      setLoading(true);
      Modal.confirm({
        title: "ยืนยันการเปลี่ยนแปลง",
        icon: <FormOutlined />,
        content: "คุณต้องการเปลี่ยนสถานะ Active ใช่ไหม ?",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          try {
            const dataSubmit = {
              id: data?.id,
              token: data?.token,
              oaCode: data?.oaCode,
              active: data?.active === true ? false : true,
              name: data?.name,
              email: data?.email,
              phone: data?.phone,
              setorganize: data?.oaOrganize,
              setroles: data?.subOrganizationProfile,
              updateBy: String(proFile.id),
            };
            const resUpdate = await OaProfileService(apiBaseUrlUser).updateUser(dataSubmit);
            if (resUpdate) {
              message.success("ทำการแก้ไขข้อมูลสำเร็จ");
              fetchData();
            }
          } catch {
            console.log("error");
          } finally {
            window.location.reload();
          }
        },
        onCancel: () => {
          setLoading(false);
        },
      });
    }
  };

  const Columns: any = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (e: string, row: any, index: number) => {
        return (
          <>
            <Typography.Text key={index}>{index + 1 + skip}</Typography.Text>
          </>
        );
      },
    },
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (e: any, row: any) => {
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <Switch ref={refcheckedadminID} onClick={() => updateactivestatus(row)} checked={e} />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        size="small"
        rowKey={"id"}
        columns={Columns}
        dataSource={dataUserOaList?.filter((e: any) => e.oaCode === oaCode)}
        onChange={handleTableChange}
      />
    </>
  );
};
