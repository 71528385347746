import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export interface IImportFileTargetService {
  upLoadfilecsv: (data: any) => Promise<any>;
}

interface ITypeCSV {
  actionCodeId: string;
  actionCodeName: string;
  appointmentDate: Date;
  cif: string;
  contact: string;
  measure: string;
  paymentAmt: string;
  paymentDate: Date;
  phone: string;
  remark: string;
  statusCodeId: string;
  statusCodeName: string;
  userId: string;
}

export const ImportFileTargetService = (serviceBackendApi: any): IImportFileTargetService => ({
  upLoadfilecsv: async (data) => {
    const result = await serviceBackendApi.post("/import-service/traget", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data?.result;
  },
});
