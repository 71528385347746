import { Card, Row, Col, Input, message, Tabs } from "antd";
import React, { useState } from "react";
// import { Icon } from "@iconify/react";
import { FilterBatchProcessJob } from "./components/FilterBatchProcessJob";
import { TableBatchProcessJob } from "./components/TableBatchProcessJob";
import { IFilter } from "./services/service";
import { ProfileFilled, TagsOutlined } from "@ant-design/icons";
import { BatchProcessJobLog } from "./components/BatchProcessJobLog";

const { TextArea } = Input;

interface Props {
  // fillterData: IFilter;
  // userId: string;
  // reloadTable: number;
  // loading: boolean;
  // handleLoadingTable: (load: boolean) => void;
  accessRole: any;
}

export const BatchProcessJob: React.FC<Props> = ({ accessRole }) => {
  const [fillterData, setFillterData] = useState<IFilter>({
    type: "",
    text: "",
  });
  const [reloadTable, setReloadTable] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const chkReload = (text: string) => {
    if (text === "1") {
      setReloadTable(reloadTable + 1);
    }
  };
  const filter = (type: string, text: string) => {
    if (type === "" || type === undefined) {
      message.warning("Please select type");
    } else {
      setFillterData({ type: type, text: text });
    }
  };

  const handleLoadingTable = (load: boolean) => {
    setTableLoading(load);
  };

  return (
    <React.Fragment>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          {/* {tabResult} */}
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            {" "}
            <TagsOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
            Job Process
          </b>
        </Card>
      </Row>

      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: "Job Process",
                  key: "1",
                  children: (
                    <>
                      <FilterBatchProcessJob
                        userId={"userId"}
                        filter={filter}
                        chkReload={chkReload}
                        handleLoadingTable={handleLoadingTable}
                        accessRole={accessRole}
                      />
                      <TableBatchProcessJob
                        accessRole={accessRole}
                        userId={"userId"}
                        fillterData={fillterData}
                        reloadTable={reloadTable}
                        loading={tableLoading}
                        handleLoadingTable={handleLoadingTable}
                      />
                    </>
                  ),
                },
                {
                  label: "Job Process Log",
                  key: "2",
                  children: (
                    <>
                      <BatchProcessJobLog
                        userId={"userId"}
                        // filter={filter}
                        // chkReload={chkReload}
                        // handleLoadingTable={handleLoadingTable}
                      />
                    </>
                  ),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
