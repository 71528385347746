import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Row,
  Table,
  Space,
  Col,
  Typography,
  Modal,
  Button,
  Select,
  Input,
  Form,
  message,
  TimePicker,
} from "antd";
import moment from "moment";

import { BatchProcessServiceJob } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();

const { Option } = Select;
const format = "HH:mm";
const { TextArea } = Input;

interface Props {
  filter: (type: string, text: string) => void;
  chkReload: (type: string) => void;
  userId: string;
  handleLoadingTable: (load: boolean) => void;
  accessRole: string;
}

export const FilterBatchProcessJob: React.FC<Props> = ({
  filter,
  userId,
  chkReload,
  handleLoadingTable,
  accessRole,
}) => {
  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDebtlink = id24Axios(appConfig.apiBaseUrlDatalink);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [form] = Form.useForm();
  const [getdatajobAll, setdatajobAll] = useState<any>([]);
  const [getdatajobType, setdatajobType] = useState<any>([]);
  const [getdatajobSMS, setdatajobSMS] = useState<any>([]);
  const [getdatajobBatch, setdatajobBatch] = useState<any>([]);
  const [getdatajobService, setdatajobService] = useState<any>([]);
  const [getcheckValue, setcheckValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getDataJobAll();
    getdataType();
    getdataSMS();
    getdataBatch();
    getdataType();
    getdataService();
  }, []);

  const onFinishAdd = async (values: any) => {
    handleLoadingTable(true);
    const data = {
      active: 1,
      runTime: moment(values.time).format("HH:mm"),
      detail: values.jobDetail,
      jobType: values.jobType,
      jobs: values.job,
    };
    const res: any = await BatchProcessServiceJob(apiBaseUrlDebtlink).addJob(
      data,
    );
    if (res) {
      message.success("เพิ่ม Job เรียบร้อย");
      setIsModalVisible(false);
      handleLoadingTable(false);
    }
  };

  const getDataJobAll = async () => {
    const resData: any = await BatchProcessServiceJob(
      apiBaseUrlDebtlink,
    ).getAllJobName();
    setdatajobAll(resData);
  };
  const getdataSMS = async () => {
    const resData: any = await BatchProcessServiceJob(
      apiBaseUrlDebtlink,
    ).getJobSMS();
    setdatajobSMS(resData);
  };
  const getdataBatch = async () => {
    const resData: any = await BatchProcessServiceJob(
      apiBaseUrlDebtlink,
    ).getJobBatch();
    setdatajobBatch(resData);
  };
  const getdataType = async () => {
    const resData: any = await BatchProcessServiceJob(
      apiBaseUrlDebtlink,
    ).getAllJobType();
    setdatajobType(resData);
  };
  const getdataService = async () => {
    const resData: any = await BatchProcessServiceJob(
      apiBaseUrlDebtlink,
    ).getJobService();
    setdatajobService(resData);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            Job Process
          </Typography.Title>
        </Col>
        {checkAccessRole("ddebtSettingBatchProcessJobAction") ? (
          <Col span={16}>
            <div style={{ textAlign: "right" }}>
              <Space size={"small"}>
                <Button type="primary" onClick={showModal}>
                  + Add Job
                </Button>

                <Modal
                  title="Add Job"
                  open={isModalVisible}
                  onCancel={handleCancel}
                  footer={[]}
                  width={1200}
                >
                  <Form
                    form={form}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 17 }}
                    onFinish={onFinishAdd}
                    onFinishFailed={onFinishFailed}
                    fields={[
                      {
                        name: ["id"],
                        value: uuidv4(),
                      },
                    ]}
                  >
                    <Form.Item hidden={true} label="ID" name="id">
                      <Input placeholder="id" style={{ width: "90%" }} />
                    </Form.Item>
                    <Form.Item
                      label="Job Type"
                      name="jobType"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Job Type",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Please Select"
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(text: any, row: any) => {
                          if (text) {
                            setcheckValue(text);
                            setLoading(true);
                          } else {
                            setcheckValue("");
                            setLoading(false);
                          }
                        }}
                      >
                        <Option key={-1} value={""} disabled>
                          Please Select
                        </Option>
                        {getdatajobType?.map((e: any, index: any, row: any) => {
                          return (
                            <Option key={index} value={e}>
                              {e}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Job Name"
                      name="job"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Job Name",
                        },
                      ]}
                    >
                      <Select
                        id="job"
                        style={{ width: "100%" }}
                        placeholder="Please Select"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        <Option disabled value={""} key={-1}>
                          Please Select
                        </Option>
                        {loading ? (
                          getcheckValue === "batch" ? (
                            getdatajobBatch?.map((e: any, index: any) => {
                              return (
                                <Option key={index} value={e}>
                                  {e}
                                </Option>
                              );
                            })
                          ) : getcheckValue === "sms" ? (
                            getdatajobSMS?.map((e: any, index: any) => {
                              return (
                                <Option key={index} value={e}>
                                  {e}
                                </Option>
                              );
                            })
                          ) : (
                            getdatajobService?.map((e: any, index: any) => {
                              return (
                                <Option key={index} value={e}>
                                  {e}
                                </Option>
                              );
                            })
                          )
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="jobDetail"
                      rules={[
                        {
                          required: true,
                          message: "Please input Description",
                        },
                      ]}
                    >
                      <TextArea
                        showCount
                        maxLength={100}
                        id="jobDetail"
                        placeholder="Job Detail"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Job Start"
                      name="time"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Job Start",
                        },
                      ]}
                    >
                      <TimePicker
                        placeholder="Select Time"
                        style={{ width: "40%" }}
                        format={format}
                      />
                    </Form.Item>
                    <Space
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        key="save"
                        type="primary"
                        htmlType="submit"
                        style={{ width: "80px" }}
                      >
                        Save
                      </Button>
                      <Button
                        key="cancel"
                        onClick={handleCancel}
                        style={{ width: "80px" }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form>
                </Modal>
              </Space>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};
