import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import type { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { Card, Row, Col, Typography, Input, Table, message, Button, Modal, Divider, Tooltip, Spin, Tag, Space } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, RightCircleFilled } from "@ant-design/icons";
import { Consditions, IuserProfile } from "../models/models-datamanagement";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { DatamanagementService } from "../services/datamanagement-service";
import { DatamanagementSimulate } from "./DatamanagementSimulate";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { Role } from "../common";
import { UserAccess } from "../../../drivers/id24/models/user-access";
import { AssignStatus } from "../enums/AssignStatus.enum";
import { ExecuteStatus } from "../enums/ExecuteStatus.enum";
import { SimulateStatus } from "../enums/SimulateStatus.enum";
import { numberWithCommasNodigit } from "../../../utils";
dayjs.extend(localizedFormat);

interface Props {
  baseUrl: string;
  accessRole: any;
}

export interface TPagination {
  current: number;
  pageSize: number;
}
interface TDetailSimulate {
  afterCap: number;
  beforeBal: number;
  beforeCap: number;
  decreasedCap: number;
  listName: string;
  listNameList: TListNameList[];
  listTeam: {};
  sumBal: number;
  teamId: string;
  teamName: string;
  totalCapability: number;
  sumAccountOld?: number;
  resultSumBalOld?: number;
  resultSumBalNew?: number;
}
interface TListNameList {
  amt: number;
  cnt: number;
  list_name: string;
  oa_name: string;
  team_name: string;
  tmp_user_id: string;
  list_name_seq: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const appConfig = loadAppConfig();

export const DatamanagementDraftTable: React.FC<Props> = ({ baseUrl, accessRole }): React.ReactElement => {
  const { push } = useHistory();
  const { tokenAccess, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const [getResualData, setResualData] = useState<Consditions[]>([]);
  const [getUpdateDateSimulate, setUpdateDateSimulate] = useState<any>();
  const [openModalSimulate, setOpenModalSimulate] = useState(false);
  const [openModelSimulateinprogress, setOpenModelSimulateinprogress] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [statusText, setStatusText] = useState<string>("All");
  const searchInput = useRef(null);
  const [getFilterlistname, setFilterlistname] = useState<any>({});
  const [getUnlistcount, setUnlistcount] = useState<number>(0);
  const [getDataGetUser, setDataGetUser] = useState<any>([]);
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [LoadingModalsimulate, setLoadingModalsimulate] = useState<boolean>(false);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<string | number>();
  const [modalStatusApply, setModalStatusApply] = useState(false);
  const [loadingTextTable, setLoadingTextTable] = useState<string>("");
  const [modalStatusAssign, setModalStatusAssign] = useState(false);
  const columnsApprove: ColumnsType<Consditions> = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      width: 50,
      sortOrder: sortedInfo.columnKey === "age" ? sortedInfo.order : "ascend",
    },
    {
      title: "List Name",
      dataIndex: "listname",
      key: "listname",
      width: 150,
      filteredValue: getFilterlistname.listname || null,
      onFilter: (value: any, record: any) => {
        return record.listname.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Expire Day",
      dataIndex: "days",
      key: "days",
      width: 110,
      render: (days: number) => {
        let result: any = 0;
        if (typeof days === "string") {
          result = dayjs(days).add(543, "year").format("DD-MM-YYYY");
        } else {
          result = days;
        }
        if (typeof days === "string") {
          return (
            <Tooltip placement="topLeft" title={result}>
              {result}
            </Tooltip>
          );
        } else {
          return (
            <Tooltip placement="topLeft" title={result}>
              {result} {"  "}วัน
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 130,
      align: "center",
      render: (value: any) => {
        let statusText: string = "";
        let color: string = "";
        {
          value === "DRAFT"
            ? ((statusText = "Draft"), (color = "gold"))
            : value === "WAITING_APPROVAL"
            ? ((statusText = "Waiting Approval"), (color = "blue"))
            : value === "APPROVED"
            ? ((statusText = "Approved"), (color = "green"))
            : ((statusText = "Disable"), (color = "red"));
        }

        return <Tag color={color}>{statusText}</Tag>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: {
        showTitle: false,
      },
      width: 300,
      render: (description: string) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
      width: 150,
      filtered: true,
    },
    {
      title: "Update Date",
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      render: (createDate: number) => {
        return <Row>{dayjs(createDate).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm")}</Row>;
      },
    },
    {
      title: "Action",
      dataIndex: "seq",
      width: 150,
      align: "center",
      render: (index: number, data: any) => {
        const statusNewpage = data.status;
        const dataBody = data;

        return (
          <>
            {multipleInArray(permissionId24, ["bank_supervisor"]) && statusNewpage === "DRAFT" ? (
              <Button type="link" onClick={() => push(`${baseUrl}/editcondition/draft`, dataBody)}>
                Edit
              </Button>
            ) : multipleInArray(permissionId24, ["bank_manager"]) && statusNewpage === "WAITING_APPROVAL" ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/wattingapprove`, dataBody)}>
                Edit
              </Button>
            ) : multipleInArray(permissionId24, ["bank_manager"]) && statusNewpage === "APPROVED" ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/showdetail`, dataBody)}>
                ...
              </Button>
            ) : (multipleInArray(permissionId24, ["bank_supervisor"]) && statusNewpage === "APPROVED") ||
              (multipleInArray(permissionId24, ["bank_supervisor"]) && statusNewpage === "DISABLED") ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/showdetail`, dataBody)}>
                View
              </Button>
            ) : multipleInArray(permissionId24, ["bank_manager"]) && statusNewpage === "DISABLED" ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/showdetail`, dataBody)}>
                View
              </Button>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const columnsAll: ColumnsType<Consditions> = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      width: 50,
      // sortOrder: "ascend",
      // sorter: (a, b) => a.seq - b.seq,
      sortOrder: sortedInfo.columnKey === "age" ? sortedInfo.order : "ascend",
    },
    {
      title: "List Name",
      dataIndex: "listname",
      key: "listname",
      width: 150,
      filteredValue: getFilterlistname.listname || null,
      onFilter: (value: any, record: any) => {
        return record.listname.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Expire Day",
      dataIndex: "days",
      key: "days",
      width: 110,
      render: (days: number) => {
        let result: any = 0;
        if (typeof days === "string") {
          result = dayjs(days).add(543, "year").format("DD-MM-YYYY");
        } else {
          result = days;
        }
        if (typeof days === "string") {
          return (
            <Tooltip placement="topLeft" title={result}>
              {result}
            </Tooltip>
          );
        } else {
          return (
            <Tooltip placement="topLeft" title={result}>
              {result} {"  "}วัน
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 130,

      align: "center",
      render: (value: any) => {
        let statusText: string = "";
        let color: string = "";
        {
          value === "DRAFT"
            ? ((statusText = "Draft"), (color = "gold"))
            : value === "WAITING_APPROVAL"
            ? ((statusText = "Waiting Approval"), (color = "blue"))
            : value === "APPROVED"
            ? ((statusText = "Approved"), (color = "green"))
            : ((statusText = "Disable"), (color = "red"));
        }

        return <Tag color={color}>{statusText}</Tag>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: {
        showTitle: false,
      },
      width: 300,
      render: (description: string) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
      width: 150,
      filtered: true,
    },
    {
      title: "Update Date",
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      render: (createDate: number) => {
        return <Row>{dayjs(createDate).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm")}</Row>;
      },
    },
    {
      title: "Action",
      dataIndex: "seq",
      width: 150,
      align: "center",
      render: (index: number, data: any) => {
        const statusNewpage = data.status;
        const dataBody = data;
        return (
          <>
            {multipleInArray(permissionId24, ["bank_supervisor"]) && statusNewpage === "DRAFT" ? (
              <Button type="link" onClick={() => push(`${baseUrl}/editcondition/draft`, dataBody)}>
                Edit
              </Button>
            ) : multipleInArray(permissionId24, ["bank_manager"]) && statusNewpage === "WAITING_APPROVAL" ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/wattingapprove`, dataBody)}>
                ...
              </Button>
            ) : multipleInArray(permissionId24, ["bank_manager"]) && statusNewpage === "APPROVED" ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/showdetail`, dataBody)}>
                ...
              </Button>
            ) : (multipleInArray(permissionId24, ["bank_supervisor"]) && statusNewpage === "APPROVED") ||
              (multipleInArray(permissionId24, ["bank_supervisor"]) && statusNewpage === "DISABLED") ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/showdetail`, dataBody)}>
                View
              </Button>
            ) : multipleInArray(permissionId24, ["bank_manager"]) && statusNewpage === "DISABLED" ? (
              <Button type="link" onClick={(statusNewpage) => push(`${baseUrl}/editcondition/showdetail`, dataBody)}>
                ...
              </Button>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });
  const [statusTable, setStatusTable] = useState<string>("ALL");
  const [countRuleBase, setCountRuleBase] = useState<number>(0);

  useEffect(() => {
    fetchData({ current: 1, pageSize: 10 });
  }, []);

  const fetchData = async (pagination: TablePaginationConfig) => {
    const resultmap = await mapRoleAccess();
    const resualProfile = await DatamanagementService(apiBaseUrlDebt).getUserDetail();
    setUserprofile(resualProfile);
    setPermissionId24(resultmap);
    const userAssign = () => {
      let AssignUserNew;
      multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
        ? (AssignUserNew = "AssignUser")
        : (AssignUserNew = `User:${resualProfile.id}:AssignUser`);
      setAssignUserNew(AssignUserNew);
      return AssignUserNew;
    };
    const userCleardist = () => {
      let AssignUserNew;
      multipleInArray(resultmap, ["bank_manager", "bank_supervisor"]) ? (AssignUserNew = 0) : (AssignUserNew = `User:${resualProfile.id}:AssignUser`);
      setCleardistNew(AssignUserNew);
      return AssignUserNew;
    };
    userCleardist();
    const resultRuleBase = await DatamanagementService(apiBaseUrl).getRuleBaseByStatus(
      userAssign(),
      "ALL",
      (Number(pagination.current) - 1) * Number(pagination.pageSize),
      Number(pagination.pageSize),
    );
    const countRulBase = await DatamanagementService(apiBaseUrl).countRuleBase(userAssign(), "ALL");
    const resultCountUnlist = await DatamanagementService(apiBaseUrlDebt).getCountUnList();
    if (resultRuleBase) {
      let index = 0;
      const mapDetailRuleBase: Consditions[] = resultRuleBase?.map((x: Consditions) => {
        index++;
        return {
          key: index,
          seq: x.seq,
          listname: x.listname,
          days: x.days,
          status: x.status,
          description: x.description,
          createDate: x.createDate,
          updateby: resultRuleBase.name,
          type: x.type,
          fields: x.fields,
          assignType: x.assignType,
          distribution: x.distribution,
          expression: x.expression,
          updateBy: x.updateBy,
          createBy: x.createBy,
          expridedType: x.expridedType,
        };
      });
      if (resultCountUnlist.length >= 1) {
        setUnlistcount(resultCountUnlist[0]._count.UserId);
      }
      setResualData(mapDetailRuleBase);
      setCountRuleBase(countRulBase);
      setLoading(false);
    }
  };

  const multipleInArray = (arr: string[], values: Role[]) => {
    return values.some((value) => {
      return arr.includes(value);
    });
  };

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  const setSearch = (e: any) => {
    const listnametext = {
      listname: e,
    };
    setFilterlistname(listnametext);
  };

  const featchDataByStatus = async (status: string) => {
    status === "DRAFT"
      ? setStatusText("Draft")
      : status === "WAITING_APPROVAL"
      ? setStatusText("Waiting Approval")
      : status === "APPROVED"
      ? setStatusText("Approved")
      : status === "DISABLED"
      ? setStatusText("Disabled")
      : setStatusText("All");
    setPagination((prevState: any) => ({
      ...prevState,
      current: 1,
      pageSize: 10,
    }));
    setStatusTable(status);
    setLoading(true);
    const resultRuleBase = await DatamanagementService(apiBaseUrl).getRuleBaseByStatus(AssignUserNew, status, 0, 10);
    if (resultRuleBase) {
      const countRulBase = await DatamanagementService(apiBaseUrl).countRuleBase(AssignUserNew, status);
      let index = 0;
      const mapDetailRuleBase: Consditions[] = resultRuleBase?.map((x: Consditions) => {
        index++;
        return {
          key: index,
          seq: x.seq,
          listname: x.listname,
          days: x.days,
          status: x.status,
          description: x.description,
          createDate: x.createDate,
          updateby: resultRuleBase.name,
          type: x.type,
          fields: x.fields,
          assignType: x.assignType,
          distribution: x.distribution,
          expression: x.expression,
          updateBy: x.updateBy,
          createBy: x.createBy,
          expridedType: x.expridedType,
        };
      });
      setResualData(mapDetailRuleBase);
      setCountRuleBase(countRulBase);
      setLoading(false);
    }
  };

  const changePagination = async (pagination: TablePaginationConfig) => {
    setLoading(true);
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));
    const resultRuleBase = await DatamanagementService(apiBaseUrl).getRuleBaseByStatus(
      AssignUserNew,
      statusTable,
      (Number(pagination.current) - 1) * Number(pagination.pageSize),
      Number(pagination.pageSize),
    );
    if (resultRuleBase) {
      const countRulBase = await DatamanagementService(apiBaseUrl).countRuleBase(AssignUserNew, statusTable);
      let index = (Number(pagination.current) - 1) * Number(pagination.pageSize);
      const mapDetailRuleBase: Consditions[] = resultRuleBase?.map((x: Consditions) => {
        index++;
        return {
          key: index,
          seq: x.seq,
          listname: x.listname,
          days: x.days,
          status: x.status,
          description: x.description,
          createDate: x.createDate,
          updateby: resultRuleBase.name,
          type: x.type,
          fields: x.fields,
          assignType: x.assignType,
          distribution: x.distribution,
          expression: x.expression,
          updateBy: x.updateBy,
          createBy: x.createBy,
          expridedType: x.expridedType,
        };
      });
      setResualData(mapDetailRuleBase);
      setCountRuleBase(countRulBase);
    }
    setLoading(false);
  };

  const onclickExecuteRuleBase = async () => {
    const executeStatus = await DatamanagementService(apiBaseUrlDebt).executeStatus();
    if (executeStatus.status === ExecuteStatus.IN_PROGRESS) {
      setModalStatusApply(true);
      return;
    } else {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "ยืนยัน Sequence Condition List นี้ใช่หรือไม่",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          F_ExcuteData();
          push(`/datamanagement`);
        },
        onCancel: () => {},
      });
    }
  };
  const F_ExcuteData = async () => {
    message.warning("กำลังดำเนินการ Apply งาน");
    DatamanagementService(apiBaseUrl).setExecuteRuleBase(CleardistNew);
  };

  const onClickShowsimulate = async () => {
    const executeStatus = await DatamanagementService(apiBaseUrlDebt).executeStatus();
    if (executeStatus.status === ExecuteStatus.IN_PROGRESS) {
      setModalStatusApply(true);
      return;
    }

    const assignStatus = await DatamanagementService(apiBaseUrlDebt).assignStatus(CleardistNew);
    if (assignStatus.status === AssignStatus.IN_PROGRESS) {
      setModalStatusAssign(true);
      return;
    }

    const simulateStatus = await DatamanagementService(apiBaseUrlDebt).simulateStatus();
    if (simulateStatus.status === SimulateStatus.IN_PROGRESS) {
      setOpenModelSimulateinprogress(true);
      return;
    }

    setOpenModalSimulate(true);
    setOpenModelSimulateinprogress(false);
    setLoadingModalsimulate(true);
    setLoadingTextTable("อยู่ในระหว่างการ simulate");
    const resultSimulateAssign = await DatamanagementService(apiBaseUrl).simulateAssign();
    setUpdateDateSimulate(resultSimulateAssign?.updateDate);

    const simulateData = Object.keys(resultSimulateAssign.detail.userData).map((collectorid) => {
      return {
        collectorid,
        detail: resultSimulateAssign.detail.userData[collectorid],
      };
    });
    const resSeq = await DatamanagementService(apiBaseUrl).getSeq();
    const dataDetail = await Promise.all(
      simulateData
        .filter((x) => x.detail.listName !== "")
        .map(async (_itemSimulate: { collectorid: string; detail: TDetailSimulate }) => {
          try {
            const userlist = await DatamanagementService(apiBaseUrlDebt).findUserIdListAll(Number(_itemSimulate.collectorid));
            if (userlist.length > 0) {
              const filteredUsers = Object.keys(resultSimulateAssign.detail.userData)
                .filter((key: string) => userlist.map(String).includes(key))
                .reduce((obj: any, key: any) => {
                  obj[key] = resultSimulateAssign.detail.userData[key];
                  return obj;
                }, {});

              let totalTotalCapability = 0;
              let totalDecreasedCap = 0;
              let totalAfterCap = 0;
              let sumBalOld = 0;
              let sumBalNew = 0;
              const results = [];
              let resultSum: number = 0;
              for (const key in filteredUsers) {
                const item = filteredUsers[key];
                totalTotalCapability += item.totalCapability;
                totalDecreasedCap += item.decreasedCap;
                totalAfterCap += item.afterCap;
                resultSum = Number(totalTotalCapability) - Number(totalAfterCap) - Number(totalDecreasedCap);
                sumBalOld += Number(item.beforeBal);
                sumBalNew += Number(item.sumBal);
              }

              for (const key in filteredUsers) {
                const item = filteredUsers[key];
                const result = {
                  userid: key,
                  sumBal: item.sumBal,
                  teamId: item.teamId,
                  afterCap: item.afterCap,
                  listName: item.listName,
                  listNameList: item.listNameList,
                  listTeam: item.listTeam,
                  teamName: item.teamName,
                  beforeBal: item.beforeBal,
                  beforeCap: item.beforeCap,
                  decreasedCap: item.decreasedCap,
                  totalCapability: item.totalCapability,
                  sumAccountOld: resultSum,
                  resultSumBalOld: sumBalOld,
                  resultSumBalNew: sumBalNew,
                };
                results.push(result);
              }
              return results.filter((x) => x.listName !== "");
            } else {
              const results = [];
              results.push({
                userid: _itemSimulate.collectorid,
                sumBal: _itemSimulate.detail.sumBal,
                teamId: _itemSimulate.detail.teamId,
                afterCap: _itemSimulate.detail.afterCap,
                listName: _itemSimulate.detail.listName,
                listNameList: _itemSimulate.detail.listNameList,
                listTeam: _itemSimulate.detail.listTeam,
                teamName: _itemSimulate.detail.teamName,
                beforeBal: _itemSimulate.detail.beforeBal,
                beforeCap: _itemSimulate.detail.beforeCap,
                decreasedCap: _itemSimulate.detail.decreasedCap,
                totalCapability: _itemSimulate.detail.totalCapability,
                sumAccountOld: _itemSimulate.detail.decreasedCap,
                resultSumBalOld: _itemSimulate.detail.beforeBal,
                resultSumBalNew: _itemSimulate.detail.sumBal,
              });
              return results;
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            return null;
          }
        }),
    );
    Promise.all(dataDetail.map((array: any) => array[0])).then((resultData) => {
      // if (resultData[0] !== undefined) {
      const dataAll: any = [];
      resultData.map((item: TDetailSimulate) => {
        item.listNameList.map((e: TListNameList) => {
          dataAll.push({
            key: uuidv4(),
            afterCap: item.afterCap,
            beforeBal: item.beforeBal,
            beforeCap: item.beforeCap,
            decreasedCap: item.decreasedCap,
            listName: item.listName,
            sumBal: item.sumBal,
            teamId: item.teamId,
            teamName: item.teamName,
            totalCapability: item.totalCapability,
            amt: e.amt,
            cnt: e.cnt,
            oa_name: e.oa_name,
            list_name: e.list_name,
            team_name: e.team_name,
            tmp_user_id: e.tmp_user_id,
            list_name_seq: e.list_name_seq,
            sumAccountOld: item.sumAccountOld,
            resultSumBalOld: item.resultSumBalOld,
            resultSumBalNew: item.resultSumBalNew,
          });
        });
      });

      const filterListNameSeq = dataAll.filter((item: any) => resSeq.includes(item.list_name_seq));
      setDataGetUser(filterListNameSeq);
      setLoadingModalsimulate(false);
      // } else {
      //   setDataGetUser([]);
      //   setLoadingModalsimulate(false);
      // }
    });
  };

  const buttonSimulateIntable = async () => {
    message.warning("กำลังดำเนินการ Simulate งาน");
    setOpenModelSimulateinprogress(false);
    setOpenModalSimulate(false);
    await DatamanagementService(apiBaseUrl).setSimulateRuleBase(CleardistNew);
  };

  const modalCancelSimulate = () => {
    setOpenModalSimulate(false);
  };

  const buttonAssign = async () => {
    const assignStatus = await DatamanagementService(apiBaseUrlDebt).assignStatus(CleardistNew);
    if (assignStatus.status === AssignStatus.IN_PROGRESS) {
      message.warning("อยู่ระหว่างการดำเนินการ Assign งาน");
      setOpenModalSimulate(false);
      setModalStatusAssign(true);
      return;
    } else {
      DatamanagementService(apiBaseUrl).assignConditions(CleardistNew);
      setOpenModelSimulateinprogress(false);
      setOpenModalSimulate(false);
      message.warning("เริ่มทำการ Assign งาน");
      setLoadingTextTable("กำลังดำเนินการ Assign งาน");
      setLoadingModalsimulate(false);
    }
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const dateStamp = (stamp: any) => {
    const date = new Date(stamp);
    const timestamp = date.toLocaleString("th-TH");
    return timestamp;
  };

  return loading ? (
    <div style={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
      <Spin indicator={antIcon} tip={"Loading...."} spinning={loading} />
    </div>
  ) : (
    <>
      <Row gutter={[8, 8]} style={{ width: "100%" }}>
        <Col span={6}>
          <Card style={{ width: "100%" }}>
            <Row>
              <Col span={14}>
                <Typography style={{ textAlign: "left", fontSize: "120%" }}>Condition List</Typography>
              </Col>
              <Col span={10} style={{ textAlign: "right", width: "80%" }}>
                {checkAccessRole("ddebtDataManagementAddConditionlist") ? (
                  <Button
                    type="primary"
                    ghost
                    onClick={(e) => {
                      push(`${baseUrl}/addcondition/basic`);
                    }}
                  >
                    <Space>
                      <PlusOutlined />
                      {`Add`}
                    </Space>
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginBottom: "6px" }}>
              <Col
                span={24}
                style={{
                  textAlign: "left",
                  color: "gray",
                  fontSize: "100%",
                }}
              >
                Status
              </Col>
            </Row>
            <Row style={{ textAlign: "left" }}>
              <Col span={24}>
                <RightCircleFilled width="8" style={{ color: "#000000" }} />{" "}
                <Button onClick={() => featchDataByStatus("ALL")} type="link">
                  All
                </Button>
              </Col>
              <Col span={24}>
                <RightCircleFilled width="8" style={{ color: "#FAAD14" }} />{" "}
                <Button onClick={() => featchDataByStatus("DRAFT")} type="link">
                  Draft
                </Button>
              </Col>
              <Col span={24}>
                <RightCircleFilled width="8" style={{ color: "#1890FF" }} />{" "}
                <Button onClick={() => featchDataByStatus("WAITING_APPROVAL")} type="link">
                  Waiting Approval
                </Button>
              </Col>
              <Col span={24}>
                <RightCircleFilled width="8" style={{ color: "#52C41A" }} />{" "}
                <Button onClick={() => featchDataByStatus("APPROVED")} type="link">
                  Approved
                </Button>
              </Col>
              <Col span={24}>
                <RightCircleFilled width="8" style={{ color: "#FF4D4F" }} />{" "}
                <Button onClick={() => featchDataByStatus("DISABLED")} type="link">
                  Disabled
                </Button>
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginBottom: "6px" }}>
              <Col span={20} style={{ textAlign: "left", color: "gray" }}>
                Status
              </Col>
              <Col span={4} style={{ textAlign: "right", color: "gray" }}>
                Account
              </Col>
            </Row>
            <Row>
              <Col span={20} style={{ textAlign: "left" }}>
                Unlists
              </Col>
              <Col span={4} style={{ textAlign: "right", color: "red" }}>
                <a
                  onClick={(e) => {
                    push(`${baseUrl}/unlist`);
                  }}
                >
                  {getUnlistcount}
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={18}>
          <Card style={{ width: "100%" }}>
            <Row style={{ justifyContent: "space-between", display: "flex" }}>
              <Col>
                <Typography>{statusText}</Typography>
              </Col>
              <Col>
                <Input
                  ref={searchInput}
                  placeholder={`Search List Name`}
                  onChange={(e: any) => setSearch(e.target.value ? [e.target.value] : [])}
                  style={{ width: "350px" }}
                />
              </Col>
            </Row>
            <br />
            <Table
              size="small"
              columns={statusText === "Approved" ? columnsApprove : columnsAll}
              dataSource={getResualData}
              scroll={{
                x: 500,
              }}
              rowKey="key"
              onChange={changePagination}
              pagination={{ total: countRuleBase, current: pagination.current, pageSize: pagination.pageSize }}
            />
            {statusText === "Approved" ? (
              <Row style={{ textAlign: "left" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  {checkAccessRole("ddebtDataManagementExecute") ? (
                    <Button type="primary" onClick={onclickExecuteRuleBase}>
                      Apply
                    </Button>
                  ) : (
                    <></>
                  )}
                  {checkAccessRole("ddebtDataManagementSimulate") ? <Button onClick={onClickShowsimulate}>Simulate Manual</Button> : <></>}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Card>

          <Modal
            open={openModalSimulate}
            onCancel={modalCancelSimulate}
            width={"80%"}
            title={
              <>
                <Row style={{ textAlign: "left" }}>Simulate ({dateStamp(getUpdateDateSimulate)})</Row>
              </>
            }
            footer={
              <>
                {checkAccessRole("ddebtDataManagementSimulate") ? (
                  <Button type="primary" onClick={buttonSimulateIntable}>
                    Simulate
                  </Button>
                ) : (
                  <></>
                )}
                {checkAccessRole("ddebtDataManagementSimulate") ? (
                  <Button type="primary" onClick={buttonAssign}>
                    Assign
                  </Button>
                ) : (
                  <></>
                )}
              </>
            }
          >
            <Spin tip={loadingTextTable} spinning={LoadingModalsimulate}>
              <DatamanagementSimulate dataUserSimulate={getDataGetUser} />
            </Spin>
          </Modal>
          <Modal
            title={
              <>
                <ExclamationCircleOutlined /> In progress
              </>
            }
            open={openModelSimulateinprogress}
            onCancel={() => {
              setOpenModelSimulateinprogress(false);
            }}
            cancelText="cancel"
            footer={
              <>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setOpenModelSimulateinprogress(false);
                  }}
                >
                  Close
                </Button>
              </>
            }
          >
            อยู่ในระหว่างการ Simulate
          </Modal>
          <Modal
            title={
              <>
                <ExclamationCircleOutlined /> In progress
              </>
            }
            open={modalStatusApply}
            onCancel={() => {
              setModalStatusApply(false);
            }}
            cancelText="cancel"
            footer={
              <>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setModalStatusApply(false);
                  }}
                >
                  Close
                </Button>
              </>
            }
          >
            อยู่ในระหว่างการประมวลผลข้อมูล
          </Modal>
          <Modal
            title={
              <>
                <ExclamationCircleOutlined /> In progress
              </>
            }
            open={modalStatusAssign}
            onCancel={() => {
              setModalStatusAssign(false);
            }}
            cancelText="cancel"
            footer={
              <>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setModalStatusAssign(false);
                  }}
                >
                  Close
                </Button>
              </>
            }
          >
            อยู่ในระหว่างการ Assign งาน
          </Modal>
        </Col>
      </Row>
    </>
  );
};
