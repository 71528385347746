import React, { useContext, useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Row,
  Table,
  Space,
  Col,
  Typography,
  Modal,
  Button,
  Select,
  Input,
  AutoComplete,
  Form,
  Radio,
  InputNumber,
  Popconfirm,
  message,
  TimePicker,
} from "antd";
import moment from "moment";
import { DeleteOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import type { FormInstance } from "antd/lib/form";

import { BatchProcessServiceDailyFile } from "../services/service";
import axios, { AxiosInstance } from "axios";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();

const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const format = "HH:mm";

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

const handleChange = (value: string) => {
  // console.log(`selected ${value}`);
};

interface EditableRowProps {
  index: number;
}

interface AccountType {
  contractId: string;
  os: string;
  contractDateStart: string;
  contractDateEnd: string;
  status: string;
}
interface Props {
  filter: (type: string, text: string) => void;
  chkReload: (type: string) => void;
  userId: string;
  handleLoadingTable: (load: boolean) => void;
}

export const FilterBatchProcessDailyFile: React.FC<Props> = ({ filter, userId, chkReload, handleLoadingTable }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [form] = Form.useForm();
  const [fillType, setFillType] = useState("");
  const [fillText, setFillText] = useState<any>("");
  const [userUsed, setUserUsed] = useState<any>();
  const [organize, setOrganize] = useState<any>([]);
  const [subGroup, setSubGroup] = useState<any>([]);

  useEffect(() => {
    async function fetchDataForSearch() {
      // const res2: any = await BatchProcessServiceDailyFile(
      //   apiBaseUrlUser,
      // ).getOaOrganize();
      // setOrganize(res2);
      // const res3: any = await BatchProcessServiceDailyFile(
      //   apiBaseUrlUser,
      // ).getSubOrganize();
      // setSubGroup(res3);
    }
    fetchDataForSearch();
  }, []);

  const onFinishAdd = async (values: any) => {
    handleLoadingTable(true);
    const data = {
      active: 1,
      runTime: moment(values.time).format("HH:mm"),
      detail: values.jobDetail,
      jobType: values.jobType,
      jobs: values.job,
    };
    const apiCaller = id24Axios("http://192.168.59.192:8995");
    const res: any = await BatchProcessServiceDailyFile(apiCaller).addDaily(data);
    console.log(data);

    // handleLoadingTable(true);
    // const res: any = await BatchProcessServiceDailyFile(apiBaseUrlUser).createUser(values);
    if (res) {
      message.success("เพิ่ม Job เรียบร้อย");
      setIsModalVisible(false);
      handleLoadingTable(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeType = () => {};

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            Daily Process
          </Typography.Title>
        </Col>
        <Col span={16}>
          <div style={{ textAlign: "right" }}>
            <Space size={"small"}>
              <Modal title="Add Job" open={isModalVisible} onCancel={handleCancel} footer={[]} width={1200}>
                <Form
                  form={form}
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 17 }}
                  onFinish={onFinishAdd}
                  onFinishFailed={onFinishFailed}
                  fields={[
                    {
                      name: ["id"],
                      value: uuidv4(),
                    },
                  ]}
                >
                  <Form.Item hidden={true} label="ID" name="id">
                    <Input placeholder="id" style={{ width: "90%" }} />
                  </Form.Item>
                  <Form.Item
                    label="เวลาทำงาน Job"
                    name="time"
                    rules={[
                      {
                        required: true,
                        message: "Please input Name",
                      },
                    ]}
                  >
                    <TimePicker format={format} />
                  </Form.Item>
                  <Form.Item
                    label="รายละเอียด Job"
                    name="jobDetail"
                    rules={[
                      {
                        required: true,
                        message: "Please input Email",
                      },
                    ]}
                  >
                    <Input id="jobDetail" placeholder="Job Detail" style={{ width: "90%" }} />
                  </Form.Item>

                  <Col>
                    <Form.Item
                      label="ประเภทของ Job"
                      name="jobType"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Organize",
                        },
                      ]}
                    >
                      <Select defaultValue="Select Job Type" style={{ width: 200 }} onChange={handleChangeType}>
                        <Option value="batch">Batch</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Job"
                      name="job"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Roles",
                        },
                      ]}
                    >
                      <Input id="job" placeholder="Job" style={{ width: "90%" }} />
                    </Form.Item>
                  </Col>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
                      Save
                    </Button>
                    <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
                      Cancel
                    </Button>
                  </Space>
                </Form>
              </Modal>
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};
