import { Card, Row, Col, Input, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FilterOaProfile } from "./components/FilterOaProfile";
import { TableOaProfile } from "./components/TableOaProfile";
import { ProfileFilled, UserSwitchOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import { OaProfileService } from "./services/oa-profile-service";
import { IUser } from "./common";
const appConfig = loadAppConfig();

export interface Props {
  userId: string;
  accessRole: string;
}

export const OaProfile: React.FC<Props> = ({ userId, accessRole }) => {
  const { id24Axios } = useId24();
  const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [reloadTable, setReloadTable] = useState(0);
  const [loading, setLoading] = useState(true);
  const [proFile, setProfile] = useState<IUser>({
    id: 0,
    type: "",
    username: "",
    name: "",
    oaCode: "",
    capabilities: {},
    level: "",
    team: "",
    token: "",
    active: false,
    parentId: "",
    parentNode: "",
    phone: "",
    email: "",
    oaOrganize: "",
    subOrganizationProfile: [],
    extension: "",
  });
  const [oaProfile, setOaProfile] = useState<any>();
  const [userOaList, setUserOalist] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resProfile = await OaProfileService(apiBaseUrlUser).getProfile();
    const resOaProfile = await OaProfileService(apiBaseUrlUser).getOaProfile({ type: "", text: "" });
    const resUserAll = await OaProfileService(apiBaseUrlDebt).getDataUserAll();
    resOaProfile.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
    setOaProfile(resOaProfile);
    resUserAll?.result.sort((a: any, b: any) => a.id - b.id);
    setUserOalist(resUserAll?.result);
    setProfile(resProfile[0]);
    setLoading(false);
  };

  const syncData = async () => {
    setLoading(true);
    const resSyncData = await OaProfileService(apiBaseUrlUser).syncData();
    if (resSyncData) {
      fetchData();
    }
  };

  const onSearch = async (type: string, text: string) => {
    if (type === "" || type === "none") {
      message.warning("Please select type");
    } else {
      const resOaProfile = await OaProfileService(apiBaseUrlUser).getOaProfile({ type: type, text: text });
      resOaProfile.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
      setOaProfile(resOaProfile);
    }
  };
  return (
    <React.Fragment>
      <Spin tip="Loading...." spinning={loading}>
        <Row>
          <Card style={{ width: "100%", textAlign: "left" }}>
            <b style={{ color: "#0056B4", fontSize: "24px" }}>
              <UserSwitchOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
              OA Profile
            </b>
          </Card>
        </Row>

        <Row
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 8, sm: 16 },
          ]}
          style={{ marginTop: "2rem" }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <FilterOaProfile proFile={proFile} onSearch={onSearch} syncData={syncData} accessRole={accessRole} />
              <TableOaProfile proFile={proFile} setLoading={setLoading} oaProfile={oaProfile} userOaList={userOaList} fetchData={fetchData} />
            </Card>
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};
