import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd";
import { ActionContact } from "./components/ActionContact";
import { ActionTodolist } from "./components/ActionTodolist";
import { ActionBroadcast } from "./components/ActionBroadcast";

import "./css/DashboardCollector.css";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
interface Props {
  baseUrl: string;
}

export const DashboardManagerCollector: React.FC<Props> = ({ baseUrl }) => {
  return (
    <div
      className="scrolling-wrapper"
      style={{
        width: "100%",
        height: "79vh",
        marginTop: 0,
        textAlign: "left",
        display: "flex",
        padding: "0",
      }}
    >
      <Row gutter={8}>
        <Col span={6}>
          <Row>
            <Col span={24} style={{ marginBottom: "10px" }}>
              <ActionTodolist baseUrl={baseUrl} />
            </Col>
            <Col span={24}>
              <ActionBroadcast baseUrl={baseUrl} />
            </Col>
          </Row>
        </Col>
        <Col span={18} style={{ width: "1000px" }}>
          <ActionContact baseUrl={baseUrl} />
        </Col>
      </Row>
    </div>
  );
};
