import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Card, Form, Row, Input, Radio, Select, Col, Button, Typography, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Consditions, DataType, ICenter, IuserProfile, typeTeam, ValueInDetail } from "../../datamanagement/models/models-datamanagement";
import { DatamanagementServiceOa } from "../services/datamanagementoa";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import NumberFormat from "react-number-format";
import { UserAccess } from "../../../drivers/id24/models/user-access";
import { IMasterDataOfBranch, Role } from "../../datamanagement/common";
const appConfig = loadAppConfig();

const { Option } = Select;
interface Props {
  baseUrl: string;
}
export const DataManagementDetailOa: React.FC<Props> = ({ baseUrl }): React.ReactElement => {
  const location: any = useLocation();
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const { push } = useHistory();
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const [getDataAll, setDataAll] = useState<Consditions>();
  const [getRadiocheckBasic, setRadiocheckBasic] = useState<boolean>(false);
  const [getRadiocheckAdvance, setRadiocheckAdvance] = useState<boolean>(false);
  const [getRadiocheckManual, setRadiocheckManual] = useState<boolean>(false);
  const [getRadiocheckAuto, setRadiocheckAuto] = useState<boolean>(false);
  const [getTeam, setTeam] = useState([]);
  const [getSelectProductType, setSelectProductType] = useState<string[]>([]);
  const [getSelectLocation, setSelectLocation] = useState<any[]>([]);
  const [getSelectLocationNew, setSelectLocationNew] = useState<any[]>([]);
  const [getValueTeam, setValueTeam] = useState<string>("");
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<string | number>();
  const [getDetailProductLoan, setDetailProductLoan] = useState<ValueInDetail[]>([]);
  const [getDetailProductCard, setDetailProductCard] = useState<ValueInDetail[]>([]);
  const [getDetailProductOD, setDetailProductOD] = useState<ValueInDetail[]>([]);

  const multipleInArray = (arr: string[], values: Role[]) => {
    return values.some((value) => {
      return arr.includes(value);
    });
  };

  useEffect(() => {
    async function getData() {
      try {
        mapRoleAccess().then(async (resultmap) => {
          getUserProfile().then(async (resualProfile) => {
            setUserprofile(resualProfile);
            setPermissionId24(resultmap);

            const userAssign = () => {
              let AssignUserNew;
              multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
                ? (AssignUserNew = "AssignUser")
                : (AssignUserNew = `User:${resualProfile.id}:AssignUser`);
              setAssignUserNew(AssignUserNew);
              return AssignUserNew;
            };
            const userCleardist = () => {
              let AssignUserNewNumber;
              multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
                ? (AssignUserNewNumber = 0)
                : (AssignUserNewNumber = `User:${resualProfile.id}:AssignUser`);
              setCleardistNew(AssignUserNewNumber);
              return AssignUserNewNumber;
            };
            userAssign();
            userCleardist();
          });
        });

        const getData = await location.state;
        setDataAll(getData);
        await getProductCode();
        {
          getData?.type === "BASIC" ? setRadiocheckBasic(true) : setRadiocheckAdvance(true);
        }
        {
          getData?.assignType === "Manual" ? setRadiocheckManual(true) : setRadiocheckAuto(true);
        }
        setSelectProductType(getData?.fields?.productCode?.value === undefined ? [] : getData?.fields?.productCode?.value);
        checkLoanTypeInput(getData?.fields?.channel?.value[0], getData?.fields?.channel?.value[1], getData?.fields?.channel?.value[2]);
        checkProductTypeInput(
          getData?.fields?.accountStatus?.value[0],
          getData?.fields?.accountStatus?.value[1],
          getData?.fields?.accountStatus?.value[2]
        );
        setSelectProductTypeNew(data[2].detail[0].value);
      } catch {}
    }
    getTeamAllInPageBasic();
    getData();
    MasterDataCenterOfBranch();
  }, []);

  const [getDataMasterDataCenterOfBranch, setDataMasterDataCenterOfBranch] = useState<IMasterDataOfBranch[]>([]);
  const MasterDataCenterOfBranch = async () => {
    const dataMasterdataResult = await DatamanagementServiceOa(apiBaseUrlDebt).masterDataCenterBranch();
    setDataMasterDataCenterOfBranch(dataMasterdataResult);
  };

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };
  const getProductCode = async () => {
    const resual: any = await DatamanagementServiceOa(apiBaseUrlDebt).getProductCode();

    await resual.map((x: any, index: number) => {
      getDetailProductLoan.push({
        title: x.productDesc,
        value: x.productCode,
        key: x.productCode,
      });
    });
  };

  const data: DataType[] = [
    {
      id: 1,
      name: "Loan Type",
      detail: [
        {
          name: "",
          type: "select",
          value: ["LOAN", "Card Link", "OD"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 2,
      name: "Account Status",
      detail: [
        {
          name: "",
          type: "select",
          value: ["Non-Npls", "Npls", "W/O"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 3,
      name: "Product Type",
      detail: [
        {
          name: "LOAN",
          type: "select",
          value: getDetailProductLoan,
          condition: ["in"],
        },
        {
          name: "Card Link",
          type: "select",
          value: getDetailProductCard,
          condition: ["in"],
        },
        {
          name: "OD",
          type: "select",
          value: getDetailProductOD,
          condition: ["in"],
        },
      ],
    },
    {
      id: 4,
      name: "Account Level",
      detail: [
        {
          name: "Non-Npls",
          type: "select",
          value: ["P0", "P1", "SM2", "SM3"],
          condition: ["in"],
        },
        {
          name: "Npls",
          type: "select",
          value: [],
          condition: ["in"],
        },
        {
          name: "W/O",
          type: "select",
          value: [],
          condition: ["in"],
        },
      ],
    },
    {
      id: 5,
      name: "Location",
      detail: [
        {
          name: "",
          type: "select",
          value: ["Area 1", "Area 2", "Area 3"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 6,
      name: "เงินงวดค้างชำระ",
      detail: [
        {
          name: "",
          type: "input",
          value: [""],
          condition: [">", "<", "=", "!=", ">=", "<="],
        },
      ],
    },
  ];

  const getUserProfile = async () => {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resualProfile = await DatamanagementServiceOa(apiBaseUrlDebt).getUserDetail();
      resolve(resualProfile);
    });
  };

  const getTeamAllInPageBasic = async () => {
    const resual = await DatamanagementServiceOa(apiBaseUrl).getTeamAll();
    setTeam(resual);
  };
  const basicRadioCheckBox = (e: string) => {
    setRadiocheckBasic(true);
    setRadiocheckAdvance(false);
  };

  const advanceRadioCheckBox = (e: string) => {
    setRadiocheckAdvance(true);
    setRadiocheckBasic(false);
  };
  const assignManualRadioCheckBox = (e: string) => {
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };

  const assignAutoRadioCheckBox = (e: string) => {
    setRadiocheckAuto(true);
    setRadiocheckManual(false);
  };

  const editSelectProductType = (e: any) => {
    setSelectProductType(e);
  };

  const checkLoanTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          setSelectProductTypeNew(data[2].detail[0].value);
          break;
        case "Card Link":
          setSelectProductTypeNew(data[2].detail[1].value);
          break;
        case "OD":
          setSelectProductTypeNew(data[2].detail[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link": {
              let valueListin: ValueInDetail[] = data[2].detail[0].value;
              data[2].detail[1].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setSelectProductTypeNew(valueListin);
              break;
            }
            case "OD": {
              let valueListin: ValueInDetail[] = data[2].detail[0].value;
              data[2].detail[2].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setSelectProductTypeNew(valueListin);
              break;
            }
          }
          break;
        case "Card Link":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin: any = data[2].detail[1].value;
                data[2].detail[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setSelectProductTypeNew(valueListin);
                break;
              }
              case "OD": {
                let valueListin = data[2].detail[1].value;
                data[2].detail[2].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setSelectProductTypeNew(valueListin);
                break;
              }
            }
          }
          break;
        case "OD":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin = data[2].detail[2].value;
                data[2].detail[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setSelectProductTypeNew(valueListin);
                break;
              }
              case "Card Link": {
                let valueListin = data[2].detail[2].value;
                data[2].detail[1].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setSelectProductTypeNew(valueListin);
                break;
              }
            }
          }
          break;
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = data[2].detail[0].value;
                    data[2].detail[1].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = data[2].detail[0].value;
                    data[2].detail[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                  }
                  break;
              }
              break;
          }
          break;
        case "Card Link":
          switch (valueTwo) {
            case "LOAN":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = data[2].detail[1].value;
                    data[2].detail[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = data[2].detail[1].value;
                    data[2].detail[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[0].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                  }
                  break;
              }
              break;
          }
          break;
        case "OD":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = data[2].detail[2].value;
                    data[2].detail[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                  }
                  break;
              }
              break;
            case "LOAN":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = data[2].detail[2].value;
                    data[2].detail[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    data[2].detail[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setSelectProductTypeNew(valueListin);
                  }
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setSelectProductTypeNew([]);
      setSelectProductType([]);
    }
  };

  const [getSelectAccountLevel, setSelectAccountLevel] = useState<ValueInDetail[]>([]);
  const [getSelectAccountLevelNew, setSelectAccountLevelNew] = useState<ValueInDetail[]>([]);
  const checkProductTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          setSelectAccountLevelNew(data[3].detail[0].value);
          break;
        case "Npls":
          setSelectAccountLevelNew(data[3].detail[1].value);
          break;
        case "W/O":
          setSelectAccountLevelNew(data[3].detail[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls": {
              let valueDataAccountLevel: any[] = data[3].detail[0].value;
              data[3].detail[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
            case "W/O": {
              let valueDataAccountLevel: any[] = data[3].detail[0].value;
              data[3].detail[2].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
          }
          break;
        case "Npls":
          {
            switch (valueTwo) {
              case "Non-Npls": {
                let valueDataAccountLevel: any[] = data[3].detail[1].value;
                data[3].detail[0].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setSelectAccountLevelNew(valueDataAccountLevel);
                break;
              }
              case "W/O": {
                let valueDataAccountLevel: any[] = data[3].detail[1].value;
                data[3].detail[2].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setSelectAccountLevelNew(valueDataAccountLevel);
                break;
              }
            }
          }
          break;
        case "W/O": {
          switch (valueTwo) {
            case "Non-Npls": {
              let valueDataAccountLevel: any[] = data[3].detail[2].value;
              data[3].detail[0].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
            case "Npls": {
              let valueDataAccountLevel: any[] = data[3].detail[2].value;
              data[3].detail[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setSelectAccountLevelNew(valueDataAccountLevel);
              break;
            }
          }
        }
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = data[3].detail[0].value;
                  data[3].detail[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[0].value;
                  data[3].detail[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "Npls":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = data[3].detail[1].value;
                  data[3].detail[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[1].value;
                  data[3].detail[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "W/O":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[2].value;
                  data[3].detail[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
            case "Npls":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = data[3].detail[2].value;
                  data[3].detail[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  data[3].detail[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setSelectAccountLevelNew(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setSelectAccountLevelNew([]);
      setSelectAccountLevel([]);
      // setcheckValueAccountLevel([]);
    }
  };
  const [getSelectProductTypeNew, setSelectProductTypeNew] = useState<ValueInDetail[]>([]);

  const onClickUpdate = async (status: string) => {
    if (status === "DISABLED") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อบันทึกข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const resual = await DatamanagementServiceOa(apiBaseUrl).updateConditionByseq(getDataAll?.seq, AssignUserNew, status);
          if (resual) {
            message.success("Success");
            push(`/debtdatamanagement/datamanagement`);
          }
        },
        onCancel: () => {},
      });
    }
  };

  const editSelectLocation = (e: any) => {
    setSelectLocation(e);
  };

  function isDecimal(n: number) {
    var result = n - Math.floor(n) !== 0;
    if (result) return true;
    else return false;
  }

  // dataBody
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem" }}
      >
        <Card style={{ width: "100%", textAlign: "left" }} title="Condition List">
          <Form labelCol={{ span: 4 }}>
            <Form.Item label="Condition List Name :">
              <Input maxLength={100} value={getDataAll?.listname} readOnly />
            </Form.Item>
            <Form.Item label="Description :">
              <Input value={getDataAll?.description} readOnly />
            </Form.Item>
          </Form>
          {getDataAll?.type === "BASIC" ? (
            <>
              <Row gutter={10}>
                <Col span={2}>No.</Col>
                <Col span={10}>Field</Col>
                <Col span={12}>Value</Col>
              </Row>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>1</Col>
                <Col span={10}>
                  <Input value="Loan Type" readOnly />
                </Col>
                <Col span={12}>
                  <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.channel?.value}></Select>
                </Col>
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>2</Col>
                <Col span={10}>
                  <Input value="Account Status" readOnly />
                </Col>
                <Col span={12}>
                  <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.accountStatus?.value}></Select>
                </Col>
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>3</Col>
                <Col span={10}>
                  <Input value="Product Type" readOnly />
                </Col>
                <Col span={12}>
                  <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.productCode?.value}>
                    {getDetailProductLoan.map((value: any, index: number) => {
                      return (
                        <Option key={index} value={value.key}>
                          {value.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>4</Col>
                <Col span={10}>
                  <Input value="Billing Cycle" readOnly />
                </Col>
                <Col span={12}>
                  <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.billCycle?.value}></Select>
                </Col>
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>5</Col>
                <Col span={10}>
                  <Input value="Account Level" readOnly />
                </Col>
                <Col span={12}>
                  <Select mode="multiple" disabled style={{ width: "100%" }} value={getDataAll?.fields?.accountLevel?.value}></Select>
                </Col>
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>6</Col>
                <Col span={10}>
                  <Input value="Location" readOnly />
                </Col>
                <Col span={12}>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    onChange={(e) => editSelectLocation(e)}
                    value={getDataAll?.fields?.centerBranch?.value}
                    disabled
                  >
                    {getDataMasterDataCenterOfBranch.map((value: IMasterDataOfBranch, index: number) => {
                      return (
                        <Option key={value.team_group_id} value={String(value.team_group_id)}>
                          {value.desc_local}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <br></br>
              <Row gutter={10}>
                <Col span={2}>
                  <Typography>7</Typography>
                </Col>
                <Col span={5}>
                  <Input value={"เงินต้นคงเหลือ"} readOnly />
                </Col>
                <Col span={5}>
                  <Input value={getDataAll?.fields?.principleAmt?.op} disabled />
                </Col>
                {getDataAll?.fields?.principleAmt?.op === "between" ? (
                  <>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(Number(getDataAll?.fields?.principleAmt?.value[0])) == true ? "" : ".00"}
                        value={getDataAll?.fields?.principleAmt?.value[0]}
                      />
                    </Col>
                    <Col span={2}>
                      <Input value={"ถึง"} readOnly />
                    </Col>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.principleAmt?.value[1]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.principleAmt?.value[1]}
                      />
                    </Col>
                  </>
                ) : (
                  <Col span={12}>
                    <NumberFormat
                      decimalScale={2}
                      className="ant-input"
                      thousandSeparator={true}
                      disabled
                      value={getDataAll?.fields?.principleAmt?.value}
                      min={0}
                      suffix={isDecimal(getDataAll?.fields?.principleAmt?.value) == true ? "" : ".00"}
                      onValueChange={(values: any) => {
                        const { formattedValue, value } = values;
                      }}
                    />
                  </Col>
                )}
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>8</Col>
                <Col span={5}>
                  <Input value="เงินงวดค้างชำระ" readOnly />
                </Col>
                <Col span={5}>
                  <Input value={getDataAll?.fields?.overdueAmt?.op} disabled />
                </Col>
                {getDataAll?.fields?.overdueAmt?.op === "between" ? (
                  <>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value[0]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.overdueAmt?.value[0]}
                      />
                    </Col>
                    <Col span={2}>
                      <Input value={"ถึง"} readOnly />
                    </Col>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.overdueAmt?.value[1]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.overdueAmt?.value[1]}
                      />
                    </Col>
                  </>
                ) : (
                  <Col span={12}>
                    <NumberFormat
                      disabled
                      decimalScale={2}
                      className="ant-input"
                      thousandSeparator={true}
                      min={0}
                      suffix={isDecimal(Number(getDataAll?.fields?.overdueAmt?.value)) == true ? "" : ".00"}
                      value={getDataAll?.fields?.overdueAmt?.value}
                      onValueChange={(values: any) => {
                        const { formattedValue, value } = values;
                        // setDataPrincipalAmount(value);
                      }}
                    />
                  </Col>
                )}
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>9</Col>
                <Col span={5}>
                  <Input value="ยอดเรียกเก็บตาม statement" readOnly />
                </Col>
                <Col span={5}>
                  <Input value={getDataAll?.fields?.stmtAmt?.op} disabled />
                </Col>
                {getDataAll?.fields?.stmtAmt?.op === "between" ? (
                  <>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value[0]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.stmtAmt?.value[0]}
                      />
                    </Col>
                    <Col span={2}>
                      <Input value={"ถึง"} readOnly />
                    </Col>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value[1]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.stmtAmt?.value[1]}
                      />
                    </Col>
                  </>
                ) : (
                  <Col span={12}>
                    <NumberFormat
                      disabled
                      className="ant-input"
                      thousandSeparator={true}
                      value={getDataAll?.fields?.stmtAmt?.value}
                      min={0}
                      decimalScale={2}
                      suffix={isDecimal(getDataAll?.fields?.stmtAmt?.value) == true ? "" : ".00"}
                      onValueChange={(values: any) => {
                        const { formattedValue, value } = values;
                      }}
                    />
                  </Col>
                )}
              </Row>
              <br></br>
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col span={2}>10</Col>
                <Col span={5}>
                  <Input value="จำนวนการใช้เงินของบัตร ณ ปัจจุบัน" readOnly />
                </Col>
                <Col span={5}>
                  <Input value={getDataAll?.fields?.curBal?.op} disabled />
                </Col>
                {getDataAll?.fields?.curBal?.op === "between" ? (
                  <>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.curBal?.value[0]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.curBal?.value[0]}
                      />
                    </Col>
                    <Col span={2}>
                      <Input value={"ถึง"} readOnly />
                    </Col>
                    <Col span={5}>
                      <NumberFormat
                        disabled
                        className="ant-input"
                        thousandSeparator={true}
                        decimalScale={2}
                        min={0}
                        suffix={isDecimal(getDataAll?.fields?.curBal?.value[1]) == true ? "" : ".00"}
                        value={getDataAll?.fields?.curBal?.value[1]}
                      />
                    </Col>
                  </>
                ) : (
                  <Col span={12}>
                    <NumberFormat
                      disabled
                      className="ant-input"
                      thousandSeparator={true}
                      value={getDataAll?.fields?.curBal?.value}
                      min={0}
                      decimalScale={2}
                      suffix={isDecimal(getDataAll?.fields?.curBal?.value) == true ? "" : ".00"}
                      onValueChange={(values: any) => {
                        const { formattedValue, value } = values;
                      }}
                    />
                  </Col>
                )}
              </Row>
            </>
          ) : (
            <>
              <Row gutter={10}>
                <Col span={3} style={{ textAlign: "right" }}>
                  No.
                </Col>
                <Col offset={1} span={20} style={{ textAlign: "left" }}>
                  Condition
                </Col>
              </Row>
              <Row style={{ marginTop: "10px", textAlign: "right" }} gutter={10}>
                <Col span={3}>1</Col>
                <Col offset={1} span={20}>
                  <Input value={getDataAll?.expression} readOnly />
                </Col>
              </Row>
              <br></br>
            </>
          )}
          <br></br>
          <Row>
            <Col span={3} style={{ textAlign: "right" }}>
              Assign Type
            </Col>
            <Col offset={1} span={20} style={{ textAlign: "left" }}>
              <Radio value={"Manual"} onChange={(e: any) => assignManualRadioCheckBox(e.target.value)} checked={getRadiocheckManual} disabled>
                Manual
              </Radio>
              <Radio value={"Manual"} onChange={(e: any) => assignAutoRadioCheckBox(e.target.value)} checked={getRadiocheckAuto} disabled>
                Auto
              </Radio>
            </Col>
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={3} style={{ textAlign: "right" }}>
              Team
            </Col>
            <Col offset={1} span={20} style={{ textAlign: "left" }}>
              <Select
                style={{ width: "20%" }}
                placeholder="Please select"
                // onChange={onChangeTeam}
                // value={getTeam}
                value={getDataAll?.distribution}
                disabled
              >
                {getTeam?.map((x: typeTeam, index: number) => {
                  return (
                    <Option value={x.id} key={index + 1}>
                      {x.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br></br>
          {multipleInArray(permissionId24, ["oa_manager"]) && getDataAll?.status !== "DISABLED" ? (
            <Row gutter={10}>
              <Col span={4} style={{ textAlign: "left" }}></Col>
              <Col span={20} style={{ textAlign: "left" }}>
                <Button type="primary" danger onClick={() => onClickUpdate("DISABLED")}>
                  Disable
                </Button>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Card>
      </Row>
    </>
  );
};
