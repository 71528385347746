import { AxiosInstance } from "axios";
import {
  ChannelReport,
  Metric,
  OverviewReport,
  PickerType,
  PurposeReport,
  SummaryTransactionReportResponse,
  TSummaryPurposeReport,
} from "../model/overview";
import {
  serviceBackend,
  // serviceBackendDebt,
} from "../../../drivers/service/axiosBackend";

export interface IDashboardService {
  getSummaryTransactionReport: (
    filter: any,
  ) => Promise<SummaryTransactionReportResponse>;
  getSummaryPurposeReport: (filter: any) => Promise<TSummaryPurposeReport>;
  getOverviewReport: (
    purposeIds: string[],
  ) => Promise<OverviewReport & { noneAll: number }>;
  getPurposeReport: (purposeId: string) => Promise<PurposeReport>;
  getChannelReport: (channelId: string) => Promise<ChannelReport>;
  getMetricsReport: (pickerType: PickerType) => Promise<Metric[]>;

  getBankManager: () => Promise<string>;
  getOaManager: () => Promise<string>;
  getOaSupervisor: (dataTeam: any, token: any) => Promise<string>;
  getOaCollectorTodoList: () => Promise<string>;
  getOaCollectorByAction: (dataTeam: any) => Promise<string>;
  getOaCollectorAll: (dataTeam: any) => Promise<string>;
  getBroadcast: (dataTeam: any) => Promise<string>;
}

export const DashboardService = (
  serviceBackendDebt: any,
): IDashboardService => ({
  getBankManager: async () => {
    const result = await serviceBackendDebt.get("/debtor/dashboardBankAuth");
    return result?.data || {};
  },
  getOaManager: async () => {
    const result = await serviceBackendDebt.get("/debtor/dashboardOaAuth");
    return result?.data || {};
  },
  getOaSupervisor: async (data, token) => {
    const result = await serviceBackendDebt.get("/debtor/dashboardOaAuth");
    return result?.data || {};
  },
  getOaCollectorTodoList: async () => {
    const result = await serviceBackendDebt.get(
      "/debtor/dashboardOaAgentTodoListAuth",
    );
    return result?.data || {};
  },
  getOaCollectorByAction: async (data) => {
    const result = await serviceBackendDebt.post(
      "/debtor/dashboardOaAgentByActionAuth",
      data,
    );
    return result?.data || {};
  },
  getOaCollectorAll: async (data) => {
    const result = await serviceBackendDebt.get("/debtor/dashboardOaAgentAuth");
    return result?.data || {};
  },
  getBroadcast: async (data) => {
    const result = await serviceBackendDebt.post("api/v1/broadcast", data);
    return result || {};
  },

  getMetricsReport: async (pickerType) => {
    const result = await serviceBackend.get("/transaction-dashboard-metrics", {
      params: { pickerType },
    });
    return result?.data || {};
  },
  getChannelReport: async (channelId) => {
    const result = await serviceBackend.get("/transaction-dashboard-channel", {
      params: { channelId },
    });
    return result?.data || {};
  },
  getOverviewReport: async (purposeIds) => {
    const result = await serviceBackend.get("/transaction-dashboard-overview", {
      params: { selectedPurposeIds: purposeIds },
    });
    return result?.data || {};
  },
  getPurposeReport: async (purposeId) => {
    const result = await serviceBackend.get("/transaction-dashboard-purpose", {
      params: { purposeId },
    });
    return result?.data || {};
  },
  getSummaryTransactionReport: async (
    filter: any,
  ): Promise<SummaryTransactionReportResponse> => {
    const version = filter?.version ? `&version=${filter.version}` : "";
    const Id = filter?.id ? `&id=${filter.id}` : "";
    const groupBy = filter?.groupBy ? `&groupBy=${filter.groupBy}` : "";

    const { status, statusText, data } = await serviceBackend.get(
      `summary-report-profile?unit=${filter.unit}${Id}${version}${groupBy}`,
    );
    if (status === 200) {
      return data || [];
    }
    throw new Error(statusText);
  },
  getSummaryPurposeReport: async (
    filter: any,
  ): Promise<TSummaryPurposeReport> => {
    const version = filter?.version ? `&version=${filter.version}` : "";
    const Id = filter?.id ? `&id=${filter.id}` : "";
    const groupBy = filter?.groupBy ? `&groupBy=${filter.groupBy}` : "";

    const { status, statusText, data } = await serviceBackend.get(
      `summary-report-purpose?unit=${filter.unit}${Id}${version}${groupBy}`,
    );
    if (status === 200) {
      return data || [];
    }
    throw new Error(statusText);
  },
});
