import dayjs from "dayjs";
import "dayjs/locale/th";
import thTH from "antd/lib/locale-provider/th_TH";
import "antd/es/date-picker/style";
var buddhistEra = require("dayjs/plugin/buddhistEra");
dayjs.extend(buddhistEra);
dayjs.locale("th");

export const CheckspecialFont = (specialfont: string) => {
  if (specialfont) {
    if (specialfont.charAt(0) === "-" || specialfont.charAt(0) === "+" || specialfont.charAt(0) === "=") {
      return specialfont.substring(1);
    } else {
      return specialfont;
    }
  } else {
    return "";
  }
};

export const numberWithCommas = (x: any) => {
  if (x === undefined || x === "-" || x === null || x === "") {
    return "0.00";
  } else {
    const fixedNumber = Number.parseFloat(x).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const numberWithCommasNodigit = (x: any) => {
  if (x === undefined || x === "-" || x === null || x === "") {
    return "-";
  } else {
    const fixedNumber = Number.parseFloat(x);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const DateStamp = (stamp: any) => {
  const stamptime = new Intl.DateTimeFormat("en-US").format(stamp);
  if (stamptime != null && stamptime != "-" && stamptime != "") {
    return dayjs(stamptime).locale("th").add(543, "year").format("DD-MM-YYYY");
  } else {
    return "-";
  }
};

export const checktext = (text: any) => {
  if (text === undefined || text === "-" || text === null || text === "") {
    return "-";
  } else {
    return text;
  }
};

export const dateCheck = (date: string) => {
  if (date != null && date != "-" && date != "") {
    return dayjs(date).locale("th").add(543, "year").format("DD-MM-YYYY");
  } else {
    return "-";
  }
};

export const dateCheckTime = (date: string) => {
  if (date != null && date != "-" && date != "") {
    return dayjs(date).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm:ss น.");
  } else {
    return "-";
  }
};

export const configuredLocale: any = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker?.lang,
      yearFormat: "BBBB",
    },
  },
};
