export interface ITimeSetService {
  getTimeSetting: () => Promise<any>;
  getTimeSettingCreate: (data: any) => Promise<any>;
  getTimeSettingUpdate: (data: any) => Promise<any>;
}

export const TimeSetService = (serviceBackendApi: any): ITimeSetService => ({
  getTimeSetting: async () => {
    const result = await serviceBackendApi.get("/frontTime/");
    return result?.data || {};
  },
  getTimeSettingCreate: async (data: any) => {
    const result = await serviceBackendApi.post("/frontTime/create", data);
    return result?.data || {};
  },
  getTimeSettingUpdate: async (data: any) => {
    const result = await serviceBackendApi.post("/frontTime/update", data);
    return result?.data || {};
  },
});
