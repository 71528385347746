import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tabs } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";
import { Taskimport } from "./Taskimport";

const { TabPane } = Tabs;

export const MainTaskImport: React.FC = ({}): React.ReactElement => {
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <FileDoneOutlined /> TASK IMPORT AND EXPORT
          </b>
        </Card>
      </Row>
      <Taskimport />
    </>
  );
};
