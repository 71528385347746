import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Form, Input, Select, message, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import moment from "moment";

import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { DebtService } from "../services/debt-service";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import TextArea from "antd/lib/input/TextArea";
const appConfig = loadAppConfig();
const DatePicker = generatePicker(dayjsGenerateConfig);
const { Option } = Select;

export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  cifId: string;
  userId: string;
  userUsed: any;
  chkAddAction: (text: string) => void;
}

export const DebtAction: React.FC<Props> = ({ caption, accountNo, baseURL, loading, cifId, userId, userUsed, chkAddAction }): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  // const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const refSelectStatusCode = useRef<any>(null);

  const [contactTypeRead, setContactTypeRead] = useState<object[]>([]);
  const [actionCodeRead, setActionCodeRead] = useState<object[]>([]);
  const [statusCodeRead, setStatusCodeRead] = useState<object[]>([]);
  const [policyRead, setPolicyRead] = useState<object[]>([]);
  const [actionNameAdd, setActionNameAdd] = useState<string>("");
  const [statusNameAdd, setStatusNameAdd] = useState<string>("");
  // start state calendar
  const [startdateAdd, setStartdateAdd] = useState<any>("");

  const [enddateAdd, setEnddateAdd] = useState<any>("");
  const [paymentAmt, setPaymentAmt] = useState<string>("");
  // end state calendar
  const [statusCodeSelected, setStatusCodeSelected] = useState<any>(null);
  const [formAction] = Form.useForm();
  const debtService = DebtService;
  useEffect(() => {
    const fetchData = async () => {
      const action = (await debtService(apiBaseUrlDebt).getActionCode()) as any;
      setActionCodeRead(action.result);
      const contact = (await debtService(apiBaseUrlDebt).getContactType()) as any;
      setContactTypeRead(contact.result);
      const policy = (await debtService(apiBaseUrlDebt).getPolicyList()) as any;
      setPolicyRead(policy.result);
      // console.log(policy)
    };
    addAction;
    fetchData();
  }, [accountNo]);

  const addAction = (e: any) => {
    // console.log(e);

    if (
      (e.statusCode === "PTP-P" ||
        e.statusCode === "PTP-F" ||
        e.statusCode === "PTP-C" ||
        e.statusCode === "PTP-R" ||
        e.statusCode === "PTP-S" ||
        e.statusCode === "PTP-SR" ||
        e.statusCode === "CPT" ||
        e.statusCode === "PP" ||
        e.statusCode === "FP" ||
        e.statusCode === "FP-AC") &&
      (e.paymentDate === null || e.paymentDate === undefined)
    ) {
      refSelectStatusCode.current.focus();
      message.warning("กรุณาเลือกวันที่นัดชำระ");
      return false;
    }

    const amounttext = e.paymentAmt?.split(".");
    const paymentAmtrmcomma = amounttext[0].replaceAll(",", "");
    if (paymentAmtrmcomma.length <= 15) {
      return Modal.confirm({
        title: "ยืนยัน",
        icon: <CheckCircleOutlined />,
        content: "ยืนยันการเพิ่มการติดตาม",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: () => {
          let form = {
            contact: e.contact,
            phone: e.phone,
            actionCodeId: e.actionCode,
            actionCodeName: actionNameAdd,
            statusCodeId: e.statusCode,
            statusCodeName: statusNameAdd,
            accountNo: accountNo,
            paymentDate: startdateAdd != undefined ? dayjs(startdateAdd) : null,
            // paymentDate: startdateAdd != undefined ? startdateAdd : null,
            paymentAmt: paymentAmt != "" ? paymentAmt.replaceAll(",", "") : 0,
            appointmentDate: enddateAdd != undefined ? dayjs(enddateAdd) : null,
            measure: e.measure === undefined ? null : e.measure === "" ? null : e.measure,
            remark: e.remark,
            cif: cifId,
            userId: String(userUsed.id),
            collName: String(userUsed.name),
            // updatedDate: dayjs(),
          };
          debtService(apiBaseUrlDebt)
            .addAction(form)
            .then(() => {
              message.success("บันทึกสำเร็จ");
              let chk: any = uuidv4;
              chkAddAction(chk);
              setPaymentAmt("");
              formAction.resetFields();
            })
            .catch((error: any) => {
              message.error(error);
            });
        },
      });
    } else {
      message.error("กรุณากรอกตัวเลขจำนวนเต็มไม่เกิน 15 จำนวน");
    }
  };

  const checkValue = (text: string, row: any) => {
    // console.log(row);

    setStatusCodeSelected(text);
    setStatusNameAdd(row.optName);
  };

  return (
    <>
      <h1>Action</h1>
      <Form
        form={formAction}
        autoComplete="off"
        layout="vertical"
        name="actionForm"
        onFinish={addAction}
        fields={[{ name: ["paymentAmt"], value: paymentAmt }]}
      >
        <Form.Item label="บุคคลที่ติดต่อ : " name="contact" rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}>
          <Select placeholder="Please Select" allowClear>
            <Option key={0} disabled>
              Please Select
            </Option>
            {contactTypeRead.length ? (
              contactTypeRead.map((opt: any) => (
                <Option key={opt.id} value={opt.contactTypeDesc}>
                  {opt.contactTypeDesc}
                </Option>
              ))
            ) : (
              <> </>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="เบอร์ที่ติดต่อ : " name="phone" rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}>
          <NumberFormat className="ant-input" placeholder="Number" format="##########" />
        </Form.Item>
        <Form.Item label="Action Code : " name="actionCode" rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}>
          <Select
            allowClear
            placeholder="Please Select"
            onChange={(text: string, row: any) => {
              if (row) {
                setActionNameAdd(row.optName);
                const stausCode: any = actionCodeRead[row.optIndex];
                setStatusCodeRead(stausCode.statusCode);
                setStatusNameAdd("");
                // setStatusCodeSelected(null)
                formAction.setFieldsValue({ statusCode: null });
              }
            }}
          >
            <Option key={0} disabled>
              Please Select
            </Option>
            {actionCodeRead.map((opt: any, index: number) => {
              return (
                <Option key={opt.actionCode} optIndex={index} optName={opt.actionName} value={opt.actionCode}>
                  {opt.actionName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Status Code : " name="statusCode" rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}>
          <Select
            ref={refSelectStatusCode}
            placeholder="Please Select"
            value={statusCodeSelected}
            onChange={(text: string, row: any) => {
              if (row) {
                checkValue(text, row);
              }
            }}
            allowClear
          >
            <Option key={0} disabled>
              Please Select
            </Option>
            {statusCodeRead.length ? (
              statusCodeRead.map((opt: any) => {
                return (
                  <Option key={opt.statusCode} value={opt.statusCode} optName={opt.statusName}>
                    {opt.statusName}
                  </Option>
                );
              })
            ) : (
              <> </>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="วันที่นัดชำระ : " name="paymentDate">
          <DatePicker
            disabledDate={(current: any) => {
              return moment().add(-1, "days") >= current;
            }}
            style={{ width: "100%" }}
            format={"DD-MM-BBBB"}
            onChange={(e: any) => setStartdateAdd(e)}
          />
          {/* enddateAdd */}
        </Form.Item>
        <Form.Item label="จำนวนเงินที่นัดชำระ : " name="paymentAmt">
          <NumberFormat
            className="ant-input"
            placeholder="Amount"
            decimalScale={5}
            thousandSeparator=","
            decimalSeparator="."
            allowNegative={false}
            onChange={(e: any) => setPaymentAmt(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="วันที่นัดหมาย : " name="appointmentDate">
          <DatePicker
            disabledDate={(current: any) => {
              return (
                moment().add(-1, "days") >= current
                // ||moment().add(1, "month") <= current
              );
            }}
            style={{ width: "100%" }}
            format={"DD-MM-BBBB"}
            onChange={(e) => setEnddateAdd(e)}
          />
        </Form.Item>
        <Form.Item label="รายงานผลการติดตามหนี้ : " name="remark" rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}>
          <TextArea showCount maxLength={250} placeholder="Text" />
        </Form.Item>
        <Form.Item label="มาตรการ : " name="measure">
          <Select placeholder="Please Select" allowClear>
            <Option key={0} disabled>
              Please Select
            </Option>
            {policyRead.length ? (
              policyRead.map((opt: any) => {
                // if (opt) {
                return (
                  <Option key={opt.id} value={opt.policyCode}>
                    {opt.policyDesc}
                  </Option>
                );
                // }
              })
            ) : (
              <> </>
            )}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
