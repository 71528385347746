import React, { useState, useEffect } from "react";
import { Card, Row, Input, Table, Select, Col, Button, Checkbox, Form, Spin, message } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { SorterResult } from "antd/lib/table/interface";
import type { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { LeadManagementAdd } from "./LeadManagementAdd";
import { LeadManagementService } from "../services/LeadManagementService";
import NumberFormat from "react-number-format";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();

interface IProject {}
interface Props {
  accessRole: any;
  userprofile: any;
}
interface DataType {
  key: React.Key;
  cif: number;
  accountno: number;
  listname: string;
  accountlevel: string;
  os: string;
  noaction: string;
}

export interface TDataLead {
  id: string;
  cif: string;
  account_no: string;
  list_name_real: string;
  account_level: string;
  overdue_amt: string;
  noactiondate: number;
  oa_code: string;
  action_code_id: number;
  cust_name: string;
  cust_last_name: string;
  oa_name: string;
  name: string;
  assign_time: string;
  closepayamt?: string;
  account_status: string;
  channel: string;
}

export interface TPagination {
  current: number;
  pageSize: number;
}

const { Option } = Select;

export const OverviewLeadManagement: React.FC<Props> = ({ accessRole, userprofile }): React.ReactElement => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [form] = Form.useForm();
  const [conditionAssign, setConditionAssign] = useState<IProject[]>([]);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [listSelect, setlistSelect] = useState<object[]>([]);
  const [oaSelect, setOaSelect] = useState(Array);
  const [actionCodeSelect, setActionCodeSelect] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
  const [totalLead, setTotalLead] = useState<number>(0);
  const [filTerListName, setFilTerListName] = useState("");
  const [filTerOa, setFilTerOa] = useState("");
  const [filTerActionCode, setFilTerActionCode] = useState("");
  const [filTerNoAction, setFilTerNoAction] = useState<string | number>("");
  const [filTerCif, setFilTerCif] = useState("");
  const [filTerAccountNo, setFilTerAccountNo] = useState("");
  const [filTerCustomerName, setFilTerCustomerName] = useState("");
  const [filTerCheckBox, setFilTerCheckBox] = useState(false);
  const [pagination, setPagination] = useState<TPagination>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchData({ current: 1, pageSize: 10 });
    fetchMasterData();
  }, []);

  const fetchMasterData = async () => {
    const resultList = await LeadManagementService(apiBaseUrlDebt).getlistnameBytoken();
    const resultOa = await LeadManagementService(apiBaseUrlDebt).getOaSelect("");
    const resultActionCode = await LeadManagementService(apiBaseUrlDebt).getActionCodeSelect();
    setlistSelect(resultList);
    setActionCodeSelect(resultActionCode);
    setOaSelect(resultOa);
  };

  const fetchData = async (pagination: TablePaginationConfig) => {
    let dataSent: any = {
      skip: (Number(pagination.current) - 1) * Number(pagination.pageSize),
      take: Number(pagination.pageSize),
      onHold: filTerCheckBox,
    };
    if (filTerListName) dataSent["listName"] = filTerListName;
    if (filTerOa) dataSent["oa"] = filTerOa;
    if (filTerActionCode) dataSent["actionCode"] = filTerActionCode;
    if (filTerCif) dataSent["cif"] = filTerCif;
    if (filTerAccountNo) dataSent["accountNo"] = filTerAccountNo.toUpperCase();
    if (filTerCustomerName) dataSent["customerName"] = filTerCustomerName;
    filTerNoAction === null ? "" : String(filTerNoAction) ? (dataSent["noAction"] = filTerNoAction) : "";
    const resForCount = await LeadManagementService(apiBaseUrlDebt).getLeadCount(dataSent);
    const resDataLead = await LeadManagementService(apiBaseUrlDebt).getDataForSearch(dataSent);
    let dataSearchToAssign: any = {};
    dataSearchToAssign = dataSent;
    dataSearchToAssign["skip"] = 0;
    dataSearchToAssign["take"] = resForCount[0].cnt > 10000 ? 10000 : resForCount[0].cnt;
    setTotalLead(resForCount[0].cnt);
    setConditionAssign(dataSearchToAssign);
    setDataSource(resDataLead);
    setLoading(false);
  };
  const onSearch = async () => {
    changePage({ current: 1, pageSize: 10 });
  };
  const onclickHoldAssignData = async (condition: any) => {
    setLoadingAssign(true);
    const resultAssignHold = await LeadManagementService(apiBaseUrlDebt).assignHold(condition);
    if (resultAssignHold.count > 0) {
      message.success("Success");
    }

    changePage({ current: 1, pageSize: 10 });
    setLoadingAssign(false);
  };
  const onclickReCallAssignData = async (condition: any) => {
    console.log(condition);

    setLoadingAssign(true);
    const resultAssignHold = await LeadManagementService(apiBaseUrlDebt).assignReCall(condition);
    if (resultAssignHold.count > 0) {
      message.success("Success");
    }

    changePage({ current: 1, pageSize: 10 });
    setLoadingAssign(false);
  };
  const onclickAssignOaData = async (condition: any) => {
    setLoadingAssign(true);
    const resultAssignHold = await LeadManagementService(apiBaseUrlDebt).assignOa(condition);
    if (resultAssignHold.count > 0) {
      message.success("Success");
    }

    changePage({ current: 1, pageSize: 10 });
    setLoadingAssign(false);
  };

  const handleListSelect = (value: any) => {
    setFilTerListName(value);
  };
  const handleOaSelect = (value: any) => {
    setFilTerOa(value);
  };
  const handleActionCodeSelect = (value: any) => {
    setFilTerActionCode(value);
  };
  const handleNoAction = (value: any) => {
    setFilTerNoAction(value);
  };
  const handlesetFilTerCif = (value: any) => {
    setFilTerCif(value);
  };
  const handlesetAccountNo = (value: any) => {
    setFilTerAccountNo(value);
  };
  const handlesetCustomerName = (value: any) => {
    setFilTerCustomerName(value);
  };
  const handlesetCheckBox = (e: CheckboxChangeEvent) => {
    setFilTerCheckBox(e.target.checked);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const changePage = (pagination: TablePaginationConfig) => {
    setLoading(true);
    const { current, pageSize } = pagination;
    setPagination((prevState: any) => ({
      ...prevState,
      current: current || 1,
      pageSize: pageSize || 10,
    }));

    fetchData({ current: current, pageSize: pageSize });
  };

  const columns: ColumnsType<any> = [
    {
      title: "CIF",
      dataIndex: "cif",
      // width: "5%",
    },
    {
      title: "Account No.",
      dataIndex: "account_no",
      // width: "10%",
    },
    {
      title: "List Name",
      dataIndex: "list_name_real",
      // width: "10%",
    },
    {
      title: "Account Level",
      dataIndex: "account_level",
      // width: "10%",
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "name",
      // width: "10%",
      render: (value: string, row: TDataLead) => `${row.cust_name} ${row.cust_last_name}`,
    },
    {
      title: "Action Code",
      dataIndex: "action_code_id",
      // width: "10%",
      render: (event: any) => (event ? event : "-"),
    },
    {
      title: "OS",
      dataIndex: "account_no",
      align: "right" as const,
      // width: "8%",
      render: (text: string, record: TDataLead) => {
        let value: any = 0;
        if (text) {
          if (record.channel === "LOAN") {
            if (record.account_status === "W/O") {
              value = record.closepayamt;
            } else {
              value = record.overdue_amt;
            }
          } else {
            value = record.overdue_amt;
          }
        }
        return (
          <>
            <NumberFormat value={value} displayType={"text"} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} />
          </>
        );
      },
    },
    {
      title: "OA",
      dataIndex: "oa_name",
      // width: "25%",
    },
    {
      title: "No Action (days)",
      dataIndex: "noactiondate",
      // width: "8%",
      render: (e: any) => {
        if (e === 0) {
          return e;
        } else {
          return e;
        }
      },
    },
  ];

  return (
    <Spin tip="กรุณารอสักครู่..." spinning={loading}>
      <Spin tip="กำลังดำเนินการ Assign..." spinning={loadingAssign}>
        <Row style={{ width: "100%", marginTop: "2rem" }}>
          <Col span={checkAccessRole("ddebtLeadManagementAssignto") ? 18 : 24} style={{ paddingRight: "10px" }}>
            <Card style={{ width: "100%", textAlign: "left" }} title={"กำหนดเงื่อนไขในการค้นหาข้อมูล"}>
              <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onSearch}
                  initialValues={{
                    onHold: false,
                  }}
                >
                  <Row>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="List" name="listSlect">
                        <Select
                          placeholder="Please Select"
                          onChange={handleListSelect}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        >
                          <Option key="0" value="" disabled>
                            <em
                              style={{
                                fontStyle: "normal",
                                color: "#C8C8C8",
                              }}
                            >
                              Please Select
                            </em>
                          </Option>
                          {listSelect.map((opt: any, num: any) => {
                            return (
                              <Option key={num + 1} value={opt}>
                                {opt}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="OA" name="oa">
                        <Select
                          placeholder="Please Select"
                          onChange={handleOaSelect}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                        >
                          {oaSelect.map((oa: any, num: number) => {
                            return (
                              <Option value={oa.oa_code} key={num + 1}>
                                {oa.oa_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="Action Code" name="actioncode">
                        <Select placeholder="Please Select" onChange={handleActionCodeSelect} allowClear>
                          {actionCodeSelect.map((action: any) => (
                            <Option value={action.actionCode} key={action.actionCode}>
                              {action.actionName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="No Action" name="noaction">
                        <Input style={{ width: "100%" }} placeholder="Number of Day" onChange={(e: any) => handleNoAction(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="CIF" name="cif">
                        <Input placeholder="Text" onChange={(e: any) => handlesetFilTerCif(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="Account No." name="accountno">
                        <Input placeholder="Text" onChange={(e: any) => handlesetAccountNo(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingLeft: "10px", paddingRight: "10px" }} xs={12} md={8} lg={6}>
                      <Form.Item label="ชื่อลูกหนี้" name="customername">
                        <Input placeholder="Text" onChange={(e: any) => handlesetCustomerName(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "30px",
                      }}
                      xs={12}
                      md={8}
                      lg={6}
                    >
                      <Form.Item name="onHold" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                        <Checkbox onChange={handlesetCheckBox}>On hold</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "30px",
                      }}
                      xs={12}
                      md={8}
                      lg={6}
                    >
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Search
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
            <Card style={{ marginTop: "15px", width: "100%" }}>
              <Table
                size="small"
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                scroll={{ x: "calc(500px + 100%)" }}
                pagination={{ total: totalLead, current: pagination.current, pageSize: pagination.pageSize }}
                onChange={changePage}
                rowKey={"id"}
              />
            </Card>
          </Col>
          {checkAccessRole("ddebtLeadManagementAssignto") ? (
            <Col span={6}>
              <LeadManagementAdd
                userprofile={userprofile}
                oaSelect={oaSelect}
                conditionAssign={conditionAssign}
                loading={loading}
                setLoading={setLoading}
                onclickHoldAssignData={onclickHoldAssignData}
                onclickReCallAssignData={onclickReCallAssignData}
                onclickAssignOaData={onclickAssignOaData}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Spin>
    </Spin>
  );
};
