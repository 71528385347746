import axios from "axios";
import { loadAppConfig } from "../../config/app-config";
const appConfig = loadAppConfig();

export const serviceBackend = axios.create({
  baseURL: appConfig.apiBaseUrl,
});

export const serviceBackendDebt = axios.create({
  baseURL: appConfig.apiBaseUrlDebt,
});

export const serviceBackendUser = axios.create({
  baseURL: appConfig.apiBaseUrlUser,
});

export const serviceBackendNoti = axios.create({
  baseURL: appConfig.apiBaseUrlNoti,
});

export const serviceBackendID24 = axios.create({
  baseURL: appConfig.apiBaseUrlDebtId24,
});
