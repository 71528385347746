import { DashboardFilled } from "@ant-design/icons";
import { Card, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Route, useRouteMatch, Switch, useParams } from "react-router-dom";
import { DebtSearch } from "./DebtSearch";
import { DebtDetail } from "./DebtDetail";

interface Props {
  baseURL: string;
  userId: string;
  accessRole: any;
}
export const DebtManagement: React.FC<Props> = ({
  baseURL,
  userId,
  accessRole,
}) => {
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;
  return (
    <Switch>
      <Route path={`${baseURL}/acc/:cif/:accountNo`}>
        <DebtDetail
          userId={userId}
          baseURL={baseURL}
          caption={""}
          value={0}
          loading={false}
          accessRole={accessRole}
        />
      </Route>
      <Route path={`${baseURL}`}>
        <DebtSearch
          baseURL={baseURL}
          caption={""}
          value={0}
          loading={false}
          accessRole={accessRole}
        />
      </Route>
    </Switch>
  );
};
