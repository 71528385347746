import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Row, Col, Space, message } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";

import { DebtAction } from "./components/DebtAction";
import { DebtTrackHistory } from "./components/DebtTrackHistory";
import { DebtService } from "./services/debt-service";
import { loadAppConfig } from "../../config/app-config";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { DebtHaveActiocn } from "./components/DebtHaveActiocn";
import { DebtNoAction } from "./components/DebtNoAction";
const appConfig = loadAppConfig();

export interface Props {
  baseURL: string;
  caption: string;
  value: number;
  loading: boolean;
  userId: string;
  accessRole: any;
}

export const DebtDetail: React.FC<Props> = ({ caption, value, baseURL, userId, accessRole }): React.ReactElement => {
  const debtService = DebtService;
  const { id24Axios } = useId24();
  // const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);

  const { push } = useHistory();
  const { cif } = useParams<{ cif: string }>();
  const { accountNo } = useParams<{ accountNo: string }>();
  const [accountNoTarget, setAccountNoTarget] = useState<string>(accountNo);
  const [accountDefault, setAccountDefault] = useState<string>("");
  const [tabResult, setTabResult] = useState([{ account_no: "", cif: "" }]);
  const [cifId, setCifId] = useState<string>("");
  const [userUsed, setUserUsed] = useState<any>();
  const [debtDetail, setDebtDetail] = useState<any>({
    custName: "",
    idCard: "",
    status: "",
    work: "",
    phone: "",
    officePhone: "",
    mobile: "",
    custLastName: "",
  });

  const [addActionStatus, setAddActionStatus] = useState("");

  const chkAddAction = (text: string) => {
    setAddActionStatus(text);
  };
  const onChange = (key: string) => {
    push(`${baseURL}/acc/${cif}/${key}`);
    setAccountNoTarget(key);
  };
  useEffect(() => {
    getUserUsed();
    getTabAccount();
    getDebtContact();
  }, []);
  const getUserUsed = async () => {
    const userDetail: any = (await debtService(apiBaseUrlDebt).getDetailUser()) as any;
    setUserUsed(userDetail.result);
  };
  const getTabAccount = async () => {
    try {
      const data = {
        accountNo: accountNo,
        cif: cif,
      };
      let array = [] as any[];
      const result = (await debtService(apiBaseUrlDebt).getAccountList(data)) as any;
      result?.result?.map((result: any) => {
        array.push({ account_no: result.accountNo, cif: result.cif });
      });
      setTabResult(array);
      setCifId(result.result[0].cif);
      setAccountDefault(accountNo);
    } catch (err) {
      message.error("get question failed.");
    }
  };

  const getDebtContact = async () => {
    const debt = (await debtService(apiBaseUrlDebt).getDebtHeader(accountNoTarget, cif)) as any;
    let data = {
      custName: debt?.result[0]?.Cus?.custName,
      idCard: debt?.result[0]?.Cus?.custIdCard,
      status: debt?.result[0]?.status,
      work: debt?.result[0]?.Cus?.occ1Desc,
      phone: debt?.result[0]?.Cus?.homePhone,
      officePhone: debt?.result[0]?.Cus?.officePhone,
      mobile: debt?.result[0]?.Cus?.mobilePhone,
      custLastName: debt?.result[0]?.Cus?.custLastName,
    };
    setDebtDetail(data);
  };

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  return accountDefault != "" ? (
    <>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <Space>
              <LeftCircleOutlined onClick={() => push(`${baseURL}`)} />
              {"รายละเอียดลูกหนี้"}
            </Space>
          </b>
        </Card>
      </Row>
      <Row style={{ marginTop: "2rem" }}>
        {checkAccessRole("ddebtDebtManagementDebtAction") ? (
          <>
            <Col span={18}>
              <DebtHaveActiocn
                onChange={onChange}
                tabResult={tabResult}
                cifId={cifId}
                baseURL={baseURL}
                caption={"Collector"}
                loading={false}
                accountNo={accountNoTarget}
                debtContact={debtDetail}
                accessRole={accessRole}
              />
            </Col>
            <Col span={6} style={{ paddingRight: "0px" }}>
              <Card style={{ width: "100%", textAlign: "left", height: "100%" }}>
                <DebtAction
                  chkAddAction={chkAddAction}
                  cifId={cifId}
                  userId={userId}
                  baseURL={baseURL}
                  caption={""}
                  loading={false}
                  accountNo={accountNoTarget}
                  userUsed={userUsed}
                />
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col span={24} style={{ paddingLeft: "0px" }}>
              <DebtNoAction
                onChange={onChange}
                tabResult={tabResult}
                cifId={cifId}
                baseURL={baseURL}
                caption={"Not Collector"}
                loading={false}
                accountNo={accountNoTarget}
                debtContact={debtDetail}
                accessRole={accessRole}
              />
            </Col>
          </>
        )}
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Card style={{ width: "100%", textAlign: "left", height: "100%" }}>
          <DebtTrackHistory
            addActionStatus={addActionStatus}
            cifId={cifId}
            baseURL={baseURL}
            caption={""}
            loading={false}
            accountNo={accountNoTarget}
          />
        </Card>
      </Row>
    </>
  ) : (
    <></>
  );
};
