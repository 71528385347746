import React from "react";
import { Card, Row, Typography } from "antd";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import { OverviewLeadManagement } from "./components/OverviewLeadManagement";

const { Title } = Typography;

export interface Props {
  accessRole: any;
  userprofile: any;
}

export const LeadManagementRoute: React.FC<Props> = ({ accessRole, userprofile }): React.ReactElement => {
  const { push } = useHistory();
  const { path } = useRouteMatch();
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <Title style={{ color: "#0056B4", fontSize: "24px" }}>
            <EditFilled /> LEAD MANAGEMENT
          </Title>
        </Card>
      </Row>
      <Switch>
        <Route path={`${path}`}>
          <OverviewLeadManagement accessRole={accessRole} userprofile={userprofile} />
        </Route>
      </Switch>
    </>
  );
};
