import React from "react";
import { Button, Result, Typography } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import Background2 from "../layouts/images/Login_GSB.jpg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useId24 } from "../drivers/id24/Id24-provider";
import { TokenResponse, useAuth24 } from "../common-auth/utils/use-auth24";

export const ShutdownPage: React.FC = (): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const { auth24 } = useAuth24();
  let history = useHistory();
  let getTimeOuts = null;

  getTimeOuts = setTimeout(() => {
    login(window.location.origin + "/");
  }, 10000);

  return (
    <div style={{ backgroundImage: `url(${Background2})`, height: "100vh" }}>
      <Result
        status="500"
        subTitle={
          <>
            <br></br>
            <Typography style={{ textAlign: "center", fontSize: "24px" }}>
              {
                "ขณะนี้ไม่สามารถใช้งานระบบได้ เนื่องจากอยู่ระหว่างประมวลผลข้อมูล"
              }
            </Typography>
            <br></br>
            <Button
              type={"primary"}
              style={{
                backgroundColor: "#ff058e",
                border: "none",
                width: "200px",
              }}
              onClick={() => login(window.location.origin + "/")}
            >
              <ArrowLeftOutlined />
              Back
            </Button>
          </>
        }
      />
    </div>
  );
};
