import { Col, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { DatamanagementServiceOa } from "../services/datamanagementoa";
import { v4 as uuidv4 } from "uuid";
import { numberWithCommasNodigit } from "../../../utils";
const appConfig = loadAppConfig();

interface Props {
  dataUser: any;
}

export const DatamanagementSimulateOa: React.FC<Props> = ({ dataUser }): React.ReactElement => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [getUserAll, setUserAll] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    featchData();
  }, []);

  const filterListname = dataUser.filter((x: any) => {
    return x.data["listName"] !== "";
  });

  const featchData = async () => {
    const resualProfile = await DatamanagementServiceOa(apiBaseUrlDebt).getUserAll();
    setUserAll(resualProfile);
    setLoading(false);
  };

  const dataAll: any = [];
  filterListname.map(
    (x: {
      data: {
        afterCap: number;
        beforeBal: number;
        beforeCap: number;
        decreasedCap: number;
        listName: string;
        sumBal: number;
        teamId: string;
        teamName: string;
        totalCapability: number;
        listNameList: any;
      };
      userid: string;
    }) => {
      if (x.data.listNameList) {
        x.data.listNameList.map(({ amt, cnt, oa_name, list_name, team_name, tmp_user_id }: any) => {
          getUserAll.filter((ua: any) => {
            if (String(tmp_user_id) === String(ua.id)) {
              dataAll.push({
                key: uuidv4(),
                listname: list_name,
                userid: x.userid,
                oacode: ua.oaCode,
                username: ua.name,
                afterCap: x.data.afterCap,
                sumBal: x.data.sumBal,
                totalCapability: x.data.totalCapability,
                beforeCap: x.data.beforeCap,
                beforeBal: x.data.beforeBal,
                decreasedCap: x.data.decreasedCap,
                teamName: team_name,
                amt: amt,
                cnt: cnt,
                oa_name: oa_name,
                list_name: list_name,
                team_name: team_name,
                tmp_user_id: tmp_user_id,
              });
            }
          });
        });
      } else {
        getUserAll.filter((ua: any) => {
          if (String(x.userid) === String(ua.id)) {
            dataAll.push({
              key: uuidv4(),
              listname: x.data.listName,
              userid: x.userid,
              oacode: ua.oaCode,
              username: ua.username,
              afterCap: x.data.afterCap,
              sumBal: x.data.sumBal,
              totalCapability: x.data.totalCapability,
              beforeCap: x.data.beforeCap,
              beforeBal: x.data.beforeBal,
              decreasedCap: x.data.decreasedCap,
              teamName: x.data.teamName,
            });
          }
        });
      }
    },
  );

  const columns: any = [
    {
      title: "List name",
      dataIndex: "listname",
    },
    {
      title: "Team",
      dataIndex: "teamName",
    },
    {
      title: "User",
      dataIndex: "username",
    },
    {
      title: "Account (old)",
      dataIndex: "team_name",
      align: "right",
      render: (_: string, data: any) => {
        return <Col>{numberWithCommasNodigit(Number(data?.totalCapability) - Number(data?.beforeCap))}</Col>;
      },
    },
    {
      title: "Account (new)",
      dataIndex: "cnt",
      align: "right",
      render: (_: string, data: any) => {
        return <Col>{numberWithCommasNodigit(data?.cnt)}</Col>;
      },
    },
    {
      title: "Account (total)",
      dataIndex: "teamName",
      align: "right",
      render: (_: string, data: any) => {
        return <Col>{numberWithCommasNodigit(data?.totalCapability - data?.afterCap)}</Col>;
      },
    },
    {
      title: "Outstanding (total)",
      dataIndex: "teamName",
      align: "right",
      render: (_: string, data: any) => {
        return (
          <NumberFormat
            value={Number(data?.beforeBal) + Number(data?.sumBal)}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        );
      },
    },
  ];
  return (
    <>
      <Spin spinning={loading} tip="Loading...">
        <Table size="small" columns={columns} dataSource={dataAll} rowKey={"key"} bordered />
      </Spin>
    </>
  );
};
