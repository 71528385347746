import React, { useContext, useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Table, Modal, message, Button, Form, Space, Typography, Input, Select, InputNumber, Radio, Popconfirm, Spin } from "antd";
import type { InputRef } from "antd";
import type { FormInstance } from "antd/lib/form";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { TeamService } from "../services/team-debt-service";
import axios, { AxiosInstance } from "axios";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { user } from "../common";
const appConfig = loadAppConfig();

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

interface AccountType {
  key: React.Key;
  UserId: number;
  name: string;
  share: number;
  userBank: number;
}
interface UserType {
  id: number;
  name: string;
}
interface DataType {
  key: number;
  no: number;
  user: string;
  description: string;
  name: string;
  updateBy: string;
  updateDate: any;
}
interface Props {
  fillterData: any;
  userId: string;
  reloadTable: number;
  accessRole: any;
}

export const TableTeamManagement: React.FC<Props> = ({ fillterData, userId, reloadTable, accessRole }) => {
  const { id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataCreateBy, setDataCreateBy] = useState("");
  const [form] = Form.useForm();
  const [count, setCount] = useState<number>(0);
  const [hidden, setHidden] = useState(true);
  const [checkOa, setCheckOa] = useState("none");
  const [checkSum, setCheckSum] = useState("none");
  const [checkStatus, setCheckStatus] = useState("none");
  const [userList, setUserlist] = useState<UserType[]>([]);
  const [userUsed, setUserUsed] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const [oaOptRadio, setOaOptRadio] = useState<any[]>([
    { label: "Prefer New", value: "NEW_USER" },
    { label: "Prefer Previous", value: "PREVIOUS_USER" },
    { label: "Random", value: "RANDOM_USER" },
  ]);
  useEffect(() => {
    async function setUserDetail() {
      const userDetail: any = (await TeamService(apiBaseUrlDebt).getDetailUser()) as any;

      setUserUsed(userDetail.result);
    }
    setUserDetail();
  }, []);
  useEffect(() => {
    getDataTable({ type: "", text: "" });
    getUserList();
  }, []);

  const getUserList = async () => {
    const resultfindoauser = await TeamService(apiBaseUrlDebt).findoauser();
    const dataUserlist = resultfindoauser?.map((x: { id: number; name: string; level: string }) => {
      const mapData = {
        id: Number(x.id),
        name: x.name,
        level: x.level,
      };
      return mapData;
    });
    setUserlist(dataUserlist);
  };

  useEffect(() => {
    getDataTable({ type: "", text: "" });
  }, [reloadTable, userUsed]);

  useEffect(() => {
    getDataTable(fillterData);
  }, [fillterData]);

  const getDataTable = async (filter: any) => {
    setLoading(true);
    const res: any = await TeamService(apiBaseUrl).getTeamList();
    let data = (await res.result.map(
      (
        x: {
          id: string;
          name: string;
          description: string;
          user: string;
          assignBy: any;
          updateBy: any;
          updateDate: string;
          createDate: string;
          cifGrouping: boolean;
          assignMode: string;
          createBy: string;
          detail: any[];
        },
        index: number,
      ) => {
        let userList = "";
        x.detail.map((row: any, index: number) => {
          if (index === 0) {
            userList += row.userBank;
          } else {
            userList += "," + row.userBank;
          }
        });
        const mapData = {
          id: x.id,
          no: index + 1,
          name: x.name,
          description: x.description,
          user: x.user,
          updateBy: x.updateBy,
          updateDate: x.updateDate,
          cifGrouping: x.cifGrouping,
          assignBy: x.assignBy,
          assignMode: x.assignMode,
          detail: x.detail,
          createBy: x.createBy,
          createDate: x.createDate,
        };
        return mapData;
      },
    )) as any;

    data = data.filter((item: any) => {
      return item.user === userUsed?.oaCode;
    });
    data.sort((a: any, b: any) => a.createDate.localeCompare(b.createDate));
    data = data.map((e: any, index: number) => {
      const mapData = {
        id: e.id,
        no: index + 1,
        name: e.name,
        description: e.description,
        user: e.user,
        updateBy: e.updateBy,
        updateDate: e.updateDate,
        cifGrouping: e.cifGrouping,
        assignBy: e.assignBy,
        assignMode: e.assignMode,
        detail: e.detail,
        createDate: e.createDate,
        createBy: e.createBy,
      };
      return mapData;
    }) as any;

    if (filter.text != "") {
      // let data = totalData;
      if (filter.type === "TeamName") {
        data = data.filter((item: any) => {
          return item.name.match(fillterData.text);
        });
      } else if (filter.type === "TeamDescription") {
        data = data.filter((item: any) => {
          return item.description.match(fillterData.text);
        });
      } else if (filter.type === "UpdateBy") {
        data = data.filter((item: any) => {
          return item.updateBy.match(fillterData.text);
        });
      }
    }
    const chkLen: number = data.length;
    setTotalData(data);
    setCount(chkLen);
    setLoading(false);
  };

  const showModal = (e: any) => {
    let oaShow = [];
    for (let i = 1; i <= e.detail.length; i++) {
      oaShow.push({
        key: i,
        UserId: e.detail[i - 1].UserId,
        name: "",
        share: e.detail[i - 1].share,
        userBank: e.detail[i - 1].userBank,
      });
    }
    setDataSource(oaShow);
    setDataCreateBy(e.createBy);
    form.setFieldValue("id", e.id);
    form.setFieldValue("name", e.name);
    form.setFieldValue("description", e.description);
    form.setFieldValue("user", e.user);
    form.setFieldValue("cifGrouping", e.cifGrouping);
    form.setFieldValue("assignBy", e.assignBy);
    form.setFieldValue("assignMode", e.assignMode);
    form.setFieldValue("detail", oaShow);
    setIsModalVisible(true);
  };

  const columns: any = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Update By",
      dataIndex: "updateBy",
      key: "updateBy",
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (e: string) => dayjs(e).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm"),
      sorter: (a: any, b: any) => a.updateDate.localeCompare(b.updateDate),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      defaultSortOrder: "ascend" as const,
      render: (e: string) => dayjs(e).locale("th").add(543, "year").format("DD-MM-YYYY HH:mm"),
      sorter: (a: any, b: any) => a.createDate.localeCompare(b.createDate),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (e: string, record: any) => {
        if (checkAccessRole("ddebtTeamManagementEditteam")) {
          return <a onClick={() => showModal(record)}>Edit</a>;
        } else {
          return <></>;
        }
      },
    },
  ];

  const onFinish = (values: any) => {
    let validate = true;
    dataSource.map((opt) => {
      if (!opt.UserId || opt.UserId === undefined || !opt.share || opt.UserId === -1) {
        validate = false;
        return;
      } else {
        setCheckOa("none");
        setHidden(true);
      }
    });
    if (validate) {
      setCheckOa("none");
      let userStatus = "true";
      dataSource.forEach((data1) => {
        dataSource.forEach((data2) => {
          if (data1.UserId === data2.UserId && data1.key != data2.key) {
            userStatus = "false";
          }
        });
      });
      if (userStatus === "false") {
        setCheckStatus("block");
        setCheckSum("none");
        setHidden(false);
      } else {
        setCheckStatus("none");

        let newValue;
        if (values.description === undefined || values.description === "") {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: "",
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
        } else {
          newValue = {
            assignBy: values.assignBy,
            assignMode: values.assignMode,
            cifGrouping: values.cifGrouping,
            description: values.description,
            detail: values.detail,
            id: values.id,
            name: values.name,
          };
        }

        const result = dataSource.reduce((sum, number) => {
          return sum + number.share;
        }, 0);
        if (result === 100) {
          let detail: any[] = [];
          dataSource.map((row: any) => {
            detail.push({
              UserId: Number(row.UserId) as number,
              share: row.share as number,
              userBank: Number(row.userBank) as number,
            });
          });
          const teamsCreate = {
            ...newValue,
            detail: detail,
            user: String(userUsed.oaCode),
            updateBy: userUsed.name,
            createBy: String(dataCreateBy),
          };
          TeamService(apiBaseUrl)
            .updateTeamById(teamsCreate)
            .then((result) => {
              form.resetFields();
              message.success("This is a success message");
              setDataSource([
                {
                  key: 1,
                  UserId: -1,
                  name: "",
                  share: 0,
                  userBank: 0,
                },
              ]);
              setCount(0);
              setIsModalVisible(false);
              getDataTable({ type: "", text: "" });
            })
            .catch((error) => {
              message.error(error);
            });
        } else {
          setCheckSum("block");
          setHidden(false);
        }
      }
    } else {
      setCheckOa("block");
      setCheckStatus("none");
      setCheckSum("none");
      setHidden(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: uuidv4(),
      title: "Collector",
      dataIndex: "UserId",
      width: "50%",
      render: (data: string, row: any, index: number) => {
        return (
          <Select
            allowClear
            placeholder="Please Select"
            size="small"
            style={{ width: "100%" }}
            defaultValue={data}
            key={uuidv4()}
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
            onChange={(e: any, option: any) => {
              if (e) {
                selectChange(option, row, index);
              }
            }}
          >
            <Option key={uuidv4()} value={-1} disabled>
              <div style={{ color: "grey" }}> Plese Select</div>
            </Option>
            {userList?.map((opt, num) => (
              <Option key={uuidv4()} value={opt.id}>
                {opt.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "%",
      dataIndex: "share",
      key: uuidv4(),
      render: (data: number, row: any, index: number) => {
        return (
          <InputNumber
            onChange={(e: any) => {
              accountChange(e, row, index);
            }}
            size="small"
            style={{ width: 80 }}
            min={0}
            max={100}
            placeholder="0-100"
            value={data}
          />
        );
      },
    },
    // {
    //   key: uuidv4(),
    //   title: "User",
    //   dataIndex: "userBank",
    //   width: "30%",
    //   render: (data: string, row: any, index: number) => {
    //     return (
    //       <Select
    //         placeholder="Select"
    //         size="small"
    //         style={{ width: "100%" }}
    //         defaultValue={data}
    //         key={uuidv4()}
    //         showSearch
    //         optionFilterProp="children"
    //         filterOption={(input, option) =>
    //           option.children.toLowerCase().includes(input.toLowerCase())
    //         }
    //         onChange={(e: any, option: any) => {
    //           bankChange(option, row, index);
    //         }}
    //       >
    //         <Option key={uuidv4()} value={-1}>
    //           Plese Select
    //         </Option>
    //         {/* {oaList.map((opt, num) => (

    //             <Option key={uuidv4()} value={opt.id} >
    //             {opt.name}
    //           </Option>

    //         ))} */}
    //         <Option key={uuidv4()} value={33}>
    //           1111
    //         </Option>
    //         <Option key={uuidv4()} value={34}>
    //           2222
    //         </Option>
    //       </Select>
    //     );
    //   },
    // },
    {
      title: "Active",
      dataIndex: "active",
      render: (_, record: any) => {
        return (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const [dataSource, setDataSource] = useState<AccountType[]>([
    {
      key: 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: 0,
    },
  ]);

  const handleAddModal = () => {
    const newData: AccountType = {
      key: dataSource.length + 1,
      UserId: -1,
      name: "",
      share: 0,
      userBank: 0,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: AccountType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columnsModal = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AccountType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
      }
    }, [editing]);

    useEffect(() => {}, [fillterData]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();

        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {}
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {}

  const handleDelete = (key: React.Key) => {
    if (dataSource.length > 1) {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    }
  };

  const accountChange = (share: any, row: any, index: number) => {
    let newArr = [...dataSource];
    newArr[index].share = share;
    setTimeout(() => {
      setDataSource(newArr);
    }, 1000);
  };

  const selectChange = (oa: any, row: any, index: number) => {
    let UserId = oa.value;
    let name = oa.children;
    let newArr = [...dataSource];
    newArr[index].UserId = UserId;
    newArr[index].name = name;
    setDataSource(newArr);
  };

  const bankChange = (e: any, row: any, index: number) => {
    let userBank = e.value;
    let newArr = [...dataSource];
    newArr[index].userBank = userBank;
    setDataSource(newArr);
  };

  const [totalData, setTotalData] = useState<DataType[]>([]);

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Spin spinning={loading} tip="Loading...">
        <Table
          columns={columns}
          dataSource={totalData}
          style={{ marginTop: 30, width: "100%" }}
          rowKey={(record: any) => record.id}
          pagination={{
            defaultPageSize: 10,
            total: count,
            showSizeChanger: true,
          }}
        />
      </Spin>
      <Modal
        width={1200}
        title="Edit Team"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item hidden={true} label="id" name="id">
            <Input hidden={true} id="id" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Name",
              },
            ]}
          >
            <Input id="name" placeholder="Team 1" style={{ width: "90%" }} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea rows={4} placeholder="Text" style={{ width: "90%" }} />
          </Form.Item>

          <Form.Item label="Choose Collector" name="detail">
            <Table
              size="small"
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnsModal as ColumnTypes}
              pagination={false}
              style={{ width: "90%" }}
              rowKey={(record: any) => {
                return record.key;
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 7 }} hidden={hidden}>
            <Text type="danger" style={{ display: checkOa }}>
              Please fill out the information completely.
            </Text>
            <Text type="danger" style={{ display: checkSum }}>
              Not 100 %.
            </Text>
            <Text type="danger" style={{ display: checkStatus }}>
              Collector ซ้ำกัน
            </Text>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 11,
            }}
          >
            <Button onClick={handleAddModal} type="primary">
              Add
            </Button>
          </Form.Item>
          <Form.Item
            label="CIF Grouping"
            name="cifGrouping"
            rules={[
              {
                required: true,
                message: "Please input CIF Grouping!",
              },
            ]}
          >
            <Radio.Group name="cifGroupingRadio">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Assign By"
            name="assignBy"
            rules={[
              {
                required: true,
                message: "Please input Assign By!",
              },
            ]}
          >
            <Radio.Group name="assignByRadio">
              <Radio value={"OS_BAL"}>Outstanding Balance</Radio>
              <Radio value={"ACCOUNT"}>Account</Radio>
            </Radio.Group>
          </Form.Item>
          {/* <br/> */}
          <Form.Item
            label="OA"
            name="assignMode"
            rules={[
              {
                required: true,
                message: "Please input OA!",
              },
            ]}
          >
            <Radio.Group name="assignModeRadio" options={oaOptRadio} />
          </Form.Item>
          <Space
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button key="save" type="primary" htmlType="submit" style={{ width: "80px" }}>
              Save
            </Button>
            <Button key="cancel" onClick={handleCancel} style={{ width: "80px" }}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
