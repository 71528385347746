import { Card, Row, Col, message, Button, Space, Select, Table } from "antd";
import React, { useEffect, useState } from "react";

import { SetSessionTimeOutService } from "./service/service";
import { ProfileFilled, ProfileOutlined } from "@ant-design/icons";
import { useId24 } from "../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../config/app-config";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
const appConfig = loadAppConfig();

export interface Props {
  userId: string;
  accessRole: string;
}

export const SetSessionTimeOut: React.FC<Props> = ({ userId, accessRole }) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  // const [form] = Form.useForm();
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isClosemodal, setClosemodal] = useState(false);
  // const [bankId, setBankId] = useState();
  // const [showLine, setShowLine] = useState<boolean | { showLeafIcon: boolean }>(
  //   true
  // );
  // const [parentId, setParentId] = useState("");
  // const [loadData, setLoadData] = useState(false);
  // const [name, setName] = useState("");
  // const [selectKey, setSelectKey] = useState(false);
  // const [pos, setPos] = useState(0);
  const [dataTimeout, setDataTimeout] = useState(0);
  // const [data, setData] = useState<any>([]);
  const { Option } = Select;

  const handleChange = (value: number) => {
    setDataTimeout(value);
  };

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   form.resetFields();
  //   setIsModalVisible(false);
  // };

  // const onFinish = async (values: any) => {
  //   const create = {
  //     key: values.id,
  //     title: values.name,
  //     bankId: bankId,
  //     parentId: parentId,
  //   };
  //   setLoadData(true);
  //   const res: any = await SetSessionTimeOutService(apiBaseUrlDebt).createBankOrganize(create);
  //   res.error ? error(res.error) : success(res.success);
  //   setLoadData(false);
  //   form.resetFields();
  // };

  // const onFinishFailed = (errorInfo: any) => {
  //   // console.log("Failed:", errorInfo);
  // };

  const getParentTitle = (key: any, tree: any): any => {
    let parentTitle;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentTitle = node.title;
        } else if (getParentTitle(key, node.children)) {
          parentTitle = getParentTitle(key, node.children);
        }
      }
    }
    return parentTitle;
  };

  // const success = (msg: string) => {
  //   message.success(msg);
  // };

  // const error = (err: string) => {
  //   message.error(err);
  // };

  interface DataType {
    key: React.Key;
    date: string;
    name: string;
    timeout: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Update Date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (data: string, row: any, index: number) => {
        if (data) {
          return data != "null"
            ? dayjs(data).add(543, "year").format("DD-MM-YYYY HH:mm:ss")
            : "-";
        }
      },
    },
    {
      title: "Update By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      responsive: ["md"],
      render: (data: string, row: any, index: number) => {
        if (data) {
          return data != "null" ? data : "-";
        }
      },
    },
    {
      title: "Timeout",
      dataIndex: "sesstionTime",
      key: "sesstionTime",
      responsive: ["lg"],
    },
  ];

  const [dataSource, setDataSource] = useState<DataType[]>();
  const [tableLoading, setTableLoading] = useState(false);

  const handleLoadingTable = (load: boolean) => {
    setTableLoading(load);
  };

  const updateTimeout = async () => {
    if (dataTimeout == 0 || dataTimeout == undefined) {
      message.error("กรุณาเลือก Timeout");
    } else {
      handleLoadingTable(true);
      const res: any = await SetSessionTimeOutService(
        apiBaseUrlDebt,
      ).updateTimeout(dataTimeout);
      if (res) {
        handleLoadingTable(false);
      }
      message.success("Update Timeout เรียบร้อย สามารถใช้งานได้ในวันถัดไป");
    }
  };

  useEffect(() => {
    async function fetchDataForSearch() {
      const res: any = await SetSessionTimeOutService(
        apiBaseUrlDebt,
      ).getTimeout();
      let convertData: any = [];
      await res.map((e: any, i: number) => {
        convertData.push({
          key: i,
          updatedDate: e.updatedDate,
          updatedBy: e.updatedBy,
          sesstionTime: e.sesstionTime + " " + "Minutes",
        });
      });
      await setDataSource(convertData);
      // console.log("res");
      // console.log(res);
      // console.log("res");
    }
    fetchDataForSearch();
  }, [tableLoading]);

  return (
    <React.Fragment>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <ProfileOutlined
              style={{ width: 36, height: 36, color: "#0056B4" }}
            />
            Set Session Timeout
          </b>
        </Card>
      </Row>

      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
        style={{ marginTop: "2rem", width: "100%" }}
      >
        <Col span={24}>
          <Card style={{ width: "100%" }}>
            {checkAccessRole("ddebtSettingSetSessionTimeoutAction") ? (
              <Row style={{ marginBottom: "20px" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Space>
                    <b>Set Session Timeout</b>
                    <Select
                      allowClear
                      placeholder="Select Time"
                      style={{ width: 120, textAlign: "left" }}
                      onChange={handleChange}
                    >
                      <Option value="0" disabled>
                        Please Select
                      </Option>
                      <Option value="2">2 Minutes</Option>
                      <Option value="5">5 Minutes</Option>
                      <Option value="10">10 Minutes</Option>
                      <Option value="15">15 Minutes</Option>
                    </Select>
                    <Button type="primary" onClick={updateTimeout}>
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Table
              columns={columns}
              dataSource={dataSource}
              loading={tableLoading}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
