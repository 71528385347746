import { DatamanageMent, ICenter, IuserProfile, typeTeam } from "../models/models-datamanagement";
import { ICountUnlist, IDataUnlist, TStatusDatamanagement } from "../common";
import { IUser } from "../../oaProfile/common";

export interface FilterTable {
  take: number;
  skip: number;
}
interface TmasterDataCenterBranch {
  team_group_id: number;
  desc_local: string;
}
export interface IDatamanagementService {
  getUserDetail: () => Promise<IuserProfile>;
  findConditionByname: (userAssign: string) => Promise<any>;
  getRuleBaseByStatus: (userAssign: string, status: string, task: number, skip: number) => Promise<any>;
  countRuleBase: (userAssign: string, status: string) => Promise<any>;
  createCondition: (rulesCreate: any) => Promise<number>;
  getDraftDataManagementByid: (userAssign: string) => Promise<DatamanageMent>;
  updateConditionByid: (userAssign: string, dataBody: any) => Promise<number>;
  updateConditionByseq: (seq: any, userAssign: string, status: string) => Promise<number>;
  getCountUnList: () => Promise<ICountUnlist[]>;
  setExecuteRuleBase: (userCleardist: any) => Promise<any>;
  setSimulateRuleBase: (userCleardist: any) => Promise<any>;
  getProductCode: () => Promise<any>;
  // getProductCodeMany: (type: string) => Promise<any>;
  getTeamList: () => Promise<typeTeam[]>;
  assignConditions: (userCleardist: any) => Promise<any>;
  // getDataRulebase: () => Promise<any>;
  getUnlistList: (filter: FilterTable) => Promise<IDataUnlist[]>;
  getOaList: () => Promise<string>;
  getCenterList: () => Promise<ICenter[]>;
  getTeamByUser: () => Promise<any>;
  getUserAll: () => Promise<any>;
  deleteRuleBase: (distributionId: any, seq: any) => Promise<any>;
  simulateAssign: () => Promise<any>;
  exportcsvUnlist: (filter: FilterTable) => Promise<any>;
  findaccountold: (manageroaid: number) => Promise<any>;
  simulateStatus: () => Promise<TStatusDatamanagement>;
  executeStatus: () => Promise<TStatusDatamanagement>;
  assignStatus: (userid?: number | string) => Promise<TStatusDatamanagement>;
  findUserIdListAll: (userid: number) => Promise<any>;
  simulateAssignOa: (oaid: number | string) => Promise<any>;
  masterDataCenterBranch: () => Promise<TmasterDataCenterBranch[]>;
  getTeamListBank: (type: string) => Promise<any>;
  getProfile: () => Promise<IUser[]>;
  getSeq: () => Promise<IUser[]>;
}

export const DatamanagementService = (serviceBackendApi: any): IDatamanagementService => ({
  getUserDetail: async () => {
    const result = await serviceBackendApi.get(`/debtor/userDetails`);
    return result.data.result;
  },
  findConditionByname: async (userAssign) => {
    const result = await serviceBackendApi.get(`/rule-base/${userAssign}`);
    return result?.data?.result;
  },
  getRuleBaseByStatus: async (userAssign, status, task, skip) => {
    const result = await serviceBackendApi.get(`/rule-base/${userAssign}/${status}/${task}/${skip}`);
    return result?.data?.result;
  },
  countRuleBase: async (name, status) => {
    const result = await serviceBackendApi.get(`/rule-base/count/${name}/${status}`);
    return result.data.result;
  },
  createCondition: async (rulesCreate: any) => {
    const databody = rulesCreate[0];
    const result = await serviceBackendApi.post("/rule-base", databody);
    return result.status;
  },
  updateConditionByid: async (userAssign: string, dataBody: any) => {
    const result = await serviceBackendApi.put(`/rule-base/${userAssign}`, dataBody);
    return result.status;
  },
  getDraftDataManagementByid: async (userAssign) => {
    const result = await serviceBackendApi.get(`/rule-base/${userAssign}`);
    return result.data.result;
  },
  updateConditionByseq: async (seq, userAssign, status) => {
    const databody = {
      seq: seq,
      status: status,
    };
    const result = await serviceBackendApi.put(`/rule-base/seq-status/${userAssign}`, databody);
    return result.status;
  },
  getCountUnList: async () => {
    const result = await serviceBackendApi.get(`/search/countUnList`);
    return result?.data.result || {};
  },
  setExecuteRuleBase: async (userCleardist) => {
    const dataBody = { userId: userCleardist };
    const result = await serviceBackendApi.post(`/rule-base/execute`, dataBody);
    return result || {};
  },

  setSimulateRuleBase: async (userCleardist) => {
    const dataBody = { userId: userCleardist };
    const result = await serviceBackendApi.post(`/debt/simulate`, dataBody);
    return result.data.result;
  },
  getProductCode: async () => {
    const result = await serviceBackendApi.get(`/productCode/search`);
    return result.data.result;
  },

  // getProductCodeMany: async (type) => {
  //   const result = await serviceBackendApi.get(`http://localhost:3001/productCode/search/${type}`);
  //   return result.data.result;
  // },
  getTeamList: async () => {
    const result = await serviceBackendApi.get("/dist/all");
    return result?.data.result || {};
  },
  assignConditions: async (userCleardist) => {
    const dataBody = {
      userId: userCleardist,
      assignType: "Manual",
    };
    const result = await serviceBackendApi.post("/debt/submit", dataBody);
    return result.data.result || {};
  },

  getUnlistList: async (filter) => {
    const result = await serviceBackendApi.post("/search/unList", filter);
    return result?.data.result || {};
  },
  exportcsvUnlist: async (filter) => {
    const result = await serviceBackendApi.post("/export-service/unlist", filter);
    return result?.data.result || {};
  },
  getOaList: async () => {
    const result = await serviceBackendApi.post("/search/oa", {
      condition: {},
    });
    return result?.data.result || {};
  },
  getCenterList: async () => {
    const result = await serviceBackendApi.get("/search/centerBranch");
    return result?.data.result || {};
  },
  getTeamByUser: async () => {
    const data = {};
    const result = await serviceBackendApi.post("/user/teambyuser", data);
    return result?.data.result || {};
  },
  getUserAll: async () => {
    const result = await serviceBackendApi.get("/debtor/getUserAll");
    return result?.data.result || {};
  },
  deleteRuleBase: async (distributionId, seq) => {
    const data = {
      seq: seq,
      distributionId: distributionId,
    };
    const result = await serviceBackendApi.post(`/debtor/rulebaseDelete`, data);
    return result?.data?.result || {};
  },

  simulateAssign: async () => {
    const data = {
      userId: 0,
    };
    const result = await serviceBackendApi.post(`/debt/simulateAssign`, data);
    return result?.data || {};
  },

  findaccountold: async (manageroaid) => {
    const result = await serviceBackendApi.get(`/debt/getOldAcc/${manageroaid}`);
    return result?.data || {};
  },
  simulateStatus: async () => {
    const data = {
      userId: 0,
    };
    const result = await serviceBackendApi.post(`/datamanagement/status/simulateStatus`, data);
    return result.data || {};
  },
  assignStatus: async (userid) => {
    const data = {
      userId: 0,
    };
    const result = await serviceBackendApi.post(`/datamanagement/status/assignStatus`, data);
    return result.data || {};
  },
  executeStatus: async () => {
    const data = {
      userId: 0,
    };
    const result = await serviceBackendApi.post(`/datamanagement/status/executeStatus`, data);
    return result.data || {};
  },
  findUserIdListAll: async (userid) => {
    const result = await serviceBackendApi.get(`/search/findUserIdListAll/${userid}`);
    return result.data || {};
  },

  simulateAssignOa: async (oa) => {
    const data = {
      userId: oa,
    };
    const result = await serviceBackendApi.post(`/debt/simulateAssign`, data);
    return result?.data || {};
  },
  masterDataCenterBranch: async () => {
    const result = await serviceBackendApi.get(`/centerBranch`);
    return result?.data || {};
  },
  getTeamListBank: async (type) => {
    const result = await serviceBackendApi.get(`/dist/all/${type}`);
    return result?.data?.result || {};
  },
  getProfile: async () => {
    const result = await serviceBackendApi.get("/profile/user");
    return result?.data || {};
  },
  getSeq: async () => {
    const result = await serviceBackendApi.get("/debt/seq");
    return result?.data || {};
  },
});
