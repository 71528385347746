import React, { useState, useEffect } from "react";
import { SetPolicy } from "../service/policy.service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Spin, Table, message } from "antd";
import { dateCheck, numberWithCommas } from "../../../utils/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const appConfig = loadAppConfig();

export interface props {
  accessRole: string;
  loading: boolean;
}

export const TablePolicy: React.FC<props> = ({ accessRole, loading }): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [getdataAlls, setdataAlls] = useState<any>([]);
  const [getloading, setloading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [getdatamodal, setdatamodal] = useState<any>([]);
  const [formReset] = Form.useForm();

  const { Option } = Select;

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getdataAll();
  }, [getdatamodal, loading]);
  const getdataAll = async () => {
    await SetPolicy(apiBaseUrlDebt)
      .getalldata()
      .then((event: any) => {
        if (event) {
          event?.result?.sort((a: any, b: any) => a.id - b.id);
          setdataAlls(event.result);
          setloading(true);
        }
      });
  };

  const showModal = (event: string, dataall: any) => {
    formReset.setFieldValue("id", dataall.id);
    formReset.setFieldValue("policyAmt", dataall.policyAmt);
    formReset.setFieldValue("policyCode", dataall.policyCode);
    formReset.setFieldValue("policyDesc", dataall.policyDesc);
    formReset.setFieldValue("updateDate", dataall.updateDate);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    formReset.resetFields();
  };

  const handelupdate = (event: any) => {
    if (isNaN(event.policyAmt)) {
      message.warning("โปรดกรอกข้อมูลในส่วนของค่าตอบแทนเป็นตัวเลข");
      return false;
    }
    const data = {
      id: Number(event.id),
      data: {
        policyCode: event.policyCode,
        policyDesc: event.policyDesc,
        policyAmt: event.policyAmt,
      },
    };
    Modal.confirm({
      title: "คุณต้องการอัพเดตข้อมูล",
      icon: <ExclamationCircleOutlined />,
      content: "โปรดตรวจสอบให้แน่ใจก่อนที่จะอัพเดตข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        await SetPolicy(apiBaseUrlDebt)
          .updatepolicy(data)
          .then((result) => {
            if (result) {
              message.success("ทำการอัพเดตข้อมูลสำเร็จ");
              setIsModalOpen(false);
              formReset.resetFields();
              getdataAll();
            }
          });
      },
    });
  };

  const handledelete = (event: any) => {
    Modal.confirm({
      title: "คุณต้องการลบข้อมูล",
      icon: <ExclamationCircleOutlined />,
      content: "โปรดตรวจสอบให้แน่ใจก่อนที่จะลบข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        await SetPolicy(apiBaseUrlDebt)
          .deltepolicy(Number(event.id))
          .then((result) => {
            if (result) {
              message.success("ทำการลบข้อมูลสำเร็จ");
              getdataAll();
            }
          });
      },
    });
  };

  const columns: any = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Policy Code",
      dataIndex: "policyCode",
      key: "policyCode",
    },
    {
      title: "มาตรการ",
      dataIndex: "policyDesc",
      key: "policyDesc",
    },
    {
      title: "ค่าตอบแทน",
      dataIndex: "policyAmt",
      key: "policyAmt",
      render: (event: any) => numberWithCommas(event),
    },
    {
      title: "Update Date",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (event: any) => dateCheck(event),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center" as const,
      render: (event: any, dataall: any) => {
        return (
          <>
            <Row
              gutter={[16, 16]}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Col>
                <Button type="primary" onClick={() => showModal(event, dataall)}>{`Edit`}</Button>
              </Col>

              <Col>
                <Button type="text" style={{ color: "red" }} onClick={() => handledelete(dataall)}>
                  {`Delete`}
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal title="แก้ไขข้อมูล" open={isModalOpen} onCancel={handleCancel} footer={false}>
        <Form form={formReset} onFinish={handelupdate} name="formupdate" layout="vertical">
          <Form.Item name={`special`} label={`เงื่อนไขพิเศษ`}>
            <Select defaultValue={"ตามมาตรการ"} placeholder={`กรุณาเลือก..`} allowClear>
              <Option value="ตามมาตรการ">{`ตามมาตรการ`}</Option>
            </Select>
          </Form.Item>
          <Form.Item label="ID" name={"id"} hidden>
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Policy Code"
            name={"policyCode"}
            rules={[
              {
                required: true,
                message: "Please input text",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="มาตรการ"
            name={"policyDesc"}
            rules={[
              {
                required: true,
                message: "Please input text",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ค่าตอบแทน"
            name={"policyAmt"}
            rules={[
              {
                required: true,
                message: "Please input text number",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              step="0.01"
              stringMode
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item style={{ justifyContent: "center", display: "flex" }}>
            <Button type="primary" htmlType="submit">{`แก้ไข`}</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Table loading={loading ? getloading : loading} size="small" dataSource={getdataAlls} rowKey={(record: any) => record.id} columns={columns} />
    </>
  );
};
