import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tabs } from "antd";
import { TableByBank } from "./DebtContact/TableByBank";
import { TableByOa } from "./DebtContact/TableByOa";
import { TablePay } from "./DebtContact/TablePay";

const { TabPane } = Tabs;

export interface Props {
  baseURL: string;
  caption: string;
  accountNo: string;
  loading: boolean;
  cifId: string;
  debtContact: any;
  accessRole: any;
}

export const DebtContact: React.FC<Props> = ({ caption, accountNo, baseURL, loading, cifId, debtContact, accessRole }): React.ReactElement => {
  const [cif, setCif] = useState(cifId);
  useEffect(() => {
    setCif(cifId);
  });

  return (
    <>
      <h1>ข้อมูลอื่นๆ</h1>
      <Tabs type="card" defaultActiveKey="bank">
        <TabPane tab="ข้อมูลติดต่อจากธนาคาร" key="bank">
          <TableByBank
            cifId={cif}
            debtContact={debtContact}
            type={"1"}
            baseURL={baseURL}
            caption={""}
            loading={false}
            accountNo={accountNo}
            accessRole={accessRole}
          />
        </TabPane>
        <TabPane tab="ข้อมูลติดต่อเพิ่มเติมจาก OA" key="oa">
          <TableByOa cifId={cif} debtContact={debtContact} type={"2"} baseURL={baseURL} caption={""} loading={false} accountNo={accountNo} />
        </TabPane>
        <TabPane tab="ข้อมูลการชำระ" key="pay">
          <TablePay cifId={cif} debtContact={debtContact} type={"3"} baseURL={baseURL} caption={""} loading={false} accountNo={accountNo} />
        </TabPane>
      </Tabs>
    </>
  );
};
