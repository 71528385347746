import { AxiosInstance } from "axios";
import { loadAppConfig } from "../../../config/app-config";
import { serviceBackend, serviceBackendDebt } from "../../../drivers/service/axiosBackend";
import { IuserProfile } from "../../datamanagement/models/models-datamanagement";

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

// let decodedCookie = getCookie("id24Token");
const decodedCookie = localStorage.getItem("id24Token");

export interface ILeadManagementService {
  getUserDetail: () => Promise<IuserProfile>;
  // getSummaryTransactionReport: (
  //   filter: any
  // ) => Promise<SummaryTransactionReportResponse>;
  // getSummaryPurposeReport: (filter: any) => Promise<TSummaryPurposeReport>;
  // getOverviewReport: (
  //   purposeIds: string[]
  // ) => Promise<OverviewReport & { noneAll: number }>;
  // getPurposeReport: (purposeId: string) => Promise<PurposeReport>;
  // getChannelReport: (channelId: string) => Promise<ChannelReport>;
  // getMetricsReport: (pickerType: PickerType) => Promise<Metric[]>;
  getDebtList: (filter: any) => Promise<string>;
  getAccountList: (accountNo: string) => Promise<string>;
  getDebtHeader: (accountNo: string) => Promise<string>;
  getDebtContact: (cif: string) => Promise<string>;
  addAction: (form: any) => Promise<string>;
  getDebtTrackLogs: (cif: string) => Promise<string>;

  getLeadCount: (data: string) => Promise<any>;
  getTeamSearch: (team: string) => Promise<string>;
  // getTeamSelect: () => Promise<string>;
  // getListSelect: () => Promise<string>;
  getOaSelect: (data: any) => Promise<string>;
  getActionCodeSelect: () => Promise<any>;
  getDataForSearch: (data: any) => Promise<any>;
  getDataForTeamPreview: (data: any, token: any) => Promise<string>;
  getDataForOaPreview: (data: any, token: any) => Promise<string>;
  postDataForAssign: (data: any, token: any) => Promise<string>;
  assignHold: (data: any) => Promise<string>;
  assignReCall: (data: any) => Promise<string>;
  assignOa: (data: any) => Promise<any>;
  getUserListOa: () => Promise<string>;
  getOACollector: () => Promise<any>;
}
export const LeadManagementService = (urlService: any): ILeadManagementService => ({
  assignReCall: async (data) => {
    const result = await urlService.post("/debtor/setRecall", data);
    return result?.data || {};
  },
  assignOa: async (data) => {
    const result = await urlService.post("/debtor/setCollector", data);
    return result?.data?.result || {};
  },
  assignHold: async (data) => {
    const result = await urlService.post("/debtor/setHold", data);
    return result?.data || {};
  },
  getLeadCount: async (data) => {
    const result = await urlService.post("/search/leadCount", data);
    return result?.data || {};
  },
  getTeamSearch: async (team) => {
    const result = await urlService.get("/dist/" + team);
    return result?.data || {};
  },
  // getTeamSelect: async () => {
  //   const result = await urlService.get("/dist/all");
  //   return result?.data || {};
  // },
  // getListSelect: async () => {
  //   const result = await urlService.get("/listname/search");
  //   return result?.data || {};
  // },
  getOaSelect: async (data) => {
    if (data) {
      const result = await urlService.get("/search/oaBytoken");
      return result?.data || {};
    }
    const result = await urlService.get("/search/oaBytoken");
    return result?.data || {};
  },
  getActionCodeSelect: async () => {
    const result = await urlService.get("/search/actioncode");
    return result?.data?.result || {};
  },
  getDataForSearch: async (data) => {
    const result = await urlService.post("/search/lead", data);
    return result?.data?.result || {};
  },

  getDataForTeamPreview: async (data, token) => {
    const result = await urlService.post("/search/debtCust", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(result)
    // console.log(result.data)
    return result?.data || {};
  },
  getDataForOaPreview: async (data, token) => {
    const result = await urlService.post("/search/debtCust", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(result)
    // console.log(result.data)
    return result?.data || {};
  },
  postDataForAssign: async (data, token) => {
    const result = await urlService.post("/search/debtCust", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(result)
    // console.log(result.data)
    return result?.data || {};
  },

  getDebtList: async (filter: any) => {
    const body: any = {
      condition: {
        cif: "598",
      },
    };
    const result = await urlService.get("/search/debtCust");
    // console.log(result)
    // console.log(result.data)
    return result?.data || {};
  },

  getAccountList: async (accountNo: string) => {
    // const result = await axiosInstance.post("/api/debt/getAccountNo",{ accountNo: accountNo });

    // console.log(result.data)
    return accountNo;
  },

  getDebtHeader: async (accountNo: string) => {
    const result = await urlService.post("/api/debt/getDebtHeader", {
      accountNo: accountNo,
    });

    // console.log(result.data)
    return result?.data || {};
  },
  getDebtContact: async (cif: string) => {
    const result = await urlService.post("/api/custaddress/getDebtContact", {
      cif: cif,
    });

    // console.log(result.data)
    return result?.data || {};
  },

  getDebtTrackLogs: async (cif: string) => {
    const result = await urlService.post("/api/wrapupLog/getDebtTrackLogs", {
      cif: cif,
    });

    // console.log(result.data)
    return result?.data || {};
  },

  addAction: async (form: any) => {
    // console.log(form);
    const result = await urlService.post("/api/wrapupLog/addAction", {
      form: form,
    });
    return result?.data || {};
  },

  getUserDetail: async () => {
    const result = await urlService.get(`/debtor/userDetails`);
    return result.data.result;
  },
  // new
  getUserListOa: async () => {
    // serviceBackendDebt
    const result = await urlService.get("/debtor/userList");
    return result?.data || {};
  },
  getOACollector: async () => {
    const result = await urlService.post("/search/oaCollector");
    return result?.data?.result || {};
  },
});
