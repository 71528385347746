import { CalendarFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import { DashboardService } from "./../services/dashboard-service";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import dayjs from "dayjs";

const appConfig = loadAppConfig();
interface Props {
  baseUrl: string;
}

export const ActionTodolist: React.FC<Props> = ({ baseUrl }) => {
  const columnStyle = {
    textAlign: "left",
    fontSize: "12px",
  } as any;

  const history = useHistory();
  const { id24Axios } = useId24();

  // function getCookie(key: string) {
  //   var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  //   return b ? b.pop() : "";
  // }

  // const decodedCookie = getCookie("id24Token");
  const [dataSourceTodoList, setDataSourceTodoList] = useState([]);

  useEffect(() => {
    async function fetchDataForSearch() {
      const apiCaller = id24Axios(appConfig.apiBaseUrlDebt);
      const resTodoList: any = await DashboardService(apiCaller).getOaCollectorTodoList();
      await setDataSourceTodoList(resTodoList.result);
    }
    fetchDataForSearch();
  }, []);

  const toDebtNoContact = (status: string, code: string, create_date: string) => {
    if (status) {
      let all = {
        actionCode: code,
        statusCode: status,
        create_date: create_date,
      };
      history.push("/debtmanagement", all);
    }
  };

  return (
    <Card
      style={{
        textAlign: "left",
        width: "auto",
        minHeight: "215px",
        height: "auto",
      }}
      title={
        <>
          <CalendarFilled
            style={{
              backgroundColor: "#FFFFFF",
              color: "#0056B4",
              fontSize: "16px",
            }}
          />
          {"          "}
          <b
            style={{
              fontSize: "16px",
            }}
          >
            To do lists
          </b>
        </>
      }
    >
      {dataSourceTodoList ? (
        dataSourceTodoList.map((todoList: any, index: any) => {
          return (
            <>
              <a
                style={{ color: "black" }}
                onClick={() => toDebtNoContact(todoList.status_code_id, todoList.action_code_id, dayjs(todoList.create_date).format("YYYY-MM-DD"))}
              >
                <Row>
                  <Col style={columnStyle} span={8}>
                    {todoList.status_code_id}
                  </Col>
                  <Col style={columnStyle} span={16}>
                    {/* <a
                  style={{ color: "black" }}
                  onClick={() =>
                    toDebtNoContact(
                      todoList.status_code_id,
                      todoList.action_code_id,
                      dayjs(todoList.create_date).format("YYYY-MM-DD"),
                    )
                  }
                > */}
                    <NumberFormat value={todoList.countac} displayType={"text"} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} />
                    {` A/C`}
                  </Col>
                </Row>
              </a>
            </>
          );
        })
      ) : (
        <></>
      )}
    </Card>
  );
};
