import React, { useState } from "react";
import { Row, Space, Col, Typography, Button, Select, Input, AutoComplete } from "antd";
import { IUser } from "../../oaProfile/common";

const { Option } = Select;
interface Props {
  proFile: IUser;
  onShearch: (type: string, text: string) => void;
  syncData: () => void;
  roles: string[];
  shearchFeachData: (type: string, text: string) => void;
  subOrganize: any[];
}

export const FilterBankUser: React.FC<Props> = ({ proFile, onShearch, syncData, roles, shearchFeachData, subOrganize }) => {
  const [fillType, setFillType] = useState("");
  const [fillText, setFillText] = useState("");

  const changeTypeFilter = (type: string) => {
    setFillType(type);
  };

  const checkAccessRole = (role: string) => {
    var result = roles.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };

  const checkFilter = (fillType: string, fillText: string) => {
    if (fillType === "roles") {
      const mapGroup = subOrganize?.filter((x: any, index: number) => fillText === x.name);
      if (mapGroup.length > 0) {
        shearchFeachData(fillType, mapGroup[0].id);
      } else {
        shearchFeachData(fillType, fillText);
      }
    } else {
      shearchFeachData(fillType, fillText);
    }
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            {`BANK USER`}
          </Typography.Title>
        </Col>
        <Col span={16}>
          <div style={{ textAlign: "right" }}>
            <Space size={"small"}>
              <Select
                allowClear
                placeholder="Filter"
                style={{ width: 120 }}
                bordered={false}
                onChange={(event: any) => {
                  changeTypeFilter(event);
                }}
              >
                <Option value="id">ID</Option>
                <Option value="name">Name</Option>
                <Option value="email">Email</Option>
                <Option value="roles">Role</Option>
                <Option value="phone">Phone Number</Option>
              </Select>
              <AutoComplete>
                <Input.Search
                  size="middle"
                  placeholder="input search text"
                  onSelect={(event: any) => {
                    setFillText(event.target.value);
                  }}
                  onSearch={() => {
                    checkFilter(fillType, fillText);
                  }}
                />
              </AutoComplete>
              {checkAccessRole("ddebtSettingSyncUserBank") ? (
                <Button type="primary" onClick={syncData}>
                  Sync
                </Button>
              ) : (
                <></>
              )}
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};
