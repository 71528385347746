import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/lib/table";
import { Card, Button, Select, Modal, Table, Radio, Collapse, Form, Row, message, Spin } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { LeadManagementService } from "../services/LeadManagementService";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
import { TDataLead } from "./OverviewLeadManagement";
const appConfig = loadAppConfig();

interface DataType {
  key: React.Key;
  list: string;
  team: any;
  oa: string;
  account_old: string;
  account_new: string;
  account_total: string;
  outstanding_old: string;
  outstanding_new: string;
  outstanding_total: string;
}

interface IProject {}

export interface Props {
  oaSelect: any;
  conditionAssign: any;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  onclickHoldAssignData: (condition: any) => void;
  onclickReCallAssignData: (condition: any) => void;
  onclickAssignOaData: (condition: any) => void;
  userprofile: any;
}

export const LeadManagementAdd: React.FC<Props> = ({
  oaSelect,
  conditionAssign,
  loading,
  setLoading,
  onclickHoldAssignData,
  onclickReCallAssignData,
  onclickAssignOaData,
  userprofile,
}) => {
  const { id24Axios } = useId24();
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [showhold, setShowHold] = useState(true);
  const [showcall, setShowCall] = useState(true);
  const [showcardteam, setShowCardTeam] = useState(true);
  const [showcardoa, setShowCardOA] = useState(true);
  const [panelShow, setPanelShow] = useState(false);
  const [panelAction, setPanelAction] = useState("1");
  const [OnHold, setOnHold] = useState("");
  const [OnRecall, setOnCall] = useState("");
  const [OnOa, setOnOa] = useState("");
  const [oaValue, setOaValue] = useState<Number>(0);
  const { Panel } = Collapse;
  const [form] = Form.useForm();

  useEffect(() => {}, [userprofile]);

  const onClickHoldAssign = async () => {
    if (conditionAssign.length > 0 || conditionAssign != "") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อ Assign",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const resDataLead = await LeadManagementService(apiBaseUrlDebt).getDataForSearch(conditionAssign);
          const condition = {
            condition: {
              id: {
                in: await resDataLead.map((x: TDataLead) => {
                  return +x.id;
                }),
              },
            },
          };
          onclickHoldAssignData(condition);
        },
      });
    } else {
      message.warning("กรุณาเลือกบัญชีลูกหนี้");
    }
  };
  const onClickReCallAssign = async () => {
    console.log("onClickReCallAssign");
    if (conditionAssign.length > 0 || conditionAssign != "") {
      Modal.confirm({
        title: "คุณแน่ใจแล้วใช่ไหม",
        icon: <ExclamationCircleOutlined />,
        content: "กดยืนยันเพื่อ Assign",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk: async () => {
          const resDataLead = await LeadManagementService(apiBaseUrlDebt).getDataForSearch(conditionAssign);
          const condition = {
            UserId: String(userprofile?.empId),
            condition: {
              id: {
                in: await resDataLead.map((x: TDataLead) => {
                  return +x.id;
                }),
              },
            },
          };
          onclickReCallAssignData(condition);
        },
      });
    } else {
      message.warning("กรุณาเลือกบัญชีลูกหนี้");
    }
  };

  const onClickOaAssign = async () => {
    setLoading(true);
    if (conditionAssign.length > 0 || conditionAssign != "") {
      if (oaValue) {
        Modal.confirm({
          title: "คุณแน่ใจแล้วใช่ไหม",
          icon: <ExclamationCircleOutlined />,
          content: "กดยืนยันเพื่อ Assign",
          okText: "ยืนยัน",
          cancelText: "ยกเลิก",
          onOk: async () => {
            const resDataLead = await LeadManagementService(apiBaseUrlDebt).getDataForSearch(conditionAssign);
            const condition = {
              condition: {
                id: {
                  in: await resDataLead.map((x: TDataLead) => {
                    return +x.id;
                  }),
                },
              },
              adminOaId: +oaValue,
            };
            onclickAssignOaData(condition);
          },
        });
      } else {
        message.warning("กรุณาเลือก OA");
      }
    } else {
      message.warning("กรุณาเลือกบัญชีลูกหนี้");
    }
    setLoading(false);
  };

  const { Option } = Select;

  const Team: React.ReactNode[] = [];
  for (let i = 1; i < 10; i++) {
    Team.push(
      <Option key={"TeamKey" + i} value={"Team" + i}>
        {"Team" + i}
      </Option>,
    );
  }

  const selectOa = async (value: number) => {
    setOaValue(value);
  };

  const genExtra = () => (
    <>
      {panelShow === false ? (
        <PlusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(true);
            setPanelAction("1");
          }}
        />
      ) : (
        <MinusSquareOutlined
          style={{
            color: "#1890FF",
            fontSize: "150%",
            backgroundColor: "#E6F7FF",
          }}
          onClick={() => {
            setPanelShow(false);
            setPanelAction("0");
          }}
        />
      )}
    </>
  );

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <Form form={form}>
          {" "}
          <Collapse>
            <Panel
              forceRender={true}
              extra={genExtra()}
              showArrow={false}
              header="Assign To"
              key="1"
              style={{
                backgroundColor: "white",
                width: "100%",
                textAlign: "left",
              }}
            >
              <Radio.Group>
                <Form.Item>
                  <Radio
                    value="On Hold"
                    onClick={() => {
                      setShowHold(false), setShowCall(true), setShowCardTeam(true), setShowCardOA(true), setOnHold("On Hold");
                    }}
                  >
                    Hold
                  </Radio>

                  <Radio
                    value="Re-Call"
                    onClick={() => {
                      setShowCall(false), setShowHold(true), setShowCardTeam(true), setShowCardOA(true), setOnCall("Re-Call");
                    }}
                  >
                    Re-Call
                  </Radio>

                  <Radio
                    value="OA"
                    onClick={() => {
                      setShowCardTeam(true), setShowCardOA(false), setShowHold(true), setShowCall(true), setOnOa("OA");
                    }}
                  >
                    OA
                  </Radio>
                </Form.Item>
              </Radio.Group>
              <Row>
                <Button hidden={showhold} htmlType="submit" type="primary" onClick={onClickHoldAssign}>
                  Assign
                </Button>
              </Row>
              <Row>
                <Button hidden={showcall} htmlType="submit" type="primary" onClick={onClickReCallAssign}>
                  Assign
                </Button>
              </Row>
            </Panel>
          </Collapse>
        </Form>
        <Card title="OA List :" bordered={false} hidden={showcardoa} style={{ width: "100%", textAlign: "left", marginTop: "10px" }}>
          <Form form={form}>
            <Form.Item>
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                style={{ width: "100%" }}
                placeholder="Please Select"
                onChange={selectOa}
              >
                {oaSelect.map((oa: any) => {
                  return (
                    <Option key={oa.oa_code} value={oa.admin_user_id}>
                      {oa.oa_name}
                    </Option>
                  );
                })}
              </Select>
              <Button
                type="primary"
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
                onClick={onClickOaAssign}
              >
                Assign
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </>
  );
};
