import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
// import { Icon } from "@iconify/react";
import { Card, Row, Typography } from "antd";
import { BellFilled, DashboardOutlined } from "@ant-design/icons";
import { UserRole } from "../../common-auth/models/user-role";
import { createAuth24TokenReader } from "../../common-auth/utils/auth24-token-reader";
import { useAuth24 } from "../../common-auth/utils/use-auth24";
import { DashboardManagerBank } from "./DashboardNotiBankmanager";
import { DashboardManagerOa } from "./DashboardNotiOamanager";
import { DashboardManager } from "./DashboardNotiManager";
import { DashboardManagerCollector } from "./DashboardNotiCollector";
import { useId24 } from "../../drivers/id24/Id24-provider";

interface DashboardMenu {
  label: string;
  path: string;
  target: string;
  roles: UserRole[];
  component: React.ReactElement;
}
interface Props {
  baseUrl: string;
}
export const OverviewNotiDashboard: React.FC<Props> = ({ baseUrl }) => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const [roles, setRoles] = useState([]);
  // const hasRole = (userRoles: UserRole[]): boolean => {
  //   const inputRoles = new Set(userRoles);
  //   const groupNameList: any[] = [];
  //   if(tokenAccess){
  //     tokenAccess.userAccess.map((group: any) => {
  //       groupNameList.push(group.groupName);
  //     });
  //   }
  //   const union = new Set(groupNameList.filter((role) => inputRoles.has(role)));
  //   return union.size > 0;
  // };
  const hasRole = (userRoles: UserRole[]): boolean => {
    const inputRoles = new Set(userRoles);
    const union = new Set(roles.filter((role) => inputRoles.has(role)));
    return union.size > 0;
  };
  // const [loading, setLoading] = useState(false);
  const { Title } = Typography;
  const { auth24 } = useAuth24();
  const reader = createAuth24TokenReader(auth24);

  //Update
  const menuItems: DashboardMenu[] = [
    {
      label: "DashboardManagerbank",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardBank],
      component: <DashboardManagerBank baseUrl={"/dashboard"} />,
    },
    {
      label: "DashboardManagerOa",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardOaManager],
      component: <DashboardManagerOa baseUrl={"/dashboard"} />,
    },
    {
      label: "DashboardManager",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardOaSupervisor],
      component: <DashboardManager baseUrl={"/dashboard"} />,
    },
    {
      label: "DashboardManager",
      icon: <DashboardOutlined />,
      path: "/dashboard",
      showInMenu: true,
      target: "/dashboard",
      roles: [UserRole.ddebtNotificationDashboardOaUser],
      component: <DashboardManagerCollector baseUrl={"/dashboard"} />,
    },
  ].filter((menu) => hasRole(menu.roles));
  //Update

  //Befor
  // const menuItems: DashboardMenu[] = [
  //   {
  //     label: "DashboardManagerbank",
  //     icon: <DashboardOutlined />,
  //     path: "/dashboard",
  //     showInMenu: true,
  //     target: "/dashboard",
  //     roles: [
  //       UserRole.authSuperAdmin,
  //       UserRole.authBankManager,
  //       UserRole.authBankSupervisor,
  //       UserRole.authBankUser,
  //       // UserRole.authBankAudit,
  //       // UserRole.authBankReporter,
  //       // UserRole.authOaCollector,
  //       // UserRole.organizationAdmin,
  //     ],
  //     component: <DashboardManagerBank baseUrl={"/dashboard"} />,
  //   },
  //   {
  //     label: "DashboardManagerOa",
  //     icon: <DashboardOutlined />,
  //     path: "/dashboard",
  //     showInMenu: true,
  //     target: "/dashboard",
  //     roles: [
  //       UserRole.authSuperAdmin,
  //       // UserRole.authBankManager,
  //       // UserRole.authBankSupervisor,
  //       UserRole.authOaManager,
  //       // UserRole.authOaSupervisor,
  //       // UserRole.authOaCollector,
  //       // UserRole.authBankUser,
  //       // UserRole.authBankAudit,
  //       // UserRole.authBankReporter,
  //       // UserRole.authOaCollector,
  //       // UserRole.organizationAdmin,
  //     ],
  //     component: <DashboardManagerOa baseUrl={"/dashboard"} />,
  //   },
  //   {
  //     label: "DashboardManager",
  //     icon: <DashboardOutlined />,
  //     path: "/dashboard",
  //     showInMenu: true,
  //     target: "/dashboard",
  //     roles: [
  //       UserRole.authSuperAdmin,
  //       // UserRole.authBankManager,
  //       // UserRole.authBankSupervisor,
  //       // UserRole.authBankUser,
  //       // UserRole.authBankAudit,
  //       // UserRole.authBankReporter,
  //       // UserRole.authOaCollector,
  //       // UserRole.authOaManager,
  //       UserRole.authOaSupervisor,
  //       // UserRole.organizationAdmin,
  //     ],
  //     component: <DashboardManager baseUrl={"/dashboard"} />,
  //   },
  //   {
  //     label: "DashboardManager",
  //     icon: <DashboardOutlined />,
  //     path: "/dashboard",
  //     showInMenu: true,
  //     target: "/dashboard",
  //     roles: [
  //       UserRole.authSuperAdmin,
  //       // UserRole.authBankManager,
  //       // UserRole.authBankSupervisor,
  //       // UserRole.authBankUser,
  //       // UserRole.authBankAudit,
  //       // UserRole.authBankReporter,
  //       UserRole.authOaCollector,
  //       // UserRole.organizationAdmin,
  //     ],
  //     component: <DashboardManagerCollector baseUrl={"/dashboard"} />,
  //   },
  // ].filter((menu) => hasRole(menu.roles));
  //Befor

  useEffect(() => {
    (async () => {
      // Update
      let datacon: any = [];
      if (tokenAccess) {
        tokenAccess.userAccess.map((groupId) => {
          groupId.roles.forEach(function (value, i) {
            datacon.push(value);
          });
        });
      }
      const uniqueNames = datacon.filter((val: any, id: any, array: any) => {
        return array.indexOf(val) == id;
      });
      setRoles(uniqueNames);
      // Update
    })();
  }, []);

  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 18 },
          { xs: 8, sm: 18 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#FF9500", fontSize: "24px" }}>
            <BellFilled /> {`NOTIFICATION`}
          </b>
        </Card>
      </Row>
      <br></br>
      {menuItems?.map((menuItem, index) => (
        <Route key={index} exact path={`${baseUrl}`}>
          {menuItem.component}
          <br></br>
        </Route>
      ))}
    </>
  );
};
