import React from "react";
import { Card, Row } from "antd";
import { Switch, Route } from "react-router-dom";
import { DataManageMentMain } from "./components/DataManageMentMain";
import { AddConditions } from "./components/AddCondition";
import { DataManagementDetailOa } from "./components/DataManagementDetailOa";
import { DatabaseFilled } from "@ant-design/icons";

interface Props {
  baseUrl: string;
}
export const DataManageMentRouteOa: React.FC<Props> = ({ baseUrl }): React.ReactElement => {
  return (
    <>
      <Row
        gutter={[
          { xs: 8, sm: 16 },
          { xs: 8, sm: 16 },
        ]}
      >
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <DatabaseFilled /> DATA MANAGEMENT
          </b>
        </Card>
      </Row>

      <Switch>
        <Route path={`${baseUrl}/editcondition`}>
          <DataManagementDetailOa baseUrl={`${baseUrl}/editcondition`} />
        </Route>
        <Route path={`${baseUrl}/addcondition`}>
          <AddConditions baseUrl={`${baseUrl}/addcondition`} />
        </Route>

        <Route path={`${baseUrl}`}>
          <DataManageMentMain baseUrl={`${baseUrl}`} />
        </Route>
      </Switch>
    </>
  );
};
