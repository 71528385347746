import { Col, Table } from "antd";
import React from "react";
import NumberFormat from "react-number-format";
import { Consditions } from "../models/models-datamanagement";
import { numberWithCommasNodigit } from "../../../utils";
interface Props {
  dataUserSimulate: TDetailSimulate[];
}
interface TDetailSimulate {
  key: string;
  afterCap: number;
  beforeBal: number;
  beforeCap: number;
  decreasedCap: number;
  listName: string;
  sumBal: number;
  teamId: string;
  teamName: string;
  totalCapability: number;
  amt: number;
  cnt: number;
  oa_name: string;
  list_name: string;
  team_name: string;
  tmp_user_id: string;
  list_name_seq: string;
  sumAccountOld: number;
  resultSumBalOld: number;
  resultSumBalNew: number;
}

export const DatamanagementSimulate: React.FC<Props> = ({ dataUserSimulate }): React.ReactElement => {
  const columns: any = [
    {
      title: "List name",
      dataIndex: "list_name",
    },
    {
      title: "Team name",
      dataIndex: "team_name",
    },

    {
      title: "OA",
      dataIndex: "oa_name",
    },
    {
      title: "Account (old)",
      dataIndex: "resultSum",
      align: "right",
      render: (_: string, data: TDetailSimulate) => {
        return <Col>{numberWithCommasNodigit(Number(data.sumAccountOld))}</Col>;
      },
    },
    {
      title: "Account (new)",
      dataIndex: "cnt",
      align: "right",
      render: (value: string, data: TDetailSimulate) => {
        return <Col>{numberWithCommasNodigit(Number(value))}</Col>;
      },
    },
    {
      title: "Account (total)",
      dataIndex: "listname",
      align: "right",
      render: (_: string, data: TDetailSimulate) => {
        return <Col>{numberWithCommasNodigit(Number(data.sumAccountOld) + Number(data.decreasedCap))}</Col>;
      },
    },
    {
      title: "Outstanding (total)",
      dataIndex: "listname",
      align: "right",
      render: (_: string, data: TDetailSimulate) => {
        return (
          <NumberFormat
            value={Number(data.resultSumBalNew) + Number(data.resultSumBalOld)}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        );
      },
    },
  ];
  return (
    <>
      <Table size="small" columns={columns} dataSource={dataUserSimulate} rowKey={"key"} bordered />
    </>
  );
};
