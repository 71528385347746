import React, { useState } from "react";
import { Row, Space, Col, Typography, Select, Input, AutoComplete, Form, Button } from "antd";
import { loadAppConfig } from "../../../config/app-config";
import { IUser } from "../common";

const { Option } = Select;
interface Props {
  proFile: IUser;
  onSearch: (type: string, text: string) => void;
  syncData: () => void;
  accessRole: string;
}

export const FilterOaProfile: React.FC<Props> = ({ proFile, onSearch, syncData, accessRole }) => {
  const [fillType, setFillType] = useState("none");
  const [fillText, setFillText] = useState("");

  const checkAccessRole = (role: string) => {
    var result = accessRole.indexOf(role) > -1;
    if (result == true) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={{ textAlign: "left" }}>
            OA Profile
          </Typography.Title>
        </Col>
        <Col span={16}>
          <div style={{ textAlign: "right" }}>
            <Space size={"small"}>
              <Select
                allowClear
                placeholder="Filter"
                style={{ width: 120 }}
                bordered={false}
                onChange={(event: any) => {
                  setFillType(event);
                }}
              >
                <Option value="oa_name">OA Name</Option>
                <Option value="email">Email</Option>
                <Option value="contact_phone">Phone</Option>
                <Option value="contact_name">Contract Name</Option>
                <Option value="contract_no">เลขที่สัญญา</Option>
              </Select>
              <AutoComplete>
                <Input.Search
                  size="middle"
                  placeholder="input search text"
                  onSelect={(event: any) => {
                    setFillText(event.target.value);
                  }}
                  onSearch={() => {
                    onSearch(fillType, fillText);
                  }}
                />
              </AutoComplete>
              {checkAccessRole("ddebtSettingSyncOaProfile") ? (
                <Button type="primary" onClick={syncData}>
                  Sync
                </Button>
              ) : (
                <></>
              )}
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};
