import { Row, Card, Button } from "antd";
import React from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import OverviewEmailTemplate from "./component/OverviewEmailTemplate";
import EditEmailTemplate from "./component/EditEmailTemplate";

import {
  ProfileFilled,
  PlayCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
interface Props {
  baseUrl: string;
  userId: string;
}

const EmailRoute: React.FC<Props> = ({
  baseUrl,
  userId,
}): React.ReactElement => {
  let history = useHistory();
  const { path } = useRouteMatch();
  return (
    <>
      <Row>
        <Card style={{ width: "100%", textAlign: "left" }}>
          {/* <Button
            style={{ border: "none", borderRadius: "100px" }}
            
          > */}

          {/* </Button> */}
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <MailOutlined style={{ width: 36, height: 36, color: "#0056B4" }} />
            Email Template
          </b>
        </Card>
      </Row>
      <Row>
        <div style={{ width: "100%" }}>
          <Switch>
            <Route path={`${baseUrl}/editemailtemplate/:name`}>
              <EditEmailTemplate baseUrl={baseUrl} userId={userId} />
            </Route>
            <Route path={`${baseUrl}`}>
              <OverviewEmailTemplate baseUrl={baseUrl} />
            </Route>
          </Switch>
        </div>
      </Row>
    </>
  );
};

export default EmailRoute;
