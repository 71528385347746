import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";
import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { Card, Form, Row, Input, Radio, Table, Select, Col, Button, InputNumber, Typography, Modal, message, TreeSelect } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { DataType, ICenter, IuserProfile, ProductType, ValueInDetail } from "../../datamanagement/models/models-datamanagement";
import { DatamanagementServiceOa } from "../services/datamanagementoa";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { loadAppConfig } from "../../../config/app-config";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { IMasterDataOfBranch, Role } from "../../datamanagement/common";
import { UserAccess } from "../../../drivers/id24/models/user-access";
dayjs.extend(localizedFormat);
const appConfig = loadAppConfig();
interface Prop {
  baseUrl: string;
}

interface typeTeam {
  id: string;
  name: string;
}

const { Option } = Select;
export const AddConditions: React.FC<Prop> = ({ baseUrl }): React.ReactElement => {
  const { push } = useHistory();
  const { tokenAccess, login, logout, id24Axios } = useId24();
  const apiBaseUrl = id24Axios(appConfig.apiBaseUrl);
  const apiBaseUrlDebt = id24Axios(appConfig.apiBaseUrlDebt);
  const [userProfile, setUserprofile] = useState<IuserProfile>({
    id: 0,
    level: "",
    name: "",
    oaCode: "",
    parentId: "",
    team: "",
    token: "",
    type: "",
    capabilities: {},
  });
  const createDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const refConditionListName = useRef<any>(null);
  const refValueLocation = useRef<any>(null);
  const refDescription = useRef<any>(null);
  const refValueLoanType = useRef<any>(null);
  const refValueAccountStatus = useRef<any>(null);
  const refValueConditionStmtAmt = useRef<any>(null);
  const refValueAccountLevel = useRef<any>(null);
  const refValueConditionAmount = useRef<any>(null);
  const refValueTeam = useRef<any>(null);
  const refValuePrincipalAmount = useRef<any>(null);
  const refValueConditionCurBal = useRef<any>(null);

  const [getConditionListName, setConditionListName] = useState<string>("");
  const [getDescription, setDescription] = useState<string>("");

  const onChangConditionLisName = (e: any) => {
    setConditionListName(e.target.value);
  };

  const onChangDescription = (e: any) => {
    setDescription(e.target.value);
  };
  //DataSubmit
  const [getDataLoanTypeSubmit, setDataLoanTypeSubmit] = useState<any>([]);
  const [getDataAccountStatusSubmit, setDataAccountStatusSubmit] = useState<any>([]);
  //Select consitions
  const [getConditionLoantype, setConditionLoantype] = useState<string>("");
  const [getConditionAccountStatus, setConditionAccountStatus] = useState<string>("");
  const [getConditionProductType, setConditionProductType] = useState<string>("");
  const [getConditionAccountLevel, setConditionAccountLevel] = useState<string>("");
  const [getDataSeq, setDataSeq] = useState<any>();
  const [getDataAmountSubmit, setDataAmountSubmit] = useState<string>("");
  const [getConditionAmount, setConditionAmount] = useState<string>("select");
  const [getConditionLocation, setConditionLocation] = useState<string>("");
  const [getcheckValueProductType, setcheckValueProductType] = useState<any>([]);
  const [getDataPrincipalAmount, setDataPrincipalAmount] = useState<string>("");
  const [getDataLocationSubmit, setDataLocationSubmit] = useState<string[]>([]);
  const [getcheckValueAccountLevel, setcheckValueAccountLevel] = useState<any>([]);
  const [getDataAccountLevelSubmit, setDataAccountLevelSubmit] = useState<any>([]);
  const [getcheckValueBillingCycle, setcheckValueBillingCycle] = useState<any>([]);
  const [getDataBillingCycleSubmit, setDataBillingCycleSubmit] = useState<any>([]);
  const [getDataProductTypeSubmit, setDataProductTypeSubmit] = useState<ValueInDetail[]>([]);
  const [getDetailProductLoan, setDetailProductLoan] = useState<ValueInDetail[]>([]);
  const [getDetailProductCard, setDetailProductCard] = useState<ValueInDetail[]>([]);
  const [getDetailProductOD, setDetailProductOD] = useState<ValueInDetail[]>([]);
  const [getValueLoanType, setValueLoanType] = useState<any>([]);
  const [getValueAccountStatus, setValueAccountStatus] = useState<any>([]);
  const [getValueProductType, setValueProductType] = useState<ProductType[]>([]);
  const [getconditionPrincipalAmount, setconditionPrincipalAmount] = useState<string>("select");
  const [getConditionStmtAmt, setConditionStmtAmt] = useState<string>("select");
  const [getDataStmtAmtSubmit, setDataStmtAmtSubmit] = useState<string>("");
  const [getConditionCurBal, setConditionCurBal] = useState<string>("select");
  const [getDataCurBalSubmit, setDataCurBalSubmit] = useState<string>("");
  const [getUserList, setUserList] = useState<any[]>([]);
  const [getValueUserList, setValueUserList] = useState<string>("");
  const [getValueAccountLevel, setValueAccountLevel] = useState<any>([]);
  const [getValueBillingCycle, setValueBillingCycle] = useState<any>([]);
  const [getValueLocation, setValueLocation] = useState<ICenter[]>([]);
  const [getValueAmount, setValueAmount] = useState<any>([]);
  const [getTeam, setTeam] = useState<typeTeam[]>([]);
  const [getLocation, setLocation] = useState<ICenter[]>([]);
  const [getResualDatamanagementDetail, setResualDatamanagementDetail] = useState<any>();
  const [getFristBetweenPrincipalAmount, setFristBetweenPrincipalAmount] = useState<string>("");
  const [getEndBetweenPrincipalAmount, setEndBetweenPrincipalAmount] = useState<string>("");
  const [getFristBetweenOverduleAmount, setFristBetweenOverduleAmount] = useState<string>("");
  const [getEndBetweenOverduleAmount, setEndBetweenOverduleAmount] = useState<string>("");
  const [getFristBetweenCurBal, setFristBetweenCurBal] = useState<string>("");
  const [getEndBetweenCurBal, setEndBetweenCurBal] = useState<string>("");
  const [getFristBetweenStmtAmt, setFristBetweenStmtAmt] = useState<string>("");
  const [getEndBetweenStmtAmt, setEndBetweenStmtAmt] = useState<string>("");
  const [permissionId24, setPermissionId24] = useState<any>([]);
  const [AssignUserNew, setAssignUserNew] = useState<string>("");
  const [CleardistNew, setCleardistNew] = useState<string | number>();

  const multipleInArray = (arr: string[], values: Role[]) => {
    return values.some((value) => {
      return arr.includes(value);
    });
  };

  useEffect(() => {
    try {
      mapRoleAccess().then(async (resultmap) => {
        getUserProfile().then(async (resualProfile) => {
          setUserprofile(resualProfile);
          setPermissionId24(resultmap);

          const userAssign = () => {
            let AssignUserNew;
            multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
              ? (AssignUserNew = "AssignUser")
              : (AssignUserNew = `User:${resualProfile.id}:AssignUser`);
            fetchDataManagementByid(AssignUserNew, resualProfile.id);
            setAssignUserNew(AssignUserNew);
            return AssignUserNew;
          };
          const userCleardist = () => {
            let AssignUserNew;
            multipleInArray(resultmap, ["bank_manager", "bank_supervisor"])
              ? (AssignUserNew = 0)
              : (AssignUserNew = `User:${resualProfile.id}:AssignUser`);
            setCleardistNew(AssignUserNew);
            return AssignUserNew;
          };
          userAssign();
          userCleardist();
        });
      });

      getUserInGroup();
      getProductCode();
      setValueLoanType(data[0].detail);
      setValueAccountStatus(data[1].detail);
      setValueProductType(data[2].detail);
      setValueAccountLevel(data[3].detail);
      setValueLocation(data[4].detail);
      setValueAmount(data[5].detail);
      setValueBillingCycle(data[6].detail);
    } catch {}
    getCenter();
    MasterDataCenterOfBranch();
  }, []);
  const [getDataMasterDataCenterOfBranch, setDataMasterDataCenterOfBranch] = useState<IMasterDataOfBranch[]>([]);
  const MasterDataCenterOfBranch = async () => {
    const dataMasterdataResult = await DatamanagementServiceOa(apiBaseUrlDebt).masterDataCenterBranch();
    setDataMasterDataCenterOfBranch(dataMasterdataResult);
  };

  const mapRoleAccess = () => {
    return new Promise<any>(async (resolve, reject) => {
      const result = tokenAccess?.userAccess.map((group: UserAccess) => group.groupName);
      resolve(result);
    });
  };

  const getUserProfile = async () => {
    return new Promise<IuserProfile>(async (resolve, reject) => {
      const resualProfile = await DatamanagementServiceOa(apiBaseUrlDebt).getUserDetail();
      setUserprofile(resualProfile);
      resolve(resualProfile);
    });
  };

  const getCenter = async () => {
    const resual = await DatamanagementServiceOa(apiBaseUrlDebt).getCenterList();
    setLocation(resual);
  };
  const onChangeValueLoanType = (e: any) => {
    setDataLoanTypeSubmit(e);
    checkLoanTypeInput(e[0], e[1], e[2]);
  };

  const onChangAccount = (e: any) => {
    setDataAccountStatusSubmit(e);
    checkProductTypeInput(e[0], e[1], e[2]);
  };

  const onClickProductType = (e: any) => {
    if (getDataLoanTypeSubmit.length === 0) {
      refValueLoanType.current.focus();
      return false;
    }
  };
  const onClickAccountLevel = (e: any) => {
    if (getDataAccountStatusSubmit.length === 0) {
      refValueAccountStatus.current.focus();
      return false;
    }
  };

  const checkCondition = (text: string) => {
    if (text === "8") {
      if (getConditionAmount === "select") {
        refValueConditionAmount.current.focus();
        message.error("โปรดเลือก Condition ของ เงินงวดค้างชำระ");
        return;
      }
    } else if (text === "9") {
      if (getConditionStmtAmt === "select") {
        refValueConditionStmtAmt.current.focus();
        message.error("โปรดเลือก Condition ของ ยอดเรียกเก็บตาม statement");
        return;
      }
    } else if (text === "10") {
      if (getConditionCurBal === "select") {
        refValueConditionCurBal.current.focus();
        message.error("โปรดเลือก Condition ของ จำนวนการใช้เงินของบัตร ณ ปัจจุบัน");
        return;
      }
    }
  };

  //functio select multi loan type
  const checkLoanTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          setDataProductTypeSubmit(getValueProductType[0].value);
          break;
        case "Card Link":
          setDataProductTypeSubmit(getValueProductType[1].value);
          setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
          break;
        case "OD":
          setDataProductTypeSubmit(getValueProductType[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link": {
              let valueListin: ValueInDetail[] = getValueProductType[0].value;
              getValueProductType[1].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setDataProductTypeSubmit(valueListin);
              setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
              break;
            }
            case "OD": {
              let valueListin: ValueInDetail[] = getValueProductType[0].value;
              getValueProductType[2].value.map((x: any) => {
                valueListin = [...valueListin, x];
              });
              setDataProductTypeSubmit(valueListin);
              break;
            }
          }
          break;
        case "Card Link":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin: any = getValueProductType[1].value;
                getValueProductType[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setDataProductTypeSubmit(valueListin);
                setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                break;
              }
              case "OD": {
                let valueListin = getValueProductType[1].value;
                getValueProductType[2].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setDataProductTypeSubmit(valueListin);
                setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                break;
              }
            }
          }
          break;
        case "OD":
          {
            switch (valueTwo) {
              case "LOAN": {
                let valueListin = getValueProductType[2].value;
                getValueProductType[0].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });

                setDataProductTypeSubmit(valueListin);
                break;
              }
              case "Card Link": {
                let valueListin = getValueProductType[2].value;
                getValueProductType[1].value.map((x: any) => {
                  valueListin = [...valueListin, x];
                });
                setDataProductTypeSubmit(valueListin);
                setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                break;
              }
            }
          }
          break;
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "LOAN":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = getValueProductType[0].value;
                    getValueProductType[1].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = getValueProductType[0].value;
                    getValueProductType[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                  }
                  break;
              }
              break;
          }
          break;
        case "Card Link":
          switch (valueTwo) {
            case "LOAN":
              switch (valueThree) {
                case "OD":
                  {
                    let valueListin = getValueProductType[1].value;
                    getValueProductType[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[2].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                  }
                  break;
              }
              break;
            case "OD":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = getValueProductType[1].value;
                    getValueProductType[2].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[0].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                  }
                  break;
              }
              break;
          }
          break;
        case "OD":
          switch (valueTwo) {
            case "Card Link":
              switch (valueThree) {
                case "LOAN":
                  {
                    let valueListin = getValueProductType[2].value;
                    getValueProductType[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                  }
                  break;
              }
              break;
            case "LOAN":
              switch (valueThree) {
                case "Card Link":
                  {
                    let valueListin = getValueProductType[2].value;
                    getValueProductType[0].value.map((x: any) => {
                      valueListin = [...valueListin, x];
                    });
                    getValueProductType[1].value.map((y: any) => {
                      valueListin = [...valueListin, y];
                    });
                    setDataProductTypeSubmit(valueListin);
                    setDataBillingCycleSubmit(getValueBillingCycle[0]?.value);
                  }
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setcheckValueBillingCycle([]);
      setDataBillingCycleSubmit([]);
      setDataProductTypeSubmit([]);
      setcheckValueProductType([]);
    }
  };

  // functio select multi account type
  const checkProductTypeInput = (valueOne: string, valueTwo: string, valueThree: string) => {
    if (valueOne && !valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          setDataAccountLevelSubmit(getValueAccountLevel[0].value);
          break;
        case "Npls":
          setDataAccountLevelSubmit(getValueAccountLevel[1].value);
          break;
        case "W/O":
          setDataAccountLevelSubmit(getValueAccountLevel[2].value);
          break;
      }
    } else if (valueOne && valueTwo && !valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
              getValueAccountLevel[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
            case "W/O": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
              getValueAccountLevel[2].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
          }
          break;
        case "Npls":
          {
            switch (valueTwo) {
              case "Non-Npls": {
                let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                getValueAccountLevel[0].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setDataAccountLevelSubmit(valueDataAccountLevel);
                break;
              }
              case "W/O": {
                let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                getValueAccountLevel[2].value.map((x: any) => {
                  valueDataAccountLevel = [...valueDataAccountLevel, x];
                });
                setDataAccountLevelSubmit(valueDataAccountLevel);
                break;
              }
            }
          }
          break;
        case "W/O": {
          switch (valueTwo) {
            case "Non-Npls": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
              getValueAccountLevel[0].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
            case "Npls": {
              let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
              getValueAccountLevel[1].value.map((x: any) => {
                valueDataAccountLevel = [...valueDataAccountLevel, x];
              });
              setDataAccountLevelSubmit(valueDataAccountLevel);
              break;
            }
          }
        }
      }
    } else if (valueOne && valueTwo && valueThree) {
      switch (valueOne) {
        case "Non-Npls":
          switch (valueTwo) {
            case "Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
                  getValueAccountLevel[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[0].value;
                  getValueAccountLevel[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "Npls":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "W/O":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                  getValueAccountLevel[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[2].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
            case "W/O":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[1].value;
                  getValueAccountLevel[2].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
        case "W/O":
          switch (valueTwo) {
            case "Non-Npls":
              switch (valueThree) {
                case "Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
                  getValueAccountLevel[0].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[1].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
            case "Npls":
              switch (valueThree) {
                case "Non-Npls":
                  let valueDataAccountLevel: any[] = getValueAccountLevel[2].value;
                  getValueAccountLevel[1].value.map((x: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, x];
                  });
                  getValueAccountLevel[0].value.map((y: any) => {
                    valueDataAccountLevel = [...valueDataAccountLevel, y];
                  });
                  setDataAccountLevelSubmit(valueDataAccountLevel);
                  break;
              }
              break;
          }
          break;
      }
    } else {
      setDataAccountLevelSubmit([]);
      setcheckValueAccountLevel([]);
    }
  };

  const onChangeUser = (e: string) => {
    setValueUserList(e);
  };

  const fetchDataManagementByid = async (data: any, profileid: any) => {
    const resual = await DatamanagementServiceOa(apiBaseUrl).getDraftDataManagementByid(data);
    const resualTeam = await DatamanagementServiceOa(apiBaseUrlDebt).getTeambyuser(profileid);
    setTeam(resualTeam);
    let max = 0;
    for (let i = 0; i < resual?.detail.length; i++) {
      if (resual?.detail[i].seq > max) {
        max = resual?.detail[i].seq;
      }
    }
    setDataSeq(max);
    // setDataSeq(resual?.detail.length);
    setResualDatamanagementDetail(resual?.detail);
  };

  const getUserInGroup = async () => {
    const resual = await DatamanagementServiceOa(apiBaseUrlDebt).getUserInGroupDatamanagement();
    if (resual) {
      setUserList(resual);
    }
  };

  const getProductCode = async () => {
    const resual: any = await DatamanagementServiceOa(apiBaseUrlDebt).getProductCode();

    await resual.map((x: any, index: number) => {
      if (x.loanType === "LOAN") {
        getDetailProductLoan.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      } else if (x.loanType === "Card Link") {
        getDetailProductCard.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      } else if (x.loanType === "OD") {
        getDetailProductOD.push({
          title: x.productDesc,
          value: x.productCode,
          key: x.productCode,
        });
      }
    });
  };

  const fnCheckstmtAmt = (e: any) => {
    setConditionStmtAmt(e);
    if (String(e) === "select") {
      setDataStmtAmtSubmit("");
    }
  };
  const fnCheckCurBal = (e: any) => {
    setConditionCurBal(e);
    if (String(e) === "select") {
      setDataCurBalSubmit("");
    }
  };

  const data: DataType[] = [
    {
      id: 1,
      name: "Loan Type",
      detail: [
        {
          name: "",
          type: "select",
          value: ["LOAN", "Card Link", "OD"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 2,
      name: "Account Status",
      detail: [
        {
          name: "",
          type: "select",
          value: ["Non-Npls", "Npls", "W/O"],
          condition: ["in"],
        },
      ],
    },
    {
      id: 3,
      name: "Product Type",
      detail: [
        {
          name: "LOAN",
          type: "select",
          value: getDetailProductLoan,
          condition: ["in"],
        },
        {
          name: "Card Link",
          type: "select",
          value: getDetailProductCard,
          condition: ["in"],
        },
        {
          name: "OD",
          type: "select",
          value: getDetailProductOD,
          condition: ["in"],
        },
      ],
    },
    {
      id: 4,
      name: "Account Level",
      detail: [
        {
          name: "Non-Npls",
          type: "select",
          value: ["P0", "P1", "SM2", "SM3"],
          condition: ["in"],
        },
        {
          name: "Npls",
          type: "select",
          value: [],
          condition: ["in"],
        },
        {
          name: "W/O",
          type: "select",
          value: [],
          condition: ["in"],
        },
      ],
    },
    {
      id: 5,
      name: "Location",
      detail: [
        {
          name: "",
          type: "select",
          value: getLocation,
          condition: ["in"],
        },
      ],
    },
    {
      id: 6,
      name: "เงินงวดค้างชำระ",
      detail: [
        {
          name: "",
          type: "input",
          value: [""],
          condition: [">", "<", "=", "!=", ">=", "<=", "between"],
        },
      ],
    },
    {
      id: 7,
      name: "Billing Cycle",
      detail: [
        {
          name: "billCycle",
          type: "select",
          value: ["10", "16", "17", "18", "20", "25", "31"],
          condition: ["in"],
        },
      ],
    },
  ];

  const onSavePage = (e: string) => {
    if (getDataLoanTypeSubmit.length === 0) {
      message.error("โปรดเลือกเงื่อนใข Loan Type");
      return true;
    }

    if (getconditionPrincipalAmount === "between") {
      if (!getFristBetweenPrincipalAmount || !getEndBetweenPrincipalAmount) {
        message.error("โปรดกรอกข้อมูลเงินต้นคงเหลือให้ครบถ้วน");
        return true;
      } else if (Number(getFristBetweenPrincipalAmount) > Number(getEndBetweenPrincipalAmount)) {
        message.error("เงินต้นคงเหลือ ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionAmount === "between") {
      if (!getFristBetweenOverduleAmount || !getEndBetweenOverduleAmount) {
        message.error("โปรดกรอกข้อมูลเงินงวดค้างชำระเหลือให้ครบถ้วน");
        return true;
      } else if (Number(getFristBetweenOverduleAmount) > Number(getEndBetweenOverduleAmount)) {
        message.error("เงินงวดค้างชำระ ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionStmtAmt === "between") {
      if (!getFristBetweenStmtAmt || !getEndBetweenStmtAmt) {
        message.error("โปรดกรอกข้อมูลยอดเรียกเก็บตาม statement");
        return true;
      } else if (Number(getFristBetweenStmtAmt) > Number(getEndBetweenStmtAmt)) {
        message.error("ยอดเรียกเก็บตาม statement ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionCurBal === "between") {
      if (!getFristBetweenCurBal || !getEndBetweenCurBal) {
        message.error("โปรดกรอกข้อมูลจำนวนการใช้เงินของบัตร ณ ปัจจุบัน");
        return true;
      } else if (Number(getFristBetweenCurBal) > Number(getEndBetweenCurBal)) {
        message.error("จำนวนการใช้เงินของบัตร ณ ปัจจุบัน ค่าเริ่มต้นควรน้อยกว่าจำนวนเงินสิ้นสุด");
        return true;
      }
    }

    if (getConditionListName === "") {
      refConditionListName.current.focus();
      message.error("กรุณากรอก Condition List Name");
      return true;
    }

    if (getValueUserList?.length === 0) {
      refValueTeam.current.focus();
      message.error("กรุณาเลือก Team");
      return true;
    }

    let fieldsInstan: any = {};
    let channel: any;
    getDataLoanTypeSubmit.length === 0
      ? (channel = null)
      : ((channel = {
          op: "in",
          type: "STRING",
          value: getDataLoanTypeSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          channel,
        }));

    let accountStatus: any;
    getDataAccountStatusSubmit.length === 0
      ? (accountStatus = null)
      : ((accountStatus = {
          op: "in",
          type: "STRING",
          value: getDataAccountStatusSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          accountStatus,
        }));

    let billCycle: any;
    getcheckValueBillingCycle.length === 0
      ? (billCycle = null)
      : ((billCycle = {
          op: "in",
          type: "STRING",
          value: getcheckValueBillingCycle,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          billCycle,
        }));

    let productCode: any;
    getcheckValueProductType.length === 0
      ? (productCode = null)
      : ((productCode = {
          op: "in",
          type: "STRING",
          value: getcheckValueProductType,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          productCode,
        }));
    let accountLevel: any;
    getcheckValueAccountLevel.length === 0
      ? (accountLevel = null)
      : ((accountLevel = {
          op: "in",
          type: "STRING",
          value: getcheckValueAccountLevel,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          accountLevel,
        }));

    let centerBranch: any;
    getDataLocationSubmit.length === 0
      ? (centerBranch = null)
      : ((centerBranch = {
          op: "in",
          type: "STRING",
          value: getDataLocationSubmit,
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          centerBranch,
        }));

    const regex = /,/i;

    let principleAmt: any;
    getconditionPrincipalAmount === "between"
      ? ((principleAmt = {
          op: getconditionPrincipalAmount,
          type: "STRING",
          value: [getFristBetweenPrincipalAmount.replace(regex, ""), getEndBetweenPrincipalAmount.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          principleAmt,
        }))
      : getDataPrincipalAmount !== "" && getDataPrincipalAmount != undefined
      ? ((principleAmt = {
          op: getconditionPrincipalAmount,
          type: "NUMBER",
          value: getDataPrincipalAmount.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          principleAmt,
        }))
      : (principleAmt = null);

    let overdueAmt: any;
    getConditionAmount === "between"
      ? ((overdueAmt = {
          op: getConditionAmount,
          type: "STRING",
          value: [getFristBetweenOverduleAmount.replace(regex, ""), getEndBetweenOverduleAmount.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          overdueAmt,
        }))
      : getDataAmountSubmit !== "" && getDataAmountSubmit != undefined
      ? ((overdueAmt = {
          op: getConditionAmount,
          type: "NUMBER",
          value: getDataAmountSubmit.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          overdueAmt,
        }))
      : (overdueAmt = null);

    let stmtAmt: any;
    getConditionStmtAmt === "between"
      ? ((stmtAmt = {
          op: getConditionStmtAmt,
          type: "STRING",
          value: [getFristBetweenStmtAmt.replace(regex, ""), getEndBetweenStmtAmt.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          stmtAmt,
        }))
      : getDataStmtAmtSubmit !== "" && getDataStmtAmtSubmit != undefined
      ? ((stmtAmt = {
          op: getConditionStmtAmt,
          type: "NUMBER",
          value: getDataStmtAmtSubmit.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          stmtAmt,
        }))
      : (stmtAmt = null);

    let curBal: any;
    getConditionCurBal === "between"
      ? ((curBal = {
          op: getConditionCurBal,
          type: "STRING",
          value: [getFristBetweenCurBal.replace(regex, ""), getEndBetweenCurBal.replace(regex, "")],
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          curBal,
        }))
      : getDataCurBalSubmit !== "" && getDataCurBalSubmit != undefined
      ? ((curBal = {
          op: getConditionCurBal,
          type: "NUMBER",
          value: getDataCurBalSubmit.replace(regex, ""),
        }),
        (fieldsInstan = {
          ...fieldsInstan,
          curBal,
        }))
      : (curBal = null);

    Modal.confirm({
      title: "คุณแน่ใจแล้วใช่ไหม",
      icon: <ExclamationCircleOutlined />,
      content: "กดยืนยันเพื่อบันทึกข้อมูล",
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk: async () => {
        if (getDataSeq >= 1) {
          const rulesCreateBasicPush = [];
          let seqPlust = getDataSeq + 1;
          let dataStringBasicUpdate: any = [];
          dataStringBasicUpdate = [
            {
              seq: seqPlust,
              days: 0,
              type: "BASIC",
              fields: fieldsInstan,
              status: "APPROVED",
              listname: getConditionListName,
              updateBy: userProfile.name,
              createBy: userProfile.id,
              createDate: createDate,
              description: getDescription,
              distribution: String(getValueUserList),
            },
          ];

          // getResualDatamanagementDetail.map((x: any) => {
          //   dataStringBasicUpdate = [x, ...dataStringBasicUpdate];
          // });
          // rulesCreateBasicPush.push({
          //   name: AssignUserNew,
          //   detail: dataStringBasicUpdate,
          // });
          console.log(dataStringBasicUpdate);
          // API update
          const resual = await DatamanagementServiceOa(apiBaseUrl).updateConditionByid(AssignUserNew, {
            name: AssignUserNew,
            detail: dataStringBasicUpdate,
          });

          if (resual === 200) {
            message.success("Success");
            push(`/debtdatamanagement/datamanagement`);
          }
        } else {
          const rulesCreateBasic = [];
          let dataStringBasic = [];
          dataStringBasic = [
            {
              seq: 1,
              days: 0,
              type: "BASIC",
              fields: fieldsInstan,
              status: "APPROVED",
              listname: getConditionListName,
              updateBy: userProfile.name,
              createBy: userProfile.id,
              createDate: createDate,
              description: getDescription,
              distribution: String(getValueUserList),
            },
          ];
          rulesCreateBasic.push({
            name: AssignUserNew,
            detail: dataStringBasic,
          });

          // API add
          const resual = await DatamanagementServiceOa(apiBaseUrl).createCondition(rulesCreateBasic);

          if (resual === 201) {
            message.success("Success");
            push(`/debtdatamanagement/datamanagement`);
          }
        }
      },
      onCancel: () => {},
    });
  };
  const onChangTreevalue = (e: any) => {
    setcheckValueProductType(e);
  };
  const checkConditionPrincipal = () => {
    if (getconditionPrincipalAmount === "select") {
      refValuePrincipalAmount.current.focus();
      message.error("โปรดเลือก Condition ของ เงินต้นคงเหลือ");
      return;
    }
  };
  const OPTIONS = data[0].detail[0].value;
  const filteredOptions = OPTIONS.filter((o: any) => !getDataLoanTypeSubmit.includes(o));
  const treeData = [
    {
      title: "เลือกทั้งหมด",
      value: "001",
      key: "001",
      children: getDataProductTypeSubmit,
    },
  ];
  const tProps = {
    treeData,
    value: getcheckValueProductType,
    onChange: onChangTreevalue,
    allowClear: true,
    treeCheckable: true,
    // treeNodeFilterProp: "",
  };
  return (
    <Row
      gutter={[
        { xs: 8, sm: 16 },
        { xs: 8, sm: 16 },
      ]}
      style={{ marginTop: "2rem" }}
    >
      <Card style={{ width: "100%", textAlign: "left" }} title="Add Condition List">
        <Form labelCol={{ span: 4 }}>
          <Form.Item label="Condition List Name :" required>
            <Input maxLength={100} ref={refConditionListName} value={getConditionListName} onChange={onChangConditionLisName} />
          </Form.Item>
          <Form.Item label="Description :">
            <TextArea rows={4} ref={refDescription} value={getDescription} onChange={onChangDescription} />
          </Form.Item>
          <Row gutter={10}>
            <Col span={2} style={{ textAlign: "left" }}>
              No.
            </Col>
            <Col span={10} style={{ textAlign: "left" }}>
              Field
            </Col>
            {/* <Col span={6} style={{ textAlign: "left" }}>
              Condition
            </Col> */}
            <Col span={12} style={{ textAlign: "left" }}>
              Value
            </Col>
          </Row>
          <br></br>
          <Row style={{ marginTop: "10px" }} gutter={10}>
            <Col span={2}>
              <Typography>{data[0].id}</Typography>
            </Col>

            <Col span={10}>
              <Input value={data[0].name} readOnly />
            </Col>
            <Col span={12}>
              <Select
                mode="multiple"
                style={{ width: " 100%" }}
                onChange={onChangeValueLoanType}
                ref={refValueLoanType}
                allowClear
                removeIcon
                showArrow={false}
              >
                {filteredOptions.map((value: any, index: any) => {
                  return (
                    <Option key={value} value={String(value)}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          {/* row 2 */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>{data[1].id}</Typography>
            </Col>
            <Col span={10}>
              <Input value={data[1].name} readOnly />
            </Col>
            <Col span={12}>
              <Select mode="multiple" style={{ width: " 100%" }} onChange={onChangAccount} ref={refValueAccountStatus}>
                {data[1].detail[0].value.map((accoutnvalue: any, index: any) => {
                  return (
                    <Option key={index} value={String(accoutnvalue)}>
                      {accoutnvalue}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          {/* row 3 */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>{data[2].id}</Typography>
            </Col>
            <Col span={10}>
              <Input value={data[2].name} readOnly />
            </Col>
            <Col span={12}>
              <TreeSelect
                treeNodeFilterProp="title"
                showArrow
                treeDefaultExpandAll
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                disabled={Object.keys(getDataLoanTypeSubmit).length === 1 && getDataLoanTypeSubmit[0] === "OD" ? true : false}
                onClick={onClickProductType}
                style={{ width: " 100%" }}
                {...tProps}
                maxTagCount={"responsive"}
              />
            </Col>
          </Row>
          {/* row 4  */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>{data[3].id}</Typography>
            </Col>
            <Col span={10}>
              <Input value={data[6].name} readOnly />
            </Col>
            <Col span={12}>
              <Select
                style={{ width: " 100%" }}
                mode="multiple"
                ref={refValueAccountLevel}
                // onClick={onClickBillingCycle}
                onChange={(e) => {
                  setcheckValueBillingCycle(e);
                }}
                value={getcheckValueBillingCycle}
                // placeholder="please select"
              >
                {getDataBillingCycleSubmit.map((x: any, index: any) => {
                  return (
                    <Option key={index} value={String(x)}>
                      {x}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          {/* row 4 */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>{data[4].id}</Typography>
            </Col>
            <Col span={10}>
              <Input value={data[3].name} readOnly />
            </Col>
            <Col span={12}>
              <Select
                style={{ width: " 100%" }}
                mode="multiple"
                ref={refValueAccountLevel}
                onClick={onClickAccountLevel}
                onChange={(e) => {
                  setcheckValueAccountLevel(e);
                }}
                value={getcheckValueAccountLevel}
                // placeholder="please select"
              >
                {getDataAccountLevelSubmit.map((x: any, index: any) => {
                  return (
                    <Option key={index} value={String(x)}>
                      {x}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          {/* row 5 */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>{data[5].id}</Typography>
            </Col>
            <Col span={10}>
              <Input value={data[4].name} readOnly />
            </Col>
            <Col span={12}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                style={{ width: " 100%" }}
                mode="multiple"
                onChange={(e) => {
                  setDataLocationSubmit(e);
                }}
                ref={refValueLocation}
              >
                {getDataMasterDataCenterOfBranch?.map((value: IMasterDataOfBranch, index: number) => {
                  return (
                    <Option key={value.team_group_id} value={String(value.team_group_id)}>
                      {value.desc_local}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          {/* row 6 */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>7</Typography>
            </Col>
            <Col span={5}>
              <Input value={"เงินต้นคงเหลือ"} readOnly />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: " 100%" }}
                onChange={(e) => setconditionPrincipalAmount(e)}
                value={getconditionPrincipalAmount}
                ref={refValuePrincipalAmount}
              >
                <Option value={"select"}>select</Option>
                {data[5].detail[0].condition.map((condition: any, index: any) => {
                  return (
                    <Option key={index} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getconditionPrincipalAmount === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenPrincipalAmount(value);
                    }}
                    value={getFristBetweenPrincipalAmount}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenPrincipalAmount(value);
                    }}
                    value={getEndBetweenPrincipalAmount}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getDataPrincipalAmount}
                  min={0}
                  onClick={checkConditionPrincipal}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    setDataPrincipalAmount(value);
                  }}
                />
              </Col>
            )}
          </Row>
          {/* row 7 */}
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>8</Typography>
            </Col>
            <Col span={5}>
              <Input value={data[5].name} readOnly />
            </Col>
            <Col span={5}>
              <Select
                style={{ width: " 100%" }}
                onChange={(e) => setConditionAmount(e)}
                defaultValue="select"
                value={getConditionAmount}
                ref={refValueConditionAmount}
              >
                <Option value={"select"}>select</Option>
                {data[5].detail[0].condition.map((condition: any, index: any) => {
                  return (
                    <Option key={index} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getConditionAmount === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenOverduleAmount(value);
                    }}
                    value={getFristBetweenOverduleAmount}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenOverduleAmount(value);
                    }}
                    value={getEndBetweenOverduleAmount}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getDataAmountSubmit}
                  min={0}
                  onClick={() => checkCondition("8")}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    setDataAmountSubmit(value);
                  }}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>9</Typography>
            </Col>
            <Col span={5}>
              <Input value={`ยอดเรียกเก็บตาม statement`} readOnly />
            </Col>
            <Col span={5}>
              <Select style={{ width: " 100%" }} onChange={(e) => fnCheckstmtAmt(e)} value={getConditionStmtAmt} ref={refValueConditionStmtAmt}>
                <Option value={"select"}>select</Option>
                {data[5].detail[0].condition.map((condition: any, index: any) => {
                  return (
                    <Option key={index} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getConditionStmtAmt === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenStmtAmt(value);
                    }}
                    value={getFristBetweenStmtAmt}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenStmtAmt(value);
                    }}
                    value={getEndBetweenStmtAmt}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getDataStmtAmtSubmit}
                  min={0}
                  onClick={() => checkCondition("9")}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    setDataStmtAmtSubmit(value);
                  }}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Row gutter={10}>
            <Col span={2}>
              <Typography>10</Typography>
            </Col>
            <Col span={5}>
              <Input value={`จำนวนการใช้เงินของบัตร ณ ปัจจุบัน`} readOnly />
            </Col>
            <Col span={5}>
              <Select style={{ width: " 100%" }} onChange={(e) => fnCheckCurBal(e)} value={getConditionCurBal} ref={refValueConditionCurBal}>
                <Option value={"select"}>select</Option>
                {data[5].detail[0].condition.map((condition: any, index: any) => {
                  return (
                    <Option key={index} value={String(condition)}>
                      {condition}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {getConditionCurBal === "between" ? (
              <>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setFristBetweenCurBal(value);
                    }}
                    value={getFristBetweenCurBal}
                  />
                </Col>
                <Col span={2}>
                  <Input value={"ถึง"} readOnly />
                </Col>
                <Col span={5}>
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator={true}
                    decimalScale={2}
                    min={0}
                    onValueChange={(values: any) => {
                      const { formattedValue, value } = values;
                      setEndBetweenCurBal(value);
                    }}
                    value={getEndBetweenCurBal}
                  />
                </Col>
              </>
            ) : (
              <Col span={12}>
                <NumberFormat
                  decimalScale={2}
                  className="ant-input"
                  thousandSeparator={true}
                  value={getDataCurBalSubmit}
                  min={0}
                  onClick={() => checkCondition("10")}
                  onValueChange={(values: any) => {
                    const { formattedValue, value } = values;
                    setDataCurBalSubmit(value);
                  }}
                />
              </Col>
            )}
          </Row>
          <br></br>
          <Form.Item label="Team" name={"team"} required>
            <Select
              //   mode="multiple"
              style={{ width: "20%" }}
              placeholder="Select Team"
              onChange={onChangeUser}
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
              value={getValueUserList}
              ref={refValueTeam}
            >
              {getTeam?.map((x: typeTeam, index: number) => {
                return (
                  <Option value={x.id} key={index + 1}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row gutter={10}>
            <Col>
              <Button style={{ backgroundColor: "#04C696", color: "white" }} onClick={() => onSavePage("Approve")}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Row>
  );
};
