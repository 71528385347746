import styled, { keyframes } from "styled-components";
import styledMap from "styled-map";

const mouse =
  "https://uploads.codesandbox.io/uploads/user/0b28efa9-b11f-4a25-b741-5366d652e167/FXqu-mouse.svg";

const scale = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
    -webkit-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
  }
`;
const scaleDown = keyframes`
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Box = styled.div`
  grid-area: ${styledMap`
    ClassComponent:class;
    HoC:hoc;
    Hook:hook;
    RenderProp:renderp;
  `};
  /* background-color: ${styledMap`
    Color-1:#e46f0a;
    Color-2:#a40b54;
    Color-3:#410936;
    Color-4:#11766d;
  `};
  color: rgba(255, 255, 255, 0.619);
  display: flex;
  flex-direction: column;
  padding: 0 0 0 2px;
  font-size: 2em; */
  /* cursor: url(${mouse}), move; */
  /* Zoom in hover animation */
  /* -webkit-animation: ${scaleDown} 0.5s linear;
  -moz-animation: ${scaleDown} 0.5s linear;
  animation: ${scaleDown} 0.5s linear;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; */
  :hover {
    /* z-index: 100; */
    /* -webkit-animation: ${scale} 0.5s linear;
    -moz-animation: ${scale} 0.5s linear;
    animation: ${scale} 0.5s linear; */
    /* transform-origin: 50% 50%;
    animation-fill-mode: forwards; */
  }
  /*End of animation */
  .mouseArea {
    height: 100%;
  }
  .mouseInfo {
    font-size: 0.65em;
    font-weight: 300;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
  }
  :hover .mouseInfo {
    visibility: visible;
    opacity: 0.75;
    transition: opacity 0.5s;
  }
`;

export default Box;
