import React, { useState } from "react";
import { ProfileOutlined } from "@ant-design/icons";
import { Button, Card, Row } from "antd";

import { AddPolicy } from "./components/AddPolicy";
import { TablePolicy } from "./components/TablePolicy";

export interface props {
  accessRole: string;
}

export const PolicyMain: React.FC<props> = ({
  accessRole,
}): React.ReactElement => {
  const [tableLoading, setTableLoading] = useState(false);
  const handleLoadingTable = (load: boolean) => {
    setTableLoading(load);
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <b style={{ color: "#0056B4", fontSize: "24px" }}>
            <ProfileOutlined
              style={{ width: 36, height: 36, color: "#0056B4" }}
            />
            {`ตั้งค่ามาตรการ`}
          </b>
        </Card>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "2rem" }}>
        <Card
          title={
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div style={{ textAlign: "left" }}>{`รายละเอียดข้อมูล`}</div>{" "}
              <div
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <AddPolicy handleLoadingTable={handleLoadingTable} />
              </div>
            </div>
          }
          style={{ width: "100%" }}
        >
          <div>
            <TablePolicy accessRole={accessRole} loading={tableLoading} />
          </div>
        </Card>
      </Row>
    </>
  );
};
