import { Badge, Button, Card, Table, Tag, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IFilter, BatchProcessFileService } from "../services/service";
import { useId24 } from "../../../drivers/id24/Id24-provider";
import { loadAppConfig } from "../../../config/app-config";
const appConfig = loadAppConfig();

import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
const DatePicker = generatePicker(dayjsGenerateConfig);

interface Props {
  fillterData: IFilter;
  userId: string;
  reloadTable: number;
  loading: boolean;
  handleLoadingTable: (load: boolean) => void;
}

export const InprogressStatusProcessFile: React.FC<Props> = ({
  fillterData,
  // userId,
  // reloadTable,
  loading,
  handleLoadingTable,
}): React.ReactElement => {
  const { tokenAccess, login, logout, id24Axios } = useId24();
  //   const apiBaseUrlUser = id24Axios(appConfig.apiBaseUrlUser);
  const apiBaseUrlDatalink = id24Axios(appConfig.apiBaseUrlDatalink);
  const { confirm } = Modal;
  const [getdataAll, setdataAll] = useState<any>([]);
  const [getdataJrId, setdataJrId] = useState<any>([]);
  const [getdataProcessId, setdataJrProcessId] = useState<any>([]);
  const [getdataJrStatus, setdataJrStatus] = useState<any>([]);
  const [getdataJrCreateAt, setdataJrCreateAt] = useState<any>([]);
  const [getdataUpdateAt, setdataUpdateAt] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const res1: any = await BatchProcessFileService(
      apiBaseUrlDatalink,
    ).getAllRunjob();
    const data = (await res1.map((x: any, row: any, index: number) => {
      const mapData = {
        jrId: String(x.jrId),
        jrProcessId: x.jrProcessId,
        jrStatus: x.jrStatus,
        jupdateAt: x.jupdateAt,
        jrMessage: x.jrMessage,
        jrCreateAt: x.jrCreateAt,
      } as any;
      return mapData;
    })) as any;
    // const dataTest: any = data.filter((x: any) => x.jrStatus === "Inprogress");
    setdataAll(data);

    let getdataJrIds: any = [];
    let getdataJrId = res1.filter(
      (ele: any, ind: any) =>
        ind === res1.findIndex((elem: any) => elem.jrId === ele.jrId),
    );
    getdataJrId.map((data: any) => {
      getdataJrIds.push({
        text: data.jrId,
        value: data.jrId,
      });
    });
    setdataJrId(getdataJrIds);

    let getdataProcessIds: any = [];
    let getdataProcessId = res1.filter(
      (ele: any, ind: any) =>
        ind ===
        res1.findIndex((elem: any) => elem.jrProcessId === ele.jrProcessId),
    );
    getdataProcessId.map((data: any) => {
      getdataProcessIds.push({
        text: data.jrProcessId,
        value: data.jrProcessId,
      });
    });
    setdataJrProcessId(getdataProcessIds);

    let getdataJrStatuss: any = [];
    let getdataJrStatus = res1.filter(
      (ele: any, ind: any) =>
        ind === res1.findIndex((elem: any) => elem.jrStatus === ele.jrStatus),
    );
    getdataJrStatus.map((data: any) => {
      getdataJrStatuss.push({
        text: data.jrStatus === "Inprogress" ? "In Progress" : data.jrStatus,
        value: data.jrStatus,
      });
    });
    setdataJrStatus(getdataJrStatuss);

    let getdataJrCreateAts: any = [];
    let getdataJrCreateAt = res1.filter(
      (ele: any, ind: any) =>
        ind ===
        res1.findIndex((elem: any) => elem.jrCreateAt === ele.jrCreateAt),
    );
    getdataJrCreateAt.map((data: any) => {
      getdataJrCreateAts.push({
        text: dayjs(data.jrCreateAt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
        value: data.jrCreateAt,
      });
    });
    setdataJrCreateAt(getdataJrCreateAts);

    let getdataUpdateAts: any = [];
    let getdataUpdateAt = res1.filter(
      (ele: any, ind: any) =>
        ind === res1.findIndex((elem: any) => elem.jupdateAt === ele.jupdateAt),
    );
    getdataUpdateAt.map((data: any) => {
      getdataUpdateAts.push({
        text: dayjs(data.jupdateAt).add(543, "year").format("DD-MM-YYYY HH:mm"),
        value: data.jupdateAt,
      });
    });
    setdataUpdateAt(getdataUpdateAts);
  }

  const handleDelete = async (e: any) => {
    if (e) {
      confirm({
        title: "คุณแน่ใจแล้วใช่ไหมที่ต้องการลบข้อมูลชุดนี้ ?",
        icon: <ExclamationCircleOutlined />,
        content: "ยืนยันการลบข้อมูล",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk() {
          const res: any = BatchProcessFileService(
            apiBaseUrlDatalink,
          ).deleteJobRun(e.jrId);
          if (res) {
            message.success("ลบข้อมูลสำเร็จ");
            fetchData();
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const Columns: any = [
    {
      key: "jrId",
      title: "Job ID",
      dataIndex: "jrId",
      sorter: (a: any, b: any) => a.jrId - b.jrId,
    },
    {
      key: "jrId",
      title: "Bath File",
      dataIndex: "jrProcessId",
      filters: getdataProcessId,
      onFilter: (value: any, record: any, key: number) =>
        record.jrProcessId.startsWith(value),
      filterSearch: true,
    },
    {
      key: "jrStatus",
      title: "Status",
      dataIndex: "jrStatus",
      filters: getdataJrStatus,
      onFilter: (value: any, record: any, key: number) =>
        record.jrStatus.startsWith(value),
      defaultFilteredValue: ["New", "Inprogress"],
      filterSearch: true,
      render: (e: any, row: number) => {
        if (e === "Inprogress") {
          return (
            <Tag key={"row"} color="orange">
              In Progress
            </Tag>
          );
        } else if (e === "New") {
          return (
            <Tag key={"row"} color="blue">
              New
            </Tag>
          );
        } else {
          return (
            <Tag key={"row"} color="green">
              Complete
            </Tag>
          );
        }
      },
    },
    { key: "jrId", title: "Description", dataIndex: "jrMessage" },
    {
      key: "jrCreateAt",
      title: "Created Date",
      dataIndex: "jrCreateAt",
      filters: getdataJrCreateAt,
      onFilter: (value: any, record: any, key: number) =>
        record.jrCreateAt.startsWith(value),
      filterSearch: true,
      render: (e: any) => {
        if (e) {
          return dayjs(e).add(543, "year").format("DD:MM:YYYY HH:mm");
        }
      },
    },
    {
      key: "jupdateAt",
      title: "Updated Date",
      dataIndex: "jupdateAt",
      filters: getdataUpdateAt,
      onFilter: (value: any, record: any, key: number) =>
        record.jupdateAt.startsWith(value),
      filterSearch: true,
      render: (e: any) => {
        if (e) {
          return dayjs(e).add(543, "year").format("DD:MM:YYYY HH:mm");
        }
      },
    },

    {
      key: "jrId",
      title: "Action",
      dataIndex: "jrId",
      render: (e: any, row: any, index: number) => {
        if (e) {
          return (
            <Button onClick={() => handleDelete(row)} type="primary" danger>
              Delete
            </Button>
          );
        }
      },
    },
  ];

  return (
    <>
      {/* <Card style={{ width: "100%" }}> */}
      <Table
        size="small"
        rowKey={(record) => record.uid}
        dataSource={getdataAll}
        columns={Columns}
        // scroll={{
        //   x: 500,
        //   // y: 400,
        // }}
      />
      {/* </Card> */}
    </>
  );
};
